import React from 'react'
import { EditableRelation } from '~/client/components/editable-relation'
import type { DetailEditableRelationProps } from '~/client/components/relation/detail/types'
import type { ZAugmentedPreferred, ZUpdatePreferred } from '~/common/schema/relation'

export const PreferredEditableRelation: React.FC<
  DetailEditableRelationProps<ZUpdatePreferred, ZAugmentedPreferred>
> = ({ data, update }) => (
  <EditableRelation
    relation={data.fundraising}
    type='FUNDRAISING'
    update={(fundraisingCryptId) => update({ fundraisingCryptId })}
  />
)
