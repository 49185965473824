// Request headers

export const authorizationHeaderKey = 'authorization'

export const sentryTransactionIdHeaderKey = 'x-transaction-id'

export const aerialDocusignJwtHeaderKey = 'x-docusign-jwt'

export const corpAuthorizationJwtHeaderKey = 'x-corp-authorization-jwt'

export const appCheckTokenHeaderKey = 'x-app-check-token'

export const appShaHeaderKey = 'x-app-sha'

// Response headers

export const isOutdatedClientResponseHeaderKey = 'x-app-outdated-client'
