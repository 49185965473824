import { useMutation } from '@tanstack/react-query'
import { FirebaseError } from 'firebase/app'
import type { User, UserCredential } from 'firebase/auth'
import React from 'react'
import { create } from 'zustand'
import { auth } from '~/client/lib/firebase'
import { attemptedProviderSessionStorageKey } from '~/client/lib/firebase-auth'
import { hooks } from '~/client/lib/hooks/dependency-injection/interface'
import { GenericErrorHandler } from '~/common/error-handler'
import { fetchRetry } from '~/common/util'
import { useOnMFARequired } from './auth-methods'
import { usePhoneAuth } from './phone-auth'

interface SignInWithRedirectErrorStore {
  redirectError: unknown
  attemptedProvider: string | undefined
}

export const useSignInWithRedirectErrorStore = create<SignInWithRedirectErrorStore>(() => ({
  redirectError: undefined,
  attemptedProvider: undefined,
}))

const fetchMicrosoftProfilePicture = async (userCredential: UserCredential) => {
  const { OAuthProvider } = await import('firebase/auth')
  const credential = OAuthProvider.credentialFromResult(userCredential)
  if (!credential) throw new Error('Could not resolve credential from login')

  try {
    // Requesting 96x96 image to match Firebase
    const response = await fetchRetry('https://graph.microsoft.com/v1.0/me/photos/96x96/$value', {
      init: {
        headers: {
          Authorization: `Bearer ${credential.accessToken}`,
        },
      },
    })

    return await response.arrayBuffer()
  } catch {
    return undefined
  }
}

/**
 * Fetch and upload MSFT profile pictures to S3, since
 * Firebase doesn't provide it as photoURL
 */
const useUploadProfilePicture = () => {
  const fetchS3ProfilePhotoWriteUrl = hooks.trpc().user.getProfilePhotoWriteUrl.useFetch()

  const uploadMicrosoftProfilePicture = React.useCallback(
    async (userCredential: UserCredential) => {
      if (userCredential.providerId !== 'microsoft.com') return

      const [photoData, { photoURL }] = await Promise.all([
        fetchMicrosoftProfilePicture(userCredential),
        fetchS3ProfilePhotoWriteUrl(),
      ])

      if (!photoData) return
      await fetchRetry(photoURL, {
        init: {
          body: photoData,
          method: 'PUT',
          headers: {
            'Content-Type': 'image/png',
          },
        },
      })
    },
    [fetchS3ProfilePhotoWriteUrl]
  )

  return React.useCallback(
    async (userCredential: UserCredential) => {
      // We don't want login to fail if this process fails
      try {
        await uploadMicrosoftProfilePicture(userCredential)
      } catch (e) {
        GenericErrorHandler.createAndCapture('Failed to upload profile picture', { cause: e })
      }
    },
    [uploadMicrosoftProfilePicture]
  )
}

const unlinkPasswordProviderIfPresent = async (user: User) => {
  const hasPasswordProvider = user.providerData.some(
    (provider) => provider.providerId === 'password'
  )
  if (hasPasswordProvider) {
    await auth.unlink(user, 'password').then(() => auth.forceTokenRefresh())
  }
}

export const useHandleOauthRedirect = (): void => {
  const sendVerificationEmailIfUnverifiedMutation = hooks
    .trpc()
    .sendVerificationEmailIfUnverified.useMutation()

  const sendNewAccountCreatedEmails = hooks.trpc().sendNewAccountCreatedEmails.useMutation()

  const { sendVerificationPhoneCode } = usePhoneAuth()
  const { onMFARequired } = useOnMFARequired({ sendVerificationPhoneCode })
  const uploadProfilePicture = useUploadProfilePicture()

  // Wrap in a mutation to notify users if something goes wrong
  const { mutate } = useMutation(async () => {
    try {
      const userCredential = await auth.getRedirectResult()
      // No sign in, and no error.
      if (!userCredential) return

      // User signed in. Will only happen once, further calls to
      // getRedirectResult will return null.

      await sendVerificationEmailIfUnverifiedMutation.mutateAsync()

      const { getAdditionalUserInfo } = await import('firebase/auth')
      // Only send email if the account is new
      const isNewUser = getAdditionalUserInfo(userCredential)?.isNewUser
      if (isNewUser) {
        await sendNewAccountCreatedEmails.mutateAsync()
      }

      useSignInWithRedirectErrorStore.setState({
        redirectError: undefined,
        attemptedProvider: undefined,
      })

      await Promise.all([
        // unlink password provider after login with redirect. This is only
        // called after a user has successfully logged using OAuth (e.g. Google or
        // Microsoft login) and is redirected back to the app.
        unlinkPasswordProviderIfPresent(userCredential.user),
        uploadProfilePicture(userCredential),
      ])
    } catch (error) {
      const attemptedProvider = window.sessionStorage.getItem(attemptedProviderSessionStorageKey)
      if (!attemptedProvider) {
        throw new Error('No attempted provider found in session storage', { cause: error })
      }
      const parsedError = auth.mightBeWrongAuthProviderError(error)
        ? await auth.mkWrongAuthProviderError({ error })
        : error
      // Store it so we can show the error next to the sign in button
      useSignInWithRedirectErrorStore.setState({
        redirectError: parsedError,
        attemptedProvider,
      })

      if (error instanceof FirebaseError && error.code === 'auth/multi-factor-auth-required') {
        await onMFARequired(error)
        // reset the error now that we've handled it
        useSignInWithRedirectErrorStore.setState({
          redirectError: undefined,
          attemptedProvider: undefined,
        })
        return
      }

      // Throw the error to report it to Sentry
      throw parsedError
    }
  })

  React.useEffect(() => {
    mutate()
  }, [mutate])
}
