import type { CryptId } from '@cryptid-module'
import { useQuery } from '@tanstack/react-query'
import { hooks } from '~/client/lib/hooks/dependency-injection/interface'
import type { RelationAutofill } from '~/common/enhance'
import { getStartDateAutofill, orderRelationAutofillDocs } from '~/common/enhance'
import { promiseAllObjectValues } from '~/common/util'

export interface AutofillMetadataString {
  type: 'document'
  value: string
  sourceCryptId: CryptId
}

export interface AutofillMetadataNumber {
  type: 'document'
  value: number
  isComputed: boolean
  sourceCryptId: CryptId
}

export interface CorpAutofill extends Pick<RelationAutofill, 'startDates'> {
  ein: AutofillMetadataString[]
  authorizedShares: AutofillMetadataNumber[]
}

const limitSuggestions = <T>(suggestions: (T[] | undefined)[]) =>
  suggestions.flat().filter(Boolean).slice(0, 3)

export const useCorpAutofill = ({
  currentDocCryptIdForAutofill,
}: { currentDocCryptIdForAutofill?: CryptId } = {}): CorpAutofill => {
  const { data: corp } = hooks.useCurrentCorp()
  const fetchMetadataSuggestions = hooks
    .trpc()
    .doc.suggestions.metadata.byDocType.useFetchWithCorp()

  // This query is mainly for controlling loading states. Caching happens in fetchMetadataSuggestions
  const { data } = useQuery({
    queryKey: ['metadata-suggestions', 'corp', corp, currentDocCryptIdForAutofill],
    queryFn: async () => {
      const docs = orderRelationAutofillDocs(
        'CORP',
        corp?.docOpts ?? [],
        currentDocCryptIdForAutofill
      )
      // It's very rare that a corp will have multiple EIN docs
      const einDocs = docs.filter((doc) => doc.type === 'IRS_EIN_ASSIGNMENT_LETTER')
      const incorporationDocs = docs.filter((doc) => doc.type === 'ARTICLES_OF_INCORPORATION')

      const suggestions = await promiseAllObjectValues({
        ein: !corp?.ein?.value
          ? Promise.all(
              einDocs.map(async (doc) => {
                const { ein } = await fetchMetadataSuggestions({ ...doc, ein: true })
                return ein?.map((suggestion) => ({
                  type: 'document' as const,
                  value: suggestion.value,
                  sourceCryptId: doc.cryptId,
                }))
              })
            )
          : [],
        authorizedShares: !corp?.authorizedShares?.value
          ? Promise.all(
              incorporationDocs.map(async (doc) => {
                const { authorized_shares: authorizedShares } = await fetchMetadataSuggestions({
                  ...doc,
                  authorizedShares: true,
                })
                return authorizedShares
                  ?.filter((suggestion) => suggestion.prob > 0.1)
                  .map((suggestion) => ({
                    type: 'document' as const,
                    value: suggestion.value,
                    isComputed: suggestion.is_computed,
                    sourceCryptId: doc.cryptId,
                  }))
              })
            )
          : [],
      })

      return promiseAllObjectValues({
        startDates: getStartDateAutofill(docs),
        ein: limitSuggestions(suggestions.ein),
        authorizedShares: limitSuggestions(suggestions.authorizedShares),
      })
    },
    enabled: !!corp,
  })

  return data ?? { startDates: [], ein: [], authorizedShares: [] }
}
