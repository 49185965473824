import { useAutoAnimate } from '@formkit/auto-animate/react'
import { Box, Center, Loader, ThemeIcon, Tooltip } from '@mantine/core'
import { IconCheck, IconX } from '@tabler/icons-react'
import type { UseMutationResult } from '@tanstack/react-query'
import React from 'react'

const BYPASS_SUCCESS_ICON_TIMEOUT = 5000

interface MutationStatusIcon {
  mutation: Pick<
    UseMutationResult<unknown, unknown, unknown, unknown>,
    'isError' | 'isLoading' | 'isSuccess'
  >
  size: number
  /** If no tooltip text is defined, no tooltip will be shown on hover.  */
  successTooltip?: string
  errorTooltip?: string

  /** If true, the success icon will fade out after a timeout, and will render
   * the children instead. */
  enableFadeOutSuccessIcon?: boolean
}

const IconComp: React.FC<MutationStatusIcon> = ({
  mutation,
  size,
  successTooltip,
  errorTooltip,
  children,
  enableFadeOutSuccessIcon = true,
}) => {
  const { isLoading, isSuccess, isError } = mutation

  const [shouldBypassSuccess, setShouldBypassSuccess] = React.useState(false)

  React.useEffect(() => {
    if (!enableFadeOutSuccessIcon) return

    if (isSuccess) {
      const timeout = setTimeout(() => {
        setShouldBypassSuccess(true)
      }, BYPASS_SUCCESS_ICON_TIMEOUT)
      return () => {
        setShouldBypassSuccess(false)
        clearTimeout(timeout)
      }
    }
  }, [isSuccess, enableFadeOutSuccessIcon])

  if (isLoading)
    return (
      <Tooltip label='Updating'>
        <Center>
          <Loader size={size} />
        </Center>
      </Tooltip>
    )
  if (isError)
    return (
      <Tooltip label={errorTooltip ?? 'An error has occurred. Please try again.'}>
        <Center>
          <ThemeIcon color='danger' display='contents'>
            <IconX size={size} />
          </ThemeIcon>
        </Center>
      </Tooltip>
    )
  if (isSuccess && !shouldBypassSuccess)
    return (
      <Tooltip label={successTooltip} disabled={!successTooltip}>
        <Center>
          <ThemeIcon color='go' display='contents'>
            <IconCheck size={size} />
          </ThemeIcon>
        </Center>
      </Tooltip>
    )
  if (children) {
    return <>{children}</>
  }
  return <Box pt={size} pr={size} />
}

export const MutationStatusIcon: React.FC<MutationStatusIcon> = (props) => {
  const [animationParent] = useAutoAnimate<HTMLDivElement>({ duration: 200 })
  return (
    <Box ref={animationParent} w={props.size} h={props.size}>
      <IconComp {...props} />
    </Box>
  )
}
