import { Input, type InputProps, Loader, createPolymorphicComponent } from '@mantine/core'
import { IconSearch } from '@tabler/icons-react'
import { forwardRef } from 'react'

interface SearchInputProps extends InputProps {
  isFetching?: boolean
}

export const SearchInputWithLoading = createPolymorphicComponent<'input', SearchInputProps>(
  forwardRef<HTMLInputElement, SearchInputProps>(({ isFetching, ...props }, ref) => (
    <Input
      {...props}
      ref={ref}
      autoFocus
      leftSection={isFetching ? <Loader size='sm' /> : <IconSearch />}
    />
  ))
)
