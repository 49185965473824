import pMap, { pMapSkip } from 'p-map'
import { type DeletedRelation, fillDeletedRelations } from '~/client/lib/deleted-relation'
import { hooks } from '~/client/lib/hooks'
import { exportRelationsXLSXBlob } from '~/client/lib/relations-xlsx'
import { mkIndexedDocs } from '~/common/doc'
import type { EnhancedDoc, EnhancedRelation } from '~/common/enhance'
import { enhanceCorp, enhanceDoc, enhanceRelation } from '~/common/enhance'
import { exhaustPaginated } from '~/common/exhausted-query'
import { RouteType } from '~/common/route/base'
import type { NavDropRouteData } from '~/common/route/top-route'
import { NavDropTopRouteShape, navDropRoutePathMap } from '~/common/route/top-route'
import type { ZAugmentedCorp, ZDocType, ZMinimalDoc } from '~/common/schema'
import { ZAugmentedRelation } from '~/common/schema/relation'
import { generateIndexPdf } from './generate-index'
import type { DownloadFileCallback, ZipFilesMap } from './util'
import {
  addZipFile,
  countDocsInFolders,
  getPathWithCounts,
  useAddDocsToFolder,
  useZipDownload,
} from './util'

const DOWNLOAD_CONCURRENCY_LIMIT = 20

interface UseZipFileMethodsRtn {
  download: (blob?: Blob, corp?: ZAugmentedCorp) => Promise<void>
  createZip: (corp?: ZAugmentedCorp) => Promise<void>
}

const usePreparationMapper = () => {
  const fetchDocs = hooks.trpc().docs.byType.useFetchWithCorp()
  const fetchRelations = hooks.trpc().relations.byType.useFetchWithCorp()
  const { addDocsToFolder } = useAddDocsToFolder()

  const addRelationToFolder = (
    enhancedRelations: (EnhancedRelation | DeletedRelation)[],
    filesMap: ZipFilesMap,
    _folderName: string
  ): DownloadFileCallback[] => {
    return enhancedRelations.flatMap((relation) => {
      const folderName = `${_folderName}/${relation.display.replaceAll('/', '-')}`
      if (relation.type === 'DELETED') {
        return {
          // using a promise so this is compatible with `pMap`
          callback: () =>
            new Promise((resolve) => {
              filesMap.set(folderName, [])
              resolve()
            }),
        }
      }
      const relationIndexList = ZAugmentedRelation.mkIndexList(relation)

      const enhancedDocs = relation.docs.map((doc) =>
        'indexList' in doc ? (doc as EnhancedDoc<ZMinimalDoc>) : enhanceDoc(doc, relationIndexList)
      )
      return addDocsToFolder({
        docs: enhancedDocs,
        filesMap,
        folderName,
        relationDisplay: relation.display,
        relationLink: relation.url,
      })
    })
  }

  const addDocsByType = async (
    docTypes: ZDocType[],
    filesMap: ZipFilesMap,
    folderName: string,
    parentIndexList: number[]
  ) => {
    const docs = await exhaustPaginated(fetchDocs, {
      types: docTypes,
      limit: Infinity,
      direction: 1,
      sortField: '_id',
    })
    return addDocsToFolder({
      docs: mkIndexedDocs(docs).map((doc) => enhanceDoc(doc, parentIndexList)),
      filesMap,
      folderName,
    })
  }

  const preparationMapper = async (
    route: NavDropRouteData,
    folderName: string,
    filesMap: ZipFilesMap,
    corp?: ZAugmentedCorp
  ): Promise<DownloadFileCallback[] | typeof pMapSkip> => {
    filesMap.set(folderName, [])
    switch (route.routeType) {
      case RouteType.CUSTOM: {
        switch (route.collection) {
          case 'unlinked':
            return pMapSkip // Special value ignored by pMap
          case 'organizational-info': {
            if (!corp) throw new Error('Corp not defined')
            const { docs } = enhanceCorp(corp)
            return addDocsToFolder({
              docs: docs.map((doc) => enhanceDoc(doc, route.indexList)),
              filesMap,
              folderName,
            })
          }
          case 'processing': {
            return addDocsByType(['PROCESSING'], filesMap, folderName, route.indexList)
          }
          // Added to avoid ESLint error
          default:
            return pMapSkip
        }
      }
      case RouteType.DOCUMENT: {
        return addDocsByType(route.docTypes, filesMap, folderName, route.indexList)
      }
      case RouteType.RELATION: {
        const relations = await exhaustPaginated(fetchRelations, {
          types: route.relationTypes,
          limit: Infinity,
          direction: 1,
          sortField: '_id',
        })
        const enhancedRelations = relations.map((r) => enhanceRelation(r))
        const enhancedAndDeletedRelations = fillDeletedRelations(enhancedRelations)
        const xlsxBlob = await exportRelationsXLSXBlob(enhancedAndDeletedRelations)
        addZipFile(filesMap, folderName, {
          fileName: `${route.display}.xlsx`,
          content: xlsxBlob,
        })
        return addRelationToFolder(enhancedAndDeletedRelations, filesMap, folderName)
      }
    }
  }

  return { preparationMapper }
}

export const useZipFileMethods = (): UseZipFileMethodsRtn => {
  const currentCorpResult = hooks.useCurrentCorp()
  const currentCorp = currentCorpResult.data

  const fetchUnlinked = hooks.trpc().doc.unlinked.useFetchWithCorp()
  const setZipFileState = hooks.useZipFileStore((state) => state.setZipFileState)
  const setData = hooks.useZipFileStore((state) => state.setData)
  const resetDocuments = hooks.useZipFileStore((state) => state.resetDocuments)
  const setPreparationProgress = hooks.useZipFileStore((state) => state.setPreparationProgress)
  const setDownloadProgress = hooks.useZipFileStore((state) => state.setDownloadProgress)

  const { download } = useZipDownload(currentCorp)
  const { addDocsToFolder } = useAddDocsToFolder()
  const { preparationMapper } = usePreparationMapper()

  const createZip = async (corp = currentCorp) => {
    resetDocuments()
    setZipFileState('loading')

    const filesMap: ZipFilesMap = new Map() // Keep a list of files associated with each folder that will be in the ZIP
    const pMapConfig = { concurrency: DOWNLOAD_CONCURRENCY_LIMIT }

    const flatRoutes = NavDropTopRouteShape.flatMap(({ display, routes }) => {
      return routes.map((route) => ({ route, folderName: `${display}/${route.display}` }))
    })
    setPreparationProgress({ total: flatRoutes.length, current: 0 })
    let preparationCurrent = 0
    // First, get all the files that need to be downloaded
    const preparationResults = await pMap(
      flatRoutes,
      async ({ route, folderName }) => {
        const result = await preparationMapper(route, folderName, filesMap, corp)
        preparationCurrent += 1
        setPreparationProgress({ total: flatRoutes.length, current: preparationCurrent })
        return result
      },
      pMapConfig
    )

    const downloadPromises: DownloadFileCallback[] = preparationResults.flat()
    const unlinkedDocs = await fetchUnlinked()
    const unlinkedRoute = navDropRoutePathMap.unlinked
    const unlinkedPromises = addDocsToFolder({
      docs: mkIndexedDocs(unlinkedDocs).map((doc) => enhanceDoc(doc, unlinkedRoute.indexList)),
      filesMap,
      folderName: `${unlinkedRoute.indexList[0]} Other Documents/${unlinkedRoute.display}`,
    })
    const allPromises = [...downloadPromises, ...unlinkedPromises]
    const downloadCount = new Set(allPromises.map((p) => p.docCryptIdStr).filter(Boolean)).size
    setDownloadProgress({ total: downloadCount, current: 0 })
    const downloaded = new Set()
    // Then, download the files with a limit on concurrency
    await pMap(
      allPromises,
      async ({ callback, docCryptIdStr }) => {
        await callback()
        if (docCryptIdStr) downloaded.add(docCryptIdStr)
        setDownloadProgress({ total: downloadCount, current: downloaded.size })
      },
      pMapConfig
    )

    const { countsMap, rootCount } = countDocsInFolders(filesMap)
    const JSZip = (await import('jszip')).default
    const zip = new JSZip()
    const documents = zip.folder(`Documents (${rootCount})`) // create root folder

    if (!documents) throw new Error('Unable to create zip folder')
    filesMap.forEach((files, folderName) => {
      const folder = documents.folder(getPathWithCounts(countsMap, folderName)) // folder() can create nested folders (separated by '/')
      files.forEach(({ fileName, content, onSuccess, onError }) => {
        if (!folder) return onError?.()
        folder.file(fileName, content)
        onSuccess?.()
      })
    })

    const pdfArrayBuffer = await generateIndexPdf({
      files: Object.entries(zip.files).map(([key, value]) => ({
        path: key,
        isDirectory: value.dir,
      })),
      corpName: corp?.name.value ?? undefined,
    })
    zip.file('Index.pdf', pdfArrayBuffer)

    const blob = await zip.generateAsync({ type: 'blob' })
    setData(blob)
    setZipFileState('loaded')
    await download(blob, corp)
  }

  return { download, createZip }
}

export type UseZipFileMethods = typeof useZipFileMethods
