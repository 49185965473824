import type { Icon } from '@tabler/icons-react'
import {
  IconBuilding,
  IconChartPie,
  IconMicroscope,
  IconReportMoney,
  IconTriangleSquareCircle,
  IconUser,
  IconUsers,
} from '@tabler/icons-react'
import { withoutIndexesPaths } from '~/common/route/without-index'
// Note: Cypress cannot import zod code
import type { CollectionPaths, CollectionRoute } from './collection'
import { collectionRouteData, collectionRouteMap } from './collection'
import type { CustomPaths, CustomRoute } from './custom'
import { customRouteData, customRouteMap } from './custom'
import type { DocViewPaths, DocViewRoute } from './doc'
import { docViewRouteData, docViewRouteMap } from './doc'

type NavDropPath = CollectionPaths | CustomPaths | DocViewPaths

const navDropRouteData = [...customRouteData, ...collectionRouteData, ...docViewRouteData]

export const navDropPaths: ReadonlyArray<NavDropPath> = navDropRouteData.map(
  (data) => data.collection
)

export const dynamicPaths = ['doc', 'relation', 'search'] as const

export const topPaths = [
  ...navDropPaths,
  ...dynamicPaths,
  ...withoutIndexesPaths,
  'processing-wizard',
] as const
export type TopPath = (typeof topPaths)[number]
export type TopPathWithoutDynamic = Exclude<TopPath, (typeof dynamicPaths)[number]>

type NavDropRoute = CollectionRoute | CustomRoute | DocViewRoute
export type NavDropRouteData = NavDropRoute & {
  indexList: number[]
  displayedIndex: string
  display: string
  topLevelTitle: string
}

const navDropRouteMap: Record<NavDropPath, NavDropRoute> = {
  ...collectionRouteMap,
  ...customRouteMap,
  ...docViewRouteMap,
}

const createNavDropDataWithIndexes = (
  route: NavDropPath[],
  parentIndex: number,
  topLevelTitle: string
): NavDropRouteData[] => {
  return route.map((path, key) => {
    const indexList = [parentIndex, key + 1]
    return {
      ...navDropRouteMap[path],
      indexList,
      displayedIndex: `${indexList.join('.')}`,
      display: `${indexList.join('.')} ${navDropRouteMap[path].name}`,
      topLevelTitle,
    }
  })
}

interface RouteShapeInput<Path extends NavDropPath> {
  title: string
  icon: Icon
  disabled: boolean
  paths: Path[]
  index: number
}

class RouteShape<Path extends NavDropPath> {
  title: string
  icon: Icon
  disabled: boolean
  routes: NavDropRouteData[]
  index: number
  display: string

  constructor({ disabled, icon, index, paths, title }: RouteShapeInput<Path>) {
    this.title = title
    this.icon = icon
    this.disabled = disabled
    this.routes = createNavDropDataWithIndexes(paths, index, title)
    this.index = index
    this.display = `${index} ${title}`
  }
}

const createNavDropRouteShape = <Path extends NavDropPath>(
  shapes: Omit<RouteShapeInput<Path>, 'index'>[]
) => shapes.map((shape, key) => new RouteShape({ ...shape, index: key + 1 }))

export const NavDropTopRouteShape = createNavDropRouteShape([
  {
    title: 'Corporate',
    icon: IconBuilding,
    disabled: false,
    paths: [
      'organizational-info',
      'board-consents',
      'stockholder-consents',
      'state-and-local',
      'insurance',
      'real-estate',
      'indemnification-agreements',
    ],
  },
  {
    title: 'Personnel',
    icon: IconUsers,
    disabled: false,
    paths: ['employee', 'officer', 'director', 'advisor', 'benefit-plan'],
  },
  {
    title: 'Equity',
    icon: IconChartPie,
    disabled: false,
    paths: ['cap-table', 'optionplan', 'valuation', 'fundraising'],
  },
  {
    title: 'Third Party',
    icon: IconUser,
    disabled: false,
    paths: ['contractor', 'customer', 'non-disclosure', 'joint-venture', 'loi-mou'],
  },
  {
    title: 'Intellectual Property',
    icon: IconMicroscope,
    disabled: false,
    paths: ['patent', 'trademark', 'copyright', 'ip-agreements'],
  },
  {
    title: 'Finance',
    icon: IconReportMoney,
    disabled: false,
    paths: ['financials', 'tax', 'debt'],
  },
  {
    title: 'Other Documents',
    icon: IconTriangleSquareCircle,
    disabled: false,
    paths: ['miscellaneous', 'non-legal', 'uncategorized', 'processing', 'unlinked'],
  },
])

export const navDropRoutePathMap: Record<NavDropPath, NavDropRouteData> =
  // eslint-disable-next-line custom-rules/prefer-map-to-object-from-entries
  Object.fromEntries(
    NavDropTopRouteShape.map((shape) =>
      shape.routes.map((route) => [route.collection, route])
    ).flat()
  )

export { RouteType } from './base'
export { relationTypeRouteMap } from './collection'
