import { pick } from 'underscore'
import type {
  DateFilterValue,
  FilterDefinitions,
  FilterValue,
} from '~/client/components/filters/filters-comp'
import { filterFieldDisplayMap } from '~/client/components/util/filter-display'
import { isTypeOption, mkTypeOptionValue } from '~/client/lib/hooks/search'
import type { SearchQueryObj } from '~/client/lib/query-params'
import type { ZDateFilter, ZDocType } from '~/common/schema'
import { ZDocFilters } from '~/common/schema'
import type { ZRelationTypeValues } from '~/common/schema/relation'
import { ZRelationFilters } from '~/common/schema/relation'
import { objectEntriesTypesafe, objectKeysTypesafe } from '~/common/util'

export const mkRelationFilterConfig = (
  relationTypeOptions: { value: ZRelationTypeValues; label: string }[],
  prefix?: 'Relation'
): FilterDefinitions => {
  const labelPrefix = prefix ? `${prefix} ` : ''
  return {
    relationPartyName: { type: 'text', label: `${labelPrefix}${filterFieldDisplayMap.party}` },
    relationStartDate: { type: 'date', label: `${labelPrefix}${filterFieldDisplayMap.startDate}` },
    relationEndDate: { type: 'date', label: `${labelPrefix}${filterFieldDisplayMap.endDate}` },
    relationType: {
      type: 'options',
      label: `${labelPrefix}${filterFieldDisplayMap.type}`,
      options: relationTypeOptions,
    },
  }
}

export const mkDocFilterConfig = (
  docTypeOptions: { value: ZDocType; label: string }[],
  prefix?: 'Doc'
): FilterDefinitions => {
  const labelPrefix = prefix ? `${prefix} ` : ''
  return {
    docTitle: { type: 'text', label: `${labelPrefix}${filterFieldDisplayMap.title}` },
    docPartyName: { type: 'text', label: `${labelPrefix}${filterFieldDisplayMap.party}` },
    docDate: { type: 'date', label: `${labelPrefix}${filterFieldDisplayMap.date}` },
    docType: {
      type: 'options',
      label: `${labelPrefix}${filterFieldDisplayMap.type}`,
      options: docTypeOptions,
    },
  }
}

const convertToDateFilter = (filter: DateFilterValue): ZDateFilter | undefined => {
  if (filter.operator === 'rangeInclusive' && filter.from && filter.to)
    return { operator: 'rangeInclusive', from: filter.from, to: filter.to }
  if (filter.operator !== 'rangeInclusive' && filter.value)
    return { operator: filter.operator, value: filter.value }
}

export const convertFiltersToFiltersArray = ({
  filterConfig,
  docFilters,
  relationFilters,
}: {
  filterConfig: FilterDefinitions
  relationFilters?: ZRelationFilters
  docFilters?: ZDocFilters
}): FilterValue[] => {
  const filters: FilterValue[] = []

  objectEntriesTypesafe(
    pick(relationFilters ?? {}, objectKeysTypesafe(ZRelationFilters.shape))
  ).forEach(([key, value]) => {
    if (!value) return
    switch (key) {
      case 'endDate':
      case 'startDate':
        {
          const field = key === 'startDate' ? 'relationStartDate' : 'relationEndDate'
          filters.push({ value, type: 'date', field, label: filterConfig[field]?.label ?? '' })
        }
        break
      case 'party':
        {
          const field = 'relationPartyName'
          filters.push({ field, label: filterConfig[field]?.label ?? '', type: 'text', value })
        }
        break
      case 'type':
        if (value.length > 0) {
          filters.push({
            field: 'relationType',
            type: 'options',
            label: filterConfig.relationType?.label ?? '',
            options: filterConfig.relationType?.options ?? [],
            value,
          })
        }
    }
  })

  objectEntriesTypesafe(pick(docFilters ?? {}, objectKeysTypesafe(ZDocFilters.shape))).forEach(
    ([key, value]) => {
      if (!value) return
      switch (key) {
        case 'date':
          {
            const field = 'docDate'
            filters.push({ field, label: filterConfig[field]?.label ?? '', type: 'date', value })
          }
          break
        case 'party':
        case 'title':
          {
            const field = key === 'party' ? 'docPartyName' : 'docTitle'
            filters.push({ type: 'text', value, field, label: filterConfig[field]?.label ?? '' })
          }
          break
        case 'type':
          if (value.length > 0) {
            filters.push({
              field: 'docType',
              label: filterConfig.docType?.label ?? '',
              type: 'options',
              options: filterConfig.docType?.options ?? [],
              value,
            })
          }
      }
    }
  )

  return filters
}

const syncQueriesWithTypes = (queries: string[], types: string[]) => {
  const filteredStrings = queries.filter(
    (query) => !isTypeOption(query) || types.some((type) => mkTypeOptionValue(type) === query)
  )
  const additionalTypes = types.filter((type) => !filteredStrings.includes(mkTypeOptionValue(type)))
  return [...filteredStrings, ...additionalTypes.map(mkTypeOptionValue)]
}

export const convertFiltersArrayToFilters = (
  filtersArray: FilterValue[],
  queryObj: SearchQueryObj
): SearchQueryObj => {
  const docFilters: SearchQueryObj['docFilters'] = {}
  const relationFilters: SearchQueryObj['relationFilters'] = {}
  const types: string[] = []

  filtersArray.forEach((filter) => {
    if (!filter.value) return
    switch (filter.field) {
      case 'docDate':
        docFilters.date = convertToDateFilter(filter.value)
        break
      case 'relationStartDate':
      case 'relationEndDate':
        {
          const field = filter.field === 'relationEndDate' ? 'endDate' : 'startDate'
          relationFilters[field] = convertToDateFilter(filter.value)
        }
        break
      case 'relationPartyName':
        relationFilters.party = filter.value
        break
      case 'docPartyName':
      case 'docTitle':
        {
          const field = filter.field === 'docPartyName' ? 'party' : 'title'
          docFilters[field] = filter.value
        }
        break
      case 'docType':
      case 'relationType':
        types.push(...filter.value)
    }
  })

  return { docFilters, relationFilters, queries: syncQueriesWithTypes(queryObj.queries, types) }
}
