import type { TextProps, TooltipProps } from '@mantine/core'
import { Text, Tooltip } from '@mantine/core'
import type { ReactNode } from 'react'

interface TextWithTooltipProps extends Omit<TextProps, 'children' | 'sx' | 'truncate'> {
  children: ReactNode
  /**
   * @default true
   * @param {('start'|'end'|true)}
   * CSS truncate overflowing text with an ellipsis.
   */
  truncate?: TextProps['truncate']
  label?: ReactNode
  position?: TooltipProps['position']
  tooltipDisabled?: boolean
}

export const TextWithTooltip: React.FC<TextWithTooltipProps> = ({
  children,
  truncate,
  label,
  position,
  tooltipDisabled,
  ...props
}) => (
  <Tooltip label={label ?? children} withinPortal position={position} disabled={tooltipDisabled}>
    <Text truncate={truncate ?? true} {...props}>
      {truncate === 'start' ? (
        /**
         * With `truncate === 'start'`, the `direction: 'rtl'` CSS property is applied
         * In this mode, punctuation (e.g. leading '/' in a filename)
         * is treated differently than text (e.g. '/' is placed at end of the filename).
         * Using `unicodeBidi: 'plaintext'` prevents this and preserves the position of "punctuation".
         *
         * See https://stackoverflow.com/a/60222979/8930600
         */
        <span style={{ unicodeBidi: 'plaintext' }}>{children}</span>
      ) : (
        children
      )}
    </Text>
  </Tooltip>
)
