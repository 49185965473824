import dynamic from 'next/dynamic'
import { StepContainer } from '~/client/components/onboarding-modal/util'

const Controls = dynamic(() =>
  import('@lottiefiles/react-lottie-player').then((mod) => mod.Controls)
)
const Player = dynamic(() => import('@lottiefiles/react-lottie-player').then((mod) => mod.Player))

export const DoneStep: React.FC = () => {
  return (
    <>
      <Player
        autoplay
        speed={0.4}
        src='/blue-confetti-animation.json'
        style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
      >
        <Controls visible={false} />
      </Player>
      <StepContainer
        title='Congratulations!'
        subtitle='You’re done'
        stepCompleted
        extraLargeTitle
        withBackButton={false}
        dataTestId='onboarding-done'
      />
    </>
  )
}
