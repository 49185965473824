import { useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

interface UseDevice {
  device: Device
}
export type Device = 'mobile' | 'tablet' | 'desktop'

export const useDeviceByWidth = (): UseDevice => {
  const { breakpoints } = useMantineTheme()
  // Mantine defines these breakpoints in "em" so we need no units
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.sm})`)
  const isTablet = useMediaQuery(
    `(min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.md})`
  )
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.md})`)

  // Media query ranges are inclusive (>= or <=)
  // checking them sequentially guarantees that only one device is detected at a time.
  // Subtracting 1 pixel from the range will cause issues with sub-pixel rendering, where no media query would match.
  if (isMobile) return { device: 'mobile' }
  if (isTablet) return { device: 'tablet' }
  if (isDesktop) return { device: 'desktop' }

  // Desktop is the default when not rendering in client
  // This prevents flashing for most users.
  // To avoid flashing for all of them, we should implement one of the strategies listed here:
  // https://www.notion.so/Responsive-React-f6dce358c79f459cb72a800f03bdbdd8?d=3da75e16337a4c21acdfc42300bc76b4#67d6a2d85b8d481ead8c25f02b877802
  return { device: 'desktop' }
}
