import { z } from 'zod'
import { ZLaunchPlan, ZPaidPlan } from '~/common/schema/subscription/plan'

const ZSubscriptionBase = z.object({
  /** This identifies the company, not a specific user from it. */
  stripeCustomerId: z.string().startsWith('cus_'),
})
type ZSubscriptionBase = z.infer<typeof ZSubscriptionBase>

export const ZSubscriptionLaunch = ZSubscriptionBase.extend({
  /** All companies default to the 'Launch' plan. If a company in a paid plan
   *  cancels their subscription, they revert to the 'Launch' plan. */
  plan: ZLaunchPlan,
})
export type ZSubscriptionLaunch = z.infer<typeof ZSubscriptionLaunch>

export const ZSubscriptionPaid = ZSubscriptionBase.extend({
  plan: ZPaidPlan,
  stripeSubscriptionId: z.string().startsWith('sub_'),
  /** Subscriptions will only have a single item. This value is useful to update
   *  the subscription billing interval, and manage upgrades. */
  stripeSubscriptionItemId: z.string().startsWith('si_'),
  /** Status of the subscription, considering that we will revert to the
   * 'Launch' plan if the subscription is canceled, incomplete_expired, paused
   *  or unpaid.
   * Expected values are ['active', 'incomplete', 'past_due', 'trialing'], but
   * we accept any string to prevent bugs on prod if Stripe adds new statuses
   *
   *  https://docs.stripe.com/api/subscriptions/object#subscription_object-status */
  stripeSubscriptionStatus: z.string(),
  /** Subscriptions do not cancel immediately. They will be effective at the end
   *  of the billing period, when the subscription cannot be renewed. */
  cancelAtPeriodEnd: z.boolean().optional(),
})
export type ZSubscriptionPaid = z.infer<typeof ZSubscriptionPaid>

export const ZSubscription = z.union([ZSubscriptionLaunch, ZSubscriptionPaid])
export type ZSubscription = z.infer<typeof ZSubscription>
