import type { BreadcrumbItem } from '~/client/components/util'
import { useCorpCryptIdSafe } from '~/client/lib/hooks/corp'
import { useCurrentTopPathFromUrl, useRouteDataFromTopPath } from '~/client/lib/hooks/route-data'
import type { TopPath } from '~/common/route/top-route'
import { adminAndSettingsRoutes } from '~/common/route/without-index'

const isAdminAndSettingsPath = (
  path: string
): path is (typeof adminAndSettingsRoutes)['paths'][number]['path'] => {
  return adminAndSettingsRoutes.paths.find((p) => p.path === path) !== undefined
}
// TODO: Handle relation breadcrumbs here as well (they are different)

const pathsWithoutBreadcrumbs = [
  'dashboard',
  'processing-wizard',
  'doc',
  'relation',
  'search',
] as const satisfies readonly TopPath[]

export const useBreadcrumbs = (): BreadcrumbItem[] | undefined => {
  const { mkCurrentCorpRoute } = useCorpCryptIdSafe() ?? {}
  const currentTopPath = useCurrentTopPathFromUrl()
  const routeData = useRouteDataFromTopPath(currentTopPath)

  if (!currentTopPath || !mkCurrentCorpRoute || !routeData) return undefined

  if (pathsWithoutBreadcrumbs.includes(currentTopPath)) {
    return undefined
  }

  if (isAdminAndSettingsPath(currentTopPath)) {
    return [{ linkTitle: 'Admin & Settings' }, { linkTitle: routeData.name, current: true }]
  }

  const currentLinkTitle = 'display' in routeData ? routeData.display : routeData.name
  return [
    { linkTitle: 'Dashboard', link: mkCurrentCorpRoute('dashboard') },
    { linkTitle: currentLinkTitle, current: true },
  ]
}
