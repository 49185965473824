import { Center, Container, Group, Stack, Text, ThemeIcon, Title } from '@mantine/core'
import { IconFlag, IconZoomMoney } from '@tabler/icons-react'
import React from 'react'
import { useMultiDocModalStore } from '~/client/components/multi-doc-drop/state'
import { theme } from '~/client/lib/theme'

const iconSize = 64

const IconRelax: React.FC<{ size: number }> = ({ size }) => {
  return (
    <svg
      width={`${size}pt`}
      height={`${size}pt`}
      version='1.1'
      viewBox='0 0 1200 1200'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill={theme.colors.gray[6]}
        d='m813.21 899.53h-431.24l-311.92 148.15 20.496 38.449 274.32-119.32 157.98 84.336-190.21 90.684h99.191l143.36-64.906 124.86 64.906h100.86l-174.23-96.012 166.25-89.676 43.754-4.2617 302.07-357.6-52.309-11.746zm-234.84 126.53-137.71-75.266h286.61zm333.84-122.51 105.55 238.27-47.242 0.14453-89.543-201.59zm-729.6 57.445s-126.64 61.703-126.79 61.754c-9.8164 3.6016-19.691-26.93-21.602-33.266-12.648-42.238 32.977-73.609 61.078-95.773 68.594-54.156 151.3-104.35 233.65-134.24 23.281-8.4609 47.496-14.676 72.238-16.824 44.484-3.8281 89.207-4.7891 133.82-5.0039 52.621-0.25391 105.04 0.57422 157.59 3.6133 14.508 0.82812 29.16 1.1055 43.559-1.3672 18.395-3.2148 36.238-9.2031 52.992-17.41 13.391-6.5625 24.875-14.914 36.887-23.566l39.504-28.812 90.719 23.461-153.71 168.66h-420.57zm420.3-675.23-512.35 204.95 444.05-433.38h628.18l-513.95 208.16 166.5 404.58-37.344 23.461zm342.65 210.3c44.242 0 80.062 35.832 80.062 80.027 0 44.23-35.82 80.074-80.062 80.074-44.207 0-80.027-35.844-80.027-80.074 0-44.184 35.82-80.027 80.027-80.027z'
      />
    </svg>
  )
}

const ValueProp: React.FC<{ icon: React.ReactNode; title: string }> = ({
  children,
  icon,
  title,
}) => {
  return (
    <Group gap='xl'>
      {icon}
      <Stack gap='xs' w={theme.other.widths.sm}>
        <Title order={3} c='primary'>
          {title}
        </Title>
        <Text>{children}</Text>
      </Stack>
    </Group>
  )
}

export const ValueProps: React.FC = () => {
  return (
    <>
      <ValueProp
        icon={
          <ThemeIcon color='urgent' size={iconSize}>
            <IconFlag size={iconSize} />
          </ThemeIcon>
        }
        title='Fundraise with confidence'
      >
        Whether you are fundraising or selling your company, due-diligence issues kill deals. Aerial
        actively identifies red flags while you are building your company so they don&apos;t derail
        deals at the last minute.
      </ValueProp>
      <ValueProp
        icon={
          <ThemeIcon color='go' size={iconSize}>
            <IconZoomMoney size={iconSize} />
          </ThemeIcon>
        }
        title='Reduce legal fees'
      >
        Aerial leverages AI to sort and organize your legal documents so your lawyer can work
        efficiently. And don&apos;t worry: lawyers love our platform because they designed it.
      </ValueProp>
      <ValueProp
        icon={
          <ThemeIcon
            size={iconSize}
            style={({ colors }) => ({ path: { fill: colors.primary[5] } })}
          >
            <IconRelax size={iconSize} />
          </ThemeIcon>
        }
        title='Stay organized with zero lift'
      >
        Just upload your documents and Aerial&apos;s AI does the rest! Aerial finds, extracts, and
        organizes information from your documents allowing you to spend time of what matters: your
        business. The more legal documents you provide Aerial, the more we can help you.
      </ValueProp>
    </>
  )
}

export const WelcomeValueProps: React.FC = () => {
  return (
    <Container m={0} maw={theme.other.widths.md}>
      <Stack align='center' gap='xl'>
        <Stack align='center' gap='xs'>
          <Title order={1} c='primary'>
            Welcome to Aerial
          </Title>
          <Title order={3} c='dimmed'>
            Become Investor Ready with Aerial’s AI
          </Title>
        </Stack>
        <Stack align='center' gap='xl'>
          <ValueProps />
        </Stack>
      </Stack>
    </Container>
  )
}

export const WelcomeValuePropsOnboardingStep: React.FC = () => {
  React.useEffect(() => {
    // Set the onboarding shown on component mount event
    useMultiDocModalStore.getState().setOnboardingShown(true)
  }, [])

  return (
    <Center h='100%'>
      <WelcomeValueProps />
    </Center>
  )
}
