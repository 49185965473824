import { Button, Group, ThemeIcon } from '@mantine/core'
import { hideNotification, showNotification } from '@mantine/notifications'
import { IconFileZip, IconFiles, IconFolder } from '@tabler/icons-react'
import React from 'react'

export const MultiDocUploadIcons: React.FC<{ isLoading?: boolean }> = ({ isLoading }) => {
  const color = isLoading ? 'gray' : 'primary'
  return (
    <Group>
      <ThemeIcon size={64} color={color}>
        <IconFiles size={64} stroke={1} />
      </ThemeIcon>
      <ThemeIcon size={64} color={color}>
        <IconFolder size={64} stroke={1} />
      </ThemeIcon>
      <ThemeIcon size={64} color={color}>
        <IconFileZip size={64} stroke={1} />
      </ThemeIcon>
    </Group>
  )
}

export const showOnboardingDismissedNotification = (onReopen: () => void): void => {
  const notificationId = Date.now().toString()
  showNotification({
    id: notificationId,
    title: 'Onboarding Tutorial Dismissed',
    message: (
      <Button
        variant='subtle'
        onClick={() => {
          hideNotification(notificationId)
          onReopen()
        }}
      >
        Reopen Tutorial
      </Button>
    ),
    autoClose: true,
    styles: () => ({
      // As Mantine does not support moving the close button with a prop, we
      // have to move the 'X' to the upper-right corner with this css.
      root: {
        alignItems: 'start',
      },
    }),
  })
}
