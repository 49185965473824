import { z } from 'zod'
import { ZAugmentedAdobeSignIntegration } from '~/common/schema/integration/adobe-sign'
import {
  ZAugmentedDocusignIntegration,
  ZAugmentedDocusignSource,
} from '~/common/schema/integration/docusign'

export const ZAugmentedIntegration = z.union([
  ZAugmentedDocusignIntegration,
  ZAugmentedAdobeSignIntegration,
])
export type ZAugmentedIntegration = z.infer<typeof ZAugmentedIntegration>

const defaultOmit = { cryptId: true, userEmail: true } as const

export const ZCreateIntegration = z.union([
  ZAugmentedDocusignIntegration.omit(defaultOmit),
  ZAugmentedAdobeSignIntegration.omit(defaultOmit),
])
export type ZCreateIntegration = z.infer<typeof ZCreateIntegration>

const integrationTypeArray = ZAugmentedIntegration.options.map(
  (integration) => integration.shape.type.value
)

export const ZIntegrationType = z.enum(
  integrationTypeArray as [(typeof integrationTypeArray)[number], ...typeof integrationTypeArray]
)
export type ZIntegrationType = z.infer<typeof ZIntegrationType>

export const ZAugmentedSource = ZAugmentedDocusignSource
export type ZAugmentedSource = z.infer<typeof ZAugmentedSource>
