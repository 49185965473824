import type { ZAugmentedIntegration } from '~/common/schema/integration'
import { objectEntriesTypesafe } from '~/common/util'
import { zenvCommon } from '~/common/zenv-common'

type IntegrationCategory =
  | 'Signature'
  | 'Cap Table'
  | 'Payroll'
  | 'Document Storage'
  | 'Legal Document Storage'
interface ImplementedIntegrationInfo {
  title: string
  blurb: string
  logoUrl: string
  isImplemented: boolean
  category: IntegrationCategory
}

interface BetaIntegrationInfo {
  title: string
  logoUrl: string
  isImplemented: false
  category: IntegrationCategory
}
export type IntegrationInfo = ImplementedIntegrationInfo | BetaIntegrationInfo

export const formatAdobeSignBlurb = (target: string): string =>
  `Imports all signed agreements for ${target}. Syncs automatically upon connection and every 24 hours.`

export const implementedIntegrationInformation: Record<
  ZAugmentedIntegration['type'],
  ImplementedIntegrationInfo
> = {
  docusign: {
    title: 'Docusign',
    blurb:
      'Imports all completed Docusign envelopes. Syncs automatically upon connection and every 24 hours.',
    logoUrl: '/logo/docusign.png',
    isImplemented: true,
    category: 'Signature',
  },
  adobeSign: {
    title: 'Adobe Acrobat Sign',
    logoUrl: '/logo/adobe-acrobat-sign.png',
    category: 'Signature',
    isImplemented: zenvCommon.NEXT_PUBLIC_ENABLE_ADOBE_SIGN,
    blurb: formatAdobeSignBlurb('either a single user or all users in the account'),
  },
}

const signatureSoftwareText =
  'Imports all signed documents. Syncs automatically upon connection and every 24 hours.'
const capTableText =
  'Imports all cap table information. Syncs automatically upon connection and every 24 hours.'
const payrollText =
  'Imports all contractors and employees. Syncs automatically upon connection and every 24 hours.'
const companyDocStorage = 'Imports all uploaded PDFs from a specified folder. Syncs in real time.'
const lawyerDocStorage =
  'Imports company records and documents. Syncs automatically upon connection and every 24 hours.'

export const defaultBlurbForCategoryMap: Record<IntegrationCategory, string> = {
  Signature: signatureSoftwareText,
  'Cap Table': capTableText,
  Payroll: payrollText,
  'Document Storage': companyDocStorage,
  'Legal Document Storage': lawyerDocStorage,
}

const betaIntegrationInformation = {
  dropboxSign: {
    title: 'Dropbox Sign',
    logoUrl: '/logo/dropbox-sign.png',
    category: 'Signature',
    isImplemented: false,
  },
  boxESign: {
    title: 'Box e-sign',
    logoUrl: '/logo/box-esign.png',
    category: 'Signature',
    isImplemented: false,
  },
  pandaDoc: {
    title: 'Panda Doc',
    logoUrl: '/logo/panda-doc.png',
    category: 'Signature',
    isImplemented: false,
  },
  signNow: {
    title: 'SignNow',
    logoUrl: '/logo/sign-now.png',
    category: 'Signature',
    isImplemented: false,
  },
  carta: {
    title: 'Carta',
    logoUrl: '/logo/carta.png',
    category: 'Cap Table',
    isImplemented: false,
  },
  shareworks: {
    title: 'Shareworks',
    logoUrl: '/logo/shareworks.png',
    category: 'Cap Table',
    isImplemented: false,
  },
  angelListStack: {
    title: 'Angel List',
    logoUrl: '/logo/angel-list.png',
    category: 'Cap Table',
    isImplemented: false,
  },
  pulley: {
    title: 'Pulley',
    logoUrl: '/logo/pulley.png',
    category: 'Cap Table',
    isImplemented: false,
  },
  gusto: {
    title: 'Gusto',
    logoUrl: '/logo/gusto.png',
    category: 'Payroll',
    isImplemented: false,
  },
  rippling: {
    title: 'Rippling',
    logoUrl: '/logo/rippling.png',
    category: 'Payroll',
    isImplemented: false,
  },
  intuitQuickbooksPayroll: {
    title: 'Intuit Quickbooks Payroll',
    logoUrl: '/logo/intuit-quickbooks.png',
    category: 'Payroll',
    isImplemented: false,
  },
  googleDrive: {
    title: 'Google Drive',
    logoUrl: '/logo/google-drive.png',
    category: 'Document Storage',
    isImplemented: false,
  },
  dropbox: {
    title: 'Dropbox',
    logoUrl: '/logo/dropbox.png',
    category: 'Document Storage',
    isImplemented: false,
  },
  box: {
    title: 'Box',
    logoUrl: '/logo/box.png',
    category: 'Document Storage',
    isImplemented: false,
  },
  clerky: {
    title: 'Clerky',
    logoUrl: '/logo/clerky.png',
    category: 'Document Storage',
    isImplemented: false,
  },
  stripeAtlas: {
    title: 'Stripe Atlas',
    logoUrl: '/logo/stripe-atlas.png',
    category: 'Document Storage',
    isImplemented: false,
  },
  iManage: {
    title: 'iManage',
    logoUrl: '/logo/i-manage.png',
    category: 'Legal Document Storage',
    isImplemented: false,
  },
  clio: {
    title: 'Clio',
    logoUrl: '/logo/clio.png',
    category: 'Legal Document Storage',
    isImplemented: false,
  },
  netDocuments: {
    title: 'NetDocuments',
    logoUrl: '/logo/netdocuments.png',
    category: 'Legal Document Storage',
    isImplemented: false,
  },
} as const satisfies Record<string, BetaIntegrationInfo>

export const integrationInformation = {
  ...implementedIntegrationInformation,
  ...betaIntegrationInformation,
}

export type IntegrationType = keyof typeof integrationInformation

// eslint-disable-next-line custom-rules/no-bad-casting-in-declaration
export const integrationsCategoryTypeMap: Record<IntegrationInfo['category'], IntegrationType[]> =
  objectEntriesTypesafe(integrationInformation).reduce(
    (acc, integration) => {
      const [name, info] = integration
      const { category } = info
      if (!acc[category]) {
        acc[category] = []
      }
      // will always be defined, as we're adding it above
      acc[category]?.push(name)
      return acc
    },
    {} as Partial<Record<IntegrationInfo['category'], IntegrationType[]>>
  ) as Record<IntegrationInfo['category'], IntegrationType[]>
