import type { TRPCClientErrorLike } from '@trpc/client'
import { TRPCClientError } from '@trpc/client'
import { TRPCError } from '@trpc/server'
import type { AppRouter } from './app-router'

/**
 * Server-side errors created using this class will show the error message to
 * the user. Should be used in mutations (query errors are generally not
 * displayed in notifications)
 *
 * @param opts safe to pass the root-cause error to `cause` for Sentry
 * logging.  It will not be shown to the user.
 */
export class TRPCNotifiableError extends TRPCError {
  public isNotifiable = true
}

/**
 * Casts error to TRPCClientErrorLike to get typed access to `data` property.
 * We are required to do this check as we collect these errors from the
 * react-query cache and they are not guaranteed to be TRPC errors.
 */
export const isTrpcClientError = (error: unknown): error is TRPCClientErrorLike<AppRouter> => {
  return error instanceof TRPCClientError
}
