import { MutationCache, QueryCache, type QueryClientConfig } from '@tanstack/react-query'
import { httpBatchLink, unstable_httpBatchStreamLink } from '@trpc/client'
import { createTRPCNext } from '@trpc/next'
import { getLimitedUseToken } from 'firebase/app-check'
import {
  ClientNotifiableError,
  handleClientMutationError,
  handleClientMutationSuccess,
  handleClientQueryError,
  notifyError,
} from '~/client/components/monitoring'
import { auth, getAppCheck } from '~/client/lib/firebase'
import { broadcastInvalidation } from '~/client/lib/hooks/invalidate-on-focus'
import { useJwtStore } from '~/client/lib/hooks/jwt-store'
import { invalidateQueries } from '~/client/lib/util'
import { GenericErrorHandler } from '~/common/error-handler'
import {
  aerialDocusignJwtHeaderKey,
  appCheckTokenHeaderKey,
  appShaHeaderKey,
  authorizationHeaderKey,
  corpAuthorizationJwtHeaderKey,
  sentryTransactionIdHeaderKey,
} from '~/common/header-key'
import { sentryTransactionId } from '~/common/sentry-transaction-id'
import { superjsonWithRegistration } from '~/common/superjson'
import type { AppRouter } from '~/common/trpc/app-router'
import { retryFn } from '~/common/util'
import { zenvCommon } from '~/common/zenv-common'
import { getMyTrpc } from './hooks/query'
import { outdatedClientLink } from './outdated-client-link'

/**
 * Adds typesafety for our custom context to operations when using custom TRPC links.
 */
interface TypedOperationContext {}

declare module '@trpc/client' {
  interface OperationContext extends TypedOperationContext {}
}

export const defaultQueryCacheSettings = {
  // unless explicitly invalidated, do not refetch data before 1 minute
  staleTime: 60 * 1000,
  // queries should be evicted from cache after 5 minutes of non use
  cacheTime: 5 * 60 * 1000,
}

/**
 * Returns jwt token, refreshing if expired or will expire in in 5 minutes.
 * This is handled automatically by `getIdToken` (see documentation in codebase,
 * not online)
 *
 * Since this is outside react-query, the error handling must be done manually.
 *
 * @returns jwt token as string or undefined if not logged in
 */
const getUserIdToken = async (): Promise<string | undefined> => {
  // We cannot use the value from the auth store here, so we retrieve it from
  // the auth object directly.  This is the only place we use it.
  const { currentUser } = await auth._loadAuthPromise
  if (!currentUser) return undefined

  try {
    // This value is cached internally by firebase.  Multiple calls to this
    // function will not result in multiple network requests to Firebase.
    return await retryFn(() => currentUser.getIdToken(), 3)
  } catch (error: unknown) {
    const sentryEventId = GenericErrorHandler.captureException({
      error,
      captureContext: { extra: { queryKey: ['idToken'] } },
    })
    return new Promise((res) => {
      notifyError({
        error,
        retry: () => res(getUserIdToken()),
        sentryEventId,
      })
    })
  }
}

const tokenGenerationErrorCopy = [
  'Our servers detected some suspicious activity coming from your IP address.',
  'To protect your account from bots, please refresh and try again.',
  `If this error persists, please contact ${zenvCommon.NEXT_PUBLIC_SUPPORT_EMAIL}`,
].join('\n')

const getAppCheckToken = async () => {
  if (!zenvCommon.NEXT_PUBLIC_ENABLE_APP_CHECK) return undefined
  try {
    const appCheck = getAppCheck()
    const tokenResult = await getLimitedUseToken(appCheck)
    return tokenResult.token
  } catch (err) {
    const tokenGenerationError = new ClientNotifiableError(tokenGenerationErrorCopy, { cause: err })
    const sentryEventId = GenericErrorHandler.captureException({
      error: tokenGenerationError,
    })
    notifyError({
      error: tokenGenerationError,
      sentryEventId,
      retry: () => window.location.reload(),
      retryText: 'Refresh Page',
      // Avoid multiple notifications when this fails repeatedly
      notificationId: 'app-check-token-generation-error',
    })
    return undefined
  }
}

const _trpc = createTRPCNext<AppRouter>({
  // Invalidate the full cache on every mutation, as recommended by KATT (trpc author).
  // This is an easy way to make sure everything is being invalidated properly, with a performance drawback.
  // https://trpc.io/docs/useContext#invalidate-full-cache-on-every-mutation
  unstable_overrides: {
    useMutation: {
      /**
       * This function is called whenever a `.useMutation` succeeds
       **/
      onSuccess: async (opts) => {
        /**
         * @note that order here matters:
         * The order here allows route changes in `onSuccess` without
         * having a flash of content change whilst redirecting.
         **/
        // Calls the `onSuccess` defined in the `useQuery()`-options:
        await opts.originalFn()
        // Invalidate all queries in the react-query cache,
        // without awaiting for the refetch to complete
        // In the global context of trpc / queryCache, these async functions will still run to completion even if not awaited
        // https://github.com/TanStack/query/blob/dec9ed34da3ea23b9d109d860ac4e6048339c70c/packages/query-core/src/queryClient.ts#L361
        if (!opts.meta.skipInvalidate) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          invalidateQueries(opts.queryClient)
        }

        // We usually use skipInvalidate when we want to perform custom invalidation logic later
        // Don't check for skipInvalidate to avoid having to add broadcastInvalidation() to those occurrences as well
        broadcastInvalidation()
      },
    },
  },
  config: ({ ctx }) => {
    /**
     * Configures the react query client inside trpc with the following criteria:
     *
     * @link https://react-query.tanstack.com/reference/QueryClient
     * @link https://tanstack.com/query/v4/docs/guides/caching
     */
    const queryClientConfig: QueryClientConfig = {
      defaultOptions: {
        queries: {
          ...defaultQueryCacheSettings,
          // Do not retrigger on window focus; but will retrigger on window refresh
          refetchOnWindowFocus: false,
          // Number of retries when first query request failed
          retry: zenvCommon.NEXT_PUBLIC_TRPC_QUERY_MAX_RETRY,
          // also note that refreshing the page will clear the in-memory cache
        },
      },
      // Global error handling for react query mutatations is done here
      // this allows us to provide a retry button in the notification
      // https://tkdodo.eu/blog/react-query-error-handling#the-global-callbacks
      mutationCache: new MutationCache({
        onError: handleClientMutationError,
        onSuccess: handleClientMutationSuccess,
      }),
      queryCache: new QueryCache({
        onError: handleClientQueryError,
      }),
    }
    // during client requests
    // https://trpc.io/docs/ssr
    // https://stackoverflow.com/a/59562136/8930600
    if (typeof window !== 'undefined') {
      const linkOptions = {
        url: '/api/trpc',
        // https://trpc.io/docs/v9/header
        // NB: header is called dynamically per request and is the recommended way to send an authorization token
        headers: async () => {
          try {
            const authorization = await getUserIdToken()
            // Don't generate appCheckToken if user is authenticated.
            // This is to avoid AppCheck from throwing errors that prevent our
            // authenticated users from making queries.
            // See https://github.com/firebase/firebase-js-sdk/issues/6708.
            const appCheckToken = !authorization ? await getAppCheckToken() : undefined
            const { aerialDocusignJwt, corpAuthJwt } = useJwtStore.getState()
            return {
              // Avoids passing { authorization: undefined } to the request's header.
              ...(authorization && { [authorizationHeaderKey]: authorization }),
              [sentryTransactionIdHeaderKey]: sentryTransactionId,
              ...(appCheckToken ? { [appCheckTokenHeaderKey]: appCheckToken } : {}),
              ...(aerialDocusignJwt ? { [aerialDocusignJwtHeaderKey]: aerialDocusignJwt } : {}),
              ...(corpAuthJwt ? { [corpAuthorizationJwtHeaderKey]: corpAuthJwt } : {}),
              [appShaHeaderKey]: zenvCommon.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
            }
          } catch (err) {
            // Make the request anyway, to have more context on Sentry when the
            // server-side call fails as well.
            GenericErrorHandler.createAndCapture(
              'The headers for the request could not be generated.',
              {
                cause: err,
              }
            )
            return {}
          }
        },
        // Request Header size is determined by headers, cookies and url length
        // Node.js max header size is 16KiB by default
        // https://nodejs.org/api/cli.html#cli_max_http_header_size_size
        //
        // From Next.js 13, the http module used by Next.js rejects the large
        // request headers with error `431: Request Header Fields Too Large` and
        // in order to prevent URL being too long in trpc requests, we need this
        // parameter.
        // https://trpc.io/docs/client/links/httpBatchLink#setting-a-maximum-url-length
        //
        // We set it to a lower value than the limit to account for the other headers and
        // cookies.
        //
        // As of 2023-12-15, the longest URL we create is in the dashboard and
        // it is ~9500 characters long. The rest of the headers and cookies have
        // a size ~2000 Kb.
        maxURLLength: 13000,
      }
      return {
        // https://trpc.io/docs/links
        links: [outdatedClientLink, unstable_httpBatchStreamLink(linkOptions)],
        transformer: superjsonWithRegistration,
        queryClientConfig,
      }
    }

    // during SSR below

    // optional: use SSG-caching for each rendered page (see caching section for more details)
    const ONE_DAY_SECONDS = 60 * 60 * 24
    ctx?.res?.setHeader('Cache-Control', `s-maxage=1, stale-while-revalidate=${ONE_DAY_SECONDS}`)

    // The server needs to know your app's full url
    const url = `https://${process.env.NEXT_PUBLIC_ALIAS_URL}/api/trpc`

    return {
      // https://trpc.io/docs/links
      links: [
        httpBatchLink({
          url,
          headers: {
            // optional - inform server that it's an ssr request
            'x-ssr': '1',
          },
        }),
      ],
      queryClientConfig,
      transformer: superjsonWithRegistration,
    }
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  // ssr: true,
})

/** You should use `hooks.trpc()` when possible so it's mockable. */
export const trpc = getMyTrpc<typeof _trpc, AppRouter>(_trpc)

export type Trpc = typeof trpc
