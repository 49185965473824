import { Box, Button, TextInput } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import NextLink from 'next/link'
import React from 'react'
import { z } from 'zod'
import { analytics } from '~/client/components/monitoring'
import { RoleSelectDropdown } from '~/client/components/role'
import { PermissionExplanationList } from '~/client/components/util/permission-explanation'
import { hooks, useCorpCryptId } from '~/client/lib/hooks'
import { useQueryParamState } from '~/client/lib/query-param-state'
import { openAddUserModalQueryKey } from '~/common/route/without-index'
import { ZEmailLowerCase, ZPermissionsLevel } from '~/common/schema'
import { FormModal } from './form-modal'

const ZPermissionsForm = z.object({
  userEmail: ZEmailLowerCase,
  level: ZPermissionsLevel,
})

export interface PermissionsModalProps {
  onClose?: (email: string) => void
}

const PermissionsModal: React.FC<PermissionsModalProps> = ({ onClose: _onClose }) => {
  const [opened, setOpened] = useQueryParamState(openAddUserModalQueryKey)

  const form = useForm<{ userEmail: string; level: ZPermissionsLevel }>({
    validate: zodResolver(ZPermissionsForm),
    initialValues: { userEmail: '', level: 'investor' },
    transformValues: (values) => ZPermissionsForm.parse(values),
  })

  const onClose = React.useCallback(
    async (email: string) => {
      _onClose?.(email)
      await setOpened(null)
      form.reset()
    },
    [_onClose, setOpened, form]
  )

  const roleCreate = hooks.trpc().role.create.useMutationWithCorp()
  const sendInviteEmail = hooks.trpc().sendInviteEmail.useMutationWithCorp({
    onSuccess: () => {
      showNotification({
        message: 'Invitation email sent',
        color: 'primary',
      })
    },
  })

  const textInputError = roleCreate.data?.success === false ? roleCreate.data.error : undefined
  const textInputProps = {
    ...form.getInputProps('userEmail'),
    ...(textInputError ? { error: textInputError } : {}),
  }

  return (
    <FormModal
      title='Add User'
      onSubmit={form.onSubmit(async (values) => {
        const result = await roleCreate.mutateAsync(values)
        if (!result.success) return
        await sendInviteEmail.mutateAsync({
          email: values.userEmail,
        })

        analytics.trackEventSuccess('PERMISSION_USER_ADDED', {
          invitedEmail: values.userEmail,
        })
        await onClose(values.userEmail)
      })}
      submitButtonLabel='Add Permission'
      opened={opened === '1'}
      onClose={async () => {
        await onClose(form.values.userEmail)
      }}
      isLoading={roleCreate.isLoading || sendInviteEmail.isLoading}
    >
      <TextInput
        autoFocus
        type='email'
        label='Email'
        placeholder='johndoe@example.com'
        data-testid='permissions-user-email'
        {...textInputProps}
      />
      <Box>
        <RoleSelectDropdown
          label='Role'
          data-testid='permissions-new-level'
          {...form.getInputProps('level')}
        />
        <Box mt='sm'>
          <PermissionExplanationList level={form.values.level} />
        </Box>
      </Box>
    </FormModal>
  )
}

export const PermissionsModalButton: React.FC<PermissionsModalProps> = ({ onClose }) => {
  const { mkCurrentCorpRoute } = useCorpCryptId()
  const openAddUserModalURL = mkCurrentCorpRoute('permissions', `?${openAddUserModalQueryKey}=1`)
  return (
    <>
      <Button
        size='sm'
        data-testid='permissions-open-add-modal'
        component={NextLink}
        href={openAddUserModalURL}
      >
        Add User
      </Button>
      <PermissionsModal onClose={onClose} />
    </>
  )
}
