import {
  ActionIcon,
  Button,
  Divider,
  Group,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
  Transition,
} from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { IconCheck, IconLogin, IconMail, IconUserPlus } from '@tabler/icons-react'
import React, { useState } from 'react'
import { z } from 'zod'
import { NextLinkOpt } from '~/client/components/util'
import { hooks } from '~/client/lib/hooks'
import { usePrefillEmailQueryParam } from '~/client/lib/query-params'
import { ZEmailLowerCase } from '~/common/schema'
import { mkUrlWithPrefillEmail } from '~/common/util'
import {
  AuthenticationFrame,
  EmailPasswordComp,
  GoogleSignInButton,
  MicrosoftSignInButton,
} from './util'

const EmailSigninComp: React.FC = () => {
  const [opened, setOpened] = useState<boolean>(false)
  const { emailLoginMutation } = hooks.useAuthMethods()

  return (
    <>
      <Button size='lg' onClick={() => setOpened((open) => !open)} data-testid='email-login-button'>
        <Group>
          <ActionIcon component='span' size={32}>
            <IconMail />
          </ActionIcon>
          <span>Email Login</span>
        </Group>
      </Button>
      <Transition mounted={opened} transition='scale-y' duration={400}>
        {(style) => (
          <div style={{ ...style }}>
            <EmailPasswordComp
              emailLoginSignup={emailLoginMutation}
              passwordLabel='Password'
              submitLabel='Log in'
              hasResetPasswordLink
            />
          </div>
        )}
      </Transition>
    </>
  )
}

export const Login: React.FC = () => {
  const prefillEmail = usePrefillEmailQueryParam()
  hooks.trpc().public.ping.useQuery() // warm up trpc serverless function

  return (
    <div data-testid='login'>
      <AuthenticationFrame title='Log in to Aerial' icon={<IconLogin />}>
        <GoogleSignInButton label='Google Login' email={prefillEmail} />
        <MicrosoftSignInButton label='Microsoft Login' email={prefillEmail} />
        <EmailSigninComp />
        <Divider label='OR' labelPosition='center' />
        <NextLinkOpt
          href={mkUrlWithPrefillEmail('/signup', prefillEmail)}
          data-testid='signup-link'
        >
          <Button fullWidth size='lg' variant='filled' leftSection={<IconUserPlus />}>
            Sign Up for Aerial
          </Button>
        </NextLinkOpt>
      </AuthenticationFrame>
    </div>
  )
}

const ZResetPassword = z.object({
  email: ZEmailLowerCase,
})
interface ZResetPassword extends z.infer<typeof ZResetPassword> {}

interface ResetPasswordProps {
  email?: string
}

export const ResetPassword: React.FC<ResetPasswordProps> = ({ email = '' }: ResetPasswordProps) => {
  const prefillEmail = usePrefillEmailQueryParam()
  hooks.trpc().public.ping.useQuery() // warm up trpc serverless function
  const { mutateAsync, isLoading, isSuccess, isError } = hooks
    .trpc()
    .sendPasswordResetEmail.useMutation({ meta: { skipInvalidate: true } })

  const form = useForm<ZResetPassword>({
    validate: zodResolver(ZResetPassword),
    initialValues: { email: prefillEmail ?? email },
  })

  return (
    <AuthenticationFrame title='Reset password' icon={<IconMail />}>
      <Text>
        Enter the email address for your account. We will send an email with a link to reset your
        password.
      </Text>
      <form
        onSubmit={form.onSubmit(async (data) => {
          if (isSuccess) return
          // We do this to visually show the user that we're lowercasing their email
          form.setFieldValue('email', form.values.email.toLowerCase())
          await mutateAsync(data)
        })}
      >
        <Stack>
          <TextInput
            label='Email'
            autoFocus
            placeholder='name@example.com'
            {...form.getInputProps('email')}
          />
          <Group justify='space-between'>
            <NextLinkOpt size='sm' href='/login'>
              Back to login page
            </NextLinkOpt>
            <Button
              type='submit'
              loading={isLoading}
              leftSection={
                isSuccess && (
                  <ThemeIcon color='go'>
                    <IconCheck />
                  </ThemeIcon>
                )
              }
              disabled={isLoading || isSuccess}
            >
              Reset Password
            </Button>
          </Group>
          {isSuccess && (
            <>
              <Text size='sm' c='dimmed'>
                We have emailed instructions to reset the password to{' '}
                <NextLinkOpt href={`mailto:${form.values.email}`}>{form.values.email}</NextLinkOpt>,
                provided this user exists.
              </Text>
              <Text size='sm' c='dimmed'>
                <b>Cannot find the email?</b> Check your spam folder, the email you entered, or{' '}
                <NextLinkOpt href='/signup'>create a new account</NextLinkOpt>.
              </Text>
            </>
          )}
          {isError && (
            <Text size='sm' c='danger'>
              An error occurred. Please try again.
            </Text>
          )}
        </Stack>
      </form>
    </AuthenticationFrame>
  )
}
