import { useRouter } from 'next/router'
import type { ParsedUrlQuery } from 'querystring'
import React from 'react'
import { omit, pick } from 'underscore'
import { useCorpCryptId } from '~/client/lib/hooks'
import { ZDateFilter, ZDocFilters } from '~/common/schema'
import { ZRelationFilters } from '~/common/schema/relation'
import { superjsonWithRegistration } from '~/common/superjson'
import type { ObjectEntriesTypesafe } from '~/common/util'
import { objectEntriesTypesafe, objectKeysTypesafe } from '~/common/util'

export interface SearchQueryObj {
  queries: string[]
  docFilters?: Omit<ZDocFilters, 'type'>
  relationFilters?: Omit<ZRelationFilters, 'type'>
}
interface UseSearchQueriesRtn {
  noQuery: boolean
  queryObj: SearchQueryObj
  setQueryObj: (_: SearchQueryObj) => Promise<void>
}

const parseQueries = (q: string[] | string | undefined): string[] => {
  if (!q) return []
  if (Array.isArray(q)) return q[0] ? decodeURIComponent(q[0]).split(';') : []
  return decodeURIComponent(q).split(';')
}

const queryKeys = [
  ...objectKeysTypesafe(omit(ZDocFilters.shape, 'type')).map((key) => `doc.${key}` as const),
  ...objectKeysTypesafe(omit(ZRelationFilters.shape, 'type')).map((key) => `rel.${key}` as const),
]

type Split<S extends string, D extends string> = S extends `${infer T}${D}${infer U}`
  ? [T, ...Split<U, D>]
  : [S]

const retrieveSearchQueryObjFromUrlQuery = (urlQuery: ParsedUrlQuery): SearchQueryObj => {
  const docFilters: ZDocFilters = {}
  const relationFilters: ZRelationFilters = {}
  const queries = parseQueries(urlQuery.q)
  const rawFilters = pick(urlQuery, queryKeys)

  // we use `objectEntriesTypesafe` safely here because we use `.forEach` based
  // on the keys and do nothing for unrecognized keys
  objectEntriesTypesafe(rawFilters).forEach(([key, value]) => {
    if (!value) return
    // eslint-disable-next-line custom-rules/no-bad-casting-in-declaration
    const keys = key.split('.') as Split<typeof key, '.'>

    switch (keys[1]) {
      case 'party':
      case 'title':
        if (typeof value !== 'string') return
        try {
          const decodedValue = decodeURIComponent(value)
          if (keys[0] === 'doc') {
            docFilters[keys[1]] = decodedValue
          } else {
            relationFilters[keys[1]] = decodedValue
          }
        } catch {
          // decoding could fail if the string is invalid. we want to ignore
          // invalid values in the url
        }
        break
      case 'date':
      case 'endDate':
      case 'startDate': {
        if (typeof value !== 'string') return
        try {
          const decodedValue = decodeURIComponent(value)
          const filterObject = superjsonWithRegistration.parse(decodedValue)
          const parsedFilter = ZDateFilter.parse(filterObject)
          if (keys[0] === 'doc') {
            docFilters[keys[1]] = parsedFilter
          } else {
            relationFilters[keys[1]] = parsedFilter
          }
        } catch {
          // decoding, superjson parse or zod parse could fail if the string is
          // invalid. we want to ignore invalid values in the url
        }
      }
    }
  })

  return { queries, docFilters, relationFilters }
}

const stringifyFilterEntries = (
  entry:
    | ObjectEntriesTypesafe<Omit<ZDocFilters, 'type'>>[number]
    | ObjectEntriesTypesafe<Omit<ZRelationFilters, 'type'>>[number],
  prefix: 'doc' | 'rel'
) => {
  if (!entry[1]) return []
  switch (entry[0]) {
    case 'party':
    case 'title':
      return [`${prefix}.${entry[0]}=${encodeURIComponent(entry[1])}`]
    case 'endDate':
    case 'startDate':
    case 'date':
      return [
        `${prefix}.${entry[0]}=${encodeURIComponent(
          superjsonWithRegistration.stringify(entry[1])
        )}`,
      ]
  }

  return []
}

const convertSearchQueryObjToUrlQueryString = (queryObj: SearchQueryObj) => {
  const { queries, docFilters, relationFilters } = queryObj

  // The `queries` also contain the types. We do not use a separate doc.type or
  // rel.type field for the types in order to preserve the order in which the
  // user entered the queries (both texts and types).
  const queryString = encodeURIComponent(queries.join(';'))
  // we use `objectEntriesTypesafe` safely here because we map based on the keys
  // and do nothing for unrecognized keys
  const docFilterStrings = docFilters
    ? objectEntriesTypesafe(docFilters).flatMap((filter) => stringifyFilterEntries(filter, 'doc'))
    : []
  const relationFilterStrings = relationFilters
    ? objectEntriesTypesafe(relationFilters).flatMap((filter) =>
        stringifyFilterEntries(filter, 'rel')
      )
    : []

  const queryStringArray = queryString ? [`q=${queryString}`] : []
  return queryStringArray.concat(docFilterStrings).concat(relationFilterStrings).join('&')
}

/**
 * Manages the URL search queries in search?q=...
 * Because the state is stored in the URL, this can
 * manage state without being an unstated-next container
 * @returns
 */
export const useSearchQueries = (): UseSearchQueriesRtn => {
  const router = useRouter()
  const { mkCurrentCorpRoute } = useCorpCryptId()
  const urlQuery = router.query

  const queryObj = React.useMemo(() => {
    return retrieveSearchQueryObjFromUrlQuery(urlQuery)
    // we only want this object to be re-computed when the query changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(urlQuery)])

  const queryString = convertSearchQueryObjToUrlQueryString(queryObj)
  const noQuery = queryString === ''

  const setQueryObj = React.useCallback(
    async (newQueryObj: SearchQueryObj) => {
      const newQueryString = convertSearchQueryObjToUrlQueryString(newQueryObj)
      const newRoute = mkCurrentCorpRoute(
        'search',
        `?${newQueryString !== '' ? `${newQueryString}&` : ''}searchBarFocus`
      )
      if (!router.pathname.endsWith('/search') && newQueryString === '') return
      // Prevent infinite route changes
      if (queryString === newQueryString) return
      await router.push(newRoute)
    },
    [mkCurrentCorpRoute, queryString, router]
  )

  return {
    noQuery,
    queryObj,
    setQueryObj,
  }
}
export type UseSearchQueries = typeof useSearchQueries

export const usePrefillEmailQueryParam = (): string | undefined => {
  const router = useRouter()
  const { email } = router.query
  return email instanceof Array ? email[0] : email
}

export const useSearchFocusQueryParam = (): boolean => {
  const router = useRouter()
  const { searchBarFocus } = router.query
  return searchBarFocus !== undefined
}
