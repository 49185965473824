import type { DocDetailProp } from '~/client/components/doc-detail/state'
import { useQueryS3Blob } from '~/client/lib/hooks/s3'
import { useObjectURL } from '~/client/lib/hooks/use-object-url'

interface UseDocFileProps extends Pick<DocDetailProp, 'doc' | 'isLoading'> {
  file?: File
}

interface UseDocFile {
  isLoadingPDF: boolean
  dataUrl?: string
}

export const useDocFile = ({ doc, file, isLoading }: UseDocFileProps): UseDocFile => {
  const localUrl = useObjectURL(file)

  const queryResult = useQueryS3Blob(doc)
  const queryObjectUrl = useObjectURL(queryResult.data)
  const dataUrl = localUrl ?? queryObjectUrl

  const isLoadingS3 = !!doc && queryResult.isLoading
  const isLoadingLocal = !!file && !localUrl

  return { isLoadingPDF: isLoading || isLoadingS3 || isLoadingLocal, dataUrl }
}
