import type { CryptId } from '@cryptid-module'
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Center,
  Group,
  Overlay,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from '@mantine/core'
import { IconChartPie2, IconUnlink } from '@tabler/icons-react'
import Router from 'next/router'
import React from 'react'
import { EditableRelationModal } from '~/client/components/editable-relation-modal'
import { boxShadowHover, hoverAppearDelay150msClasses } from '~/client/lib/css-util.css'
import { useCurrentCorpAuth } from '~/client/lib/hooks'
import { theme } from '~/client/lib/theme'
import { AugmentedMetadataDate } from '~/common/schema'
import type { MinimalAugmentedRelation, ZRelationTypeValues } from '~/common/schema/relation'
import { ZAugmentedRelation, typeAugmentedRelationMap } from '~/common/schema/relation'
import { nestedClickArea } from './util'
import { TextWithTooltip } from './util/text-with-tooltip'

const textStyle = {
  color: 'inherit',
  maxWidth: 240,
} as const

const RelationComp: React.FC<{
  relation?: MinimalAugmentedRelation
  onChange?: () => void
  type: ZRelationTypeValues
}> = ({ relation, onChange, type }) => {
  const { data: auth } = useCurrentCorpAuth()

  return (
    <Group
      onClick={nestedClickArea.avoid(() => (relation ? Router.push(relation.url) : onChange?.()))}
      className={`${hoverAppearDelay150msClasses.hover} ${boxShadowHover}`}
      align='stretch'
      style={{
        height: 114,
        cursor: 'pointer',
        position: 'relative',
        width: 380,
        overflow: 'clip',
        borderRadius: 10,
        border: `1px solid ${theme.colors.gray[2]}`,
        backgroundColor: 'white',
        gap: 0,
      }}
    >
      <Box
        style={{
          backgroundColor: theme.colors.gray[2],
          flex: 'none',
          overflow: 'hidden',
          position: 'relative',
          width: 114,
          minHeight: 114,
        }}
      >
        {relation && (
          <>
            {onChange && (
              <Box
                className={hoverAppearDelay150msClasses.appear}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  zIndex: 1,
                  justifyContent: 'center',
                }}
              >
                <Overlay color='#000' zIndex={2} blur={5} backgroundOpacity={0.7} />
                <Center>
                  <Tooltip withinPortal label='Change Relation'>
                    <ActionIcon
                      disabled={auth?.level === 'investor'}
                      className={nestedClickArea.cssClass}
                      onClick={onChange}
                      style={{ zIndex: 3 }}
                    >
                      <IconUnlink />
                    </ActionIcon>
                  </Tooltip>
                </Center>
              </Box>
            )}
            <Center style={{ height: '100%' }}>
              <ThemeIcon style={{ height: '100%', width: '100%' }}>
                <IconChartPie2 height='3rem' width='3rem' />
              </ThemeIcon>
            </Center>
          </>
        )}
      </Box>
      <Box>
        <Stack
          p='xs'
          style={{
            gap: theme.spacing.xs,
          }}
        >
          <Group style={{ justifyContent: 'space-between' }}>
            <Text>
              <b>{typeAugmentedRelationMap[type].display}</b>
            </Text>
            {!relation && <Badge color='urgent'>Missing</Badge>}
          </Group>
          {relation ? (
            <>
              <TextWithTooltip style={textStyle}>
                {ZAugmentedRelation.displayFn(relation)}
              </TextWithTooltip>
              <Text style={textStyle} fw='bold'>
                {/* TODO: replace with DateRange when all relations have endDate */}
                {AugmentedMetadataDate.display(relation.startDate?.value)}
              </Text>
            </>
          ) : (
            <Button style={{ width: 'fit-content' }} color='urgent'>
              Link
            </Button>
          )}
        </Stack>
      </Box>
    </Group>
  )
}

export interface EditableRelationProp {
  relation?: MinimalAugmentedRelation
  type: ZRelationTypeValues
  update: (cryptId?: CryptId | null) => Promise<unknown>
}

export const EditableRelation: React.FC<EditableRelationProp> = ({ relation, type, update }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <RelationComp relation={relation} onChange={() => setOpen((o) => !o)} type={type} />
      <EditableRelationModal
        type={type}
        opened={open}
        onClose={() => setOpen(false)}
        isLinked={(linkableRelation) =>
          !!relation && linkableRelation.cryptId.equals(relation.cryptId)
        }
        update={update}
      />
    </>
  )
}
