import type { CryptId } from '@cryptid-module'
import React from 'react'

type UseCryptIdRtn = [
  cryptId: CryptId | undefined,
  setCryptId: (newValue: CryptId | undefined) => void,
]

/**
 * Wrapper around useState for CryptId to avoid unnecessarily changing
 * state when setting state to the same id but different object reference.
 */
export const useCryptId = (initialValue?: CryptId): UseCryptIdRtn => {
  const [cryptId, _setCryptId] = React.useState<CryptId | undefined>(initialValue)

  const setCryptId = React.useCallback((newValue: CryptId | undefined) => {
    _setCryptId((current) => {
      if (!newValue) return undefined
      // This prevents changing the object reference if unchanged id
      return current?.equals(newValue) ? current : newValue
    })
  }, [])

  // The initial value can change when a doc is unlinked/deleted
  React.useEffect(() => {
    setCryptId(initialValue)
  }, [setCryptId, initialValue])

  return [cryptId, setCryptId]
}
