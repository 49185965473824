import type {
  FetchStatus,
  Mutation,
  MutationKey,
  MutationMeta,
  Query,
  QueryMeta,
} from '@tanstack/react-query'
import { TRPCClientError } from '@trpc/client'
import { defaultScrubber } from '~/client/components/monitoring/data-scrubber'
import { superjsonWithRegistration } from '~/common/superjson'
import { getErrorMessage } from '~/common/util'
import { zenvCommon } from '~/common/zenv-common'

const stringifyPretty = (input: Object) => {
  const { json } = superjsonWithRegistration.serialize(input)
  return JSON.stringify(json, null, 2)
}

export const extractFullDataToParseProperty = (error: unknown): { fullDataToParse?: string } => {
  if (error instanceof TRPCClientError && error.meta) {
    const { responseText } = error.meta
    if (zenvCommon.NEXT_PUBLIC_ENABLE_CY_STORE_LAST_JSON_PARSE_ERROR) {
      window.cyLastJsonParseErrorKey = responseText
    }

    if (typeof responseText === 'string') {
      return { fullDataToParse: responseText }
    }
  }
  return {}
}

export interface ExtraDataFromMutation {
  page: string
  /** String representation of the combination of the mutationKey property and
   * the inputs associated with it (in the way that queryKeys are stored in the
   * cache: `[mutationKey, input]`). It's stringified to make sure it does not
   * display [Object] on Sentry */
  mutationKey: string
  /** Contains the `mutation.options.mutationKey` property as an Object to prevent it from being
   * truncated in Mixpanel (strings have a limit of 255 chars) */
  mutationKeyObject: MutationKey | undefined
  /** Contains the `mutation.state.variables` property as an Object to prevent it from being
   * truncated in Mixpanel (strings have a limit of 255 chars) */
  mutationInputObject: unknown
  meta: MutationMeta | undefined
  state: {
    failureCount: number
  }
  error?: string
}

export const getExtraDataFromMutation = (
  mutation: Mutation<unknown, unknown, unknown, unknown>
): ExtraDataFromMutation => {
  const data: ExtraDataFromMutation = {
    page: typeof window !== 'undefined' ? window.location.href : '',
    mutationKey: stringifyPretty([
      ...(mutation.options.mutationKey || []),
      [defaultScrubber.scrub(mutation.state.variables)],
    ]),
    mutationKeyObject: mutation.options.mutationKey,
    mutationInputObject: defaultScrubber.scrub(mutation.state.variables),
    meta: mutation.meta,
    state: {
      failureCount: mutation.state.failureCount,
    },
  }
  if (mutation.state.error != null) {
    return { ...data, error: getErrorMessage(mutation.state.error) }
  }
  return data
}

export interface ExtraDataFromQuery {
  page: string
  queryKey: string
  meta: QueryMeta | undefined
  state: {
    failureCount: number
    dataUpdateCount: number
    dataUpdatedAt: number
    errorUpdateCount: number
    errorUpdatedAt: number
    isInvalidated: boolean
    fetchStatus: FetchStatus
  }
  error?: string
}

export const getExtraDataFromQuery = (query: Query): ExtraDataFromQuery => {
  const data = {
    page: typeof window !== 'undefined' ? window.location.href : '',
    // stringify to prevent Sentry from showing [Object]
    queryKey: stringifyPretty([query.queryKey]),
    meta: query.meta,
    state: {
      failureCount: query.state.fetchFailureCount,
      dataUpdateCount: query.state.dataUpdateCount,
      dataUpdatedAt: query.state.dataUpdatedAt,
      errorUpdateCount: query.state.errorUpdateCount,
      errorUpdatedAt: query.state.errorUpdatedAt,
      isInvalidated: query.state.isInvalidated,
      fetchStatus: query.state.fetchStatus,
    },
  }
  if (query.state.error != null) {
    return { ...data, error: getErrorMessage(query.state.error) }
  }
  return data
}

/** Retrieves the path from a URL, without query parameters or hash. For dynamic
 *  routes, this replaces the cryptId with `***`. This is useful for creating
 *  reports that group the same page for different corps. */
export const mkStaticPathFromUrl = (pathOrUrl: string): string => {
  const path = new URL(pathOrUrl, window.location.origin).pathname
  // Note that some paths might have 2 of these, like `/corp/***/doc/***`
  // cryptIds are 16 characters long
  return path
    .replace(/\/corp\/.{16}/, '/corp/***')
    .replace(/\/relation\/.{16}$/, '/relation/***')
    .replace(/\/doc\/.{16}/, '/doc/***')
}
