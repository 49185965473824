import type { AnchorProps } from '@mantine/core'
import { Anchor, useMantineTheme } from '@mantine/core'
import NextLink from 'next/link'
import * as React from 'react'

export interface NextLinkOptProps extends AnchorProps {
  href?: string
  onClick?: (e: MouseEvent) => void
  target?: string
}
export const NextLinkOpt: React.FC<NextLinkOptProps> = React.forwardRef(
  ({ href, onClick, target, children, style, ...props }, ref: React.Ref<HTMLDivElement>) => {
    const { colors } = useMantineTheme()
    return (
      <Anchor
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        component={href ? NextLink : (undefined as any)}
        href={href}
        onClick={onClick}
        style={{
          color: !(href || onClick) ? colors.inactive[7] : 'primary',
          ...(style ?? {}),
        }}
        target={target}
        ref={ref}
        {...props}
      >
        {children}
      </Anchor>
    )
  }
)
