import { useEffect, useState } from 'react'

export type ObjectURLSource = File | Blob | MediaSource

const objectURLWeakMap = new WeakMap<ObjectURLSource, string>()
/** We keep an account of each reference to the ObjectUrl while they are in the
 * cache. This allows us to only revoke an item from the cache after all
 * components have been unmounted and the longest cache time has passed */
const urlReferenceCount = new Map<string, number>()

// Default cache time to 30 seconds
const defaultCacheTime = 30_000

export const useObjectURL = (
  source: ObjectURLSource | undefined,
  cacheTime: number = defaultCacheTime // Default for 30 seconds
): string | undefined => {
  // Initializing the state with the cache prevents images from flickering on page refresh
  const [objectURL, setObjectURL] = useState<string | undefined>(
    source ? objectURLWeakMap.get(source) : undefined
  )
  useEffect(() => {
    if (!source) {
      setObjectURL(undefined)
      return
    }
    const url = objectURLWeakMap.get(source) ?? URL.createObjectURL(source)
    objectURLWeakMap.set(source, url)
    const refCount = urlReferenceCount.get(url) ?? 0
    urlReferenceCount.set(url, refCount + 1)
    setObjectURL(url)
    return () => {
      setTimeout(() => {
        const count = urlReferenceCount.get(url)
        if (count && count >= 2) {
          urlReferenceCount.set(url, count - 1)
          return
        }
        // Remove when last ref reached (count === 1)
        urlReferenceCount.delete(url)
        URL.revokeObjectURL(url)
        objectURLWeakMap.delete(source)
      }, cacheTime)
    }
  }, [source, cacheTime])
  return objectURL
}

export const getObjectURL = (source: ObjectURLSource): string | undefined => {
  return objectURLWeakMap.get(source)
}

export const getObjectURLReferenceCount = (url: string): number | undefined => {
  return urlReferenceCount.get(url)
}
