import { CryptId } from '@cryptid-module'
import type { UseQueryResult } from '@tanstack/react-query'
import type { UseTRPCMutationResult, UseTRPCQueryResult } from '@trpc/react-query/shared'
import Router, { useRouter } from 'next/router'
import React from 'react'
import { useMultiDocModalStore } from '~/client/components/multi-doc-drop/state'
import { useCurrentCorpJWT } from '~/client/lib/hooks/auth/corp-jwt'
import { hooks } from '~/client/lib/hooks/dependency-injection/interface'
import { useInvestorPreviewStore } from '~/client/lib/hooks/investor-preview'
import { optimisticMutationOptions } from '~/client/lib/hooks/optimistic-update'
import type { ZCorpAuthJwtPayload } from '~/common/jwt-payload'
import type { TopPath } from '~/common/route/top-route'
import type { ZAugmentedCorp, ZUpdateCorp } from '~/common/schema'
import { mkCorpRoute } from '~/common/util'

export interface UseCorpCryptIdRtn {
  corpCryptId: CryptId
  /** Navigate the browser to a path with the given corpId.
   *  If path param is not specified, the user will be redirected to the same collection/page with the new corpId.
   */
  switchCurrentCorp: (corpCryptId: CryptId, path?: TopPath) => void
  /**
   * Get the route for the given path prefixed with the current corpId.
   * Supports paths with and without a leading slash ('/dashboard' and 'dashboard')
   */
  mkCurrentCorpRoute: (topPath: TopPath, subPath?: string) => string
}

export type UseCorpCryptIdSafe = () => UseCorpCryptIdRtn | undefined
export const useCorpCryptIdSafe: UseCorpCryptIdSafe = () => {
  const {
    asPath,
    query: { collection, corpCryptIdStr },
  } = useRouter()

  const corpCryptId = React.useMemo(() => {
    // Mostly for type safety
    if (Array.isArray(corpCryptIdStr)) {
      throw new Error('corpCryptId cannot be array')
    }
    return corpCryptIdStr ? new CryptId(corpCryptIdStr) : undefined
  }, [corpCryptIdStr])

  const switchCurrentCorp = React.useCallback(
    (_corpCryptId: CryptId, path?: TopPath) => {
      if (path) {
        return Router.push(mkCorpRoute(_corpCryptId, path))
      }
      const match = /corp\/[A-Za-z0-9-]+\/([A-Za-z0-9-]+)\/?$/g.exec(asPath)
      // eslint-disable-next-line custom-rules/no-bad-casting-in-declaration
      const topPath = (collection ?? (match ? match[1] : 'dashboard')) as TopPath
      return Router.push(mkCorpRoute(_corpCryptId, topPath))
    },
    [asPath, collection]
  )

  if (!corpCryptId) return undefined

  const mkCurrentCorpRoute = (topPath: TopPath, subPath?: string) =>
    mkCorpRoute(corpCryptId, topPath, subPath)

  return { corpCryptId, switchCurrentCorp, mkCurrentCorpRoute }
}

export type UseCorpCryptId = () => UseCorpCryptIdRtn
export const useCorpCryptId: UseCorpCryptId = () => {
  const corpCryptIdSafe = hooks.useCorpCryptIdSafe()
  // checks if this was called in a page that doesn't have corpId in its URL
  if (!corpCryptIdSafe) {
    throw new Error('Invalid page')
  }
  return corpCryptIdSafe
}

export const useCurrentCorp = (opts?: {
  onSuccess?: (data: ZAugmentedCorp) => void
}): UseTRPCQueryResult<ZAugmentedCorp, unknown> => {
  const { corpCryptId } = useCorpCryptId()
  return hooks.trpc().corp.read.useQuery({ corpCryptId }, opts)
}

export type UseCurrentCorp = typeof useCurrentCorp

/**
 * Fetches the current Corp Auth JWT and keeps it up to date.
 * Represents the user's authorization, including client-side level downgrades
 * for preview mode (e.g. `isInvestorPreview`)
 */
export const useCurrentCorpAuth = (): Pick<
  UseQueryResult<ZCorpAuthJwtPayload | undefined, unknown>,
  'data' | 'error' | 'isLoading'
> => {
  const isInvestorPreview = useInvestorPreviewStore((state) => state.isInvestorPreview)
  const { data, error, isLoading } = useCurrentCorpJWT()

  if (error || isLoading || !data) {
    return { data, error, isLoading }
  }

  // Impersonation is client-side only, so the user will keep their permission
  // levels, but will see the UI as the level they impersonate
  const impersonatedLevel = isInvestorPreview ? ('investor' as const) : data.level

  return {
    data: { ...data, level: impersonatedLevel },
    error,
    isLoading,
  }
}

export const useResetOnboardingOnCorpChange = (): void => {
  const { corpCryptId } = useCorpCryptId()
  const { data: auth } = useCurrentCorpAuth()

  const newCorpCryptId = auth?.corpCryptIdStr !== corpCryptId.idStr

  const setOnboardingShown = useMultiDocModalStore((state) => state.setOnboardingShown)
  React.useEffect(() => {
    if (newCorpCryptId) {
      // Clear the onboarding shown state when corpId changes
      setOnboardingShown(false)
    }
  }, [newCorpCryptId, setOnboardingShown])
}

interface UseCorpDelayCallRtn {
  corpDelayCall: <A extends unknown[]>(
    func: (corp: ZAugmentedCorp, ...args: A) => Promise<void> | void
  ) => (...args: A) => Promise<void>
  loading: boolean
}

export const useCorpDelayCall = (): UseCorpDelayCallRtn => {
  const [loading, setLoading] = React.useState(false)

  // use refs so components don't require rerender to get the latest state
  const funcCall = React.useRef<((data: ZAugmentedCorp) => Promise<void>) | undefined>()
  const data = React.useRef<ZAugmentedCorp>()
  const isLoading = React.useRef<boolean>(true)

  const corpResult = useCurrentCorp({
    onSuccess: async (corp) => {
      isLoading.current = false
      data.current = corp
      const funcCallPromise = funcCall.current?.(corp)
      // reset ref so call back is not called when `useCurrentCorp` is invalidated
      funcCall.current = undefined
      await funcCallPromise
      setLoading(false)
    },
  })

  // keeps refs in sync with actual state
  React.useEffect(() => {
    isLoading.current = corpResult.isLoading
    data.current = corpResult.data
  }, [corpResult.data, corpResult.isLoading])

  return {
    loading,
    corpDelayCall:
      <A extends unknown[]>(func: (corp: ZAugmentedCorp, ...args: A) => Promise<void> | void) =>
      async (...args: A) => {
        if (isLoading.current || !data.current) {
          funcCall.current = async (corp: ZAugmentedCorp) => {
            await func(corp, ...args)
          }
          setLoading(true)
        } else {
          await func(data.current, ...args)
        }
      },
  }
}

export const useOptimisticCorpUpdate = (): UseTRPCMutationResult<
  unknown,
  unknown,
  { data: Partial<ZUpdateCorp> },
  unknown
> => {
  const utils = hooks.trpc().useContext()
  const { corpCryptId } = useCorpCryptId()

  return hooks.trpc().corp.update.useMutationWithCorp({
    ...optimisticMutationOptions(utils.corp.read, { corpCryptId }, (input, oldData) => {
      if (!oldData) {
        return
      }
      return { ...oldData, ...input.data }
    }),
  })
}
