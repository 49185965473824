import { Text } from '@mantine/core'
import { openConfirmModal } from '@mantine/modals'
import type { ConfirmModalProps } from '@mantine/modals/lib/ConfirmModal'
import type { OpenConfirmModal } from '@mantine/modals/lib/context'
import React from 'react'
import { zIndex } from '~/client/components/z-index'

interface PromptConfirmProps extends Pick<ConfirmModalProps, 'cancelProps' | 'confirmProps'> {
  title: React.ReactNode
  subtitle: React.ReactNode
  confirmText?: string | undefined
  cancelText?: string | undefined
  styles?: OpenConfirmModal['styles']
  /** Continue buttons will always be on the right (and cancel on the left).
   * We use colors to encourage or discourage the user from continuing with the operation  */
  buttonsColorVariant: 'encourage' | 'discourage'
}

const promptConfirmAsync = ({
  title,
  subtitle,
  confirmText,
  cancelText,
  confirmProps,
  cancelProps,
  styles,
  buttonsColorVariant,
}: PromptConfirmProps): Promise<boolean> => {
  return new Promise((res) => {
    openConfirmModal({
      title,
      children: (
        <Text size='sm' style={{ whiteSpace: 'pre-wrap' }}>
          {subtitle}
        </Text>
      ),
      labels: { confirm: confirmText ?? 'Confirm', cancel: cancelText ?? 'Cancel' },
      onConfirm: () => res(true),
      // `onClose` is also called when the user confirms the modal but
      // before it is called, the promise would already resolve with true as
      // `onConfirm` is called first
      onClose: () => res(false),
      confirmProps: {
        'data-autofocus': true,
        'data-testid': 'confirm-btn',
        color: buttonsColorVariant === 'encourage' ? 'primary' : 'danger',
        variant: 'outline',
        ...confirmProps,
      },
      cancelProps: {
        color: buttonsColorVariant === 'encourage' ? 'gray' : 'primary',
        variant: 'outline',
        ...cancelProps,
      },
      zIndex: zIndex.promptConfirm,
      styles,
    })
  })
}

export default promptConfirmAsync
