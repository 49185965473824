import { Notifications } from '@mantine/notifications'
import { Theme } from '~/client/components/provider/theme'
import { zIndex } from '~/client/components/z-index'
import { ImplementationProvider, hooksImpl } from '~/client/lib/hooks'
import { useHandleUserChange } from '~/client/lib/hooks/auth'

export const AppProvider: React.FC = (props) => {
  useHandleUserChange()
  return (
    <ImplementationProvider implementation={hooksImpl}>
      <Theme>
        <Notifications zIndex={zIndex.notification} />
        {props.children}
        <div id='recaptcha' style={{ display: 'none' }} />
      </Theme>
    </ImplementationProvider>
  )
}
