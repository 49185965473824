import type { TotpSecret } from 'firebase/auth'
import React from 'react'
import { auth } from '~/client/lib/firebase'
import { hooks } from '~/client/lib/hooks/dependency-injection/interface'
import { atOrThrow } from '~/common/util'
import { zenvCommon } from '~/common/zenv-common'
import { handleMFAError, useOnRequireRecentLogin } from './mfa'

export interface TotpSecretAndUri {
  secret: TotpSecret
  uri: string
}

export interface UseTotpAuth {
  enrollTotp: () => Promise<TotpSecretAndUri | undefined>
  verifyEnrollTotp: (data: { code: string; secret: TotpSecret }) => Promise<void>
  verifySignIn: (data: { code: string }) => Promise<void>
}

export const useTotpAuth = (): UseTotpAuth => {
  const multiFactorUser = hooks.useAuthStore((state) => state.multiFactorUser)
  const email = hooks.useAuthStore((state) => state.user?.email)
  const loginResolver = hooks.useAuthStore((state) => state.loginResolver)
  const { onRequireRecentLogin } = useOnRequireRecentLogin()

  // https://firebase.google.com/docs/auth/web/totp-mfa
  const enrollTotp = React.useCallback(async () => {
    if (!multiFactorUser) throw new Error('Undefined multiFactorUser')
    const secret = await auth.generateTotpSecret(multiFactorUser)
    const uri = secret.generateQrCodeUrl(
      email ?? undefined,
      zenvCommon.NEXT_PUBLIC_TOTP_ISSUER_NAME
    )
    return { secret, uri }
  }, [multiFactorUser, email])

  const verifyEnrollTotp = React.useCallback(
    async ({ code, secret }: { code: string; secret: TotpSecret }) => {
      if (!multiFactorUser) throw new Error('Undefined multiFactorUser')
      const assertion = await auth.generateEnrollTotpAssertion(secret, code)
      await multiFactorUser.enroll(assertion, 'authenticator app')
    },
    [multiFactorUser]
  )

  const verifySignIn = React.useCallback(
    async ({ code }: { code: string }) => {
      if (!loginResolver) throw new Error('Undefined loginResolver')
      // Since we only allow 1 MFA option active for each user, this array will have 1 element
      const mfaInfo = atOrThrow(loginResolver.hints, 0)
      const assertion = await auth.verifyTotp(mfaInfo.uid, code)
      await loginResolver.resolveSignIn(assertion)
    },
    [loginResolver]
  )

  return {
    enrollTotp: handleMFAError<void, TotpSecretAndUri>(enrollTotp, onRequireRecentLogin),
    verifyEnrollTotp: handleMFAError(verifyEnrollTotp, onRequireRecentLogin),
    verifySignIn: handleMFAError(verifySignIn, onRequireRecentLogin),
  }
}
