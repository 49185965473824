import { Button, Center, Divider } from '@mantine/core'
import type { UseInfiniteQueryResult } from '@tanstack/react-query'
import React from 'react'
import type { Paginated } from '~/common/schema'

export const LoadMoreInfiniteQuery: React.FC<{
  query: Pick<
    UseInfiniteQueryResult<Omit<Paginated<unknown>, 'count'>>,
    'data' | 'hasNextPage' | 'fetchNextPage' | 'isLoading' | 'isFetchingNextPage'
  >
}> = ({ query }) => {
  const firstPage = query.data?.pages[0]?.data

  if (!firstPage || firstPage.length === 0) return null

  if (!query.hasNextPage) return <Divider label='No more data' />

  return (
    <Center>
      <Button
        onClick={async () => {
          await query.fetchNextPage()
        }}
        loading={query.isFetchingNextPage || query.isLoading}
      >
        Load More
      </Button>
    </Center>
  )
}
