import React from 'react'
import type { DetailTableProps } from '~/client/components/relation/detail/types'
import type { ZUpdateRelation } from '~/common/schema/relation'
import { type ZAugmentedRelation } from '~/common/schema/relation'
import { OptionEditableRelation } from './option'
import { PreferredEditableRelation } from './preferred'

export const MetadataEditableRelation: React.FC<
  DetailTableProps<ZUpdateRelation, ZAugmentedRelation>
> = ({ data, update }) => {
  switch (data.type) {
    case 'OPTION':
      return <OptionEditableRelation data={data} update={update} />
    case 'PREFERRED':
      return <PreferredEditableRelation data={data} update={update} />
    default:
      return null
  }
}
