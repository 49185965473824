import React from 'react'
import { initAppCheck } from '~/client/lib/firebase'

// To set up Firebase App Check, follow these instructions
// https://firebase.google.com/docs/app-check/web/recaptcha-provider
export const useAppCheck = (): void => {
  React.useEffect(() => {
    initAppCheck()
  }, [])
}
