import { create } from 'zustand'
import type { SearchQueryObj } from '~/client/lib/query-params'
import type { ZDocType } from '~/common/schema'
import type { ZRelationTypeValues } from '~/common/schema/relation'

export interface FilterProps {
  mode: 'RELATION' | 'DOCUMENT' | 'RELATION_AND_DOCUMENT'
  allowedTypes?: (ZDocType | ZRelationTypeValues)[]
  queryObj: SearchQueryObj
  setQueryObj: (queryObj: SearchQueryObj) => void
}

export type FilterState =
  | {
      state: 'closed'
    }
  | { state: 'opened'; props: FilterProps }

export interface FilterStore {
  filterState: FilterState
  openModal: (props: FilterProps) => void
  closeModal: () => void
}

export const useFilterStore = create<FilterStore>()((set) => ({
  filterState: { state: 'closed' },
  closeModal: () => set({ filterState: { state: 'closed' } }),
  openModal: (props) => set({ filterState: { state: 'opened', props } }),
}))
