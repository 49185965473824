import { z } from 'zod'
import {
  AugmentedMetadataDate,
  AugmentedMetadataNumber,
  AugmentedMetadataPrice2,
  ZCryptId,
  ZParty,
  withDisplaySchema,
} from '~/common/schema'
import { util } from '~/common/schema/relation/util'
import { ZAugmentedFundraising } from './util'

const preferredUpdateShape = {
  type: z.literal('PREFERRED'),
  party: withDisplaySchema(ZParty, 'party', 'Equity Holder'),
  shares: withDisplaySchema(AugmentedMetadataNumber.optional(), 'number', 'Shares'),
  investment: withDisplaySchema(AugmentedMetadataPrice2.optional(), 'price2', 'Investment'),
  startDate: withDisplaySchema(AugmentedMetadataDate.optional(), 'date', 'Issue Date'),
  fundraisingCryptId: ZCryptId.nullish(),
}

const { fundraisingCryptId: _p, ..._preferredAugmentedShape } = preferredUpdateShape
const fundraisingOmitFields = { corpCryptId: true, docCryptIds: true, docOpts: true } as const

const preferredAugmentedShape = {
  ..._preferredAugmentedShape,
  fundraising: ZAugmentedFundraising.omit(fundraisingOmitFields).optional(),
}

export const { ZAugmented: ZAugmentedPreferred, ZUpdate: ZUpdatePreferred } = util.createSchemas(
  {
    updateShape: preferredUpdateShape,
    augmentedShape: preferredAugmentedShape,
    defaultValue: { party: { name: '' } },
  },
  {
    requiredTypes: [],
    optionalTypes: [
      'MISCELLANEOUS',
      'BOARD_CONSENT_AND_MINUTES',
      'STOCKHOLDER_CONSENT',
      'PREFERRED_STOCK_PURCHASE_AGREEMENT',
      'ADVISOR_AGREEMENT',
    ],
    display: 'Preferred Stock',
    displayFn: (obj) => `Preferred Shares ${obj.party.name}`,
    identifyingField: 'party.name',
    tooltipContentFn: (obj, shape) => [
      [shape.party.displaySchema.display, ZParty.display(obj.party)],
      [shape.startDate.displaySchema.display, AugmentedMetadataDate.display(obj.startDate?.value)],
      [shape.shares.displaySchema.display, AugmentedMetadataNumber.display(obj.shares?.value)],
      [
        shape.investment.displaySchema.display,
        AugmentedMetadataPrice2.display(obj.investment?.value),
      ],
    ],
  }
)

export interface ZUpdatePreferred extends z.infer<typeof ZUpdatePreferred> {}
export interface ZAugmentedPreferred extends z.infer<typeof ZAugmentedPreferred> {}
