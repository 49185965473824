import { partition, sortBy } from 'underscore'
import { corpRedFlagRules, getRelationDocRules, typeRedFlagRuleMap } from '~/common/red-flags'
import type {
  WithIndexNumber,
  ZAugmentedDoc,
  ZDocType,
  ZMinimalDoc,
  ZMissingDoc,
} from '~/common/schema'
import { AugmentedDocImpreciseDate, docTypeStr } from '~/common/schema'
import type { ZAugmentedRecordsRedFlag } from '~/common/schema/red-flag'
import type { ZAugmentedRelation } from '~/common/schema/relation'

export type DocTypeState = 'missing' | 'present' | 'dismissed'

interface EnhancedMissingDoc extends ZMissingDoc {
  key: ZDocType
}

interface MissingDocsData {
  missingDocs: ZMissingDoc[]
  typesWithState: [ZAugmentedDoc['type'], DocTypeState][]
  enhancedMissingDocs: EnhancedMissingDoc[]
}

const getDocTypes = (redFlags: ZAugmentedRecordsRedFlag[]) =>
  redFlags.map((redFlag) => typeRedFlagRuleMap.get(redFlag.type)?.docType).filter(Boolean)

const getMissingDocsData = (
  redFlags: ZAugmentedRecordsRedFlag[],
  docRules: { type: ZAugmentedRecordsRedFlag['type']; docType: ZDocType }[]
): MissingDocsData => {
  const [activeRedFlags, dismissedRedFlags] = partition(redFlags, (redFlag) => !redFlag.dismissed)

  const missingTypes = getDocTypes(activeRedFlags)
  const getDocTypeState = (docType: ZDocType) => {
    if (missingTypes.includes(docType)) return 'missing'
    if (getDocTypes(dismissedRedFlags).includes(docType)) return 'dismissed'
    return 'present'
  }

  const typesWithState: [ZAugmentedDoc['type'], DocTypeState][] = docRules.map((rule) => [
    rule.docType,
    getDocTypeState(rule.docType),
  ])

  const missingDocs: ZMissingDoc[] = missingTypes.map((type) => ({
    type: 'MISSING',
    docType: type,
  }))
  const enhancedMissingDocs = missingDocs.map((doc) => ({
    ...doc,
    key: doc.docType,
  }))

  return {
    missingDocs,
    typesWithState,
    enhancedMissingDocs,
  }
}

export const getRelationMissingDocsData = (
  relation: ZAugmentedRelation,
  redFlags: ZAugmentedRecordsRedFlag[]
): MissingDocsData => {
  const docRules = getRelationDocRules(relation.type)

  return getMissingDocsData(redFlags, docRules)
}

export const getCorpMissingDocsData = (redFlags: ZAugmentedRecordsRedFlag[]): MissingDocsData =>
  getMissingDocsData(redFlags, [...corpRedFlagRules])

interface DocDisplayProps extends Pick<ZMinimalDoc, 'dateImprecise' | 'title' | 'type'> {
  indexList?: number[]
}

export const docDisplay = (
  doc: DocDisplayProps,
  { short, withDate }: { short?: boolean; withDate?: boolean } = {}
): string => {
  const dateDisplay =
    withDate && doc.dateImprecise
      ? ` ${AugmentedDocImpreciseDate.display(doc.dateImprecise)?.replaceAll('/', '-')}`
      : ''
  const defaultDisplay = `${doc.title || docTypeStr(doc.type, short)}${dateDisplay}`

  if (doc.indexList) return `${doc.indexList.join('.')} ${defaultDisplay}`
  return defaultDisplay
}

/**
 * Sorts docs by `_id` and then add indexNumber, return results in sorted order
 * @param docs
 * @returns
 */
export const mkIndexedDocs = <TDoc extends ZMinimalDoc>(docs: TDoc[]): WithIndexNumber<TDoc>[] =>
  sortBy(docs, (doc) => doc.createdAt).map((doc, key) => ({
    ...doc,
    indexNumber: key + 1,
  }))

export const mkDocFileName = (
  doc: Pick<ZAugmentedDoc, 'dateImprecise' | 'title' | 'type' | 'party'>
): string => {
  const date = AugmentedDocImpreciseDate.display(doc.dateImprecise)?.replaceAll('/', '-')
  const docFields = [doc.title, date, docTypeStr(doc.type), doc.party?.name].filter(Boolean)

  return `${docFields.join(' - ')}.pdf`
}
