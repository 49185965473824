import { useAutoAnimate } from '@formkit/auto-animate/react'
import { Stack, type StackProps } from '@mantine/core'
import React from 'react'
import { theme } from '~/client/lib/theme'

export const DocsLayout: React.FC<StackProps> = ({ children, ...stackProps }) => {
  const [animationParent] = useAutoAnimate<HTMLDivElement>({ duration: 500 })

  return (
    <Stack style={{ gap: theme.spacing.xl }} ref={animationParent} {...stackProps}>
      {children}
    </Stack>
  )
}
