import type { RecaptchaVerifier } from 'firebase/auth'
import React from 'react'
import { create } from 'zustand'
import { auth } from '~/client/lib/firebase'
import { zenvCommon } from '~/common/zenv-common'

interface RecaptchaStore {
  recaptcha?: RecaptchaVerifier
  setRecaptcha: (recaptcha: RecaptchaVerifier) => void
}

export const useRecaptchaStore = create<RecaptchaStore>()((set) => ({
  recaptcha: undefined,
  setRecaptcha: (recaptcha) => set({ recaptcha }),
}))

/** Create the recaptcha verifier and add it to the store. Do not return it so
 * we use it from the zustand store. This allows us to get the updated value
 * even on a `useEffect` execution, which is what happens when we handle the
 * redirect result. Otherwise the handler would have `undefined` for the
 * function execution. */
export const useRecaptchaVerifier = (): void => {
  const { recaptcha, setRecaptcha } = useRecaptchaStore((state) => state)

  React.useEffect(() => {
    ;(async () => {
      if (!zenvCommon.NEXT_PUBLIC_ENABLE_APP_VERIFICATION) {
        await auth.disableAppVerification()
      }

      if (!recaptcha) setRecaptcha(await auth.createRecaptchaVerifier())
    })()
  }, [recaptcha, setRecaptcha])
}
