import { Loader, Select } from '@mantine/core'
import type { ComboboxParsedItem, SelectProps } from '@mantine/core'
import React from 'react'
import { sortBy } from 'underscore'
import { mkSelectFilter } from '~/client/components/util/select-filter'
import { zIndex } from '~/client/components/z-index'
import type { ZDocType } from '~/common/schema'
import { ZAugmentedDoc, docTypeStr } from '~/common/schema'

const sortByLabel = <T extends { label: string }>(data: T[]) => sortBy(data, (d) => d.label)

interface DocTypesSelectProps extends Omit<SelectProps, 'data'> {
  isLoading?: boolean
  allowedTypes: readonly ZDocType[]
  allowAllTypes?: boolean
}

const docTypesSelectFilter = mkSelectFilter(
  (search, item) =>
    item.label.toLowerCase().includes(search.toLowerCase().trim()) ||
    item.value.toLowerCase().includes(search.toLowerCase().trim()) ||
    item.label === 'Miscellaneous'
)

export const DocTypesSelect: React.FC<DocTypesSelectProps> = ({
  isLoading,
  allowedTypes,
  allowAllTypes,
  ...selectProps
}) => {
  const selectData = React.useMemo<ComboboxParsedItem[]>(() => {
    if (!allowAllTypes) {
      return sortByLabel(
        allowedTypes.map((type) => ({
          value: type,
          label: docTypeStr(type),
        }))
      )
    }

    const allowedTypesSet = new Set(allowedTypes)
    return [
      {
        group: 'Recommended Types',
        items: sortByLabel(
          allowedTypes.map((type) => ({
            value: type,
            label: docTypeStr(type),
          }))
        ),
      },
      {
        group: 'Other Types',
        items: sortByLabel(
          ZAugmentedDoc.types
            .filter((type) => !allowedTypesSet.has(type))
            .map((type) => ({
              value: type,
              label: docTypeStr(type),
            }))
        ),
      },
    ]
  }, [allowedTypes, allowAllTypes])

  const inputRef = React.useRef<HTMLInputElement>(null)

  return (
    <Select
      {...selectProps}
      searchable
      rightSection={isLoading ? <Loader size='xs' /> : undefined}
      selectFirstOptionOnChange
      data={selectData}
      filter={docTypesSelectFilter}
      ref={inputRef}
      styles={{ dropdown: { zIndex: zIndex.modal }, ...selectProps.styles }}
      onFocus={() => inputRef.current?.select()}
    />
  )
}
