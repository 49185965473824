import { Box, Button, Group, Menu, Portal, ScrollArea, Stack, Text, Tooltip } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { IconChevronDown, IconUpload } from '@tabler/icons-react'
import React from 'react'
import { partition } from 'underscore'
import { useDocDetailViewStore } from '~/client/components/doc-detail/state'
import { nestedClickArea } from '~/client/components/util'
import { MiniDoc, MiniObject } from '~/client/components/util/doc'
import type { EnhancedRelation, MissingOrDoc, RedFlagInfo } from '~/common/enhance'
import type { ZCryptId, ZDocType, ZMissingDoc } from '~/common/schema'
import { docTypeStr } from '~/common/schema'
import type { MinimalAugmentedRelation } from '~/common/schema/relation'
import { ZAugmentedRelation } from '~/common/schema/relation'

const MissingDoc: React.FC<{
  doc: ZMissingDoc
  onClick?: () => void
}> = ({ doc, onClick }) => (
  // within portal ensures the tooltip does not overflow the MiniDocDisplay
  <Tooltip label={`Missing ${docTypeStr(doc.docType)}`} withinPortal>
    <Button color='urgent' leftSection={<IconUpload />} onClick={onClick}>
      Upload Doc
    </Button>
  </Tooltip>
)

interface MiniDocDisplayProps {
  docs: MissingOrDoc[]
  subRelation?: React.ReactNode
  isActive?: boolean
  portalId?: string
  onClickMissingDoc?: (doc: ZMissingDoc) => void
  supportedTypes: ZDocType[]
  sourceCryptIds: ZCryptId[]
}

const MiniDocDisplayLayout: React.FC<
  Omit<MiniDocDisplayProps, 'onClickMissingDoc' | 'supportedTypes' | 'sourceCryptIds'>
> = ({ docs, subRelation, isActive = true, portalId, children }) => {
  const group = useElementSize()
  const container = useElementSize()

  // Sometimes the calculated group.width returns 0. In this case we will collapse for the sake of safety.
  // The + 20 is a hack that probably closes window prematurely.
  const collapse = group.width === 0 || container.width < group.width + 20

  const [missingDocs, _docs] = partition(docs, (doc) => doc.type === 'MISSING')
  const count = _docs.length + (subRelation ? 1 : 0)
  const noMissingOrDoc = missingDocs.length + count === 0

  const id = portalId ?? Date.now()

  return (
    <div ref={container.ref}>
      {noMissingOrDoc ? (
        <Text c='dimmed'>No Documents</Text>
      ) : collapse ? (
        <Menu>
          <Menu.Target>
            <Button
              rightSection={<IconChevronDown size={16} />}
              size='sm'
              bg='white'
              color={isActive ? 'primary' : 'inactive'}
              className={nestedClickArea.cssClass}
            >
              {`Documents (${count})`}
            </Button>
          </Menu.Target>
          <Menu.Dropdown className={nestedClickArea.cssClass}>
            <ScrollArea.Autosize mah={160} type='auto'>
              <Stack p='sm' gap='sm'>
                {children}
                {subRelation}
              </Stack>
            </ScrollArea.Autosize>
          </Menu.Dropdown>
        </Menu>
      ) : (
        <Group className={nestedClickArea.cssClass}>
          {children}
          {subRelation}
        </Group>
      )}

      {/* Hack to contain the Portal in a large canvas */}
      <Box pos='absolute' left='100%' id={`portal-container-${id}`} />
      {/* Hack: render Group off screen so that we can monitor it's minimal size */}
      <Portal target={`#portal-container-${id}`}>
        <div ref={group.ref}>
          <Group style={{ visibility: 'hidden', flexWrap: 'nowrap' }}>
            {children}
            {subRelation}
          </Group>
        </div>
      </Portal>
    </div>
  )
}

export const MiniDocDisplay: React.FC<MiniDocDisplayProps> = ({
  docs,
  isActive,
  onClickMissingDoc,
  supportedTypes,
  sourceCryptIds,
  ...props
}) => {
  return (
    <MiniDocDisplayLayout docs={docs} isActive={isActive} {...props}>
      {docs.map((doc) => {
        switch (doc.type) {
          case 'MISSING':
            return <MissingDoc key={doc.key} doc={doc} onClick={() => onClickMissingDoc?.(doc)} />

          default:
            return (
              <MiniDoc
                key={doc.key}
                doc={doc}
                isInactive={!isActive}
                supportedTypes={supportedTypes}
                // Allow the user to select different types (even if it will unlink the doc from the
                // current relation) unless the doc is used as source for a metadata field on that
                // relation
                allowAllTypes={!sourceCryptIds.some((cryptId) => cryptId.equals(doc.cryptId))}
              />
            )
        }
      })}
    </MiniDocDisplayLayout>
  )
}

interface MiniDocDisplayRelationProps {
  relation: Pick<
    EnhancedRelation,
    'isActive' | 'type' | 'cryptId' | 'docs' | 'supportedTypes' | 'sourceCryptIds'
  >
  redFlagInfo: RedFlagInfo | undefined
  linkedRelation?: MinimalAugmentedRelation
}
export const RelationMiniDocDisplay: React.FC<MiniDocDisplayRelationProps> = ({
  relation,
  redFlagInfo,
  linkedRelation,
}) => {
  const openDocDetail = useDocDetailViewStore((state) => state.openModal)
  const { isActive } = relation

  const subRelation = linkedRelation ? (
    <MiniObject name={ZAugmentedRelation.displayFn(linkedRelation)} href={linkedRelation.url} />
  ) : null

  const onClickMissingDoc = (doc: ZMissingDoc) =>
    openDocDetail({
      preselectedType: doc.docType,
      linkOptions: { type: 'relation', cryptId: relation.cryptId },
    })

  return (
    <MiniDocDisplay
      portalId={relation.cryptId.idStr}
      docs={redFlagInfo?.missingWithDocs ?? relation.docs}
      subRelation={subRelation}
      isActive={isActive}
      onClickMissingDoc={onClickMissingDoc}
      supportedTypes={relation.supportedTypes}
      sourceCryptIds={relation.sourceCryptIds}
    />
  )
}
