import { Button, Group, HoverCard, Stack, TagsInput, Text } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { useListState } from '@mantine/hooks'
import { IconCheck, IconInfoCircle } from '@tabler/icons-react'
import type { UseTRPCMutationResult } from '@trpc/react-query/shared'
import React, { useEffect, useMemo } from 'react'
import { z } from 'zod'
import {
  StepContainer,
  useNavigateNextOnboardingStep,
} from '~/client/components/onboarding-modal/util'
import promptConfirmAsync from '~/client/components/util/prompt-confirm-async'
import { zIndex } from '~/client/components/z-index'
import { hooks } from '~/client/lib/hooks'
import { theme } from '~/client/lib/theme'
import { getErrorMessage } from '~/common/util'

const ZLawyerInviteForm = z.object({
  emails: z.string().email().array(),
  searchEmail: z.string().email().optional().or(z.literal('')),
})
type ZLawyerInviteForm = z.infer<typeof ZLawyerInviteForm>

const DoNotHaveALawyerButton: React.FC<{
  sendOnboardingNoLawyerEmailToAerial: UseTRPCMutationResult<unknown, unknown, void, unknown>
  disabled?: boolean
}> = ({ sendOnboardingNoLawyerEmailToAerial, disabled }) => {
  const navigateNextStep = useNavigateNextOnboardingStep()

  return (
    <Button
      variant='subtle'
      onClick={async () => {
        const wantsEmail = await promptConfirmAsync({
          title: 'Need a lawyer?',
          subtitle:
            'We can help you find one through our extensive network of lawyers. We will contact you via email to get you in touch with one.',
          confirmText: 'Contact me via email',
          cancelText: 'No, thanks',
          confirmProps: { variant: 'filled' },
          buttonsColorVariant: 'encourage',
          styles: {
            title: { fontSize: theme.fontSizes.xl, fontWeight: 'bold' },
          },
        })

        if (wantsEmail) {
          await sendOnboardingNoLawyerEmailToAerial.mutateAsync()
          await navigateNextStep()
        }
      }}
      loading={sendOnboardingNoLawyerEmailToAerial.isLoading}
      w='fit-content'
      size='lg'
      disabled={disabled}
    >
      I do not have a lawyer
    </Button>
  )
}

export const AddLawyerStep: React.FC = () => {
  const form = useForm<ZLawyerInviteForm>({
    initialValues: { emails: [], searchEmail: '' },
    validate: zodResolver(ZLawyerInviteForm),
  })

  const errorInInput = useMemo(() => {
    const errors = Object.keys(form.errors)
    if (errors.length > 0 && errors[0]) {
      return form.errors[errors[0]]
    }
  }, [form.errors])

  // We have to validate the form ourselves as it will not validate properly
  // otherwise: https://github.com/mantinedev/mantine/issues/6034
  useEffect(() => {
    form.validate()
    // We cannot add form to the dependencies as it will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values])

  const roleCreate = hooks.trpc().role.create.useMutationWithCorp()
  const sendInviteEmail = hooks.trpc().sendInviteEmail.useMutationWithCorp()

  // Handling the state here is easier than using react-query, as we have
  // multiple mutations in parallel.
  const [invitationState, setInvitationState] = React.useState<
    'idle' | 'loading' | 'success' | 'error'
  >('idle')

  const [roleCreateErrors, roleCreateErrorHandlers] = useListState<string>()

  const error =
    invitationState === 'error'
      ? roleCreate.error || sendInviteEmail.error || roleCreateErrors.join(', ')
      : undefined

  const navigateNextStep = useNavigateNextOnboardingStep()

  const sendOnboardingNoLawyerEmailToAerial = hooks
    .trpc()
    .sendOnboardingNoLawyerEmailToAerial.useMutationWithCorp()

  const hasSubmittedLawyer =
    form.values.emails.length > 0 || ['success', 'loading'].includes(invitationState)

  return (
    <StepContainer
      title='Add your lawyer'
      subtitle='Connect your lawyer to save on legal fees'
      stepCompleted={invitationState === 'success'}
      // Enable skip button in case of error
      disableNextButton={hasSubmittedLawyer && invitationState !== 'error'}
      disableNextButtonTooltip='Please send invitation to continue'
      nextButtonLoading={sendOnboardingNoLawyerEmailToAerial.isLoading}
      dataTestId='onboarding-add-lawyer-step'
    >
      <form
        style={{ height: '100%', flex: '1', display: 'flex' }}
        onSubmit={form.onSubmit(async ({ emails, searchEmail }) => {
          roleCreateErrorHandlers.setState([])
          setInvitationState('loading')
          try {
            const results = await Promise.all(
              [...emails, ...(searchEmail ? [searchEmail] : [])].map(async (email) => {
                const result = await roleCreate.mutateAsync({ userEmail: email, level: 'attorney' })
                if (!result.success) {
                  roleCreateErrorHandlers.append(result.error)
                  return { success: false }
                }
                await sendInviteEmail.mutateAsync({ email, isLawyerInvite: true })
                return { success: true }
              })
            )

            if (results.some((result) => result.success === false)) {
              setInvitationState('error')
              return
            }

            setInvitationState('success')

            await navigateNextStep()
          } catch (e) {
            setInvitationState('error')
          }
        })}
      >
        <Stack gap='xl' flex='1' pt='xl'>
          <TagsInput
            mih={105}
            size='lg'
            miw='60ch'
            maw={theme.spacing.xl}
            label='Lawyer Email'
            placeholder='lawyer@example.com, lawyer@example2.com'
            splitChars={[',', ';', ' ']}
            {...form.getInputProps('emails')}
            searchValue={form.getInputProps('searchEmail').value}
            onSearchChange={(value) => {
              form.getInputProps('searchEmail').onChange(value.trim())
            }}
            error={errorInInput || (error && getErrorMessage(error))}
          />

          <Group>
            <IconCheck color={theme.colors.go[6]} />
            <Text size='lg'>Invite to Aerial and onboard documents</Text>

            <HoverCard withinPortal zIndex={zIndex.modal + 1} position='top'>
              <HoverCard.Target>
                <IconInfoCircle color={theme.colors.primary[6]} />
              </HoverCard.Target>

              <HoverCard.Dropdown>
                {/* // Prevent a single line of text, which is too long to read */}
                <Text maw='60ch' style={{ whiteSpace: 'normal' }} p='xs'>
                  Don’t worry! As a professional courtesy, lawyers <b>do not charge clients</b> for
                  signing up to apps or requesting their legal documents.
                </Text>
              </HoverCard.Dropdown>
            </HoverCard>
          </Group>

          <Group>
            <DoNotHaveALawyerButton
              sendOnboardingNoLawyerEmailToAerial={sendOnboardingNoLawyerEmailToAerial}
              disabled={hasSubmittedLawyer}
            />
            <Button
              type='submit'
              disabled={
                (form.values.emails.length === 0 && !form.values.searchEmail) ||
                !form.isValid() ||
                !['idle', 'error'].includes(invitationState)
              }
              w='fit-content'
              loading={invitationState === 'loading'}
              leftSection={invitationState === 'success' ? <IconCheck /> : undefined}
              variant='filled'
              size='lg'
            >
              Send Invitation
            </Button>
          </Group>
        </Stack>
      </form>
    </StepContainer>
  )
}
