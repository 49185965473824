import { Button, Group, Tooltip } from '@mantine/core'
import { IconCloudUpload } from '@tabler/icons-react'
import React from 'react'
import { promptCancelNewDoc, useDocDetailState } from '~/client/components/doc-detail/state'
import { usePopulateFormWithAutofill } from '~/client/components/doc-detail/term/populate-form-with-autofill'
import { PropertiesFields } from '~/client/components/doc-detail/term/properties-fields'
import { docDetailButtonClass } from '~/client/lib/css-util.css'
import { hooks, useCurrentCorpAuth } from '~/client/lib/hooks'

const SaveButton: React.FC<{
  autocheckAllOk: boolean
  isLoading: boolean
  isDirty: boolean
}> = ({ autocheckAllOk, isLoading, isDirty }) => {
  const { data: auth } = useCurrentCorpAuth()
  const isInvestor = auth?.level === 'investor'
  const saveButtonDisabled = !isDirty || isInvestor

  const tooltipProps = (() => {
    if (!autocheckAllOk && !saveButtonDisabled)
      return {
        label: 'Autocheck found issues in this document. Please double check before saving',
      }
    if (!isInvestor && saveButtonDisabled)
      return {
        label: 'Your changes have been saved!',
        'data-testid': 'save-doc-detail-tooltip-success',
      }
    return { label: '', disabled: true }
  })()

  return (
    <Tooltip {...tooltipProps}>
      <Button
        color={autocheckAllOk ? 'primary' : 'urgent'}
        type='submit'
        loading={isLoading}
        leftSection={<IconCloudUpload />}
        data-testid='save-doc-detail'
        // HACK so tooltip is only shown when button is disabled
        // https://github.com/mantinedev/mantine/issues/2959#issuecomment-1314064763
        {...(saveButtonDisabled ? { 'data-disabled': true } : undefined)}
        className={docDetailButtonClass}
        onClick={saveButtonDisabled ? (e) => e.preventDefault() : undefined}
      >
        Save
      </Button>
    </Tooltip>
  )
}

export const PropertiesTab: React.FC<{ onCancel?: () => void }> = ({ onCancel }) => {
  const form = useDocDetailState((state) => state.form)
  const isNewDoc = useDocDetailState((state) => state.isNewDoc)
  const cryptId = useDocDetailState((state) => state.docCryptId)
  const isSaving = useDocDetailState((state) => state.isSaving)
  const autocheckAllOk = useDocDetailState((state) => state.autocheck.autocheckAllOk)

  const deleteDoc = hooks.trpc().doc.delete.useMutationWithCorp()

  usePopulateFormWithAutofill()

  // Shows Delete when creating a doc and Cancel when editing one
  const showDeleteButton = isNewDoc

  return (
    <>
      <PropertiesFields />
      <Group my='md' gap='lg' justify='flex-end'>
        {onCancel &&
          (showDeleteButton ? (
            <Button
              color='gray'
              variant='subtle'
              onClick={async () => {
                const hasConfirmed = await promptCancelNewDoc()
                if (cryptId && hasConfirmed) {
                  deleteDoc.mutate({ cryptId })
                  // Prevent a second confirmation to discard unsaved changes
                  form.resetDirty()
                  onCancel()
                }
              }}
              disabled={isSaving}
              data-testid='delete-doc-detail'
            >
              Delete
            </Button>
          ) : (
            <Button
              color='gray'
              variant='subtle'
              onClick={onCancel}
              disabled={isSaving}
              data-testid='cancel-doc-detail'
            >
              Cancel
            </Button>
          ))}
        <SaveButton autocheckAllOk={autocheckAllOk} isLoading={isSaving} isDirty={form.isDirty()} />
      </Group>
    </>
  )
}
