import { Box, Button, List, Stack, Text, Title } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { IconCopy } from '@tabler/icons-react'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { groupBy, partition, sortBy } from 'underscore'
import type { RedFlagModalState } from '~/client/components/red-flags/custom-red-flag-detail'
import { useGetRedFlagPrimaryObjectUrl } from '~/client/components/red-flags/util'
import { NextLinkOpt } from '~/client/components/util'
import { LoadingErrorComp } from '~/client/components/util/error'
import { hooks } from '~/client/lib/hooks'
import { copyRenderedElementToClipboard } from '~/client/lib/util'
import { exhaustPaginated } from '~/common/exhausted-query'
import {
  isCorpRedFlagType,
  isCustomRedFlag,
  isRelationRedFlagType,
  relationRedFlagRuleTypeMap,
} from '~/common/red-flags'
import { relationTypeRouteMap } from '~/common/route/collection'
import { navDropRoutePathMap } from '~/common/route/top-route'
import type {
  ZAllAugmentedCustomRedFlag,
  ZAugmentedRecordsRedFlag,
  ZAugmentedRedFlag,
} from '~/common/schema/red-flag'
import { atOrThrow } from '~/common/util'

export interface RedFlagsProps {
  openCustomRedFlagModal: (state: Exclude<RedFlagModalState, { mode: 'closed' }>) => void
}

const RedFlagList: React.FC<{
  redFlags: ZAugmentedRedFlag[]
  openCustomRedFlagModal: RedFlagsProps['openCustomRedFlagModal']
}> = ({ redFlags, openCustomRedFlagModal }) => {
  const getRedFlagPrimaryObjectUrl = useGetRedFlagPrimaryObjectUrl()

  return (
    <List p='xs' listStyleType='disc'>
      {redFlags.map((redFlag) => (
        <List.Item key={redFlag.cryptId.idStr}>
          {/* these links are meant to be copied and shared, the browser will convert relative links to absolute ones” */}
          <NextLinkOpt
            href={getRedFlagPrimaryObjectUrl(redFlag)}
            onClick={
              redFlag.type === 'CUSTOM'
                ? () => openCustomRedFlagModal({ mode: 'edit', redFlag })
                : undefined
            }
          >
            {redFlag.display}
          </NextLinkOpt>
          {redFlag.dismissed && (
            <>
              {' - '}
              <Text component='span'>Dismissed</Text>
            </>
          )}
        </List.Item>
      ))}
    </List>
  )
}

interface RedFlagsWithIndexList extends ZAugmentedRecordsRedFlag {
  indexList: number[]
  topLevelTitle: string
  topLevelIndex: number
}

const mkRedFlagsWithIndexList = (redFlags: ZAugmentedRecordsRedFlag[]): RedFlagsWithIndexList[] =>
  redFlags.map((redFlag) => {
    const extra = (() => {
      if (isRelationRedFlagType(redFlag.type)) {
        const rule = relationRedFlagRuleTypeMap.get(redFlag.type)
        if (!rule) {
          throw new Error(`No relation red flag rule found for type ${redFlag.type}`)
        }

        const { indexList, topLevelTitle } =
          navDropRoutePathMap[relationTypeRouteMap[rule.relationType].collection]

        return { indexList, topLevelTitle, topLevelIndex: atOrThrow(indexList, 0) }
      }

      if (isCorpRedFlagType(redFlag.type)) {
        return { indexList: [1, 1], topLevelTitle: 'Corporation', topLevelIndex: 1 }
      }

      // TODO: handle other red flags
      return { indexList: [], topLevelTitle: '', topLevelIndex: 0 }
    })()

    return {
      ...extra,
      ...redFlag,
    }
  })

const compareIndexList = (a: number[], b: number[]) => {
  const minLength = Math.min(a.length, b.length)
  for (let i = 0; i < minLength; i += 1) {
    const ai = atOrThrow(a, i)
    const bi = atOrThrow(b, i)
    if (ai < bi) {
      return -1
    }
    if (ai > bi) {
      return 1
    }
  }
  return a.length - b.length
}

export const RedFlagsListView: React.FC<RedFlagsProps> = ({ openCustomRedFlagModal }) => {
  const fetchRedFlags = hooks.trpc().redFlags.get.all.useFetchWithCorp()
  const redFlagsQueryResult = useQuery(['allActiveRedFlags'], () =>
    exhaustPaginated(fetchRedFlags, { limit: Infinity })
  )

  const [activeRedFlags, dismissedRedFlags] = partition(
    redFlagsQueryResult.data ?? [],
    (redFlag) => !redFlag.dismissed
  )
  const redFlagsRef = React.useRef<HTMLDivElement>(null)

  // eslint-disable-next-line custom-rules/no-bad-casting-in-declaration
  const [activeCustomRedFlags, activeRecordsRedFlags] = partition(
    activeRedFlags,
    isCustomRedFlag
  ) as [ZAllAugmentedCustomRedFlag[], ZAugmentedRecordsRedFlag[]]

  const groupedActiveRecordsRedFlags = React.useMemo(() => {
    const redFlagsWithIndexList = mkRedFlagsWithIndexList(activeRecordsRedFlags)
    redFlagsWithIndexList.sort((a, b) => compareIndexList(a.indexList, b.indexList))
    return sortBy(
      groupBy(redFlagsWithIndexList, 'topLevelIndex'),
      (list) => atOrThrow(list, 0).topLevelIndex
    )
  }, [activeRecordsRedFlags])

  const copy = async () => {
    if (!redFlagsRef.current) return
    await copyRenderedElementToClipboard(redFlagsRef.current)
    showNotification({ message: 'Red flags copied to clipboard' })
  }

  return (
    <Box>
      <LoadingErrorComp queryResult={redFlagsQueryResult}>
        <Button onClick={copy} leftSection={<IconCopy />}>
          Copy Red Flags
        </Button>
        {activeRedFlags.length > 0 && (
          <>
            <Title order={3} mt='lg'>
              Active Red Flags
            </Title>
            {/* Using a Stack instead of nested Lists to avoid formatting getting */}
            {/* messed up on gmail */}
            <Stack p='lg' ref={redFlagsRef} gap={0}>
              {activeCustomRedFlags.length > 0 && (
                <Stack gap={0}>
                  <Text>Custom Red Fags</Text>
                  <RedFlagList
                    redFlags={activeCustomRedFlags}
                    openCustomRedFlagModal={openCustomRedFlagModal}
                  />
                </Stack>
              )}
              {groupedActiveRecordsRedFlags.map((redFlags, index) => {
                const { topLevelIndex, topLevelTitle } = atOrThrow(redFlags, 0)

                return (
                  <Stack key={index} gap={0}>
                    <Text>{`${topLevelIndex}. ${topLevelTitle}:`}</Text>
                    <RedFlagList
                      redFlags={redFlags}
                      openCustomRedFlagModal={openCustomRedFlagModal}
                    />
                  </Stack>
                )
              })}
            </Stack>
          </>
        )}
        {dismissedRedFlags.length > 0 && (
          <>
            <Title order={3}>Dismissed Red Flags</Title>
            <List>
              <RedFlagList
                redFlags={dismissedRedFlags}
                openCustomRedFlagModal={openCustomRedFlagModal}
              />
            </List>
          </>
        )}
      </LoadingErrorComp>
    </Box>
  )
}
