import { Box, Breadcrumbs, Text } from '@mantine/core'
import { theme } from '~/client/lib/theme'
import { NextLinkOpt } from './link'

export interface BreadcrumbItem {
  linkTitle: string
  link?: string
  current?: true
}

const BreadCrumbStep: React.FC<{ item: BreadcrumbItem }> = ({ item }) => {
  if (item.current)
    return (
      <Text truncate fw='bold'>
        {item.linkTitle}
      </Text>
    )
  if (item.link)
    return (
      <NextLinkOpt display='block' truncate href={item.link}>
        {item.linkTitle}
      </NextLinkOpt>
    )
  return <Text truncate>{item.linkTitle}</Text>
}

export const AppBreadcrumbs: React.FC<{ items: BreadcrumbItem[] }> = ({ items }) => {
  return (
    <Breadcrumbs mb='xl' separator='›'>
      {items.map((item) => (
        <Box style={{ maxWidth: theme.other.widths.xs }} key={item.linkTitle}>
          <BreadCrumbStep item={item} />
        </Box>
      ))}
    </Breadcrumbs>
  )
}
