import { createFacade } from 'react-facade'
import type { UseAuth, UseAuthStatus, UseAuthStore } from '~/client/lib/hooks/auth'
import type { UseCorpCryptIdSafe, UseCurrentCorp } from '~/client/lib/hooks/corp'
import type { UseGenerateThumbnail } from '~/client/lib/hooks/generate-thumbnail'
import type { UseIsIntegrationFetchingDocuments } from '~/client/lib/hooks/is-integration-fetching-documents'
import type { UseDownloadS3, UseS3Upload } from '~/client/lib/hooks/s3'
import type { UseNavigationTabs } from '~/client/lib/hooks/use-navigation-tabs'
import type { UseCurrentUser } from '~/client/lib/hooks/user'
import type { UseZipFileMethods, UseZipFileStore } from '~/client/lib/hooks/zip-state'
import type { UseSearchQueries } from '~/client/lib/query-params'
import type { Trpc } from '~/client/lib/trpc'

export interface Hooks {
  useAuthStore: UseAuthStore
  useCorpCryptIdSafe: UseCorpCryptIdSafe
  useCurrentCorp: UseCurrentCorp
  useAuthMethods: UseAuth
  useAuthStatus: UseAuthStatus
  useS3Upload: UseS3Upload
  trpc: () => Trpc
  useCurrentUser: UseCurrentUser
  useZipFileStore: UseZipFileStore
  useZipFileMethods: UseZipFileMethods
  useGenerateThumbnail: UseGenerateThumbnail
  useDownloadS3: UseDownloadS3
  useNavigationTabs: UseNavigationTabs
  useSearchQueries: UseSearchQueries
  useIsIntegrationFetchingDocuments: UseIsIntegrationFetchingDocuments
}

// no implementation!
export const [hooks, ImplementationProvider] = createFacade<Hooks>({ strict: false })
