import dynamic from 'next/dynamic'
import React from 'react'
import { zenvCommon } from '~/common/zenv-common'

const ReactQueryDevtoolsProduction = dynamic(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then((d) => d.ReactQueryDevtools)
)

export const ReactQueryDevtoolsToggle: React.FC = () => {
  const [showDevtools, setShowDevtools] = React.useState<boolean>(zenvCommon.NEXT_PUBLIC_DEVTOOLS)
  React.useEffect(() => {
    window.toggleDevtools = () => setShowDevtools((old) => !old)
  }, [])

  return showDevtools ? <ReactQueryDevtoolsProduction initialIsOpen={false} /> : null
}
