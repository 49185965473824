import { z } from 'zod'

export const ZDateFilter = Object.assign(
  z.union([
    z.object({
      operator: z.enum(['before', 'since', 'on']),
      value: z.date(),
      inclusive: z.boolean().optional(),
      includeNullValues: z.boolean().optional(),
    }),
    z.object({ operator: z.literal('rangeInclusive'), from: z.date(), to: z.date() }),
  ]),
  { type: 'date' as const }
)

export type ZDateFilter = z.infer<typeof ZDateFilter>

export const ZStringFilter = Object.assign(z.string(), { type: 'string' as const })
export type ZStringFilter = z.infer<typeof ZStringFilter>
