import type { ZIntegrationType } from '~/common/schema/integration'

export const displayIntegrationType = (type: ZIntegrationType): string => {
  // Ensure we have human-readable names for these and future integrations
  switch (type) {
    case 'docusign':
      // The company no longer uses DocuSign capitalized that way.
      return 'Docusign'
    case 'adobeSign':
      return 'AdobeSign'
  }
}
