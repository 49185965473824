import { Group, Skeleton, Stack } from '@mantine/core'
import React from 'react'
import { isEqual } from 'underscore'
import { LinkedRelationsSkeleton } from '~/client/components/doc-card/data'
import { theme } from '~/client/lib/theme'
import { Random } from '~/common/random'

const random = new Random()

const _SkeletonContent = (): React.ReactElement => {
  return (
    <Stack p='16px' flex={1} gap={0}>
      <Group align='flex-start' wrap='nowrap' justify='space-between' mb='xs'>
        {/* Doc name */}
        <Skeleton width={`${random.randomInt(50) + 25}%`} height={theme.fontSizes.lg} mb='xs' />
        {/* Badge */}
        <Skeleton width={75} height={theme.fontSizes.lg} mb='xs' ml='auto' />
      </Group>

      <Group align='flex-start' wrap='nowrap' justify='space-between'>
        <Stack gap='xs' flex={1}>
          {/* Party name */}
          <Skeleton width={`${random.randomInt(25) + 10}%`} height={theme.fontSizes.lg} mb='xs' />
          {/* Date */}
          <Skeleton width={100} height={theme.fontSizes.lg} mb='xs' />
        </Stack>

        {/* Linked Relations */}
        <LinkedRelationsSkeleton />
      </Group>
    </Stack>
  )
}

// Using a memo here to avoid recalculating random widths on each re-render
// isEqual is the underscore function for deep equality
export const SkeletonContent = React.memo(_SkeletonContent, isEqual)
