import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'
import { getPdfDocument } from '~/common/pdfjs'

/**
 * Generates thumbnail;
 *
 * Note that because
 * @param buffer
 * @returns
 */
export const generateThumbnail = async (buffer: ArrayBuffer): Promise<Blob> => {
  const typedArray = new Uint8Array(buffer)
  const pdf = await getPdfDocument({
    data: typedArray,
  })
  const page = await pdf.getPage(1)
  const viewport = page.getViewport({ scale: 0.5 })
  //
  // Prepare canvas using 200 by 150 proportion (size of doc component image holder)
  //
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  canvas.height = viewport.width * 0.75
  canvas.width = viewport.width
  //
  // Render PDF page into canvas context
  //
  if (!context) throw new Error("Missing canvas' context 2d")
  const renderContext = {
    canvasContext: context,
    viewport,
  }
  await page.render(renderContext).promise

  const image = await new Promise<Blob>((resolve) => {
    canvas.toBlob((blob) => {
      if (blob) resolve(blob)
    })
  })

  return image
}

interface GenerateThumbnailMutationInput {
  buffer: ArrayBuffer
}
export const useGenerateThumbnail = (): UseMutationResult<
  ArrayBuffer,
  unknown,
  GenerateThumbnailMutationInput,
  unknown
> => {
  return useMutation(
    async ({ buffer }) => {
      const thumb = await generateThumbnail(buffer)
      return thumb.arrayBuffer()
    },
    // To distinguish in Mixpanel analytics
    {
      mutationKey: ['generate-thumbnail'],
      // Don't show these errors to the user because they might be caused by a corrupted PDF
      meta: { noErrorNotification: true },
    }
  )
}
export type UseGenerateThumbnail = typeof useGenerateThumbnail
