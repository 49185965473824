import { z } from 'zod'
import { ZSimplifiedDisplaySchema } from '~/common/schema/with-display-schema'

interface DisplayFieldInfo<T extends z.ZodTypeAny> {
  key: keyof z.infer<T>
  label: string
  getValue: (obj: z.infer<T>) => z.infer<T>[keyof z.infer<T>]
  displayFn: (obj: z.infer<T>) => string | null | undefined
}

/**
 * Find all fields that have a displaySchema (were wrapped in withDisplaySchema)
 * and return the following information for each one:
 * key: the field's key in the schema
 * label: the human-readable field name (example: Strike Price)
 * displayFn: a function that takes the whole object (for type-safety purposes)
 * and returns the formatted version of that single field
 * @param schema
 */
export const getSchemaDisplayableFieldsInfo = <T extends z.AnyZodObject>(
  schema: Pick<T, 'shape'>
): DisplayFieldInfo<T>[] => {
  return Object.entries(schema.shape)
    .map(([key, value]) => [key as keyof z.infer<T>, value] as const)
    .map(([key, value]) => {
      if (!(typeof value === 'object' && value && 'displaySchema' in value)) return null

      const parsed = ZSimplifiedDisplaySchema.safeParse(value.displaySchema)
      if (!parsed.success) return null

      const { display, displayFn } = parsed.data

      // Just for type-safety
      const getValue = (obj: z.infer<T>) => obj[key]

      return {
        key,
        label: display,
        getValue,
        displayFn: (obj: z.infer<T>) => {
          const attribute = getValue(obj)
          if (attribute === undefined || attribute === null) return attribute
          const innerValue =
            typeof attribute === 'object' && 'value' in attribute ? attribute.value : attribute
          if (innerValue === undefined || innerValue === null) return innerValue
          // Zod can't assert the return type of the function
          return z.string().nullish().parse(displayFn(innerValue))
        },
      }
    })
    .filter(Boolean)
}
