import type { CryptId } from '@cryptid-module'
import { Anchor, Card, Group, Popover, Skeleton, Stack, Text } from '@mantine/core'
import React from 'react'
import { NextLinkOpt, nestedClickArea } from '~/client/components/util'
import { DisplayDate } from '~/client/components/util/date-range'
import { HoverOnClippedText, PrimaryBadge } from '~/client/components/util/doc'
import { LoadingErrorComp } from '~/client/components/util/error'
import { hooks, useCorpCryptId } from '~/client/lib/hooks'
import { useIsLinkedToCorp } from '~/client/lib/hooks/doc-links'
import { docDisplay } from '~/common/doc'
import { enhanceRelation } from '~/common/enhance'
import type { ZAugmentedDoc } from '~/common/schema'
import { mkCorpRoute } from '~/common/util'

const textStyle = {
  maxWidth: '300px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  cursor: 'text',
  width: 'fit-content',
} as const

export const LinkedRelationsSkeleton: React.FC = () => (
  <Text>
    <Skeleton>Linked Relations: 0</Skeleton>
  </Text>
)

const LinkedRelationsDisplay: React.FC<{ docCryptId: CryptId }> = ({ docCryptId }) => {
  const linkedRelations = hooks.trpc().relations.byLinkedDoc.useQueryWithCorp({ docCryptId })
  const { isLinkedToCorp } = useIsLinkedToCorp(docCryptId)
  const { corpCryptId } = useCorpCryptId()

  const linkedRelationsAndCorp = React.useMemo(() => {
    const corpLink = isLinkedToCorp
      ? [
          {
            display: `1.1 Organizational Info`,
            url: mkCorpRoute(corpCryptId, 'organizational-info'),
            key: 'corp',
          },
        ]
      : []
    const relationLinks =
      linkedRelations.data?.map((relation) => ({
        display: enhanceRelation(relation).display,
        url: mkCorpRoute(corpCryptId, 'relation', relation.cryptId.idStr),
        key: relation.cryptId.idStr,
      })) ?? []
    return [...corpLink, ...relationLinks]
  }, [isLinkedToCorp, corpCryptId, linkedRelations])

  return (
    <LoadingErrorComp
      queryResult={linkedRelations}
      variant='tooltip'
      skeleton={<LinkedRelationsSkeleton />}
    >
      {linkedRelationsAndCorp.length ? (
        <Popover width={350} position='right' withArrow shadow='md' radius='md'>
          <Popover.Target>
            <Anchor className={nestedClickArea.cssClass}>
              Linked Relations: <b>{linkedRelationsAndCorp.length}</b>
            </Anchor>
          </Popover.Target>
          <Popover.Dropdown p={0}>
            <Card radius='md' className={nestedClickArea.cssClass}>
              <Card.Section withBorder inheritPadding py='xs'>
                <Text fw='bold'>Linked Relations</Text>
              </Card.Section>
              <Card.Section withBorder inheritPadding py='xs'>
                <Stack>
                  {linkedRelationsAndCorp.map((item) => (
                    <NextLinkOpt key={item.key} href={item.url}>
                      {item.display}
                    </NextLinkOpt>
                  ))}
                </Stack>
              </Card.Section>
            </Card>
          </Popover.Dropdown>
        </Popover>
      ) : (
        <Text>No Linked Relations</Text>
      )}
    </LoadingErrorComp>
  )
}

export const DocCardData: React.FC<{ doc: ZAugmentedDoc | undefined }> = ({ doc }) => {
  if (!doc) throw new Error('No doc. Should never happen.')
  const { party, dateImprecise } = doc

  return (
    <Stack p='16px' data-testid='doc-card-with-doc' flex={1} gap={0}>
      {/* 2 groups to make sure LinkedRelationsDisplay is aligned with Counterparty */}
      <Group align='flex-start' wrap='nowrap' justify='space-between' mb='xs'>
        <HoverOnClippedText
          textStyle={{
            ...textStyle,
            fontWeight: 'bold',
          }}
          text={docDisplay(doc)}
        />
        <PrimaryBadge>Active</PrimaryBadge>
      </Group>

      <Group align='flex-start' wrap='nowrap' justify='space-between'>
        <Stack gap='xs' flex={1}>
          {party && <HoverOnClippedText textStyle={textStyle} text={party.name} />}
          <Text style={textStyle}>
            <DisplayDate dateImprecise={dateImprecise} />
          </Text>
        </Stack>
        <LinkedRelationsDisplay docCryptId={doc.cryptId} />
      </Group>
    </Stack>
  )
}
