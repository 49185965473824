import { useAutoAnimate } from '@formkit/auto-animate/react'
import { Group, Stack } from '@mantine/core'
import dynamic from 'next/dynamic'
import React from 'react'
import type { DocCardStateProps } from '~/client/components/doc-card'
import { DocCard, MissingDocCard } from '~/client/components/doc-card'
import { type EnhancedDoc } from '~/common/enhance'
import type { ZAugmentedDocWithHighlights, ZDocType, ZMissingDoc } from '~/common/schema'

interface DocViewProps extends DocCardStateProps {
  docs: EnhancedDoc<ZAugmentedDocWithHighlights>[] | ZAugmentedDocWithHighlights[]
}

const DocsLayout = dynamic(() =>
  import('~/client/components/util/doc').then((mod) => mod.DocsLayout)
)

export const DocView: React.FC<DocViewProps> = ({ docs, ...props }) => {
  const [animationParent] = useAutoAnimate<HTMLDivElement>({ duration: 500 })

  return (
    <Stack ref={animationParent} gap='xl'>
      {docs.map((doc) => {
        return (
          <div key={doc.cryptId.idStr}>
            <DocCard doc={doc} docState={{ ...props }} />
          </div>
        )
      })}
    </Stack>
  )
}

interface MissingAndDocsViewProps extends DocViewProps {
  missingDocs: ZMissingDoc[] | undefined
  link: (type: ZDocType) => void
  upload: (type: ZDocType) => void
}

export const MissingAndDocsView: React.FC<MissingAndDocsViewProps> = ({
  missingDocs,
  link,
  upload,
  ...docViewProps
}) => {
  return (
    <DocsLayout>
      {missingDocs?.map((doc, key) => (
        <MissingDocCard key={key} type={doc.docType} link={link} upload={upload} />
      ))}
      <DocView {...docViewProps} />
    </DocsLayout>
  )
}
export const DocComponentsLayout: React.FC<{
  headerElements?: JSX.Element
}> = ({ headerElements, children }) => {
  return (
    <>
      <Group gap='xl'>{headerElements}</Group>
      {children}
    </>
  )
}
