import { z } from 'zod'

const _ZParty = z.object({
  name: z.string(),
  email: z.string().email().optional(),
})

export interface ZParty extends z.infer<typeof _ZParty> {}

export const ZParty = Object.assign(_ZParty, {
  display: ({ name, email }: ZParty) => (email ? `${name} (${email})` : name),
})
