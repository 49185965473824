import { Box, Button, Group, Image, Stack, Text, TextInput, Title } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { IconQrcode } from '@tabler/icons-react'
import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation, useQuery } from '@tanstack/react-query'
import dynamic from 'next/dynamic'
import React from 'react'
import { z } from 'zod'
import { AuthenticationFrame } from '~/client/components/login/util'
import { LogoutButton } from '~/client/components/login/verifications'
import { NextLinkOpt } from '~/client/components/util'
import { LoadingErrorComp } from '~/client/components/util/error'
import { hooks } from '~/client/lib/hooks'
import { getMFAError, useAuthMethods } from '~/client/lib/hooks/auth'

const TotpQRCode = dynamic(() => import('qrcode.react').then((module) => module.QRCodeSVG))

const ZTotpCodeForm = z.object({
  code: z.string().length(6).regex(/\d{6}/, 'The code should only contain numbers'),
})
interface ZTotpCodeForm extends z.infer<typeof ZTotpCodeForm> {}

const TotpTokenForm: React.FC<{
  showSwitchUser?: boolean
  submitMutation: UseMutationResult<void, unknown, { code: string }>
  onBack?: () => void
}> = ({ showSwitchUser, submitMutation, onBack }) => {
  const form = useForm<ZTotpCodeForm>({
    initialValues: { code: '' },
    validate: zodResolver(ZTotpCodeForm),
  })

  return (
    <form onSubmit={form.onSubmit(({ code }) => submitMutation.mutateAsync({ code }))}>
      {/* This needs to support leading zeros because TOTP codes can start with those */}
      {/* and we must always send 6-digit codes to the server */}
      <TextInput
        {...form.getInputProps('code')}
        maxLength={6}
        minLength={6}
        label='Authentication code:'
        placeholder='123456'
        data-testid='input-totp-auth-code'
        // Show a numeric keyboard on mobile, but still treats the content as text
        inputMode='numeric'
      />
      {submitMutation.error && (
        <Text c='danger' size='xs' mt='xs'>
          {getMFAError(submitMutation.error)}
        </Text>
      )}
      <Group mt='lg' justify='flex-end'>
        {onBack && (
          <Button color='gray' variant='subtle' onClick={onBack}>
            Back
          </Button>
        )}
        {showSwitchUser && <LogoutButton />}
        <Button type='submit' loading={submitMutation.isLoading}>
          Verify
        </Button>
      </Group>
    </form>
  )
}

const StoreLink: React.FC<{ href: string }> = ({ href }) => {
  const store: 'apple' | 'google' | undefined = href.startsWith('https://apps.apple.com')
    ? 'apple'
    : href.startsWith('https://play.google.com')
      ? 'google'
      : undefined
  if (!store) {
    throw new Error(`Invalid store link: ${href}`)
  }

  const imageSrc =
    store === 'apple'
      ? '/authenticator/app-store-badge.svg'
      : '/authenticator/google-play-badge.png'
  const imageAlt = store === 'apple' ? 'App Store Link' : 'Google Play Link'
  return (
    <a href={href} target='_blank' rel='noreferrer'>
      <Image w='auto' h='3em' src={imageSrc} alt={imageAlt} />
    </a>
  )
}

interface TotpStepProps {
  step: number
  textBody: string
}
const TotpStep: React.FC<TotpStepProps> = ({ step, textBody, children }) => {
  return (
    <>
      <Stack gap={0}>
        <Text fw={700} fz='lg'>
          Step {step}
        </Text>
        <Text>{textBody}</Text>
      </Stack>
      {children}
    </>
  )
}

export const EnrollTotpAuth: React.FC<{ onBack?: () => void }> = ({ onBack }) => {
  const skipEnrollMFA = hooks.useAuthStore((state) => state.skipEnrollMFA)
  const {
    totpAuth: { enrollTotp, verifyEnrollTotp },
  } = useAuthMethods()

  const enrollQuery = useQuery(['enroll-totp'], enrollTotp, {
    // Don't invalidate because Firebase throttles generating secrets
    meta: { noGlobalInvalidation: true },
    // Firebase Totp secrets are valid for 15 minutes
    cacheTime: 15 * 60,
    staleTime: 14 * 60,
  })

  const submitMutation = useMutation(
    async ({ code }: { code: string }) => {
      if (!enrollQuery.data) return
      return verifyEnrollTotp({ code, secret: enrollQuery.data.secret })
    },
    { meta: { noErrorNotification: true } }
  )

  return (
    <AuthenticationFrame title='Two Factor Auth' icon={<IconQrcode />}>
      <Title order={3}>Secure Client Data</Title>
      <TotpStep
        step={1}
        textBody='Download Google Authenticator or use your preferred authenticator app'
      >
        {/* We want this to be closer to the text above than to step 2  */}
        <Box mb='sm' mt={-8}>
          <Title order={5}>Google Authenticator</Title>
          <Group gap='lg'>
            <StoreLink href='https://apps.apple.com/us/app/google-authenticator/id388497605' />
            <StoreLink href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2' />
          </Group>
        </Box>
      </TotpStep>

      <TotpStep
        step={2}
        textBody='Open Google Authenticator (or your preferred authenticator app), and add the QR code'
      />

      <Group justify='center'>
        <Stack h='12rem' w='12rem' justify='center'>
          <LoadingErrorComp queryResult={enrollQuery}>
            {enrollQuery.data && (
              <TotpQRCode value={enrollQuery.data.uri} width='100%' height='100%' />
            )}
          </LoadingErrorComp>
        </Stack>
      </Group>

      <TotpStep step={3} textBody='Enter the 6-digit code provided by your authenticator app.' />
      <TotpTokenForm submitMutation={submitMutation} onBack={onBack} />
      <Text c='dimmed' size='xs'>
        You can{' '}
        <NextLinkOpt data-testid='skip-MFA' onClick={() => skipEnrollMFA()}>
          skip
        </NextLinkOpt>{' '}
        this step.
      </Text>
    </AuthenticationFrame>
  )
}

export const VerifyTotpAuth: React.FC = () => {
  const email = hooks.useAuthStore((state) => state.user?.email ?? undefined)
  const {
    totpAuth: { verifySignIn },
  } = useAuthMethods()

  const submitMutation = useMutation(verifySignIn, { meta: { noErrorNotification: true } })

  return (
    <AuthenticationFrame title='Verify Auth Token' subtitle={email} icon={<IconQrcode />}>
      <Title order={4}>Secure Client Data</Title>
      <Text>Please enter the 6-digit code provided by your authenticator app.</Text>
      <TotpTokenForm showSwitchUser submitMutation={submitMutation} />
    </AuthenticationFrame>
  )
}
