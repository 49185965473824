import { ActionIcon, Stack, Title } from '@mantine/core'
import { IconPlus } from '@tabler/icons-react'
import Router from 'next/router'
import React from 'react'
import { useCorpCryptId } from '~/client/lib/hooks'
import { IntegrationCardBase } from './integration-card'

export const AddIntegrationCard: React.FC = () => {
  const { mkCurrentCorpRoute } = useCorpCryptId()
  return (
    <IntegrationCardBase onClick={() => Router.push(mkCurrentCorpRoute('integrations', 'add'))}>
      <Stack align='center' justify='center' h='100%'>
        <ActionIcon color='primary' radius='xl' size='xl' variant='subtle'>
          <IconPlus size='100%' />
        </ActionIcon>
        <Title order={4} ta='center' c='primary'>
          Add Integration
        </Title>
      </Stack>
    </IntegrationCardBase>
  )
}
