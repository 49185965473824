import React from 'react'

export class DisposableEmailError extends Error {}

export const useEmailIsDisposable = (): {
  emailIsDisposable: (email: string) => Promise<boolean>
} => {
  // Preload the list to avoid delays on signup
  // Both https://www.npmjs.com/package/disposable-email and https://www.npmjs.com/package/disposable-email-domains
  // were considered for this, but were discarded because they were too outdated to be effective
  const [fakeFilterPromise] = React.useState(() => import('fakefilter'))

  const emailIsDisposable = React.useCallback(
    async (email: string) => {
      const { isFakeEmail } = await fakeFilterPromise
      return !!isFakeEmail(email)
    },
    [fakeFilterPromise]
  )

  return { emailIsDisposable }
}
