import type { CryptId } from '@cryptid-module'
import { Box, Center, Group, ScrollArea, Stack, Text } from '@mantine/core'
import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { DocCard } from '~/client/components/doc-card'
import { useFilterStore } from '~/client/components/filters/store'
import { BaseModal } from '~/client/components/modals'
import { MultiSelectSearchInput } from '~/client/components/multi-select-search-input'
import type { UseSetLinkMutationResult } from '~/client/components/relation/'
import { LoadingErrorComp } from '~/client/components/util/error'
import { LinkSwitchWithTooltip } from '~/client/components/util/link-switch-with-tooltip'
import { LoadMoreInfiniteQuery } from '~/client/components/util/load-more-infinite-query'
import { zIndex } from '~/client/components/z-index'
import {
  mkDocsAndCount,
  mkTypeOptionValue,
  stringComparisonOptionsFilterFn,
  useDocSearch,
  useSearchOptions,
} from '~/client/lib/hooks/search'
import type { DocSearchResults } from '~/client/lib/hooks/search'
import { countTotalFilters } from '~/client/lib/hooks/search/'
import { theme } from '~/client/lib/theme'
import { enhanceCount } from '~/common/enhance'
import type { ZAugmentedDoc, ZDocType } from '~/common/schema'

interface DocLinkProps {
  setLink: UseSetLinkMutationResult
  docCryptIds: CryptId[]
}
interface DocSearchProp extends DocLinkProps {
  allowedDocTypes?: ZDocType[]
}

interface DocSearchModalProp extends DocSearchProp {
  open: boolean
  setOpen: (state: boolean) => void
}

interface DocRowProps extends DocLinkProps {
  doc: ZAugmentedDoc
}

const DocRow: React.FC<DocRowProps> = ({ doc, docCryptIds, setLink: _setLink }) => {
  const setLink = useMutation({ mutationFn: _setLink.mutateAsync })
  const docIsLinked = docCryptIds.some((cryptId) => cryptId.equals(doc.cryptId))
  return (
    <Group>
      <DocCard doc={doc} />
      <LinkSwitchWithTooltip
        isLinked={docIsLinked}
        onClick={() => setLink.mutateAsync({ docCryptId: doc.cryptId, linkValue: !docIsLinked })}
        isLoading={setLink.isLoading}
        linkType='document'
      />
    </Group>
  )
}

interface DocsResultViewProps extends DocLinkProps {
  queryResult: DocSearchResults
  noQuery: boolean
}

const DocsResultView: React.FC<DocsResultViewProps> = ({
  queryResult,
  noQuery,
  ...docLinkProps
}) => {
  const { docs, count } = mkDocsAndCount(queryResult)

  if (noQuery) {
    return (
      <Center>
        <Text size='lg' c='dimmed'>
          Query Needed
        </Text>
      </Center>
    )
  }
  return (
    <LoadingErrorComp queryResult={queryResult}>
      {(() => {
        if (docs.length > 0) {
          return (
            <>
              <Center>
                <Text>
                  Displaying {docs.length} of {enhanceCount(count)} search results
                </Text>
              </Center>
              <ScrollArea style={{ flex: 1 }}>
                <Stack mr='auto' style={{ gap: theme.spacing.sm }}>
                  {docs.map((doc) => (
                    <DocRow key={doc.cryptId.idStr} doc={doc} {...docLinkProps} />
                  ))}
                  <LoadMoreInfiniteQuery query={queryResult} />
                </Stack>
              </ScrollArea>
            </>
          )
        }
        return (
          <Center>
            <Text size='xl' c='dimmed' mb='lg'>
              No Documents Found
            </Text>
          </Center>
        )
      })()}
    </LoadingErrorComp>
  )
}

const DocSearch: React.FC<DocSearchProp> = ({ allowedDocTypes = [], ...docLinkProps }) => {
  const defaultQueryObj = { queries: allowedDocTypes.map(mkTypeOptionValue) }
  const { setQueryObj, queryObj, noQuery, searchResults } = useDocSearch(defaultQueryObj)
  const options = useSearchOptions([], allowedDocTypes, stringComparisonOptionsFilterFn)
  const openFilterModal = useFilterStore((state) => state.openModal)

  return (
    <Stack
      style={{
        width: theme.other.widths.md + 125,
        height: '75vh',
        gap: theme.spacing.lg,
      }}
      align='stretch'
    >
      <Box>
        <MultiSelectSearchInput
          options={options}
          value={queryObj.queries}
          onChange={(value) => setQueryObj((q) => ({ ...q, queries: value }))}
          isFetching={searchResults.isFetching}
          multiline
          placeholder='Search all documents'
          filterOptions={{
            count: countTotalFilters(queryObj),
            openFilterModal: () =>
              openFilterModal({
                mode: 'DOCUMENT',
                queryObj,
                setQueryObj,
                allowedTypes: allowedDocTypes,
              }),
          }}
        />
      </Box>
      <DocsResultView queryResult={searchResults} noQuery={noQuery} {...docLinkProps} />
    </Stack>
  )
}

export const DocSearchModal: React.FC<DocSearchModalProp> = ({
  open,
  setOpen,
  ...docSearchProps
}) => {
  return (
    <BaseModal
      opened={open}
      onClose={() => setOpen(false)}
      title='Link Documents'
      zIndex={zIndex.linkDocsModal}
    >
      <DocSearch {...docSearchProps} />
    </BaseModal>
  )
}
