import type { MantineSize } from '@mantine/core'
import { HoverCard, List, Stack, Text } from '@mantine/core'
import * as React from 'react'
import { RedFlagStatusIcon } from '~/client/components/red-flags'
import type { DocTypeState } from '~/common/doc'
import type { EnhancedCorp, EnhancedRelation, RedFlagInfo } from '~/common/enhance'
import { docTypeStr } from '~/common/schema'
import { ZAugmentedRelation } from '~/common/schema/relation'

const getStateString = (state: DocTypeState) => {
  switch (state) {
    case 'dismissed':
      return ' (Dismissed)'
    case 'missing':
      return ' (Active)'
    default:
      return ''
  }
}

interface HoverCardContentProps {
  item: EnhancedRelation | EnhancedCorp
  redFlagInfo: RedFlagInfo
}

const HoverCardContent: React.FC<HoverCardContentProps> = React.forwardRef<
  HTMLDivElement,
  HoverCardContentProps
>(({ item, redFlagInfo }, ref) => {
  const statusText = item.isActive ? 'Active' : 'Inactive'
  const displayText = ZAugmentedRelation.safeParse(item).success
    ? ZAugmentedRelation.displayFn(item as ZAugmentedRelation)
    : 'Corporation'
  const summaryLine = React.useMemo(() => {
    if (redFlagInfo.typesWithState.length === 0)
      return `${statusText} ${displayText} has no required documents.`
    if (redFlagInfo.missingDocs.length > 0)
      return `${statusText} ${displayText} has some documents missing:`
    if (redFlagInfo.redFlags.filter((redFlag) => !redFlag.dismissed).length > 0)
      return `${statusText} ${displayText} has all required documents but has some red flags:`
    return `${statusText} ${displayText} has all required documents:`
  }, [displayText, redFlagInfo, statusText])

  return (
    <Stack gap={4} ref={ref}>
      <Text>{summaryLine}</Text>
      <List size='sm'>
        {redFlagInfo.typesWithState.map(([type, state]) => (
          <List.Item key={type} icon={<RedFlagStatusIcon size='sm' state={state} />}>
            {docTypeStr(type)}
            {getStateString(state)}
          </List.Item>
        ))}
      </List>
    </Stack>
  )
})

export const RelationStatusDisplay: React.FC<{
  size?: MantineSize | number
  item: EnhancedRelation | EnhancedCorp
  redFlagInfo: RedFlagInfo
}> = ({ size, item, redFlagInfo }) => {
  const iconState = (() => {
    if (!item.isActive) return 'inactive'
    return redFlagInfo.redFlags.filter((redFlag) => !redFlag.dismissed).length > 0
      ? 'missing'
      : 'present'
  })()

  return (
    <HoverCard shadow='md' position='top-start' data-testid='relation-status-display'>
      <HoverCard.Target>
        <RedFlagStatusIcon state={iconState} size={size ?? 28} />
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <div>
          <HoverCardContent item={item} redFlagInfo={redFlagInfo} />
        </div>
      </HoverCard.Dropdown>
    </HoverCard>
  )
}
