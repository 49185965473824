import type { CryptId } from '@cryptid-module'
import { Loader, Menu, ThemeIcon, Tooltip } from '@mantine/core'
import {
  IconArrowsDiagonal,
  IconDotsVertical,
  IconDownload,
  IconShare,
  IconTrash,
  IconUnlink,
} from '@tabler/icons-react'
import type { UseMutationResult } from '@tanstack/react-query'
import React from 'react'
import type { DocCardStateProps } from '~/client/components/doc-card'
import { useDocDetailViewStore } from '~/client/components/doc-detail/state'
import { HoverActionIcon, nestedClickArea } from '~/client/components/util'
import { useDocShare } from '~/client/components/util/doc'
import { deletePromptConfirm } from '~/client/components/util/prompt'
import promptConfirmAsync from '~/client/components/util/prompt-confirm-async'
import { hooks, useCurrentCorpAuth } from '~/client/lib/hooks'
import { theme } from '~/client/lib/theme'
import type { ZAugmentedDoc } from '~/common/schema'

const iconStyle = { color: theme.colors.gray[4] }

export interface DocActionsProp {
  doc: ZAugmentedDoc
  docState?: DocCardStateProps
  deleteDoc: UseMutationResult<unknown, unknown, { cryptId: CryptId }, unknown>
}
const MoreDocActions: React.FC<DocActionsProp> = ({ doc, docState, deleteDoc }) => {
  const { data: auth } = useCurrentCorpAuth()
  const openDocDetail = useDocDetailViewStore((state) => state.openModal)
  const { setLink, sourceCryptIds } = docState ?? {}
  const allowAllTypes = !sourceCryptIds?.some((cryptId) => cryptId.equals(doc.cryptId))
  const isSourceIdFunc = (_cryptId?: CryptId) =>
    !!sourceCryptIds?.find((cryptId) => _cryptId && cryptId.equals(_cryptId))
  const { downloadS3, disabled } = hooks.useDownloadS3(doc)
  const share = useDocShare()

  const isSourceId = isSourceIdFunc(doc.cryptId)
  const isInvestor = auth?.level === 'investor'
  const deletePrompt = async () => {
    const confirm = await deletePromptConfirm({
      dataTestId: 'confirm-document-delete',
      itemName: 'document',
    })
    if (confirm) deleteDoc.mutate({ cryptId: doc.cryptId })
  }

  const unlinkPrompt = async () => {
    const confirm = await promptConfirmAsync({
      title: 'Unlink document?',
      subtitle: 'Are you sure you want to unlink this document?',
      confirmText: 'Unlink',
      confirmProps: { 'data-testid': 'confirm-document-unlink' },
      buttonsColorVariant: 'discourage',
    })
    if (confirm) setLink?.mutate({ docCryptId: doc.cryptId, linkValue: false })
  }

  return (
    <Menu shadow='md' width={200} position='bottom' withinPortal>
      <Menu.Target>
        <Tooltip label='More'>
          <HoverActionIcon
            className={nestedClickArea.cssClass}
            data-testid='more-doc-actions'
            style={iconStyle}
          >
            <IconDotsVertical />
          </HoverActionIcon>
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown className={nestedClickArea.cssClass}>
        <Menu.Item
          leftSection={
            <ThemeIcon>
              <IconArrowsDiagonal />
            </ThemeIcon>
          }
          onClick={() => openDocDetail({ doc, allowAllTypes, ...docState })}
        >
          Open
        </Menu.Item>
        <Menu.Item
          leftSection={
            <ThemeIcon>
              <IconShare />
            </ThemeIcon>
          }
          onClick={() => share(doc)}
        >
          Share
        </Menu.Item>
        <Menu.Item
          leftSection={
            <ThemeIcon>
              <IconDownload />
            </ThemeIcon>
          }
          disabled={disabled}
          onClick={downloadS3}
        >
          Download
        </Menu.Item>
        {setLink && !isSourceId && (
          <Menu.Item
            disabled={setLink.isLoading || isInvestor}
            leftSection={
              <ThemeIcon {...(isInvestor ? { color: 'inactive' } : {})}>
                {setLink.isLoading ? <Loader size='sm' color='inactive' /> : <IconUnlink />}
              </ThemeIcon>
            }
            onClick={() => unlinkPrompt()}
            data-testid='unlink-doc'
          >
            Unlink
          </Menu.Item>
        )}
        {!isSourceId && (
          <Menu.Item
            disabled={deleteDoc.isLoading || isInvestor}
            color='danger'
            leftSection={
              <ThemeIcon color={isInvestor ? 'inactive' : 'danger'}>
                {deleteDoc.isLoading ? <Loader size='sm' color='inactive' /> : <IconTrash />}
              </ThemeIcon>
            }
            onClick={async () => {
              await deletePrompt()
            }}
            data-testid='delete-doc'
          >
            Delete
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  )
}

/**
 * The actions that appear upon hovering over the doc card image.
 * @param doc
 * @returns
 */
export const DocActions: React.FC<DocActionsProp> = ({ doc, docState, deleteDoc }) => {
  const { downloadS3, disabled } = hooks.useDownloadS3(doc)
  const share = useDocShare()

  return (
    <>
      <Tooltip label='Share'>
        <HoverActionIcon
          className={nestedClickArea.cssClass}
          onClick={() => share(doc)}
          style={iconStyle}
        >
          <IconShare />
        </HoverActionIcon>
      </Tooltip>
      <Tooltip label='Download'>
        <HoverActionIcon
          className={nestedClickArea.cssClass}
          onClick={downloadS3}
          style={iconStyle}
          disabled={disabled}
        >
          <IconDownload />
        </HoverActionIcon>
      </Tooltip>
      <MoreDocActions doc={doc} docState={docState} deleteDoc={deleteDoc} />
    </>
  )
}
