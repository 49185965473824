import {
  Button,
  Group,
  type ModalProps,
  Stack,
  Text,
  type TextProps,
  ThemeIcon,
  Title,
} from '@mantine/core'
import { useInterval } from '@mantine/hooks'
import { hideNotification, showNotification } from '@mantine/notifications'
import { IconCloudDownload } from '@tabler/icons-react'
import { useEffect } from 'react'
import { create } from 'zustand'
import { BaseModal } from '~/client/components/modals/util'
import { trpc } from '~/client/lib/trpc'
import { zenvCommon } from '~/common/zenv-common'
import { NextLinkOpt } from './link'

interface VersionUpdateModalState {
  opened: boolean
  open: () => void
  close: () => void
}

export const useVersionUpdateModal = create<VersionUpdateModalState>((set) => ({
  opened: false,
  open: () => set({ opened: true }),
  close: () => set({ opened: false }),
}))

const VersionUpdateComponent: React.FC<TextProps> = (props) => (
  <>
    <Text {...props}>
      A new version of the app is available.{' '}
      <NextLinkOpt onClick={() => window.location.reload()}> Refresh </NextLinkOpt> to update.
    </Text>
    <Text {...props}>The app may not work properly until you refresh.</Text>
  </>
)
const id = 'version_update'

const notifyVersionUpdate = (): void => {
  showNotification({
    id,
    title: 'Version Update',
    message: <VersionUpdateComponent size='sm' />,
    icon: <IconCloudDownload />,
    color: 'urgent',
    withCloseButton: false,
    autoClose: false,
  })
}

export const openVersionUpdateModal = (): void => {
  hideNotification(id)
  useVersionUpdateModal.getState().open()
}

export const VersionUpdateModal: React.FC<Omit<ModalProps, 'title' | 'size' | 'children'>> = (
  props
) => (
  <BaseModal
    size={520}
    title={
      <Group p='sm'>
        <ThemeIcon>
          <IconCloudDownload />
        </ThemeIcon>
        <Title order={3} lh={1}>
          Version Update
        </Title>
      </Group>
    }
    {...props}
  >
    <Group p='sm' gap='xl' justify='center'>
      <Stack gap={0} w='100%'>
        <VersionUpdateComponent size='lg' />
      </Stack>
      <Button size='sm' variant='filled' onClick={() => window.location.reload()}>
        Refresh App
      </Button>
    </Group>
  </BaseModal>
)

export const NewAppVersionModal: React.FC = () => {
  const opened = useVersionUpdateModal((state) => state.opened)
  const close = useVersionUpdateModal((state) => state.close)

  // Can't use `hooks.` here
  const trpcContext = trpc.useContext()
  const interval = useInterval(
    async () => {
      const serverCommitSha = await trpcContext.client.public.vercelGitCommitSha.query()
      if (zenvCommon.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA !== serverCommitSha) {
        openVersionUpdateModal()
      } else {
        close()
        hideNotification(id)
      }
    },
    60 * 60 * 1000
  ) // one hour

  useEffect(() => {
    interval.start()
    return () => interval.stop()
  }, [interval])

  return (
    <VersionUpdateModal
      opened={opened}
      onClose={() => {
        close()
        notifyVersionUpdate()
      }}
      closeOnEscape={false}
    />
  )
}
