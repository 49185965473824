import { RouteType } from './base'

class _CustomRoute<Collection extends string> {
  readonly routeType = RouteType.CUSTOM
  collection: Collection
  name: string
  constructor(collection: Collection, name: string) {
    this.collection = collection
    this.name = name
  }
}

export const customRouteData = [
  new _CustomRoute('unlinked', 'Unlinked Documents'),
  new _CustomRoute('organizational-info', 'Organizational Info'),
  new _CustomRoute('processing', 'Processing'),
]

export type CustomPaths = (typeof customRouteData)[number]['collection']

export type CustomRoute = (typeof customRouteData)[number]

// eslint-disable-next-line custom-rules/prefer-map-to-object-from-entries, custom-rules/no-bad-casting-in-declaration
export const customRouteMap = Object.fromEntries(
  customRouteData.map((route) => [route.collection, route])
) as Record<CustomPaths, CustomRoute>
