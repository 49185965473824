/* eslint-disable @typescript-eslint/no-explicit-any */
import type { MutationStatus, QueryStatus, UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'
import { getQueryKey } from '@trpc/react-query'
import type {
  AnyMutationProcedure,
  AnyProcedure,
  inferProcedureInput,
  inferProcedureOutput,
} from '@trpc/server'

export type InferStatus<T extends AnyProcedure> = T extends AnyMutationProcedure
  ? MutationStatus
  : QueryStatus

export type MockValueSource<TInput, TOutput> =
  | TOutput
  | ((input: TInput) => Promise<TOutput> | TOutput)

// Apply strict typechecking on value type MockResultInput
export type MockValue<
  TStatus extends MutationStatus = MutationStatus,
  TOutput = unknown,
  TInput = unknown,
> = TStatus extends Exclude<TStatus, 'error' | 'success'>
  ? { status: Exclude<TStatus, 'error' | 'success'> }
  : TStatus extends Exclude<TStatus, 'error'>
    ? { status: Exclude<TStatus, 'error'>; data: MockValueSource<TInput, TOutput> }
    : TStatus extends Exclude<TStatus, 'success'>
      ? { status: Exclude<TStatus, 'success'>; error?: unknown } // If error is not specified, will use the default unknown error
      : { status: TStatus; data: MockValueSource<TInput, TOutput>; error?: unknown } // If error is not specified, will use the default unknown error

export type MockResultInput<TProcedure extends AnyProcedure = AnyProcedure> = MockValue<
  InferStatus<TProcedure>,
  inferProcedureOutput<TProcedure>,
  inferProcedureInput<TProcedure>
>

export const getProcedurePath = (procedure: any): string => {
  const queryKey = getQueryKey(procedure)[0]
  if (!Array.isArray(queryKey)) throw new Error('Query key should be an array of strings')
  return queryKey.join('.')
}

const defaultError = () => new Error('Unknown Error')

/**
 * mocks useMutation with only status and associated fields
 * @param status
 * @returns
 */
export const mockUseMutationStatus = <
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown,
>(
  status: MutationStatus
): UseMutationResult<TData, TError, TVariables, TContext> =>
  ({
    status,
    isSuccess: status === 'success',
    isError: status === 'error',
    isLoading: status === 'loading',
    isIdle: status === 'idle',
  }) as any

export const createFnFromMock =
  (mockValue: MockResultInput) =>
  (input?: unknown): any => {
    if (mockValue.status === 'loading') {
      return new Promise(() => {})
    }
    if (mockValue.status === 'success') {
      const { data } = mockValue
      if (typeof data === 'function') {
        const result = data(input)
        if (typeof result === 'object' && typeof result.then === 'function') {
          return result
        }
        return Promise.resolve(result)
      }
      return Promise.resolve(data)
    }
    return Promise.reject(mockValue.error ?? defaultError())
  }

export const mockUseMutationResult =
  <TData = unknown>(
    value: MockValue<MutationStatus, TData>
  ): ((input?: any) => UseMutationResult<TData>) =>
  (_options?: any) => {
    const defaults = {
      data: undefined,
      mutate: () => {},
      mutateAsync: () => new Promise<TData>(() => {}),
      reset: () => {},
      context: undefined,
      variables: undefined,
      isLoading: false,
      isSuccess: false,
      isIdle: false,
      isPaused: false,
      isError: false,
      error: null,
      failureCount: 0,
      failureReason: null,
    } as const

    if (value.status === 'success' && typeof value.data === 'function') {
      const mutationFn = createFnFromMock(value)
      return useMutation({
        mutationFn: (input) => mutationFn(input),
        retry: false,
      })
    }

    // Avoid violating the rule of hooks
    useMutation({})

    if (value.status === 'success') {
      const { data } = value
      if (typeof data === 'function') throw new Error('This error should never happen')
      // We don't use createFnFromMock here because we want to simulate the
      // UseMutationResult even before mutate() or mutateAsync() get called.
      return {
        ...defaults,
        isSuccess: true,
        data,
        status: 'success',
        mutateAsync: () => Promise.resolve(data),
      }
    }
    if (value.status === 'error') {
      return {
        ...defaults,
        error: value.error ?? defaultError(),
        isError: true,
        failureCount: 1,
        failureReason: value.error ?? defaultError(),
        status: 'error',
        mutateAsync: () => Promise.reject(value.error ?? defaultError()),
      }
    }
    if (value.status === 'loading') {
      return {
        ...defaults,
        isLoading: true,
        status: 'loading',
      }
    }
    return {
      ...defaults,
      isIdle: true,
      status: 'idle',
    }
  }
