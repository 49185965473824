import { z } from 'zod'
import {
  AugmentedMetadataDate,
  AugmentedMetadataNumber,
  ZParty,
  withDisplaySchema,
} from '~/common/schema'
import { util } from '~/common/schema/relation/util'

const warrantAugmentedShape = {
  type: z.literal('WARRANT'),
  party: withDisplaySchema(ZParty, 'party', 'Equity Holder'),
  shares: withDisplaySchema(AugmentedMetadataNumber.optional(), 'number', 'Shares'),
  startDate: withDisplaySchema(AugmentedMetadataDate.optional(), 'date', 'Issue Date'),
  endDate: withDisplaySchema(
    AugmentedMetadataDate.optional(),
    'date',
    'Conversion or Termination Date'
  ),
}

export const { ZUpdate: ZUpdateWarrant, ZAugmented: ZAugmentedWarrant } = util.createSchemas(
  {
    updateShape: warrantAugmentedShape,
    augmentedShape: warrantAugmentedShape,
    defaultValue: { party: { name: '' } },
  },
  {
    requiredTypes: [
      'WARRANT_AGREEMENT', // Primary Document
      'BOARD_CONSENT_AND_MINUTES',
    ],
    optionalTypes: ['MISCELLANEOUS', 'STOCKHOLDER_CONSENT'],
    display: 'Warrant',
    displayFn: (obj) => `Warrant ${obj.party.name}`,
    identifyingField: 'party.name',
    tooltipContentFn: (obj, shape) => [
      [shape.party.displaySchema.display, ZParty.display(obj.party)],
      [shape.startDate.displaySchema.display, AugmentedMetadataDate.display(obj.startDate?.value)],
      [shape.endDate.displaySchema.display, AugmentedMetadataDate.display(obj.endDate?.value)],
      [shape.shares.displaySchema.display, AugmentedMetadataNumber.display(obj.shares?.value)],
    ],
  }
)
export interface ZUpdateWarrant extends z.infer<typeof ZUpdateWarrant> {}
export interface ZAugmentedWarrant extends z.infer<typeof ZAugmentedWarrant> {}
