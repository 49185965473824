import { MockedTrpcBuilder } from '~/client/lib/hooks-mock/mocked-trpc-builder'
import { trpc } from '~/client/lib/trpc'

const mockSuggestionResponse = { dates: [], types: [], emails: [] }

// Export without building, to be able to extend this mock in other files.
export const defaultTRPCMock = new MockedTrpcBuilder()
  .mock(trpc.doc.isDuplicate, { status: 'success', data: {} })
  .mock(trpc.doc.suggestions.dates.byUrl, { status: 'success', data: mockSuggestionResponse.dates })
  .mock(trpc.doc.suggestions.types.byUrl, { status: 'success', data: mockSuggestionResponse.types })
  .mock(trpc.doc.suggestions.emails.byUrl, {
    status: 'success',
    data: mockSuggestionResponse.emails,
  })
