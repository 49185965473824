import React from 'react'
import { type StoreApi, useStore } from 'zustand'

interface CreateContextStoreHookRtn<TState> {
  useContextStore: <TSlice>(
    selector: (state: TState) => TSlice,
    equalityFn?: (left: TSlice, right: TSlice) => boolean
  ) => TSlice
}

// Pattern to simplify accessing the store's fields
// https://docs.pmnd.rs/zustand/guides/initialize-state-with-props#extracting-context-logic-into-a-custom-hook
export const createContextStoreHook = <TState>(
  context: React.Context<StoreApi<TState> | null>
): CreateContextStoreHookRtn<TState> => ({
  useContextStore: (selector, equalityFn) => {
    const store = React.useContext(context)
    if (!store) throw new Error(`Missing ${context.displayName} provider in the tree`)
    return useStore(store, selector, equalityFn)
  },
})
