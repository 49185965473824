import type { CryptId } from '@cryptid-module'
import { Button, Menu, ThemeIcon, Tooltip } from '@mantine/core'
import { IconLink, IconPlus } from '@tabler/icons-react'
import React from 'react'
import { useDocDetailState } from '~/client/components/doc-detail/state'
import { CreateRelationModal } from '~/client/components/modals'
import { zIndex } from '~/client/components/z-index'
import { useCurrentCorpAuth } from '~/client/lib/hooks'
import { docTypeMap } from '~/common/schema'
import type { ZRelationTypeValues } from '~/common/schema/relation'

interface RelationsTabActionsMenuProps {
  onLink: () => void
  onCreate?: (relationCryptId: CryptId) => void
  docCryptId: CryptId
  allowCorp: boolean
  allowedTypes: ZRelationTypeValues[]
}

export const RelationsTabActionsMenu: React.FC<RelationsTabActionsMenuProps> = ({
  onLink,
  docCryptId,
  allowCorp,
  allowedTypes,
  onCreate,
}) => {
  const { data: auth } = useCurrentCorpAuth()
  const [modalOpened, setModalOpened] = React.useState(false)
  const docType = useDocDetailState((state) => state.form.values.type)

  const linkEnabled = allowedTypes.length > 0 || allowCorp
  const addEnabled = allowedTypes.length > 0

  if (!linkEnabled && !addEnabled) return null

  return (
    <>
      <Menu
        data-testid='relations-actions-menu'
        position='bottom-end'
        disabled={auth?.level === 'investor'}
        zIndex={zIndex.modal}
        styles={{ label: { padding: 12 } }}
      >
        <Menu.Target>
          <Button disabled={auth?.level === 'investor'} size='sm'>
            Actions
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            data-testid='link-relation-btn'
            onClick={onLink}
            disabled={!linkEnabled}
            leftSection={
              <ThemeIcon>
                <IconLink />
              </ThemeIcon>
            }
          >
            Link Relation
          </Menu.Item>
          <Tooltip
            label={`There are no relation types compatible with ${docTypeMap[docType].short}`}
            disabled={addEnabled}
          >
            {/* Extra div is necessary for the tooltip to be displayed properly */}
            <div>
              <Menu.Item
                data-testid='create-relation-btn'
                onClick={() => setModalOpened(true)}
                disabled={!addEnabled}
                leftSection={
                  <ThemeIcon>
                    <IconPlus />
                  </ThemeIcon>
                }
              >
                Add Relation
              </Menu.Item>
            </div>
          </Tooltip>
        </Menu.Dropdown>
      </Menu>

      {addEnabled && (
        <CreateRelationModal
          opened={modalOpened}
          onClose={() => setModalOpened(false)}
          onCreate={onCreate}
          docType={docType}
          allowedTypes={allowedTypes}
          docCryptId={docCryptId}
        />
      )}
    </>
  )
}
