globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"0c0a4603eb8d5963089a60278531d92756553467"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs'
import { sentryTransactionId } from '~/common/sentry-transaction-id'
import { zenvCommon } from '~/common/zenv-common'
import {
  SENTRY_PROJECT_TAG,
  SENTRY_SENTINEL,
  SentrySender,
  enrichEventWithErrorExtra,
} from '~/sentry.util'

const filterBreadcrumb = (breadcrumb: Sentry.Breadcrumb): Sentry.Breadcrumb | null => {
  // We should not send console logs to sentry
  if (breadcrumb.category === 'console') return null

  return breadcrumb
}

export const manageLogFrontend = (event: Sentry.Breadcrumb | Sentry.Event | null): void => {
  if (!event) return

  if (zenvCommon.NEXT_PUBLIC_SENTRY_ENABLE_CONSOLE_LOG) {
    // eslint-disable-next-line no-console
    console.log(SENTRY_SENTINEL, event)
  }
}

Sentry.init({
  release: zenvCommon.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  dsn: zenvCommon.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 0.1,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
    // https://github.com/Hopding/pdf-lib/issues/1029#issuecomment-944990533
    'Parsed number that is too large for some PDF readers',
    // Just a warning: can safely ignore. More details:
    // https://www.notion.so/Sentry-Removing-parsed-object-0-0-R-9811bd2b9c0843fa860b8f8a0ba79dd3?pvs=4
    'Removing parsed object: 0 0 R',
    // Ignore requests to retrieve appCheck tokens as they clutter the logs.
    // Users do not get any UX issues on these cases.
    // Note that we do not ignore the errors where the appCheck token is required or invalid
    'AppCheck: Requests throttled due to 403 error',
  ],
  integrations: [
    // Capturing console logs generated by other processes (e.g. Firebase)
    Sentry.captureConsoleIntegration({ levels: ['warn', 'error', 'debug', 'assert'] }),
    Sentry.replayIntegration(),
    Sentry.browserTracingIntegration({ instrumentNavigation: true }),
  ],
  debug: zenvCommon.NEXT_PUBLIC_SENTRY_ENABLE_DEBUG,
  environment: zenvCommon.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  beforeSend: (event, hint) => {
    const enrichedEvent: Sentry.ErrorEvent = {
      ...enrichEventWithErrorExtra(event, hint),
      tags: {
        ...event.tags,
        sender: SentrySender.CLIENT,
        // `project` has special meaning in sentry
        project_name: SENTRY_PROJECT_TAG,
        // `canary` and `production` are both under the production environment,
        // but we want to be able to filter between the two on issues or traces
        is_canary: zenvCommon.NEXT_PUBLIC_ALIAS_URL.includes('canary.aerialops.io'),
      },
    }
    manageLogFrontend(enrichedEvent)
    return enrichedEvent
  },
  beforeBreadcrumb: (breadcrumb) => {
    const filteredBreadcrumb = filterBreadcrumb(breadcrumb)
    if (!filteredBreadcrumb) return null

    const enrichedBreadcrumb: Sentry.Breadcrumb = {
      ...filteredBreadcrumb,
      data: {
        ...filteredBreadcrumb.data,
        sender: SentrySender.CLIENT,
      },
    }

    manageLogFrontend(enrichedBreadcrumb)
    return enrichedBreadcrumb
  },
  beforeSendTransaction: (transaction) => {
    return transaction
  },

  // When, between 0~1, replays should be recorded when an error doesn't happen.
  replaysSessionSampleRate: 0.1,

  // "If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs."
  replaysOnErrorSampleRate: 1.0,
} satisfies Sentry.BrowserOptions)

// Set the transactionId on the server (rather than useEffect) so the initial
// load is associated with the transaction id.
// This has been tested and does generate a new transaction id on each page
// load, not per build.
Sentry.getCurrentScope().setTag('transaction_id', sentryTransactionId)
