import { z } from 'zod'
import {
  ZUpdateLocalCorporation,
  ZUpdateStateCorporation,
} from '~/common/schema/relation/corporate'
import {
  ZUpdateCommon,
  ZUpdateConvertibleNote,
  ZUpdateFundraising,
  ZUpdateOption,
  ZUpdateOptionPlan,
  ZUpdatePreferred,
  ZUpdateSafe,
  ZUpdateValuation,
  ZUpdateWarrant,
} from '~/common/schema/relation/equity-types'
import { ZUpdateAdvisor, ZUpdateDirector, ZUpdateEmployee, ZUpdateOfficer } from './personnel'

const typeUpdateRelationMap = {
  ADVISOR: ZUpdateAdvisor,
  COMMON: ZUpdateCommon,
  DIRECTOR: ZUpdateDirector,
  EMPLOYEE: ZUpdateEmployee,
  FUNDRAISING: ZUpdateFundraising,
  LOCAL: ZUpdateLocalCorporation,
  OFFICER: ZUpdateOfficer,
  OPTIONPLAN: ZUpdateOptionPlan,
  SAFE: ZUpdateSafe,
  CONVERTIBLE: ZUpdateConvertibleNote,
  WARRANT: ZUpdateWarrant,
  STATE: ZUpdateStateCorporation,
  VALUATION: ZUpdateValuation,
  OPTION: ZUpdateOption,
  PREFERRED: ZUpdatePreferred,
} as const

export type AllUpdateRelationMap = typeof typeUpdateRelationMap

type UpdateRelation = AllUpdateRelationMap[keyof AllUpdateRelationMap]
const allUpdateRelations = Object.values(typeUpdateRelationMap)

export const ZUpdateRelation = z.union(
  allUpdateRelations as [UpdateRelation, UpdateRelation, ...UpdateRelation[]]
)
export type ZUpdateRelation = z.infer<typeof ZUpdateRelation>

const allUpdateRelationsPartial = allUpdateRelations.map((schema) => schema.partial())

type _ZUpdateRelationPartial = (typeof allUpdateRelationsPartial)[number]

export const ZUpdateRelationPartial = z.union(
  allUpdateRelationsPartial as [
    _ZUpdateRelationPartial,
    _ZUpdateRelationPartial,
    ..._ZUpdateRelationPartial[],
  ]
)

export type ZUpdateRelationPartial = z.infer<typeof ZUpdateRelationPartial>
