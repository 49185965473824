import type { ZRelationTypeValues } from '~/common/schema/relation/augmented'
import { ZStateLocalType } from '~/common/schema/relation/corporate'
import { ZAugmentedEquity } from '~/common/schema/relation/equity'
import {
  ZAugmentedFundraising,
  ZAugmentedOptionPlan,
  ZAugmentedValuation,
} from '~/common/schema/relation/equity-types'
import {
  ZAugmentedAdvisor,
  ZAugmentedDirector,
  ZAugmentedEmployee,
  ZAugmentedOfficer,
} from '~/common/schema/relation/personnel'
import { RouteType } from './base'

class _CollectionRoute<Collection extends string> {
  readonly routeType = RouteType.RELATION
  collection: Collection
  name: string
  relationTypes: ZRelationTypeValues[]
  constructor(collection: Collection, name: string, relationTypes: ZRelationTypeValues[]) {
    this.collection = collection
    this.name = name
    this.relationTypes = relationTypes
  }
}

export const collectionRouteData = [
  new _CollectionRoute('cap-table', ZAugmentedEquity.display, [
    'SAFE',
    'PREFERRED',
    'COMMON',
    'OPTION',
    'CONVERTIBLE',
    'WARRANT',
  ]),
  new _CollectionRoute('optionplan', ZAugmentedOptionPlan.display, ['OPTIONPLAN']),
  new _CollectionRoute('fundraising', `${ZAugmentedFundraising.display}s`, ['FUNDRAISING']),
  new _CollectionRoute('valuation', `${ZAugmentedValuation.display}s`, ['VALUATION']),
  new _CollectionRoute('employee', `${ZAugmentedEmployee.display}s`, ['EMPLOYEE']),
  new _CollectionRoute('officer', `${ZAugmentedOfficer.display}s`, ['OFFICER']),
  new _CollectionRoute('director', `${ZAugmentedDirector.display}s`, ['DIRECTOR']),
  new _CollectionRoute('advisor', `${ZAugmentedAdvisor.display}s`, ['ADVISOR']),
  new _CollectionRoute('state-and-local', ZStateLocalType.display, ['STATE', 'LOCAL']),
]

export type CollectionPaths = (typeof collectionRouteData)[number]['collection']

export type CollectionRoute = (typeof collectionRouteData)[number]

// eslint-disable-next-line custom-rules/prefer-map-to-object-from-entries, custom-rules/no-bad-casting-in-declaration
export const collectionRouteMap = Object.fromEntries(
  collectionRouteData.map((route) => [route.collection, route])
) as Record<CollectionPaths, CollectionRoute>

// eslint-disable-next-line custom-rules/prefer-map-to-object-from-entries, custom-rules/no-bad-casting-in-declaration
export const relationTypeRouteMap = Object.fromEntries(
  collectionRouteData.flatMap((route) => route.relationTypes.map((type) => [type, route]))
) as Record<ZRelationTypeValues, CollectionRoute>
