/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  InfiniteData,
  QueryClient,
  QueryStatus,
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult,
} from '@tanstack/react-query'
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query'
import type { DecorateProcedure } from '@trpc/react-query/shared'
import type { AnyMutationProcedure, AnyProcedure, AnyQueryProcedure } from '@trpc/server'
import type { MockResultInput, MockValue } from '~/client/lib/hooks-mock/mocked-trpc-builder.utils'
import {
  createFnFromMock,
  getProcedurePath,
  mockUseMutationResult,
} from '~/client/lib/hooks-mock/mocked-trpc-builder.utils'
import {
  useFetchWithCorpKey,
  useInfiniteQueryWithCorpKey,
  useMutationWithCorpKey,
  useQueryWithCorpKey,
} from '~/client/lib/hooks/query'
import { type Trpc, defaultQueryCacheSettings, trpc } from '~/client/lib/trpc'

const defaultQueryMockValue = { status: 'success', data: [] } as const
const defaultMutationMockValue = { status: 'idle' } as const

export class MockedTrpcBuilder {
  private _trpc: Trpc
  private _mockMap = new Map<string, MockResultInput>()
  private _defaultQueryMock?: MockResultInput
  private _defaultInfiniteQueryMock?: MockResultInput
  private _defaultMutationMock?: MockResultInput<AnyMutationProcedure>

  constructor(_trpc?: Trpc) {
    this._trpc = _trpc ?? trpc
  }

  /**
   * Mocks a TRPC Query, InfiniteQuery or Mutation in a typesafe way.
   * Passing a function as mock value will make the procedure call the function
   * as queryFn/mutationFn.
   * Otherwise, the value will be regarded as the result of the procedure call.
   * This means that hooks that call `useMutation` will get the result
   * immediately, without calling `mutateAsync` (which is useful for quickly
   * assessing state in storybook)
   * @param procedure - Procedure to mock (Query or Mutation)
   * @param mock {MockResultInput<TProcedure>} - Either `MockResult` or function that can be used as queryFn/mutationFn
   * @returns this (builder pattern)
   */
  mock = <TProcedure extends AnyProcedure, TFlags, TPath extends string>(
    procedure: DecorateProcedure<TProcedure, TFlags, TPath>,
    mock: MockResultInput<TProcedure>
  ): MockedTrpcBuilder => {
    this._mockMap.set(getProcedurePath(procedure), mock)
    return this
  }

  mockDefaultQuery = (mock: MockResultInput<AnyQueryProcedure>): MockedTrpcBuilder => {
    this._defaultQueryMock = mock
    return this
  }

  mockDefaultInfiniteQuery = (
    mock: MockValue<QueryStatus, InfiniteData<unknown>>
  ): MockedTrpcBuilder => {
    this._defaultInfiniteQueryMock = mock
    return this
  }

  mockDefaultMutation = (mock: MockResultInput<AnyMutationProcedure>): MockedTrpcBuilder => {
    this._defaultMutationMock = mock
    return this
  }

  build = (): Trpc => {
    const mkUseContextHandler = (client: QueryClient, queryPath: string[]): ProxyHandler<any> => ({
      get: (target, key) => {
        if (key === 'invalidate')
          return () =>
            client.invalidateQueries({
              predicate: (query) => query.queryHash.includes(queryPath.join('.')),
            })
        return new Proxy(target[key], mkUseContextHandler(client, [...queryPath, key as string]))
      },
    })
    const handler: ProxyHandler<any> = {
      get: (target, key) => {
        if (key === 'useQuery' || key === useQueryWithCorpKey) return this._getQuery(target)
        if (key === 'useMutation' || key === useMutationWithCorpKey)
          return this._getMutation(target)
        if (key === 'useInfiniteQuery' || key === useInfiniteQueryWithCorpKey)
          return this._getInfiniteQuery(target)
        if (key === 'useFetch' || key === useFetchWithCorpKey) return this._getFetch(target)
        if (key === 'useContext')
          return () => new Proxy(target.useContext(), mkUseContextHandler(useQueryClient(), []))

        return new Proxy(target[key], handler)
      },
    }
    return new Proxy(this._trpc, handler)
  }

  private _getQueryOptions = (procedure: any) => {
    const path = getProcedurePath(procedure)
    const mock = this._mockMap.get(path) ?? this._defaultQueryMock ?? defaultQueryMockValue
    const queryFn = createFnFromMock(mock)
    const extraQueryKeys = typeof mock === 'object' ? [mock] : []
    return { path, mock, queryFn, extraQueryKeys }
  }

  private _getQueryKey(path: string, input: unknown, extraQueryKeys: unknown[]) {
    return ['mocked-query', path, input, ...extraQueryKeys]
  }

  private _getQuery = (procedure: any): ((input?: any) => UseQueryResult) => {
    const { path, extraQueryKeys, queryFn } = this._getQueryOptions(procedure)
    return (input?: any) =>
      useQuery({
        queryKey: this._getQueryKey(path, input, extraQueryKeys),
        queryFn: () => queryFn(input),
        retry: false,
        ...defaultQueryCacheSettings,
      })
  }

  private _getMutation = (procedure: any): ((input?: any) => UseMutationResult) => {
    const mockValue =
      this._mockMap.get(getProcedurePath(procedure)) ??
      this._defaultMutationMock ??
      defaultMutationMockValue
    return mockUseMutationResult(mockValue)
  }

  private _getInfiniteQuery = (
    procedure: any
  ): ((input: any, options: any) => UseInfiniteQueryResult) => {
    const path = getProcedurePath(procedure)
    const mock = this._mockMap.get(path) ?? this._defaultInfiniteQueryMock ?? defaultQueryMockValue
    const extraQueryKeys = typeof mock === 'object' ? [mock] : []
    const queryFn = createFnFromMock(mock)
    return (input: any, options: any) =>
      useInfiniteQuery(
        ['mocked-infinite-query', path, input, ...extraQueryKeys],
        ({ pageParam: cursor }) => queryFn({ ...input, cursor }),
        { retry: false, ...options }
      )
  }

  private _getFetch(procedure: any): () => (input?: any) => Promise<any> {
    const { queryFn, extraQueryKeys, path } = this._getQueryOptions(procedure)
    return () => {
      const queryClient = useQueryClient()
      // Using react-query's fetchQuery instead of just calling the function directly
      // to handle caching more realistically
      return (input) =>
        queryClient.fetchQuery({
          queryKey: this._getQueryKey(path, input, extraQueryKeys),
          queryFn: () => queryFn(input),
          retry: false,
          ...defaultQueryCacheSettings,
        })
    }
  }
}
