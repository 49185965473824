import { useAuthMethods, useAuthStatus, useAuthStore } from '~/client/lib/hooks/auth'
import { useCorpCryptIdSafe, useCurrentCorp } from '~/client/lib/hooks/corp'
import { useGenerateThumbnail } from '~/client/lib/hooks/generate-thumbnail'
import { useIsIntegrationFetchingDocuments } from '~/client/lib/hooks/is-integration-fetching-documents'
import { useDownloadS3, useS3Upload } from '~/client/lib/hooks/s3'
import { useNavigationTabs } from '~/client/lib/hooks/use-navigation-tabs'
import { useCurrentUser } from '~/client/lib/hooks/user'
import { useZipFileMethods, useZipFileStore } from '~/client/lib/hooks/zip-state'
import { useSearchQueries } from '~/client/lib/query-params'
import { trpc } from '~/client/lib/trpc'
import type { Hooks } from './interface'

export const hooksImpl: Hooks = {
  useCorpCryptIdSafe,
  useCurrentCorp,
  useAuthStore,
  useAuthMethods,
  useAuthStatus,
  useS3Upload,
  trpc: () => trpc,
  useCurrentUser,
  useZipFileStore,
  useZipFileMethods,
  useGenerateThumbnail,
  useDownloadS3,
  useNavigationTabs,
  useSearchQueries,
  useIsIntegrationFetchingDocuments,
}
