import type { MultiFactorResolver, MultiFactorUser, User } from 'firebase/auth'
import { z } from 'zod'
import type { StateCreator } from 'zustand'
import { create } from 'zustand'
import { auth, firebaseConfig } from '~/client/lib/firebase'
import { Random } from '~/common/random'
import { atOrThrow, fetchRetry, promiseAllObjectValues } from '~/common/util'
import { zenvCommon } from '~/common/zenv-common'
import type { AuthToken } from './auth-status'

const ZGoogleApiData = z.object({ users: z.object({ passwordUpdatedAt: z.number() }).array() })

type PasswordInfo = { isPasswordUser: true; passwordUpdatedAt: number } | { isPasswordUser: false }

const fetchPasswordInfo = async (
  user: User | null,
  token: AuthToken | undefined
): Promise<PasswordInfo> => {
  const isPasswordUser = user?.providerData.some((provider) => provider.providerId === 'password')

  if (!isPasswordUser || !token) {
    return { isPasswordUser: false }
  }

  const userDataResult = await fetchRetry(
    `https://identitytoolkit.googleapis.com/v1/accounts:lookup?key=${firebaseConfig.apiKey}`,
    {
      init: {
        method: 'POST',
        body: JSON.stringify({ idToken: token.token }),
        headers: { 'Content-Type': 'application/json' },
      },
    }
  )

  const userDataJson = ZGoogleApiData.parse(await userDataResult.json())

  const { passwordUpdatedAt } = atOrThrow(userDataJson.users, 0)
  return { isPasswordUser: true, passwordUpdatedAt }
}

export interface AuthStore {
  loginResolver?: MultiFactorResolver
  setLoginResolver: (loginResolver?: MultiFactorResolver) => void

  enrollMFA: boolean
  setEnrollMFA: (enrollMFA: boolean) => void
  skipEnrollMFA: () => void

  shouldShowMFA: boolean
  setShouldShowMFA: (shouldShowMFA: boolean) => void

  user?: Omit<User, 'photoURL'>
  passwordInfo?: PasswordInfo
  token?: AuthToken
  multiFactorUser?: MultiFactorUser
  setUser: (user: User | null) => Promise<void>
  isLoadingFirstTime: boolean
  /**
   * Profile photo url available for Google users.
   * Do not use this directly because profile photos for
   * MSFT users are stored in S3
   */
  _firebasePhotoUrl?: string
}

export const createAuthStore: StateCreator<AuthStore> = (set, get) => ({
  enrollMFA: true,
  isLoadingFirstTime: true,
  // Only show MFA prompt 20% of the time or in Cypress.
  shouldShowMFA: zenvCommon.NEXT_PUBLIC_FORCE_MFA || new Random().random() < 0.2,

  setLoginResolver: (loginResolver) => set({ loginResolver }),
  setEnrollMFA: (enrollMFA) => set({ enrollMFA }),
  skipEnrollMFA: () => get().setEnrollMFA(false),
  setShouldShowMFA: (shouldShowMFA) => set({ shouldShowMFA }),
  setUser: async (user) => {
    // Force refresh the token to ensure it's up to date. This guarantees that
    // the email_verified field is correct and prevents the user from being
    // unable to login when they just verified their email.
    const token = user ? await user.getIdTokenResult(get().isLoadingFirstTime) : undefined
    set(
      await promiseAllObjectValues({
        token,
        isLoadingFirstTime: false,
        user: user ?? undefined,
        multiFactorUser: user ? auth.multiFactor(user) : undefined,
        passwordInfo: fetchPasswordInfo(user, token),
        _firebasePhotoUrl: user?.photoURL ?? undefined,
      })
    )
  },
})

export const useAuthStore = create<AuthStore>()(createAuthStore)

export type UseAuthStore = typeof useAuthStore
