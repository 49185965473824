import { CryptId } from '@cryptid-module'
import { Group, Radio, Table, Text } from '@mantine/core'
import React from 'react'
import { useDocDetailState } from '~/client/components/doc-detail/state'
import { RelationSearchWithAutofillModal } from '~/client/components/doc-detail/term/relation-search-modal'
import { RelationsTabActionsMenu } from '~/client/components/doc-detail/term/relations-tab-actions-menu'
import { CorporateSummaryTable } from '~/client/components/relation/corporate'
import { MetadataTable } from '~/client/components/relation/detail'
import { LoadingErrorComp } from '~/client/components/util/error'
import { SkeletonRows } from '~/client/components/util/skeleton-rows'
import { hooks, useCorpCryptId, useOptimisticCorpUpdate } from '~/client/lib/hooks'
import { useDocLinks } from '~/client/lib/hooks/doc-links'
import { optimisticMutationOptions } from '~/client/lib/hooks/optimistic-update'
import { useCorpAutofill } from '~/client/lib/hooks/relation-autofill'
import { enhanceCorp, enhanceRelation } from '~/common/enhance'
import { ZAugmentedCorp } from '~/common/schema'
import type { ZAugmentedRelation } from '~/common/schema/relation'
import { getSupportedRelationTypesFromDocType } from '~/common/schema/relation'

const useOptimisticRelationUpdate = (docCryptId: CryptId) => {
  const utils = hooks.trpc().useContext()
  const { corpCryptId } = useCorpCryptId()
  return hooks.trpc().relation.update.useMutationWithCorp({
    ...optimisticMutationOptions(
      utils.relations.byLinkedDoc,
      { docCryptId, corpCryptId },
      (input, oldData) => {
        if (!oldData) return oldData
        const relationIndex = oldData.findIndex((rel) => rel.cryptId.equals(input.cryptId))
        const relation = oldData[relationIndex]
        if (relationIndex === -1 || !relation) return oldData

        return [
          ...oldData.slice(0, relationIndex),
          { ...relation, ...input.data } as ZAugmentedRelation,
          ...oldData.slice(relationIndex + 1),
        ]
      }
    ),
  })
}

const EditRelationTable: React.FC<{
  relation: ZAugmentedRelation
  docCryptId: CryptId
}> = ({ relation, docCryptId }) => {
  const { mutateAsync } = useOptimisticRelationUpdate(docCryptId)
  const enhancedRelation = enhanceRelation(relation, {
    // We want to only show autofill suggestions from the current doc
    currentDocCryptIdForAutofill: docCryptId,
  })

  return (
    <MetadataTable
      data={enhancedRelation}
      update={(data) =>
        mutateAsync({ cryptId: relation.cryptId, data: { type: relation.type, ...data } })
      }
      size='sm'
    />
  )
}

const EditCorpTable: React.FC<{ docCryptId: CryptId }> = ({ docCryptId }) => {
  const corp = hooks.useCurrentCorp()
  const update = useOptimisticCorpUpdate()
  const corpAutofill = useCorpAutofill({ currentDocCryptIdForAutofill: docCryptId })

  return (
    <LoadingErrorComp queryResult={corp}>
      {corp.data && (
        <CorporateSummaryTable
          data={enhanceCorp(corp.data)}
          size='sm'
          update={(data) => update.mutateAsync({ data })}
          autofill={corpAutofill}
        />
      )}
    </LoadingErrorComp>
  )
}

export const usePreloadRelationsTab = (docCryptId?: CryptId): void => {
  hooks
    .trpc()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .relations.byLinkedDoc.useQueryWithCorp({ docCryptId: docCryptId! }, { enabled: !!docCryptId })
}

type SelectedItem = { type: 'relation'; cryptId: CryptId } | { type: 'corp' }

const LinkedRelationsEdit: React.FC<{
  docCryptId: CryptId
  selectedItem?: SelectedItem
  setSelectedItem: (item?: SelectedItem) => void
}> = ({ docCryptId, selectedItem, setSelectedItem }) => {
  const { isLinkedToCorp } = useDocLinks()
  const queryResult = hooks.trpc().relations.byLinkedDoc.useQueryWithCorp({ docCryptId })
  const relations = queryResult.data ?? []
  const selectedRelation =
    selectedItem?.type === 'relation'
      ? relations.find((relation) => relation.cryptId.equals(selectedItem.cryptId))
      : undefined
  const corpRadioValue = 'corp'
  const isLinkedToRelationsOrCorp = relations.length > 0 || isLinkedToCorp

  return (
    <LoadingErrorComp
      queryResult={queryResult}
      skeleton={
        <Table striped>
          <Table.Tbody>
            <SkeletonRows types={['RADIO']} rowCount={5} />
          </Table.Tbody>
        </Table>
      }
    >
      <Radio.Group
        value={selectedItem?.type === 'corp' ? corpRadioValue : selectedItem?.cryptId.idStr ?? ''}
        onChange={(value) =>
          setSelectedItem(
            value === corpRadioValue
              ? { type: 'corp' }
              : { type: 'relation', cryptId: new CryptId(value) }
          )
        }
      >
        {/* We don't want the table to be striped when we show "No linked relations" */}
        <Table striped={isLinkedToRelationsOrCorp} mb='md'>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Linked Relations</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {!isLinkedToRelationsOrCorp && (
              <Table.Tr>
                <Table.Td colSpan={99}>
                  <Text c='dimmed' ta='center' my='xl' size='lg'>
                    No linked relations
                  </Text>
                </Table.Td>
              </Table.Tr>
            )}

            {isLinkedToCorp && (
              <Table.Tr>
                <Table.Td>
                  <Radio value={corpRadioValue} label='1.1 Organizational Info' />
                </Table.Td>
              </Table.Tr>
            )}

            {relations.map((relation) => (
              <Table.Tr key={relation.cryptId.idStr}>
                <Table.Td>
                  <Radio value={relation.cryptId.idStr} label={enhanceRelation(relation).display} />
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Radio.Group>
      {selectedRelation && (
        <EditRelationTable relation={selectedRelation} docCryptId={docCryptId} />
      )}
      {isLinkedToCorp && selectedItem?.type === 'corp' && <EditCorpTable docCryptId={docCryptId} />}
      {isLinkedToRelationsOrCorp && !selectedRelation && (
        <Text size='xs' c='dimmed'>
          Select a relation above to edit its metadata
        </Text>
      )}
    </LoadingErrorComp>
  )
}

export const RelationsTab: React.FC<{ docCryptId: CryptId }> = ({ docCryptId }) => {
  const docType = useDocDetailState((state) => state.form.values.type)
  const [linkModalOpened, setLinkModalOpened] = React.useState(false)
  const allowedTypes = getSupportedRelationTypesFromDocType(docType) ?? []
  const allowCorp = ZAugmentedCorp.supportedTypes.includes(docType)

  const [selectedItem, setSelectedItem] = React.useState<SelectedItem>()

  return (
    <>
      <Group justify='flex-end' mb='md'>
        <RelationsTabActionsMenu
          onLink={() => setLinkModalOpened(true)}
          onCreate={(cryptId) => setSelectedItem({ type: 'relation', cryptId })}
          docCryptId={docCryptId}
          allowCorp={allowCorp}
          allowedTypes={allowedTypes}
        />
      </Group>
      <LinkedRelationsEdit
        docCryptId={docCryptId}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
      <RelationSearchWithAutofillModal
        allowedTypes={allowedTypes}
        docCryptId={docCryptId}
        opened={linkModalOpened}
        onClose={() => setLinkModalOpened(false)}
        allowCorp={allowCorp}
      />
    </>
  )
}
