import { UnstyledButton, type UnstyledButtonProps, createPolymorphicComponent } from '@mantine/core'
import { forwardRef } from 'react'
import { appHeaderHeight } from '~/client/components/layout/header'

export const HeaderButton = createPolymorphicComponent<'button', UnstyledButtonProps>(
  forwardRef<HTMLButtonElement, UnstyledButtonProps>(({ children, ...props }, ref) => {
    return (
      <UnstyledButton
        // We take a pixel here to avoid covering up the header border
        h={appHeaderHeight - 1}
        px='sm'
        style={(theme) => ({ ':hover': { backgroundColor: theme.colors.gray[1] } })}
        ref={ref}
        {...props}
      >
        {children}
      </UnstyledButton>
    )
  })
)
