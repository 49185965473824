import type { CryptId } from '@cryptid-module'
import type { UseMutationOptions, UseMutationResult } from '@tanstack/react-query'
import { useMutation, useQuery } from '@tanstack/react-query'
import React from 'react'
import { analytics } from '~/client/components/monitoring'
import { useCurrentCorpAuth } from '~/client/lib/hooks/corp'
import { getHighlights, highlightTextsInPdf } from '~/client/lib/hooks/highlights'
import { useObjectURL } from '~/client/lib/hooks/use-object-url'
import { mkDocFileName } from '~/common/doc'
import type { ZAugmentedDoc, ZAugmentedDocWithHighlights } from '~/common/schema'
import { type OmitOkField, fetchRetry, retryFn } from '~/common/util'
import { zenvCommon } from '~/common/zenv-common'
import { hooks } from './dependency-injection/interface'

export interface S3UploadMutationArgs {
  url: string
  file: BodyInit
  contentType: string
}

export const useS3Upload = (
  options?: UseMutationOptions<void, unknown, S3UploadMutationArgs, unknown>
): UseMutationResult<void, unknown, S3UploadMutationArgs, unknown> => {
  return useMutation(
    async ({ url, file, contentType }) => {
      await fetchRetry(url, {
        init: {
          method: 'PUT',
          body: file,
          ...(contentType ? { headers: { 'Content-Type': contentType } } : {}),
        },
      })
    },
    {
      // To distinguish in Mixpanel analytics
      mutationKey: ['use-s3-upload'],
      retry: 3,
      retryDelay: 1000,
      ...options,
      meta: {
        preventUserRetry: true,
        ...options?.meta,
      },
    }
  )
}
export type UseS3Upload = typeof useS3Upload

export const download = async (blob: Blob, doc: ZAugmentedDoc): Promise<void> => {
  const FileSaver = await import('file-saver')
  FileSaver.default(blob, mkDocFileName(doc), { autoBom: false })
}

interface UseS3Utils {
  /**
   * Gets an S3 signed url for the document's file and fetches that url,
   * returning a response. It retries the whole operation (getting the url +
   * downloading it) once to ensure there are no issues with caching
   * expired urls
   * @param cryptId
   */
  fetchS3: (cryptId: CryptId) => Promise<{ fileUrl: string; response: OmitOkField<Response> }>
  /** Convenience function for fetchS3(cryptId).then(res => res.blob()) */
  fetchS3Blob: (cryptId: CryptId) => Promise<Blob>
}

export const s3FileUrlQueryCacheConfig = {
  // mark as stale a few seconds before expiration to fetch a new signed URL
  staleTime: (zenvCommon.NEXT_PUBLIC_S3_SIGNED_URL_EXPIRATION_SECONDS - 5) * 1000,
  // remove from cache after the expiration time passed, as the URL is no longer valid
  cacheTime: zenvCommon.NEXT_PUBLIC_S3_SIGNED_URL_EXPIRATION_SECONDS * 1000,
  meta: {
    // Don't invalidate before the staleTime, as docs are immutable
    // This also prevents "doc not found" errors when user deletes a doc,
    // and the new doc list has not been fetched yet.
    noGlobalInvalidation: true,
  },
} as const

export const useS3Utils = ({
  maxRetriesS3 = zenvCommon.NEXT_PUBLIC_S3_MAX_RETRY,
}: { maxRetriesS3?: number } = {}): UseS3Utils => {
  const fetchFileUrl = hooks.trpc().doc.fileUrl.useFetchWithCorp(s3FileUrlQueryCacheConfig)
  const utils = hooks.trpc().useContext()

  const rawFetchS3 = React.useCallback(
    async (cryptId: CryptId) => {
      const fileUrl = await fetchFileUrl({ cryptId }).then((res) => res.file)
      try {
        const response = await fetchRetry(fileUrl)
        return { fileUrl, response }
      } catch (error) {
        // Invalidate to try again without cache
        // eslint-disable-next-line custom-rules/prefer-invalidate-queries-to-trpc-context-invalidate
        await utils.doc.fileUrl.invalidate({ cryptId })
        throw error
      }
    },
    [fetchFileUrl, utils]
  )

  const fetchS3 = React.useCallback(
    async (cryptId: CryptId) => {
      return retryFn(() => rawFetchS3(cryptId), maxRetriesS3)
    },
    [rawFetchS3, maxRetriesS3]
  )

  const fetchS3Blob = React.useCallback(
    async (cryptId: CryptId) => {
      const { response } = await fetchS3(cryptId)
      return response.blob()
    },
    [fetchS3]
  )

  return { fetchS3, fetchS3Blob }
}

export interface UseDownloadS3Rtn {
  downloadS3: () => Promise<void>
  disabled: boolean
}

/**
 * Provides a download function that does not cache the PDF in memory. This is
 * useful for doc views where we want to show multiple download buttons, but not
 * store the PDFs.
 */
export const useDownloadS3 = (doc: ZAugmentedDoc | undefined): UseDownloadS3Rtn => {
  const { fetchS3Blob } = useS3Utils()

  const downloadS3 = React.useCallback(async () => {
    if (!doc) throw Error('Unable to fetch without doc object')
    const { cryptId } = doc
    const blob = await fetchS3Blob(cryptId)
    await download(blob, doc)
    analytics.trackEventSuccess('DOC_DOWNLOAD')
  }, [doc, fetchS3Blob])

  return { downloadS3, disabled: !doc }
}
export type UseDownloadS3 = typeof useDownloadS3

export interface UseQueryS3ObjectUrlRtn {
  data: Blob | undefined
  isLoading: boolean
}

/** Store Blob on react-query cache. Will require creating ObjectURLs when
 * showing the PDF to the user. This is slightly more inefficient than storing
 * the ObjectURL in memory (as it requires us to allocate twice the amount of
 * memory for the current PDF only) but allows us to avoid revoking the ObjectURLs in
 * a global hook subscribed to the query cache. This approach uses O(1) more memory */
export const useQueryS3Blob = (
  doc: Pick<ZAugmentedDocWithHighlights, 'cryptId' | 'highlights'> | undefined
): UseQueryS3ObjectUrlRtn => {
  const { fetchS3Blob } = useS3Utils()

  const textsToHighlight = getHighlights(doc)

  const { data, isLoading } = useQuery(
    [
      'use-query-s3-object-url',
      doc?.cryptId.idStr,
      ...(textsToHighlight && textsToHighlight.length > 0
        ? [[...textsToHighlight].sort().join(',')]
        : []),
    ],
    async () => {
      if (!doc?.cryptId) return
      const file = await fetchS3Blob(doc.cryptId)

      // add highlights to the pdf if the doc has highlights
      // adding this here so we cache the pdf with the highlights
      if (!textsToHighlight || textsToHighlight.length === 0) return file
      return highlightTextsInPdf(file, textsToHighlight)
    },
    {
      retry: 0,
      staleTime: Infinity,
      enabled: !!doc?.cryptId,
      meta: {
        noGlobalInvalidation: true,
      },
    }
  )

  return { data, isLoading }
}

export const useDownloadOrGenerateThumbnail = (
  doc?: ZAugmentedDoc
): { isLoading: boolean; objectUrl: string | undefined } => {
  const generateThumbnail = hooks.useGenerateThumbnail()
  const upload = hooks.useS3Upload({ meta: { noErrorNotification: true } })
  const fetchThumbWriteUrl = hooks.trpc().doc.thumbWriteUrl.useFetchWithCorp()
  const { data: auth } = useCurrentCorpAuth()
  const { fetchS3 } = useS3Utils()

  const { data, isLoading } = useQuery({
    queryKey: ['use-download-or-generate-thumbnail', doc?.thumb],
    // We can't return an objectURL from the query because that will be cached, causing us to use expired urls
    queryFn: async (): Promise<{ blob?: Blob }> => {
      // cannot return undefined from queryFn
      if (!doc) return {}
      try {
        const thumbResponse = await fetchRetry(doc.thumb)
        return { blob: await thumbResponse.blob() }
      } catch {
        // We don't report the error here to Sentry because we can have
        // documents without a thumbnail when we download them from an
        // integration

        // we fetch the fileUrl here to avoid prop drilling and to only generate
        // it when required.
        const response = await fetchS3(doc.cryptId).then(
          (result) => result.response,
          () => undefined
        )
        if (!response) return {}

        const file = await response.arrayBuffer()
        const thumbBuffer = await generateThumbnail.mutateAsync({ buffer: file })

        // investors are not allowed to upload files to s3
        if (auth?.level && auth.level !== 'investor') {
          const { thumb: thumbWriteUrl } = await fetchThumbWriteUrl({ cryptId: doc.cryptId })
          await upload.mutateAsync({
            url: thumbWriteUrl,
            file: new Uint8Array(thumbBuffer),
            contentType: 'image/png',
          })
        }

        return { blob: new Blob([new Uint8Array(thumbBuffer)]) }
      }
    },
  })
  const objectUrl = useObjectURL(data?.blob)

  return { isLoading, objectUrl }
}
