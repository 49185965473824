import type { ZDocForm } from '~/client/components/doc-detail/state'
import promptConfirmAsync from '~/client/components/util/prompt-confirm-async'
import { docTypeStr } from '~/common/schema'

interface PromptSaveProp {
  docType: ZDocForm['type']
  oldDocType?: ZDocForm['type']
  docTypeRecommended: boolean
}

export const promptCancelNewDoc = async (): Promise<boolean> => {
  /**
   * Returns `true` to discard the changes, `false` to cancel the discard.
   * */
  const discardDocPrompt = (subtitle: string) =>
    promptConfirmAsync({
      title: 'Cancel doc upload',
      subtitle,
      confirmText: 'Delete',
      confirmProps: {
        'data-testid': 'cancel doc upload',
      },
      cancelProps: { 'data-testid': 'keep doc' },
      cancelText: 'Keep document',
      buttonsColorVariant: 'discourage',
    })

  return discardDocPrompt('Are you sure you want to delete this document?')
}

export const promptCloseDiscardChanges = async (): Promise<boolean> => {
  /**
   * Returns `true` to discard the changes, `false` to cancel the discard.
   * */
  return promptConfirmAsync({
    title: 'Discard changes to document',
    subtitle:
      'There are unsaved changes to this document.' +
      ' Do you want to discard the changes to this document?',
    confirmText: 'Discard',
    confirmProps: {
      'data-testid': 'discard doc detail changes',
    },
    cancelProps: { 'data-testid': 'cancel doc detail discard' },
    buttonsColorVariant: 'discourage',
  })
}
/**
 * Return `true` to save changes prompting user
 */
export const promptSave = async ({
  docType,
  oldDocType,
  docTypeRecommended,
}: PromptSaveProp): Promise<boolean> => {
  const warningChangesPrompt = (subtitle: string) =>
    promptConfirmAsync({
      title: 'Confirm document type',
      subtitle,
      confirmText: 'Save',
      buttonsColorVariant: 'encourage',
    })

  if (!oldDocType || docType === oldDocType) {
    return true
  }
  if (oldDocType === 'PROCESSING') {
    // We want to move documents out of 'PROCESSING', and we should not prompt users
    // when doing so
    return true
  }

  if (docTypeRecommended) {
    return warningChangesPrompt(
      `Changing this document’s type to “${docTypeStr(
        docType
      )}” will unlink it from all other currently linked sections.`
    )
  }

  // !docTypeRecommended && allowNotRecommended
  return warningChangesPrompt(
    `Changing this document’s type to “${docTypeStr(
      docType
    )}” will move this document out of this section and unlink it from all currently linked sections.`
  )
}
