/**
 * This method clears all local data to "reset" the state of the app de novo
 * Allows users to get rid of (local) stateful errors
 * Warning: will log user out
 */
export const clearIndexDbAndLocalStorage = (): void => {
  // we clear firebase object store on the browser, which logs out the user
  // we avoid deleting the database since that causes cypress tests to fail for an unknown reason
  indexedDB.open('firebaseLocalStorageDb').onsuccess = (event) => {
    const db = (event.target as IDBOpenDBRequest).result
    const request = db
      .transaction('firebaseLocalStorage', 'readwrite')
      .objectStore('firebaseLocalStorage')
    request.clear()
  }
  localStorage.clear()
}
