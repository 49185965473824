import { z } from 'zod'
import {
  AugmentedMetadataDate,
  AugmentedMetadataNumber,
  AugmentedMetadataPrice4,
  AugmentedMetadataString,
} from '~/common/schema/metadata'
import { util } from '~/common/schema/relation/util'
import { withDisplaySchema } from '~/common/schema/with-display-schema'

// TODO: rename variables and update comments
const optionPlanAugmentedShape = {
  type: z.literal('OPTIONPLAN'),
  poolSize: withDisplaySchema(AugmentedMetadataNumber.optional(), 'number', 'Total Equity Pool'), // The current aggregate size of option pool
}

export const { ZUpdate: ZUpdateOptionPlan, ZAugmented: ZAugmentedOptionPlan } = util.createSchemas(
  {
    updateShape: optionPlanAugmentedShape,
    augmentedShape: optionPlanAugmentedShape,
    defaultValue: {},
  },
  {
    requiredTypes: [
      'STOCK_OPTION_PLAN', // Primary Document
      'BOARD_CONSENT_AND_MINUTES',
      'STOCKHOLDER_CONSENT',
    ],
    optionalTypes: ['MISCELLANEOUS'],
    display: 'Equity Plan',
    displayFn: (obj) =>
      `Equity Incentive Plan ${AugmentedMetadataDate.display(obj.startDate?.value) ?? ''}`,
    identifyingField: 'startDate',
    tooltipContentFn: (obj, shape) => [
      [shape.startDate.displaySchema.display, AugmentedMetadataDate.display(obj.startDate?.value)],
      [shape.endDate.displaySchema.display, AugmentedMetadataDate.display(obj.endDate?.value)],
      [shape.poolSize.displaySchema.display, AugmentedMetadataNumber.display(obj.poolSize?.value)],
    ],
  }
)

export interface ZUpdateOptionPlan extends z.infer<typeof ZUpdateOptionPlan> {}
export interface ZAugmentedOptionPlan extends z.infer<typeof ZAugmentedOptionPlan> {}

const valuationAugmentedShape = {
  type: z.literal('VALUATION'),
  valuation: withDisplaySchema(AugmentedMetadataPrice4.optional(), 'price4', 'Valuation'), // consider changing to sharePrice
}

export const { ZUpdate: ZUpdateValuation, ZAugmented: ZAugmentedValuation } = util.createSchemas(
  {
    updateShape: valuationAugmentedShape,
    augmentedShape: valuationAugmentedShape,
    defaultValue: {},
  },
  {
    requiredTypes: [
      '_409A_REPORT', // Primary Document
      'BOARD_CONSENT_AND_MINUTES',
    ],
    optionalTypes: ['MISCELLANEOUS'],
    display: '409A Valuation',
    displayFn: (obj) =>
      `409A Valuation (${AugmentedMetadataDate.display(obj.startDate?.value) ?? ''})`,
    identifyingField: 'startDate',
    tooltipContentFn: (obj, shape) => [
      [shape.startDate.displaySchema.display, AugmentedMetadataDate.display(obj.startDate?.value)],
      [shape.endDate.displaySchema.display, AugmentedMetadataDate.display(obj.endDate?.value)],
      [
        shape.valuation.displaySchema.display,
        AugmentedMetadataPrice4.display(obj.valuation?.value),
      ],
    ],
  }
)

export interface ZUpdateValuation extends z.infer<typeof ZUpdateValuation> {}
export interface ZAugmentedValuation extends z.infer<typeof ZAugmentedValuation> {}

const fundraisingAugmentedShape = {
  type: z.literal('FUNDRAISING'),
  round: withDisplaySchema(AugmentedMetadataString.optional(), 'string', 'Round'),
  sharePrice: withDisplaySchema(AugmentedMetadataPrice4.optional(), 'price4', 'Share Price'),
  shares: withDisplaySchema(AugmentedMetadataNumber.optional(), 'number', 'Number of Shares'),
  startDate: withDisplaySchema(AugmentedMetadataDate.optional(), 'date', 'Date'),
}

export const { ZUpdate: ZUpdateFundraising, ZAugmented: ZAugmentedFundraising } =
  util.createSchemas(
    {
      updateShape: fundraisingAugmentedShape,
      augmentedShape: fundraisingAugmentedShape,
      defaultValue: {},
    },
    {
      requiredTypes: [
        'PREFERRED_STOCK_PURCHASE_AGREEMENT', // Primary Document
        'VOTING_AGREEMENT',
        'RIGHT_OF_FIRST_REFUSAL_AND_COSALE_AGREEMENT',
        'INVESTOR_RIGHTS_AGREEMENT',
        'BOARD_CONSENT_AND_MINUTES',
        'STOCKHOLDER_CONSENT',
        'ARTICLES_OF_INCORPORATION',
      ],
      optionalTypes: [
        'MISCELLANEOUS',
        'LEGAL_OPINION',
        'SIDE_LETTER',
        'SECURITIES_LAW_FILING',
        'PRO_FORMA_CAP_TABLE',
      ],
      display: 'Fundraising Round',
      displayFn: (obj) => `${obj.round?.value ?? 'Unknown'} Funding Round`,
      identifyingField: 'startDate',
      tooltipContentFn: (obj, shape) => [
        [shape.round.displaySchema.display, AugmentedMetadataString.display(obj.round?.value)],
        [
          shape.startDate.displaySchema.display,
          AugmentedMetadataDate.display(obj.startDate?.value),
        ],
        [shape.shares.displaySchema.display, AugmentedMetadataNumber.display(obj.shares?.value)],
        [
          shape.sharePrice.displaySchema.display,
          AugmentedMetadataPrice4.display(obj.sharePrice?.value),
        ],
      ],
    }
  )

export interface ZUpdateFundraising extends z.infer<typeof ZUpdateFundraising> {}
export interface ZAugmentedFundraising extends z.infer<typeof ZAugmentedFundraising> {}
