import React from 'react'
import type { DetailTableProps } from '~/client/components/relation/detail/types'
import type { ZAugmentedRelation, ZUpdateRelation } from '~/common/schema/relation'
import {
  ZAugmentedAdvisor,
  ZAugmentedDirector,
  ZAugmentedEmployee,
  ZAugmentedOfficer,
} from '~/common/schema/relation'
import { CommonMetadataTable } from './common'
import { ConvertibleMetadataTable } from './convertible'
import { LocalMetadataTable, StateMetadataTable } from './corporate'
import { FundraisingMetadataTable } from './fundraising'
import { OptionMetadataTable } from './option'
import { OptionPlanMetadataTable } from './option-plan'
import { PersonnelMetadataTable } from './personnel'
import { PreferredMetadataTable } from './preferred'
import { SafeMetadataTable } from './safe'
import { ValuationMetadataTable } from './valuation'
import { WarrantMetadataTable } from './warrant'

export const MetadataTable: React.FC<DetailTableProps<ZUpdateRelation, ZAugmentedRelation>> = ({
  data,
  ...props
}) => {
  const { type } = data
  switch (type) {
    case 'LOCAL':
      return <LocalMetadataTable data={data} {...props} />
    case 'STATE':
      return <StateMetadataTable data={data} {...props} />
    case 'COMMON':
      return <CommonMetadataTable data={data} {...props} />
    case 'OPTION':
      return <OptionMetadataTable data={data} {...props} />
    case 'PREFERRED':
      return <PreferredMetadataTable data={data} {...props} />
    case 'SAFE':
      return <SafeMetadataTable data={data} {...props} />
    case 'CONVERTIBLE':
      return <ConvertibleMetadataTable data={data} {...props} />
    case 'WARRANT':
      return <WarrantMetadataTable data={data} {...props} />
    case 'VALUATION':
      return <ValuationMetadataTable data={data} {...props} />
    case 'OPTIONPLAN':
      return <OptionPlanMetadataTable data={data} {...props} />
    case 'FUNDRAISING':
      return <FundraisingMetadataTable data={data} {...props} />
    case 'DIRECTOR':
      return <PersonnelMetadataTable schema={ZAugmentedDirector} data={data} {...props} />
    case 'ADVISOR':
      return <PersonnelMetadataTable schema={ZAugmentedAdvisor} data={data} {...props} />
    case 'OFFICER':
      return <PersonnelMetadataTable schema={ZAugmentedOfficer} data={data} {...props} />
    case 'EMPLOYEE':
      return <PersonnelMetadataTable schema={ZAugmentedEmployee} data={data} {...props} />
  }
}
