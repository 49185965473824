import { authorizationHeaderKey } from '~/common/header-key'

export interface ScrubberConfig {
  /** Value to be used when replacing sensitive data. */
  scrubString: string
  /** Will scrub data if the key name includes one of these values */
  partialKeyMatchers: string[]
  /** Will scrub data if the key name is an exact match with one of these values */
  literalKeyMatchers: string[]

  excludeFiles: boolean
  excludeBlobs: boolean
  excludeArrayBuffers: boolean
}

/** A Scrubber instance exposes the `.scrub()` method to scrub potentially
 * sensitive data given the configuration used to create the instance.*/
export class Scrubber {
  config: ScrubberConfig

  constructor(config: ScrubberConfig) {
    this.config = config
  }

  private isKeyNamePossiblySensitive(key: string) {
    const lowerCaseKey = key.toLowerCase()
    const { partialKeyMatchers, literalKeyMatchers } = this.config
    return (
      partialKeyMatchers.some((matcher) => lowerCaseKey.includes(matcher)) ||
      literalKeyMatchers.includes(lowerCaseKey)
    )
  }

  /**
   * Remove data that is likely sensitive from an input, so it can
   * be safely stored.
   * @param input
   */
  scrub = (input: unknown): unknown => {
    // Scrub individual values in the array. This does not match typed arrays (like Uint8Array)
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/isArray#using_array.isarray
    if (Array.isArray(input)) return input.map(this.scrub)

    // Keep everything that is not an object
    if (typeof input !== 'object' || input === null) return input

    // Scrub ArrayBufferView and all TypedArrays (like Uint8Array).
    // Also check the constructor name because we had problems in the past with
    // 'instanceof ArrayBuffer'. Also, there are cases where instanceof fails
    // because of different execution environments:
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/instanceof#instanceof_and_multiple_realms
    const isBuffer =
      ArrayBuffer.isView(input) ||
      input instanceof ArrayBuffer ||
      input.constructor.name === 'ArrayBuffer'
    if (isBuffer && this.config.excludeArrayBuffers) return this.config.scrubString
    if (isBuffer && !this.config.excludeArrayBuffers) return input

    if (input instanceof Blob && this.config.excludeBlobs) return this.config.scrubString
    if (input instanceof Blob && !this.config.excludeBlobs) return input

    if (input instanceof File && this.config.excludeFiles) return this.config.scrubString
    if (input instanceof File && !this.config.excludeFiles) return input

    const record: Record<string, unknown> = {}
    Object.entries(input).forEach(([key, value]) => {
      if (this.isKeyNamePossiblySensitive(key)) {
        record[key] = this.config.scrubString
        return
      }
      record[key] = this.scrub(value)
    })
    return record
  }
}

export const DEFAULT_SCRUBBER_CONFIG: ScrubberConfig = {
  scrubString: '***',
  // Includes values from Sentry's default list of sensitive keys
  // https://github.com/getsentry/sentry-python/blob/d97e7d75f740942adfd61742372747b041a76228/sentry_sdk/scrubber.py#L61
  partialKeyMatchers: [
    'password',
    'secret',
    'token',
    'jwt',
    'passwd',
    'api_key',
    /* cspell:disable-next-line */
    'apikey',
    'auth',
    'credentials',
    'mysql_pwd',
    'privatekey',
    'private_key',
    'token',
    'ip_address',
    'session',
    'remote_addr',
    'forwarded_for',
    'set_cookie',
    'cookie',
    'authorization',
    authorizationHeaderKey,
    'connect.sid',
    'csrf',
    /* cspell:disable-next-line */
    'phpsessid',
    /* cspell:disable-next-line */
    'symfony',
    'xsrf',
  ],
  literalKeyMatchers: [],

  excludeFiles: true,
  excludeBlobs: true,
  excludeArrayBuffers: true,
}

export const defaultScrubber = new Scrubber(DEFAULT_SCRUBBER_CONFIG)
