import type { z } from 'zod'
import { ZAugmentedCorp } from '~/common/schema/corp'
import { ZPermissionsLevel } from '~/common/schema/role'

export const ZAugmentedQueryCorpsItem = ZAugmentedCorp.pick({
  name: true,
  startDate: true,
  cryptId: true,
  partnershipTier: true,
}).extend({ userRole: ZPermissionsLevel })

export interface ZAugmentedQueryCorpsItem extends z.infer<typeof ZAugmentedQueryCorpsItem> {}
