import { Box, Button, Stack, Text, TextInput } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { closeAllModals, openModal } from '@mantine/modals'
import type { ReactNode } from 'react'
import { z } from 'zod'

interface WarningConfirmationAsyncProps {
  title: string
  description: ReactNode
  buttonLabel: string
  buttonIcon?: ReactNode
  nameValidation: string
}

interface WarningConfirmationFormProps extends Omit<WarningConfirmationAsyncProps, 'title'> {
  resolver: (_: boolean) => void
}

const WarningConfirmationForm = ({
  buttonLabel,
  buttonIcon,
  description,
  nameValidation,
  resolver,
}: WarningConfirmationFormProps) => {
  const form = useForm<{ name: string }>({
    validate: zodResolver(
      z.object({
        name: z.custom((name) => name === nameValidation, {
          message: `does not match ${nameValidation}`,
        }),
      })
    ),
  })

  return (
    <form
      onSubmit={form.onSubmit(() => {
        resolver(true)
        closeAllModals()
      })}
    >
      <Stack>
        <Box>
          {description}
          <Text mt='sm'>
            To proceed, please type <b>{nameValidation}</b> below to confirm:
          </Text>
        </Box>
        <TextInput {...form.getInputProps('name')} data-testid='warning-confirmation-modal-input' />
        <Button
          type='submit'
          color='danger'
          leftSection={buttonIcon}
          data-testid='warning-confirmation-modal-button'
        >
          {buttonLabel}
        </Button>
      </Stack>
    </form>
  )
}

export const warningConfirmationAsync = ({
  title,
  ...rest
}: WarningConfirmationAsyncProps): Promise<boolean> => {
  return new Promise((res) => {
    openModal({
      title,
      children: <WarningConfirmationForm {...rest} resolver={res} />,
      size: 'lg',
    })
  })
}
