import { Button, Table } from '@mantine/core'
import type { UseQueryResult } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import React from 'react'
import { useDocDetailState } from '~/client/components/doc-detail/state'
import { NoRedFlags, RedFlagRow, useCustomRedFlagDetailModal } from '~/client/components/red-flags'
import { nestedClickArea } from '~/client/components/util'
import { LoadingErrorComp } from '~/client/components/util/error'
import { SkeletonRows } from '~/client/components/util/skeleton-rows'
import { TableHeaderRightAligned } from '~/client/components/util/table-cell-right-aligned'
import type { ZAugmentedRedFlag } from '~/common/schema/red-flag'

const DocDetailRedFlagRow: React.FC<{
  redFlag: ZAugmentedRedFlag
  openRedFlagModal: () => void
}> = ({ redFlag, openRedFlagModal }) => {
  const { query } = useRouter()

  React.useEffect(() => {
    if (query.redFlag === redFlag.cryptId.idStr) openRedFlagModal()
    // we do not add `openRedFlagModal` to the deps here to avoid the effect
    // from running when we don't want it to
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.redFlag, redFlag.cryptId.idStr])

  return (
    <RedFlagRow
      compact
      maxWidth={180}
      redFlag={redFlag.type === 'CUSTOM_DOC' ? { ...redFlag, display: redFlag.text } : redFlag}
      onClick={nestedClickArea.avoid(openRedFlagModal)}
    />
  )
}

export const RedFlagsTab: React.FC<{
  redFlagsQueryResult: UseQueryResult<ZAugmentedRedFlag[]>
}> = ({ redFlagsQueryResult }) => {
  const { modal, openCustomRedFlagModal } = useCustomRedFlagDetailModal()
  const docCryptId = useDocDetailState((state) => state.docCryptId)

  return (
    <>
      <Table highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            <Table.Th w='7%' />
            <Table.Th pl='xs'>Flag</Table.Th>
            <TableHeaderRightAligned>
              <Button
                size='compact-sm'
                disabled={!docCryptId}
                data-testid='add-red-flag-btn'
                onClick={() =>
                  docCryptId &&
                  openCustomRedFlagModal({
                    mode: 'new',
                    props: { type: 'CUSTOM_DOC', primaryCryptId: docCryptId },
                  })
                }
              >
                Add
              </Button>
            </TableHeaderRightAligned>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <LoadingErrorComp
            queryResult={redFlagsQueryResult}
            skeleton={<SkeletonRows types={['STATUS', 'NAME', 'DEFAULT']} />}
            fullTableWidth
          >
            {(redFlagsQueryResult.data ?? []).length > 0 ? (
              redFlagsQueryResult.data?.map((redFlag) => (
                <DocDetailRedFlagRow
                  redFlag={redFlag}
                  key={redFlag.cryptId.idStr}
                  openRedFlagModal={() => {
                    if (redFlag.type === 'CUSTOM_DOC') {
                      openCustomRedFlagModal({ mode: 'edit', redFlag })
                    }
                  }}
                />
              ))
            ) : (
              <Table.Td colSpan={3}>
                <NoRedFlags noIcon title='No Red Flags' subtitle='No red flags for this document' />
              </Table.Td>
            )}
          </LoadingErrorComp>
        </Table.Tbody>
      </Table>
      {modal}
    </>
  )
}
