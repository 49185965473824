import { Table } from '@mantine/core'
import React from 'react'
import type { DetailTableProps } from '~/client/components/relation/detail/types'
import { MetadataRow } from '~/client/components/util/metadata'
import type { ZUpdateSafe } from '~/common/schema/relation'
import { ZAugmentedSafe } from '~/common/schema/relation'

export const SafeMetadataTable: React.FC<DetailTableProps<ZUpdateSafe, ZAugmentedSafe>> = ({
  data,
  update,
  size,
}) => (
  <Table striped>
    <Table.Tbody>
      <MetadataRow
        displayProps={ZAugmentedSafe.shape.party.mkDisplayProps({
          data,
          update,
          property: 'party',
          autofillData: data.autofill.parties,
        })}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedSafe.shape.startDate.mkDisplayProps({
          data,
          update,
          property: 'startDate',
          autofillData: data.autofill.startDates,
        })}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedSafe.shape.endDate.mkDisplayProps({
          data,
          update,
          property: 'endDate',
        })}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedSafe.shape.investment.mkDisplayProps({
          data,
          update,
          property: 'investment',
        })}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedSafe.shape.valuationCap.mkDisplayProps({
          data,
          update,
          property: 'valuationCap',
        })}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedSafe.shape.discount.mkDisplayProps({
          data,
          update,
          property: 'discount',
        })}
        size={size}
      />
    </Table.Tbody>
  </Table>
)
