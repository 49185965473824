import type { CryptId } from '@cryptid-module'
import { Box, Center, Group, Loader, Tooltip } from '@mantine/core'
import { IconArrowsDiagonal, IconShare, IconTrash } from '@tabler/icons-react'
import NextLink from 'next/link'
import React, { useEffect } from 'react'
import {
  DocDetailStateProvider,
  useDocDetailState,
  useDocDetailViewStore,
} from '~/client/components/doc-detail/state'
import { appHeaderHeight } from '~/client/components/layout'
import { DocFrameModal } from '~/client/components/modals'
import { NotFound } from '~/client/components/not-found'
import { HoverActionIcon } from '~/client/components/util'
import { useDocShare } from '~/client/components/util/doc'
import { PageTitle } from '~/client/components/util/page-title'
import { deletePromptConfirm } from '~/client/components/util/prompt'
import { useRibbonHeight } from '~/client/components/util/ribbon'
import { hooks, useCurrentCorpAuth } from '~/client/lib/hooks'
import { theme } from '~/client/lib/theme'
import type { ZAugmentedDoc } from '~/common/schema'
import { DocDetailIFrame } from './iframe'
import { Terms } from './term'

const TermsSideMenu: React.FC = () => {
  const form = useDocDetailState((state) => state.form)
  const docCryptId = useDocDetailState((state) => state.docCryptId)
  const shouldShowTerms = !!form.values.file || !!docCryptId
  return shouldShowTerms ? <Terms /> : null
}

const ShareDocument: React.FC<{ doc: ZAugmentedDoc }> = ({ doc }) => {
  const share = useDocShare()

  return (
    <Tooltip label='Share'>
      <HoverActionIcon onClick={() => share(doc)}>
        <IconShare />
      </HoverActionIcon>
    </Tooltip>
  )
}

const FullPageIcon: React.FC<{ url: string }> = ({ url }) => (
  <Tooltip label='Full page'>
    <NextLink href={url}>
      <HoverActionIcon>
        <IconArrowsDiagonal />
      </HoverActionIcon>
    </NextLink>
  </Tooltip>
)

const DeleteDocument: React.FC<{ docCryptId: CryptId; onDelete?: () => void }> = ({
  docCryptId,
  onDelete,
}) => {
  const { data: auth } = useCurrentCorpAuth()
  const isInvestor = auth?.level === 'investor'
  const deleteDoc = hooks.trpc().doc.delete.useMutationWithCorp({ onSuccess: () => onDelete?.() })

  const deletePrompt = async () => {
    const confirm = await deletePromptConfirm({ itemName: 'document' })
    if (confirm)
      await deleteDoc.mutateAsync({
        cryptId: docCryptId,
      })
  }
  return (
    <Tooltip label='Delete'>
      <HoverActionIcon
        loading={deleteDoc.isLoading}
        disabled={isInvestor}
        style={{
          ':hover': { color: theme.colors.red[5] },
        }}
        onClick={async () => {
          await deletePrompt()
        }}
      >
        {deleteDoc.isLoading ? <Loader size='sm' color='inactive' /> : <IconTrash />}
      </HoverActionIcon>
    </Tooltip>
  )
}

export const DocDetailHeader: React.FC<{ doc?: ZAugmentedDoc; onDelete?: () => void }> = ({
  doc,
  onDelete,
}) => {
  if (!doc) return null

  return (
    <>
      <FullPageIcon url={doc.url} />
      <ShareDocument doc={doc} />
      <DeleteDocument docCryptId={doc.cryptId} onDelete={onDelete} />
    </>
  )
}

/**
 * DocDetail without the modal is used in the '/doc' page for fullscreen editing.
 * Used for both uploading new documents and editing existing ones.
 */
export const DocDetail: React.FC = ({ children }) => {
  return (
    <DocDetailStateProvider>
      <Group w='100%' h='100%' gap='lg' align='stretch'>
        <Box flex='auto' h='100%'>
          <DocDetailIFrame />
        </Box>
        <TermsSideMenu />
      </Group>
      {children}
    </DocDetailStateProvider>
  )
}

export const DocDetailModal: React.FC = () => {
  const docDetailState = useDocDetailViewStore((state) => state.docDetailViewState)
  const closeDocDetail = useDocDetailViewStore((state) => state.closeModal)
  return (
    <DocFrameModal
      title={
        <Group>
          {docDetailState.prop?.doc ? docDetailState.prop.doc.title : 'Upload Document'}
          <Group>
            <DocDetailHeader doc={docDetailState.prop?.doc} onDelete={closeDocDetail} />
          </Group>
        </Group>
      }
      opened={docDetailState.state === 'modal'}
      onClose={closeDocDetail}
      data-testid='doc-detail-modal'
      // Hitting the close key will close the modal anyway, showing a prompt if there
      // are unsaved changes. Without this prop, the modal closes before showing the prompt.
      closeOnEscape={false}
    >
      <DocDetail />
    </DocFrameModal>
  )
}

export const FullScreenDocumentComp: React.FC<{ cryptId: CryptId }> = ({ cryptId }) => {
  const { data, isLoading, isError } = hooks.trpc().doc.readByCryptId.useQueryWithCorp({ cryptId })
  const setDocDetailState = useDocDetailViewStore((state) => state.setDocDetailViewState)
  const ribbonHeight = useRibbonHeight()

  useEffect(() => {
    setDocDetailState({
      prop: { doc: data, isLoading },
      state: 'fullscreen',
    })
  }, [data, isLoading, setDocDetailState])

  if (isError) {
    return (
      <Center h='60vh'>
        <NotFound type='document' />
      </Center>
    )
  }

  return (
    <Box
      maw={1200}
      /* 60px is the estimated height of the header */
      h={`calc(100vh - ${appHeaderHeight + 115 + ribbonHeight}px)`}
      mr='auto'
    >
      <PageTitle title={isLoading ? 'Skeleton title' : data.title ?? ''} isSkeleton={isLoading} />
      <DocDetail />
    </Box>
  )
}

export * from './util'
