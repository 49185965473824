import { ActionIcon, Button, Group, Loader, TextInput, Tooltip } from '@mantine/core'
import { type UseFormReturnType, useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { IconFlag, IconFlagOff, IconTrash } from '@tabler/icons-react'
import React from 'react'
import { BaseModal } from '~/client/components/modals'
import { deletePromptConfirm } from '~/client/components/util/prompt'
import promptConfirmAsync from '~/client/components/util/prompt-confirm-async'
import { hooks } from '~/client/lib/hooks'
import type {
  ZAllAugmentedCustomRedFlag,
  ZAugmentedCustomRecordsRedFlag,
  ZAugmentedCustomRedFlag,
} from '~/common/schema/red-flag'

const ActionButtons: React.FC<{
  redFlag: ZAllAugmentedCustomRedFlag
  closeModal: () => void
}> = ({ redFlag, closeModal }) => {
  const mutationProps = { onSuccess: () => closeModal() }
  const deleteMutation = hooks.trpc().redFlags.custom.delete.useMutationWithCorp(mutationProps)
  const dismissMutation = hooks.trpc().redFlags.dismiss.useMutationWithCorp(mutationProps)
  const reFlagMutation = hooks.trpc().redFlags.removeDismissal.useMutationWithCorp(mutationProps)

  const { cryptId } = redFlag

  const deleteRedFlag = async () => {
    const shouldDelete = await deletePromptConfirm({
      itemName: 'red flag',
      dataTestId: 'confirm-red-flag-delete',
    })

    if (shouldDelete) deleteMutation.mutate({ cryptId })
    showNotification({ message: 'Red Flag Deleted' })
  }

  return (
    <>
      {redFlag.dismissed ? (
        <Tooltip label='Re-flag Red Flag'>
          <ActionIcon
            disabled={reFlagMutation.isLoading}
            onClick={() => reFlagMutation.mutate({ cryptId })}
          >
            {reFlagMutation.isLoading ? <Loader size='xs' color='inactive' /> : <IconFlag />}
          </ActionIcon>
        </Tooltip>
      ) : (
        <Tooltip label='Dismiss Red Flag'>
          <ActionIcon
            disabled={dismissMutation.isLoading}
            onClick={() => dismissMutation.mutate({ cryptId })}
          >
            {dismissMutation.isLoading ? <Loader size='xs' color='inactive' /> : <IconFlagOff />}
          </ActionIcon>
        </Tooltip>
      )}
      <Tooltip label='Delete Red Flag'>
        <ActionIcon
          color='danger'
          onClick={deleteRedFlag}
          data-testid='delete-red-flag-btn'
          disabled={deleteMutation.isLoading}
        >
          {deleteMutation.isLoading ? <Loader size='xs' color='inactive' /> : <IconTrash />}
        </ActionIcon>
      </Tooltip>
    </>
  )
}

const promptClose = (mode: RedFlagModalState['mode']) => {
  if (mode === 'edit') {
    return promptConfirmAsync({
      title: 'Discard changes to red flag',
      subtitle:
        'There are unsaved changes to this red flag. Do you want to discard the changes to this red flag',
      confirmText: 'Discard',
      buttonsColorVariant: 'discourage',
    })
  }

  return promptConfirmAsync({
    title: 'Abort red flag creation',
    subtitle: 'Do you want to abort red flag creation?',
    confirmText: 'Abort',
    buttonsColorVariant: 'discourage',
  })
}

export type RedFlagModalState =
  | {
      mode: 'new'
      props: { type: 'CUSTOM' } | Pick<ZAugmentedCustomRecordsRedFlag, 'type' | 'primaryCryptId'>
    }
  | { mode: 'closed' }
  | { mode: 'edit'; redFlag: ZAugmentedCustomRedFlag | ZAugmentedCustomRecordsRedFlag }

export const CustomRedFlagDetailModal: React.FC<{
  onClose: () => void
  state: RedFlagModalState
  form: UseFormReturnType<Pick<ZAugmentedCustomRedFlag, 'text'>>
}> = ({ state, onClose, form }) => {
  const redFlag = state.mode === 'edit' ? state.redFlag : undefined

  const createCustomRedFlagMutation = hooks.trpc().redFlags.custom.create.useMutationWithCorp()
  const updateCustomRedFlagMutation = hooks.trpc().redFlags.custom.update.useMutationWithCorp()

  const isLoading = createCustomRedFlagMutation.isLoading || updateCustomRedFlagMutation.isLoading

  const cancelModal = async () => {
    const isDirty = form.isDirty()
    const shouldClose = !isDirty || isLoading || (await promptClose(state.mode))

    if (shouldClose) onClose()
  }

  return (
    <BaseModal
      size={600}
      onClose={cancelModal}
      opened={state.mode !== 'closed'}
      title={
        <Group>
          Custom Red Flag
          {redFlag && <ActionButtons closeModal={onClose} redFlag={redFlag} />}
        </Group>
      }
    >
      <form
        onSubmit={form.onSubmit(async ({ text }) => {
          if (state.mode === 'new') {
            await createCustomRedFlagMutation.mutateAsync({ ...state.props, text })
          } else if (state.mode === 'edit') {
            await updateCustomRedFlagMutation.mutateAsync({
              cryptId: state.redFlag.cryptId,
              data: { type: state.redFlag.type, text },
            })
          }

          onClose()
        })}
      >
        <TextInput
          autoFocus
          required
          label='Red Flag Name'
          {...form.getInputProps('text')}
          data-testid='red-flag-name-input'
        />
        <Group my='md' gap='lg' justify='flex-end'>
          <Button color='gray' variant='subtle' onClick={cancelModal}>
            Cancel
          </Button>
          <Button disabled={isLoading} type='submit'>
            {isLoading ? <Loader size='sm' /> : 'Save'}
          </Button>
        </Group>
      </form>
    </BaseModal>
  )
}

export const useCustomRedFlagDetailModal = (): {
  modal: JSX.Element
  openCustomRedFlagModal: (state: RedFlagModalState) => void
} => {
  const [redFlagModalState, setRedFlagModalState] = React.useState<RedFlagModalState>({
    mode: 'closed',
  })
  const form = useForm({
    initialValues: { text: '' },
  })

  const modal = (
    <CustomRedFlagDetailModal
      form={form}
      state={redFlagModalState}
      onClose={() => setRedFlagModalState({ mode: 'closed' })}
    />
  )

  const openCustomRedFlagModal = (state: RedFlagModalState) => {
    const values = { text: state.mode === 'edit' ? state.redFlag.text : '' }
    form.setValues(values)
    form.resetDirty(values)

    setRedFlagModalState(state)
  }

  return { modal, openCustomRedFlagModal }
}
