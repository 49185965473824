import type { CheckboxProps } from '@mantine/core'
import { Button, Checkbox, Group, Stack, Text, Title } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { BaseModal } from '~/client/components/modals'
import { hooks } from '~/client/lib/hooks'
import { theme } from '~/client/lib/theme'
import { CURRENT_AGREEMENT_VERSION, currentAgreement } from '~/common/agreement'
import { NextLinkOpt } from './util'

const useLatestAgreement = (): { needSignature: boolean } => {
  const { data: user, isLoading } = hooks.useCurrentUser()
  const router = useRouter()

  // wait until user has loaded
  if (isLoading || !user) {
    return { needSignature: false }
  }

  // we do not show the prompt to agree to the terms if the user is reading them
  if (
    user.agreementVersion >= CURRENT_AGREEMENT_VERSION ||
    router.pathname.startsWith('/agreement/')
  ) {
    return { needSignature: false }
  }

  return {
    needSignature: true,
  }
}

const tosCheckboxCommonProps: CheckboxProps = {
  size: 'xs',
  styles: { body: { alignItems: 'center' } },
}

export const AgreementModal: React.FC = () => {
  const { logout } = hooks.useAuthMethods()
  const { needSignature } = useLatestAgreement()

  const { mutateAsync, isLoading: isLoadingUpdate } = hooks
    .trpc()
    .user.updateAgreementVersion.useMutation({
      onSuccess: () => {
        showNotification({
          title: 'Agreement Signed',
          message: 'Thank you for signing the agreement.',
          color: 'green',
        })
      },
    })

  const [opened, setOpened] = useState(true)

  const [checkedTos, setCheckedTos] = useState(false)
  const [checkedPrivacy, setCheckedPrivacy] = useState(false)
  const [checkedDisclaimer, setCheckedDisclaimer] = useState(false)

  const enableContinue = checkedTos && checkedPrivacy && checkedDisclaimer
  if (!needSignature) return null
  return (
    <BaseModal
      title={<Title order={3}>Aerial, Inc. Customer Agreement</Title>}
      onClose={() => {} /* not dismissible */}
      opened={opened}
      withCloseButton={false}
      closeOnEscape={false}
      closeOnClickOutside={false}
      data-testid='agreement-modal'
      styles={{ content: { padding: theme.spacing.sm } }}
    >
      <Stack align='start'>
        <Text size='lg' w='100%'>
          Check here to indicate that you have read and agree to each of these:
        </Text>

        <Checkbox
          checked={checkedTos}
          onChange={() => setCheckedTos(!checkedTos)}
          data-testid='agreement-modal-checkbox-tos'
          label={
            <NextLinkOpt href={currentAgreement.tosPath} target='_blank'>
              Terms of Service
            </NextLinkOpt>
          }
          {...tosCheckboxCommonProps}
        />
        <Checkbox
          checked={checkedPrivacy}
          onChange={() => setCheckedPrivacy(!checkedPrivacy)}
          data-testid='agreement-modal-checkbox-privacy'
          label={
            <NextLinkOpt href={currentAgreement.privacyPath} target='_blank'>
              Privacy Policy
            </NextLinkOpt>
          }
          {...tosCheckboxCommonProps}
        />

        <Checkbox
          checked={checkedDisclaimer}
          onChange={() => setCheckedDisclaimer(!checkedDisclaimer)}
          data-testid='agreement-modal-checkbox-disclaimer'
          label={
            <NextLinkOpt href={currentAgreement.legalDisclaimerPath} target='_blank'>
              Legal Disclaimer
            </NextLinkOpt>
          }
          {...tosCheckboxCommonProps}
        />

        <Group align='center' justify='end' w='100%'>
          <Button variant='subtle' color='gray' onClick={() => logout()}>
            Log out
          </Button>
          <Button
            disabled={!enableContinue}
            loading={isLoadingUpdate}
            onClick={async () => {
              await mutateAsync({ agreementVersion: CURRENT_AGREEMENT_VERSION })
              setOpened(false)
            }}
            data-testid='agreement-modal-continue-button'
          >
            Accept and continue
          </Button>
        </Group>
      </Stack>
    </BaseModal>
  )
}
