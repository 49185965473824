import { useHover } from '@mantine/hooks'
import type { RefObject } from 'react'
import { useState } from 'react'

interface HoverClick {
  active: boolean
  hoverRef: RefObject<HTMLDivElement>
  handleClick: () => void
}

/**
 * Used to track hover on an element and click on another
 *
 * When click is detected, hover is disabled
 *
 * Use `hoverRef` as ref for element to listen for hover
 * and use `handleClick` as click handler for element to listen for clicks
 * @returns: { active, hoverRef, handleClick }
 */

export const useHoverClick = (): HoverClick => {
  // keeps track of open and close clicks
  const [openedClicked, setOpenedClicked] = useState(false)
  // determines if hover is disables or not
  const [clickControl, setClickControl] = useState(false)
  const { hovered, ref: hoverRef } = useHover()

  const active = clickControl ? openedClicked : hovered

  const handleClick = () => {
    setClickControl(true)
    setOpenedClicked(!active)
  }

  return { active, hoverRef, handleClick }
}
