import { Button, Center, Group, Stack, Text, Title } from '@mantine/core'
import React from 'react'
import { useCorpCryptId } from '~/client/lib/hooks'
import { theme } from '~/client/lib/theme'
import { zenvCommon } from '~/common/zenv-common'
import { NextLinkOpt } from './util'

type NotFoundType = 'page' | 'corporation' | 'relation' | 'document' | 'corpPage'

const notFoundMsg = (type: Exclude<NotFoundType, 'corpPage'>) => (
  <>
    The {type} you are trying to open does not exist or you do not have access to it. You may have
    mistyped the address or the page has been moved to another URL. If you believe you found this
    page in error, please contact support at{' '}
    <a href={`mailto:${zenvCommon.NEXT_PUBLIC_SUPPORT_EMAIL}`} target='_blank' rel='noreferrer'>
      {zenvCommon.NEXT_PUBLIC_SUPPORT_EMAIL}
    </a>
    .
  </>
)

interface NotFoundError {
  title: string
  message: JSX.Element
}

const getNotFoundError = (type: NotFoundType): NotFoundError => {
  switch (type) {
    case 'document':
      return { title: 'Document not found', message: notFoundMsg('document') }
    case 'corporation':
      return { title: 'Corporation not found', message: notFoundMsg('corporation') }
    case 'relation':
      return { title: 'Relation not found', message: notFoundMsg('relation') }
    case 'page':
    case 'corpPage':
      return { title: 'Nothing to see here', message: notFoundMsg('page') }
  }
}

const typesWithinCorp: NotFoundType[] = ['relation', 'document', 'corpPage']

const DashboardLink: React.FC = () => {
  const { mkCurrentCorpRoute } = useCorpCryptId()

  return (
    <NextLinkOpt href={mkCurrentCorpRoute('dashboard')}>
      <Button size='md'>Take me back to dashboard</Button>
    </NextLinkOpt>
  )
}

const Content: React.FC<{ type?: NotFoundType }> = ({ type = 'page' }) => {
  const backLink = React.useMemo(() => {
    if (typesWithinCorp.includes(type)) {
      return <DashboardLink />
    }

    return (
      <NextLinkOpt href='/'>
        <Button size='md'>Take me back to the home page</Button>
      </NextLinkOpt>
    )
  }, [type])

  return (
    <Stack data-testid='404' style={{ gap: theme.spacing.xl }}>
      <Title fw={900} ta='center' size={38}>
        {getNotFoundError(type).title}
      </Title>
      <Text c='dimmed' size='lg' ta='center' maw={540} mx='auto'>
        {getNotFoundError(type).message}
      </Text>
      <Group style={{ justifyContent: 'center' }}>{backLink}</Group>
    </Stack>
  )
}

export const NotFound: React.FC<{ fullPage?: boolean; type?: NotFoundType }> = ({
  fullPage,
  ...props
}) => {
  return fullPage ? (
    <Center w='100vw' h='100vh'>
      <Content {...props} />
    </Center>
  ) : (
    <Center w='100%' h='100%'>
      <Content {...props} />
    </Center>
  )
}
