import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'

export const useQueryParamState = (
  param: string
): [string | undefined, (newValue: string | null | undefined) => Promise<boolean>] => {
  const router = useRouter()
  const { query, replace } = router
  const { [param]: currentValue, ...otherQueryParams } = query

  const value = useMemo(() => {
    if (currentValue === undefined) {
      return undefined
    }
    if (typeof currentValue !== 'string') {
      throw new Error(
        `Expected query parameter ${param} to be a string, but got ${typeof currentValue}`
      )
    }
    return currentValue
  }, [currentValue, param])

  const setQuery = useCallback(
    async (newValue: string | null | undefined) => {
      if (newValue === undefined || newValue === null) {
        return replace(
          {
            query: otherQueryParams,
          },
          undefined,
          { shallow: true }
        )
      }

      return replace(
        {
          query: {
            ...otherQueryParams,
            [param]: newValue,
          },
        },
        undefined,
        { shallow: true }
      )
    },
    [otherQueryParams, param, replace]
  )

  return [value, setQuery]
}
