import { sortBy } from 'underscore'
import type { CollectionPaths } from '~/common/route/collection'
import { relationTypeRouteMap } from '~/common/route/collection'
import { navDropRoutePathMap } from '~/common/route/top-route'
import type { ZAugmentedRelation } from '~/common/schema/relation'

export interface DeletedRelation {
  type: 'DELETED'
  index: number
  display: string
}

/**
 * Returns a deleted relation
 */
const mkDeletedRelation = ({
  collection,
  index,
}: {
  collection: CollectionPaths
  index: number
}): DeletedRelation => {
  const { indexList } = navDropRoutePathMap[collection]
  return { index, type: 'DELETED', display: `${[...indexList, index].join('.')} Deleted` }
}

/** Fills missing indices with deleted relations. `relations` array must contain
 *  relations in the same index group.
 *
 *  We add in empty folders for deleted relation indexes to make it more clear
 *  to users that a relation was deleted.  We do not do this on the website
 *  because of the UX complexities that arise under sorting.  See
 *  https://www.notion.so/How-to-handle-deleted-relations-bdc3408f4fca43b6b9796eeb4fd5d9e6?pvs=4”
 * */
export const fillDeletedRelations = <R extends Pick<ZAugmentedRelation, 'index' | 'type'>>(
  relations: R[]
): (R | DeletedRelation)[] =>
  sortBy(relations, (relation) => relation.index).reduce(
    (relArray, relation) => {
      const lastIndex = relArray.at(-1)?.index ?? 0

      // checks if there's a gap between the last index and the next one
      if (relation.index - lastIndex <= 1) return [...relArray, relation]

      // if theres a gap between indices, create deleted relations to fill gap
      const deletedRelations: DeletedRelation[] = new Array(relation.index - (lastIndex + 1))
        .fill(null)
        .map((_, i) =>
          mkDeletedRelation({
            collection: relationTypeRouteMap[relation.type].collection, // use any relation type from the index group to get the collection
            index: lastIndex + (i + 1),
          })
        )

      return [...relArray, ...deletedRelations, relation]
    },
    [] as (R | DeletedRelation)[]
  )
