import dynamic from 'next/dynamic'
import React from 'react'
import { z } from 'zod'
import type { InlineCommonProps } from '~/client/components/util/inline/base'
import { InlineBaseInput } from '~/client/components/util/inline/base'
import { ZLawFirm } from '~/common/law-firms'

// Lazy loading so that we don’t have to load the list of law firms unless we visit the page
const LawFirmSelect = dynamic(() =>
  import('~/client/components/util/inline/law-firm-select-comp').then((mod) => mod.LawFirmSelect)
)

export const InlineLawFirmSelect: React.FC<Omit<InlineCommonProps<ZLawFirm>, 'docs'>> = ({
  update,
  initialValue,
  alignment,
  noWrap,
}) => {
  return (
    <InlineBaseInput
      initialValues={{ value: initialValue }}
      schema={z.object({ value: ZLawFirm })}
      update={update && (({ values }) => update(values.value))}
      display={initialValue}
      valueIsFilled={!!initialValue}
      alignment={alignment}
      noWrap={noWrap}
      dropOverContent={({ formGetInputProps }) => <LawFirmSelect {...formGetInputProps('value')} />}
    />
  )
}
