import type { MantineColor, StyleProp } from '@mantine/core'
import { Table } from '@mantine/core'
import React from 'react'
import { SortHeader } from '~/client/components/util/sort'
import { TableHeaderRightAligned } from '~/client/components/util/table-cell-right-aligned'
import type { ZRelationPaginationSortField } from '~/common/pagination'
import type { ZRelationTypeValues } from '~/common/schema/relation'
import type { NonEmptyArray } from '~/common/util-types'
import * as classes from './base.css'
import type { RelationSort, SortRenderer } from './util'
import { AddRelation, statusStyle } from './util'

interface RelationHeadersProps extends RelationSort {
  noRedFlags?: boolean
  noStartDate?: boolean
  noEndDate?: boolean
  relationTypes: NonEmptyArray<ZRelationTypeValues>
  // sort by nameSortField e.g "party.name" if provided
  nameSortField?: Extract<ZRelationPaginationSortField, 'party.name'>
  baseHeaderName?: {
    name?: string
    startDate?: string
    endDate?: string
  }
  renderCustomHeaders?: SortRenderer
  stickyColBg?: StyleProp<MantineColor>
}

export const RelationHeaders: React.FC<RelationHeadersProps> = ({
  children,
  noRedFlags,
  noStartDate,
  noEndDate,
  stickyColBg,
  relationTypes,
  nameSortField,
  baseHeaderName,
  sortState,
  renderCustomHeaders,
}) => {
  const stickyProps = { pos: 'sticky', bg: stickyColBg ?? '#fff', style: { zIndex: 5 } } as const
  const redFlagProps = { ...stickyProps, style: { ...stickyProps.style, ...statusStyle } }

  return (
    // Apply headers style to header row for making columns header have no wrapped labels
    <Table.Tr className={classes.headers}>
      {!noRedFlags && <Table.Th {...redFlagProps} left={0} />}
      {nameSortField ? (
        <SortHeader
          {...stickyProps}
          left={noRedFlags ? 0 : 44}
          sortField={nameSortField}
          sortState={sortState}
        >
          {baseHeaderName?.name ?? 'Name'}
        </SortHeader>
      ) : (
        <Table.Th {...stickyProps} left={noRedFlags ? 0 : 44}>
          {baseHeaderName?.name ?? 'Name'}
        </Table.Th>
      )}

      {!noStartDate && (
        <SortHeader sortField='startDate' sortState={sortState}>
          {baseHeaderName?.startDate ?? 'Start Date'}
        </SortHeader>
      )}
      {!noEndDate && (
        <SortHeader sortField='endDate' sortState={sortState}>
          {baseHeaderName?.endDate ?? 'End Date'}
        </SortHeader>
      )}

      {renderCustomHeaders?.({ sortState })}
      {children}
      {/* Make Documents column as wide as possible */}
      <Table.Th w='100%'>Documents</Table.Th>
      <TableHeaderRightAligned>
        <AddRelation options={relationTypes} />
      </TableHeaderRightAligned>
    </Table.Tr>
  )
}
