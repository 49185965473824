import { mapObject, sortBy, uniq } from 'underscore'
import type { DataApi } from '~/common/data-api'
import toUTCDate from '~/common/date-utc'
import type { DocAutofill } from '~/common/enhance'
import type { AugmentedDocImpreciseDate, ZDoc } from '~/common/schema'
import { ZAugmentedDoc, type ZParty, mkSortDate } from '~/common/schema'
import type { ZDocAutocheckField } from '~/common/schema/autocheck'
import { dedupeDates } from '~/common/util'

export type SuggestionWithoutSource = {
  [K in keyof DataApi.Suggestions]: Omit<DataApi.Suggestions[K][number], 'source'>[]
}

interface MkAutofillInput {
  suggestions: SuggestionWithoutSource
  initialAutofill?: DocAutofill
  docTypesFilter?: (type: ZAugmentedDoc['type']) => boolean
}

const mkAutofill = ({
  initialAutofill,
  docTypesFilter,
  suggestions,
}: MkAutofillInput): DocAutofill => {
  const mkDateAutofill = () => {
    const previousAutofill = initialAutofill?.dates ?? []
    const suggestionDates = suggestions.dates.map((date) => toUTCDate(new Date(date.value)))

    return dedupeDates([...previousAutofill, ...suggestionDates]).sort(mkSortDate())
  }

  const mkTypesAutofill = (): ZAugmentedDoc['type'][] => {
    const typeValues = suggestions.types.map((item) => item.value).filter(ZAugmentedDoc.isType)
    return docTypesFilter ? typeValues.filter(docTypesFilter) : typeValues
  }

  const mkEmailAutofill = (): string[] => {
    const initialEmails = initialAutofill?.emails ?? []
    return uniq([...suggestions.emails.map((e) => e.value), ...initialEmails])
  }

  const mkTitleAutofill = (): string[] => suggestions.titles.map((item) => item.value)

  const mkPartyAutofill = (): ZParty[] => {
    const mlSuggestions = suggestions.parties.map((item) => ({ name: item.value }))
    const linkedSuggestions = initialAutofill?.parties ?? []

    // Case insensitive dedupe
    return uniq([...linkedSuggestions, ...mlSuggestions], (item) => item.name.toLowerCase())
  }

  return {
    parties: mkPartyAutofill(),
    emails: mkEmailAutofill(),
    dates: mkDateAutofill(),
    types: mkTypesAutofill(),
    titles: mkTitleAutofill(),
  }
}

export const mkAutofillWithHiddenSuggestions = ({
  initialAutofill,
  docTypesFilter,
  suggestions,
}: MkAutofillInput): DocAutofill =>
  mkAutofill({
    suggestions: {
      ...suggestions,
      // We have to filter types here (using a lower threshold) because the data endpoints
      // returns all types (even if they have a probability close to 0)
      types: suggestions.types.filter((type) => type.prob > 0.05),
    },
    initialAutofill,
    docTypesFilter,
  })

export const mkUserFacingAutofill = ({
  initialAutofill,
  docTypesFilter,
  suggestions,
}: MkAutofillInput): DocAutofill =>
  mkAutofill({
    suggestions: {
      types: suggestions.types.filter((type) => type.prob > 0.1),
      // Item should already be sorted, just making sure to avoid potential bugs
      parties: sortBy(suggestions.parties, (item) => -item.prob)
        .slice(0, 2)
        .filter((item) => item.prob > 0.1),
      titles: sortBy(suggestions.titles, (item) => -item.prob)
        .slice(0, 2)
        .filter((item) => item.prob > 0.1),
      dates: suggestions.dates.slice(0, 2),
      emails: suggestions.emails.slice(0, 5),
    },
    initialAutofill,
    docTypesFilter,
  })

export type AutocheckValue = 'ok' | 'warning'

export interface AutocheckResult {
  autocheckAllOk: boolean
  // Using literals to avoid potential mistakes with booleans
  autocheckFieldResults: Record<ZDocAutocheckField, AutocheckValue>
}

type Nullish<T> = { [K in keyof T]?: T[K] | null }

interface PartialPartyDoc extends Omit<Partial<ZDoc>, 'party' | 'dateImprecise'> {
  party?: Partial<ZParty>
  dateImprecise?: Nullish<AugmentedDocImpreciseDate> | null
}

interface ComputeAutocheckInput {
  values: PartialPartyDoc
  autofill: DocAutofill
  autofillWithHiddenSuggestions: DocAutofill
  dismissedFields: ZDocAutocheckField[]
}

export const computeAutoCheck = ({
  values,
  autofillWithHiddenSuggestions,
  dismissedFields,
  autofill,
}: ComputeAutocheckInput): AutocheckResult => {
  const dismissedFieldsSet = new Set(dismissedFields)
  const checkStrArray = (arr: string[], search: string) =>
    arr.map((v) => v.trim().toLowerCase()).includes(search.trim().toLowerCase())

  const autoCheckOk = {
    title:
      !values.title ||
      // Only mark a field as potentially incorrect if we have at least one user-facing
      // suggestion (not hidden). This serves 2 purposes:
      // 1. If our ml can't find a single suggestion, it's probably very uncertain about the prediction
      // 2. Avoids a situation where our system tells that a field is wrong, but doesn't provide a way
      //    to fix it, and the user has to guess the hidden suggestion or dismiss the autocheck
      !autofill.titles.length ||
      checkStrArray(autofillWithHiddenSuggestions.titles, values.title),
    email:
      !values.party?.email ||
      !autofill.emails.length ||
      checkStrArray(autofillWithHiddenSuggestions.emails, values.party.email),
    party:
      !values.party?.name ||
      !autofill.parties.length ||
      checkStrArray(
        autofillWithHiddenSuggestions.parties.map((v) => v.name),
        values.party.name
      ),
    type:
      !values.type ||
      !autofill.types.length ||
      values.type === 'PROCESSING' ||
      autofillWithHiddenSuggestions.types.includes(values.type),
    date:
      !values.dateImprecise?.date ||
      !autofill.dates.length ||
      autofillWithHiddenSuggestions.dates
        .map((v) => v.getTime())
        .includes(values.dateImprecise.date.getTime()),
  }

  const autocheckOkOrDismissed = mapObject(
    autoCheckOk,
    (fieldOk, fieldKey) => fieldOk || dismissedFieldsSet.has(fieldKey)
  )

  return {
    autocheckAllOk: Object.values(autocheckOkOrDismissed).every(Boolean),
    autocheckFieldResults: mapObject(autocheckOkOrDismissed, (result) =>
      result ? 'ok' : 'warning'
    ),
  }
}
