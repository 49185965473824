import { Avatar, type AvatarProps, Loader } from '@mantine/core'

export const AvatarWithLoader: React.FC<{
  isLoading: boolean
  photoUrl?: string | null
  size?: AvatarProps['size']
}> = ({ isLoading, photoUrl, size }) => {
  if (isLoading) {
    return <Loader size={size} />
  }
  return (
    <Avatar
      size={size}
      src={photoUrl}
      radius='xl'
      imageProps={{
        // needed to avoid 403 errors from google
        referrerPolicy: 'no-referrer',
      }}
    />
  )
}
