import { useQuery } from '@tanstack/react-query'
import { useAuthStore } from '~/client/lib/hooks/auth'
import { hooks } from '~/client/lib/hooks/dependency-injection/interface'
import { useObjectURL } from '~/client/lib/hooks/use-object-url'
import { hasProfilePhotoStoredInS3 } from '~/common/firebase'
import { fetchRetry } from '~/common/util'

/**
 * Query that downloads a user profile image and return an
 * object url referencing it. Should be used for both Google
 * and MSFT users to avoid inconsistent behavior.
 * @param photoURL
 */
export const useProfilePhotoObjectURL = (photoURL: string | undefined): string | undefined => {
  const currentUserProfilePhotoQuery = useQuery({
    queryFn: () => {
      if (!photoURL) throw new Error('Need photoURL to fetch')
      return fetchRetry(photoURL, {
        init: {
          referrerPolicy: 'no-referrer',
        },
      }).then((res) => res.blob())
    },
    queryKey: ['fetchProfilePhoto', photoURL],
    enabled: !!photoURL,
    // We can't refetch this query because this url might be expired
    meta: { noGlobalInvalidation: true },
    staleTime: Infinity,
    cacheTime: Infinity,
  })
  return useObjectURL(currentUserProfilePhotoQuery.data)
}

/**
 * Returns the url of the profile picture for the logged-in user.
 * Even though Google users have their images stored on Firebase and
 * MSFT have those in S3, this method returns object urls for both cases.
 */
export const useCurrentUserProfilePhotoURL = (): string | undefined => {
  const user = useAuthStore((state) => state.user)
  const firebaseUrl = useAuthStore((state) => state._firebasePhotoUrl)

  const enabled = !!user && !firebaseUrl && hasProfilePhotoStoredInS3(user.providerData)

  const s3UrlQuery = hooks.trpc().user.getProfilePhotoReadUrl.useQuery(undefined, {
    enabled,
    meta: { noGlobalInvalidation: true },
    // Large stale time (1h) because profile images don't change often
    staleTime: 60 * 60 * 1_000,
    // Avoid removing it from the cache
    cacheTime: Infinity,
  })

  const photoURL = firebaseUrl || s3UrlQuery.data?.photoURL
  return useProfilePhotoObjectURL(photoURL)
}
