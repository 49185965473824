import { ActionIcon, Button, Group, Text } from '@mantine/core'
import { IconMail } from '@tabler/icons-react'
import Router from 'next/router'
import React from 'react'
import {
  AuthenticationFrame,
  GoogleSignInButton,
  MicrosoftSignInButton,
} from '~/client/components/login/util'
import { LogoutButton } from '~/client/components/login/verifications'
import { hooks } from '~/client/lib/hooks'
import { mkUrlWithPrefillEmail } from '~/common/util'

export const CompulsoryPasswordReset: React.FC = () => {
  const { logout } = hooks.useAuthMethods()
  const email = hooks.useAuthStore((state) => state.user?.email ?? undefined)

  // We use ref so we still have the email when user logs out
  const emailRef = React.useRef(email)

  return (
    <AuthenticationFrame title='Reset Password'>
      <Text>
        Accounts using email login need to reset their password every 90 days. If your email is
        associated with a Google or Microsoft login, convert your login to go password-less{' '}
        <b>(recommended)</b>.
      </Text>
      <GoogleSignInButton label='Convert to Google' email={emailRef.current} />
      <MicrosoftSignInButton label='Convert to Microsoft' email={emailRef.current} />
      <Button
        size='lg'
        onClick={async () => {
          await logout()
          await Router.push(mkUrlWithPrefillEmail('/reset-password', emailRef.current))
        }}
      >
        <Group>
          <ActionIcon component='span' size={32}>
            <IconMail />
          </ActionIcon>
          <span>Reset your password</span>
        </Group>
      </Button>
      <LogoutButton />
    </AuthenticationFrame>
  )
}
