import { useRouter } from 'next/router'
import { useCorpCryptIdSafe } from '~/client/lib/hooks/corp'
import type { NavDropRouteData, TopPath } from '~/common/route/top-route'
import { dynamicPaths, navDropPaths, navDropRoutePathMap, topPaths } from '~/common/route/top-route'
import type { WithoutIndexesPath, WithoutIndexesRouteData } from '~/common/route/without-index'
import { withoutIndexesPaths, withoutIndexesRouteMap } from '~/common/route/without-index'

/** Returns the top data for the current top path. Returns undefined on server
 * side renders, and on unknown paths. */
export const useCurrentTopPathFromUrl = (): TopPath | undefined => {
  const { asPath } = useRouter()
  const { corpCryptId, mkCurrentCorpRoute } = useCorpCryptIdSafe() ?? {}
  if (!mkCurrentCorpRoute || !corpCryptId) return undefined

  const currentPath = asPath.split(`${corpCryptId.idStr}/`)[1]
  const currentTopPathWithoutQuery = currentPath?.split('?')[0]?.split('#')[0]?.split('/')[0]
  if (!currentTopPathWithoutQuery) return undefined
  // Valid paths should always be contained in topPaths, and only 404s should not.
  if (!topPaths.includes(currentTopPathWithoutQuery)) return undefined
  return currentTopPathWithoutQuery
}

export const useRouteDataFromTopPath = (
  path: TopPath | undefined
): NavDropRouteData | WithoutIndexesRouteData | undefined => {
  // We do not have route data for "dynamic" paths like doc, relation, search
  if (!path || dynamicPaths.includes(path)) return undefined
  if (navDropPaths.includes(path)) return navDropRoutePathMap[path]
  if (withoutIndexesPaths.includes(path)) return withoutIndexesRouteMap[path as WithoutIndexesPath]
  return undefined
}
