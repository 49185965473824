import { z } from 'zod'
import { relationTypeRouteMap } from '~/common/route/collection'
import { navDropRoutePathMap } from '~/common/route/top-route'
import { ZWarningAggregate, withDisplaySchema } from '~/common/schema'
import type { ZAugmentedDoc } from '~/common/schema/doc'
import type { ZParty } from '~/common/schema/party'
import {
  ZAugmentedCommon,
  ZAugmentedConvertibleNote,
  ZAugmentedFundraising,
  ZAugmentedOption,
  ZAugmentedOptionPlan,
  ZAugmentedPreferred,
  ZAugmentedSafe,
  ZAugmentedValuation,
  ZAugmentedWarrant,
} from '~/common/schema/relation/equity-types'
import {
  ZAugmentedAdvisor,
  ZAugmentedDirector,
  ZAugmentedEmployee,
  ZAugmentedOfficer,
} from '~/common/schema/relation/personnel'
import type { util } from '~/common/schema/relation/util'
import { type DisplayExcludeFields } from '~/common/schema/relation/util'
import type { OmitUnion } from '~/common/util-types'
import { ZAugmentedLocalCorporation, ZAugmentedStateCorporation } from './corporate'

export const typeAugmentedRelationMap = {
  ADVISOR: ZAugmentedAdvisor,
  COMMON: ZAugmentedCommon,
  DIRECTOR: ZAugmentedDirector,
  EMPLOYEE: ZAugmentedEmployee,
  FUNDRAISING: ZAugmentedFundraising,
  LOCAL: ZAugmentedLocalCorporation,
  OFFICER: ZAugmentedOfficer,
  OPTION: ZAugmentedOption,
  OPTIONPLAN: ZAugmentedOptionPlan,
  PREFERRED: ZAugmentedPreferred,
  SAFE: ZAugmentedSafe,
  CONVERTIBLE: ZAugmentedConvertibleNote,
  WARRANT: ZAugmentedWarrant,
  STATE: ZAugmentedStateCorporation,
  VALUATION: ZAugmentedValuation,
} as const

export type AllAugmentedRelationsMap = typeof typeAugmentedRelationMap
export type ZRelationTypeValues = keyof AllAugmentedRelationsMap
// eslint-disable-next-line custom-rules/no-bad-casting-in-declaration
export const zRelationTypes = Object.keys(typeAugmentedRelationMap) as [
  ZRelationTypeValues,
  ...ZRelationTypeValues[],
]

export const ZRelationTypeValues = z.enum(zRelationTypes)

type AugmentedRelation = AllAugmentedRelationsMap[keyof AllAugmentedRelationsMap]

// For some reason we need 2 relations in that array: https://github.com/colinhacks/zod/issues/831#issuecomment-1064636972
// eslint-disable-next-line custom-rules/no-bad-casting-in-declaration
export const allAugmentedRelations = Object.values(typeAugmentedRelationMap) as [
  AugmentedRelation,
  AugmentedRelation,
  ...AugmentedRelation[],
]
export const _ZAugmentedRelation = z.union(allAugmentedRelations)

export type _ZAugmentedRelation = z.infer<typeof _ZAugmentedRelation>

/** Omits some of the fields that are not needed to generate the display of a
 * relation. This makes the display function compatible with mongo and augmented
 * relations  */
type DisplayInput = util.OmitSourceCryptId<OmitUnion<_ZAugmentedRelation, DisplayExcludeFields>> & {
  index?: number
}

export const ZAugmentedRelation = Object.assign(_ZAugmentedRelation, {
  types: zRelationTypes,
  isType: (s: string): s is ZRelationTypeValues => zRelationTypes.includes(s),

  /**
   * Generates the index list for the given relation
   */
  mkIndexList: (obj: Pick<_ZAugmentedRelation, 'type' | 'index'>) => {
    const { collection } = relationTypeRouteMap[obj.type]
    const { indexList: parentIndexList } = navDropRoutePathMap[collection]
    return [...parentIndexList, obj.index]
  },
  /**
   * Returns display string for the relation, optionally including an index list,
   * only generated if present in the relation
   */
  displayFn: (obj: DisplayInput, withIndex = true) => {
    // HACK: ts typing isn't clever enough to figure out that this is typesafe, even though we know it is
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultDisplay = typeAugmentedRelationMap[obj.type].displayFn(obj as any)
    if (withIndex && obj.index) {
      const indexList = ZAugmentedRelation.mkIndexList({ ...obj, index: obj.index })
      return `${indexList.join('.')} ${defaultDisplay}`
    }
    return defaultDisplay
  },
  /**
   * Returns the party property of the relation adding the index list to the name.
   */
  partyNameEmailWithIndex: (obj: _ZAugmentedRelation & { party?: ZParty }) => ({
    name: obj.party && `${ZAugmentedRelation.mkIndexList(obj).join('.')} ${obj.party.name}`,
    email: obj.party?.email,
  }),
  identifyingField: (obj: _ZAugmentedRelation) =>
    typeAugmentedRelationMap[obj.type].identifyingField,
})

export type ZAugmentedRelation = z.infer<typeof ZAugmentedRelation>

// Partial version of the ZAugmentedRelation union, used for validating parts of relations, useful for updating them.
// Do not use zodDeepPartial because that is not typesafe
const allAugmentedRelationsPartial = allAugmentedRelations.map((relation) => relation.partial())
type _ZAugmentedRelationPartial = (typeof allAugmentedRelationsPartial)[number]
export const ZAugmentedRelationPartial = z.union(
  allAugmentedRelationsPartial as [
    _ZAugmentedRelationPartial,
    _ZAugmentedRelationPartial,
    ..._ZAugmentedRelationPartial[],
  ]
)
export type ZAugmentedRelationPartial = z.infer<_ZAugmentedRelationPartial>

interface RelationRequired {
  type: ZRelationTypeValues
  required: boolean
}

export const supportedTypeRelationMap: Record<
  ZAugmentedDoc['type'],
  RelationRequired[] | undefined
> = (() => {
  const flatArray = ZAugmentedRelation.options.flatMap((relation) =>
    relation.requiredTypes
      .map<[ZAugmentedDoc['type'], RelationRequired]>((docType) => [
        docType,
        { type: relation.type, required: true },
      ])
      .concat(
        relation.optionalTypes.map((docType) => [docType, { type: relation.type, required: false }])
      )
  )

  // eslint-disable-next-line custom-rules/no-bad-casting-in-declaration
  const _docRelationMap = {} as Record<ZAugmentedDoc['type'], RelationRequired[] | undefined>
  flatArray.forEach(([docType, relationType]) => {
    const values = _docRelationMap[docType]
    _docRelationMap[docType] = [...(values ?? []), relationType]
  })
  return _docRelationMap
})()

/**
 * Get the supported relation types for a given document type.
 * May contain duplicates.
 */
export const getSupportedRelationTypesFromDocType = (
  docType: ZAugmentedDoc['type']
): ZRelationTypeValues[] | undefined =>
  supportedTypeRelationMap[docType]?.map((relationType) => relationType.type)

export const ZEquitySummary = z.object({
  optionPoolTotal: withDisplaySchema(ZWarningAggregate, 'aggregate', 'Equity Incentive Plan Total'),
  commonShares: withDisplaySchema(ZWarningAggregate, 'aggregate', 'Common Shares'),
  warrantShares: withDisplaySchema(ZWarningAggregate, 'aggregate', 'Warrants'),
  commonPoolShares: withDisplaySchema(
    ZWarningAggregate,
    'aggregate',
    'Common Shares Issued Under Plan'
  ),
  optionShares: withDisplaySchema(ZWarningAggregate, 'aggregate', 'Options Issued Under Plan'),
  optionPoolRemaining: withDisplaySchema(ZWarningAggregate, 'aggregate', 'Equity Plan Remaining'),
  optionPoolUsed: withDisplaySchema(
    ZWarningAggregate,
    'aggregate',
    'Total Equity Issued Under Plan'
  ),
  preferredShares: withDisplaySchema(ZWarningAggregate, 'aggregate', 'Preferred Shares'),
  fullyDiluted: withDisplaySchema(ZWarningAggregate, 'aggregate', 'Fully Diluted'),
  totalShares: withDisplaySchema(ZWarningAggregate, 'aggregate', 'Total Shares'),
  totalFunding: withDisplaySchema(ZWarningAggregate, 'aggregate', 'Total Funding'),
})
export type ZEquitySummary = z.infer<typeof ZEquitySummary>

// maps a mongo relation to it's augmented relation
export type AugmentRelation<T extends { type: ZRelationTypeValues }> = z.infer<
  AllAugmentedRelationsMap[T['type']]
>

export type MinimalAugmentedRelation = OmitUnion<
  ZAugmentedRelation,
  'corpCryptId' | 'docCryptIds' | 'docOpts'
>
