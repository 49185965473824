import React from 'react'
import { createStore } from 'zustand'
import { createContextStoreHook } from '~/client/lib/zustand-utils'

export type ZipFileState = 'awaiting' | 'loading' | 'loaded'

export interface ProcessedDocStatus {
  documentDisplay: string
  documentLink: string
  relationDisplay?: string
  relationLink?: string
  tooltip?: string
}

export interface ProgressData {
  total: number
  current: number
}

export interface ZipFileStore {
  zipFileState: ZipFileState
  setZipFileState: (zipFileState: ZipFileState) => void
  data: Blob | undefined
  setData: (blob: Blob | undefined) => void
  failedDocuments: ProcessedDocStatus[]
  addFailedDocument: (doc: ProcessedDocStatus) => void
  successfulDocuments: ProcessedDocStatus[]
  addSuccessfulDocument: (doc: ProcessedDocStatus) => void
  /**
   * Keeps the links (urls) for all documents added to either successfulDocuments
   * or failedDocuments, so we don't count the same doc twice for the status summary.
   * Document links are guaranteed to be unique because they contain the doc cryptId.
   * Note that if a doc is linked to two relations, it'll still be downloaded twice.
   */
  documentLinksSet: Set<string>
  preparationProgress: ProgressData
  setPreparationProgress: (preparationProgress: ProgressData) => void
  downloadProgress: ProgressData
  setDownloadProgress: (downloadProgress: ProgressData) => void
  resetDocuments: () => void
}

const createZipFileStore = () =>
  createStore<ZipFileStore>()((set, get) => ({
    zipFileState: 'awaiting',
    setZipFileState: (zipFileState) => set({ zipFileState }),
    data: undefined,
    setData: (blob) => set({ data: blob }),
    failedDocuments: [],
    addFailedDocument: (doc) => {
      const { documentLinksSet } = get()
      if (documentLinksSet.has(doc.documentLink)) return
      set((state) => ({ failedDocuments: [...state.failedDocuments, doc] }))
      documentLinksSet.add(doc.documentLink)
    },
    successfulDocuments: [],
    addSuccessfulDocument: (doc) => {
      const { documentLinksSet } = get()
      if (documentLinksSet.has(doc.documentLink)) return
      set((state) => ({ successfulDocuments: [...state.successfulDocuments, doc] }))
      documentLinksSet.add(doc.documentLink)
    },
    documentLinksSet: new Set<string>(),

    downloadProgress: { total: 0, current: 0 },
    setDownloadProgress: (downloadProgress) => set({ downloadProgress }),
    preparationProgress: { total: 0, current: 0 },
    setPreparationProgress: (preparationProgress) => set({ preparationProgress }),

    resetDocuments: () =>
      set({
        failedDocuments: [],
        successfulDocuments: [],
        documentLinksSet: new Set<string>(),
        downloadProgress: { total: 0, current: 0 },
        preparationProgress: { total: 0, current: 0 },
      }),
  }))

const StoreContext = React.createContext<ReturnType<typeof createZipFileStore> | null>(null)
export const ZipFileStoreProvider: React.FC = ({ children }) => {
  const store = React.useRef(createZipFileStore()).current
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

export const { useContextStore: useZipFileStore } = createContextStoreHook(StoreContext)
export type UseZipFileStore = typeof useZipFileStore
