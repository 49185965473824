import { Badge, Box, Button, Card, Group, Loader, Skeleton, Stack, Text } from '@mantine/core'
import { IconPlus, IconTrash } from '@tabler/icons-react'
import Image from 'next/image'
import React from 'react'
import { format } from 'timeago.js'
import { TextWithTooltip } from '~/client/components/util/text-with-tooltip'
import { integrationCardBoxShadowClass, shakeOnceAnimationClass } from '~/client/lib/css-util.css'
import { theme } from '~/client/lib/theme'
import type { IntegrationType } from './integration-info'
import { integrationInformation } from './integration-info'

const INTEGRATION_CARD_HEIGHT = 440
export const INTEGRATION_CARD_WIDTH = 300

export const IntegrationCardBase: React.FC<{ onClick?: () => void }> = ({ children, onClick }) => {
  return (
    <Card
      w={INTEGRATION_CARD_WIDTH}
      h={INTEGRATION_CARD_HEIGHT}
      style={{
        border: `1px solid ${theme.colors.gray[2]}`,
        cursor: onClick ? 'pointer' : 'default',
      }}
      className={integrationCardBoxShadowClass}
      radius='sm'
      onClick={onClick}
    >
      {children}
    </Card>
  )
}

export interface IntegrationCardProps {
  title: string
  subtitle?: string
  lastSuccessfulQuery?: Date
  integrationType: IntegrationType
  variant: 'add' | 'connect' | 'remove' | 'querying' | 'beta'
  onClickButton: () => void | Promise<void>
  isLoadingButton?: boolean
  disabledButton?: boolean
  /** Known errors for the integration will be shown in the card, to ensure
   * users are aware that something happened */
  errorMessage?: string
  beta?: boolean
  blurb: string
}

const buttonHeight = 36 // default button height
const IntegrationCardButton: React.FC<
  Pick<IntegrationCardProps, 'variant' | 'onClickButton' | 'isLoadingButton' | 'disabledButton'>
> = ({ onClickButton, variant, isLoadingButton, disabledButton }) => {
  const buttonProps = {
    h: buttonHeight,
    color: 'primary',
    variant: 'outline',
    onClick: onClickButton,
    loading: isLoadingButton,
    disabled: disabledButton,
    tt: 'capitalize',
  } as const
  switch (variant) {
    case 'add':
    case 'connect':
      return (
        <Button
          {...buttonProps}
          leftSection={<IconPlus />}
          data-testid='integration-card-connect-button'
        >
          {variant}
        </Button>
      )
    case 'remove':
      return (
        <Button {...buttonProps} color='gray' variant='subtle' leftSection={<IconTrash />}>
          {variant}
        </Button>
      )
    case 'querying':
      return (
        <Group h={buttonHeight} justify='center'>
          <Loader size='sm' />
          <Text size='sm' c='dimmed'>
            Fetching Documents
          </Text>
        </Group>
      )
    case 'beta':
      return (
        <Button {...buttonProps} leftSection={<IconPlus />}>
          Request to Join Beta
        </Button>
      )
  }
}

const IntegrationBadge: React.FC<Pick<IntegrationCardProps, 'variant'>> = ({ variant }) => {
  switch (variant) {
    case 'connect':
      return (
        <Badge color='primary' w='fit-content' style={{ overflow: 'visible' }}>
          Available
        </Badge>
      )
    case 'remove':
    case 'querying':
      return (
        <Badge color='green' w='fit-content' style={{ overflow: 'visible' }}>
          Connected
        </Badge>
      )
    default:
      return null
  }
}

const textHeight = 24
const IntegrationTopBody: React.FC<
  Pick<IntegrationCardProps, 'title' | 'subtitle' | 'lastSuccessfulQuery' | 'variant'>
> = ({ title, subtitle, lastSuccessfulQuery, variant }) => {
  if (variant === 'add' || variant === 'beta') return null
  return (
    <Group wrap='nowrap' justify='space-between'>
      <Stack gap={4} miw={0}>
        <TextWithTooltip fw={600}>{title}</TextWithTooltip>
        {subtitle ? (
          <Text size='sm' c='dimmed' h={textHeight}>
            {subtitle}
          </Text>
        ) : (
          <Box h={textHeight} />
        )}
      </Stack>
      <Stack gap={4} align='end'>
        <IntegrationBadge variant={variant} />
        {lastSuccessfulQuery ? (
          <TextWithTooltip
            size='sm'
            c='dimmed'
            h={textHeight}
            ta='end'
            label={`Last synced: ${format(lastSuccessfulQuery)}`}
            // Add a little bit of padding since the border radius from the
            // badge makes it look like it's not aligned
            pr={4}
          >
            {format(lastSuccessfulQuery)}
          </TextWithTooltip>
        ) : (
          <Box h={textHeight} />
        )}
      </Stack>
    </Group>
  )
}

export const IntegrationCard: React.FC<IntegrationCardProps> = ({
  children: _,
  beta,
  blurb,
  ...props
}) => {
  const { integrationType } = props
  const { logoUrl } = integrationInformation[integrationType]

  return (
    <IntegrationCardBase>
      <Card.Section pos='relative'>
        {beta && (
          <Badge pos='absolute' top={0} right={0} m='sm' variant='filled'>
            Beta
          </Badge>
        )}
        <Image
          src={logoUrl}
          width={300}
          height={200}
          style={{ objectFit: 'cover', objectPosition: 'center' }}
          alt={`logo-${integrationType}`}
        />
      </Card.Section>
      <Stack mt='lg' gap='sm'>
        <IntegrationTopBody {...props} />
        <IntegrationCardButton {...props} />
      </Stack>
      <Box pt='md'>
        {props.errorMessage ? (
          <Text c='danger' size='sm' className={shakeOnceAnimationClass}>
            {props.errorMessage}
          </Text>
        ) : (
          <Text size='sm'>{blurb}</Text>
        )}
      </Box>
    </IntegrationCardBase>
  )
}

export const IntegrationCardSkeleton: React.FC = () => {
  return (
    <Skeleton visible animate w='fit-content'>
      <IntegrationCardBase />
    </Skeleton>
  )
}
