import type { DateInputProps, DatePickerInputProps } from '@mantine/dates'
import { DateInput, DatePickerInput } from '@mantine/dates'
import React from 'react'
import { fromUTCDate, toUTCDate } from '~/common/date-utc'

export const DatePickerInputUTC = React.forwardRef<HTMLInputElement, DateInputProps>(
  ({ onChange, value, ...props }, ref) => (
    <DateInput
      clearButtonProps={{
        'aria-label': 'Clear Date',
      }}
      clearable
      {...props}
      ref={ref}
      onChange={onChange && ((v) => onChange(toUTCDate(v) ?? null))}
      value={fromUTCDate(value) ?? null}
    />
  )
)

export const DateRangePickerInputUTC = React.forwardRef<
  HTMLButtonElement,
  Omit<DatePickerInputProps<'range'>, 'type'>
>(({ onChange, value, ...props }, ref) => (
  <DatePickerInput
    type='range'
    clearable
    ref={ref}
    value={value ? [fromUTCDate(value[0]), fromUTCDate(value[1])] : undefined}
    onChange={(v) => onChange && onChange([toUTCDate(v[0]), toUTCDate(v[1])])}
    {...props}
  />
))
