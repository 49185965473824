import { Accordion, Button, Group, Stack, Text, TextInput, Title } from '@mantine/core'
import type { ButtonProps } from '@mantine/core/lib/components'
import { useForm, zodResolver } from '@mantine/form'
import { IconMailOpened, IconPhoneCalling } from '@tabler/icons-react'
import type { PropsWithChildren, ReactElement } from 'react'
import { z } from 'zod'
import { NextLinkOpt } from '~/client/components/util'
import { MutationStatusIcon } from '~/client/components/util/mutation-status-icon'
import { hooks } from '~/client/lib/hooks'
import { type AuthUseMutationResult, getMFAError } from '~/client/lib/hooks/auth'
import { theme } from '~/client/lib/theme'
import { zenvCommon } from '~/common/zenv-common'
import { AuthenticationFrame } from './util'

export const LogoutButton: React.FC<ButtonProps> = ({ ...props }) => {
  const { logout } = hooks.useAuthMethods()
  return (
    <Button color='gray' onClick={() => logout()} w='fit-content' {...props}>
      Log Out
    </Button>
  )
}

export interface VerifyPhoneCompProps<TRetryInput> {
  prompt: React.ReactNode
  skipable: boolean
  submitMutation: AuthUseMutationResult<string>
  retryMutation: AuthUseMutationResult<TRetryInput>
  retryMutationInput: TRetryInput
}

const ZCodeForm = z.object({ code: z.string().regex(/^\d+$/).min(6) })
interface ZCodeForm extends z.infer<typeof ZCodeForm> {}

// Define Functional Component with Generic Props, because retry mutation input
// is different according to use cases.
export const VerifyPhoneComp = <TRetryInput,>({
  skipable,
  prompt,
  submitMutation,
  retryMutation,
  retryMutationInput,
}: PropsWithChildren<VerifyPhoneCompProps<TRetryInput>>): ReactElement | null => {
  const skipEnrollMFA = hooks.useAuthStore((state) => state.skipEnrollMFA)
  const codeForm = useForm<ZCodeForm>({
    validate: zodResolver(ZCodeForm),
    initialValues: { code: '' },
  })

  const isLoading = submitMutation.isLoading || retryMutation.isLoading
  const error = submitMutation.error ?? retryMutation.error

  const submitDisabled = codeForm.values.code.length < 6 || isLoading
  const showSwitchUser = !skipable

  return (
    <div data-testid='verify-phone'>
      <AuthenticationFrame title='Verify Phone' icon={<IconPhoneCalling />}>
        <Title order={4}>Secure Client Data</Title>
        <Text>{prompt}</Text>
        <form
          data-testid='verify-number-form'
          onSubmit={codeForm.onSubmit(({ code }) => submitMutation.mutateAsync(code))}
        >
          <TextInput
            autoFocus
            {...codeForm.getInputProps('code')}
            // show validation errors and mutation errors
            error={codeForm.getInputProps('code').error ?? getMFAError(error)}
            label='Verification Code'
            placeholder='123456'
            data-testid='verify-number-input'
          />
          <Group
            mt='lg'
            justify={showSwitchUser ? 'space-between' : 'flex-end'}
            gap={showSwitchUser ? 0 : theme.spacing.xs}
          >
            {showSwitchUser && <LogoutButton />}
            <Button
              disabled={isLoading}
              onClick={() => retryMutation.mutateAsync(retryMutationInput)}
              color='gray'
              loading={retryMutation.isLoading}
            >
              Resend
            </Button>
            <Button
              disabled={submitDisabled}
              data-testid='verify-number-btn'
              type='submit'
              loading={submitMutation.isLoading}
            >
              Verify
            </Button>
          </Group>
        </form>
        {skipable && (
          <Text c='gray' size='sm'>
            You can{' '}
            <NextLinkOpt data-testid='skip-MFA' onClick={() => skipEnrollMFA()}>
              skip
            </NextLinkOpt>{' '}
            this step.
          </Text>
        )}
      </AuthenticationFrame>
    </div>
  )
}

interface AccordionItemTroubleshootProps {
  title: string
  'data-testid'?: string
}
const AccordionItemTroubleshoot: React.FC<AccordionItemTroubleshootProps> = ({
  title,
  'data-testid': dataTestId,
  children,
}) => {
  return (
    <Accordion.Item value={title} data-testid={dataTestId}>
      <Accordion.Control styles={{ control: { paddingLeft: 0 } }}>
        <Text fw={500}>{title}</Text>
      </Accordion.Control>
      <Accordion.Panel styles={{ content: { paddingLeft: 0 } }}>
        <Stack>{children}</Stack>
      </Accordion.Panel>
    </Accordion.Item>
  )
}

export const VerifyEmailComp: React.FC<{ email: string | null }> = ({ email }) => {
  const { logout } = hooks.useAuthMethods()

  const verificationEmailMutation = hooks
    .trpc()
    .sendVerificationEmailIfUnverified.useMutation({ meta: { skipInvalidate: true } })

  const { mutateAsync, isSuccess, isLoading } = verificationEmailMutation

  return (
    <div data-testid='verify-email'>
      <AuthenticationFrame title='Verify email' icon={<IconMailOpened />}>
        <Text>
          A verification email has been sent to your email <a href={`mailto:${email}`}>{email}</a>.
          Please click the link in the email to verify your account.
        </Text>
        <Accordion>
          <AccordionItemTroubleshoot
            title="Didn't receive the email?"
            data-testid='no-email-troubleshoot-title'
          >
            <Text>
              Please check your spam folder for the email from{' '}
              <a href={`mailto:${zenvCommon.NEXT_PUBLIC_SENDGRID_REGISTRATION_SENDER}`}>
                {zenvCommon.NEXT_PUBLIC_SENDGRID_REGISTRATION_SENDER}
              </a>
              .
            </Text>
            <Group>
              <Button
                data-testid='resend-email'
                onClick={() => mutateAsync()}
                disabled={isLoading || isSuccess}
              >
                {isSuccess ? 'Email Resent' : 'Resend Email'}
              </Button>
              <MutationStatusIcon
                mutation={verificationEmailMutation}
                size={18}
                successTooltip='Successfully sent email'
                enableFadeOutSuccessIcon={false}
              />
            </Group>
          </AccordionItemTroubleshoot>
          <AccordionItemTroubleshoot title='Already clicked a verification link?'>
            <Text>
              If you have already clicked the verification link and still see this message, please{' '}
              <NextLinkOpt onClick={() => logout()}>logout</NextLinkOpt> and log back in.
            </Text>
            <LogoutButton color='primary' />
          </AccordionItemTroubleshoot>

          <AccordionItemTroubleshoot
            title='Trying to log in with another email?'
            data-testid='login-with-another-email-troubleshoot-title'
          >
            <Text>
              You are logged in as <b>{email}</b>. If you are trying to log in as a different user,{' '}
              <NextLinkOpt onClick={() => logout()}>logout</NextLinkOpt> and re-log in.
            </Text>
            <LogoutButton
              color='primary'
              data-testid='verification-screen-another-email-logout-button'
            />
          </AccordionItemTroubleshoot>
        </Accordion>
      </AuthenticationFrame>
    </div>
  )
}

export const AuthenticatePhone: React.FC = () => {
  const {
    phoneAuth: { sendVerificationPhoneCodeMutation, verifyPhoneMutation },
  } = hooks.useAuthMethods()
  return (
    <VerifyPhoneComp
      skipable={false}
      submitMutation={verifyPhoneMutation}
      retryMutation={sendVerificationPhoneCodeMutation}
      retryMutationInput={undefined}
      prompt='Please enter the 6-digit code sent by text message to your registered phone number'
    />
  )
}
