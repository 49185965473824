import {
  Badge,
  Group,
  HoverCard,
  Stack,
  Text,
  ThemeIcon,
  Title,
  useMantineTheme,
} from '@mantine/core'
import { IconCircleCheck, IconClockEdit } from '@tabler/icons-react'
import pluralize from 'pluralize'
import React from 'react'
import { AnimatedCountBadge } from '~/client/components/util/animated-count-badge'
import { LoadingErrorComp } from '~/client/components/util/error'
import { InvestorRedFlagExplanationHoverCard } from '~/client/components/util/red-flags'
import { zIndex } from '~/client/components/z-index'
import { hooks, useCurrentCorpAuth } from '~/client/lib/hooks'
import { enhanceCount } from '~/common/enhance'
import type { ZNumberExceed } from '~/common/number-exceed'
import { ActiveRedFlagIcon, redFlagExplanation } from '~/common/red-flags'

interface BadgeHoverCardProps {
  color: 'urgent' | 'primary' | 'go'
  icon: React.ReactNode
  badge: React.ReactNode
  noBadgeWrap?: boolean
  hoverTitle: string
  hoverText: React.ReactNode
}

export const BadgeHoverCard: React.FC<BadgeHoverCardProps> = ({
  color,
  icon,
  badge,
  noBadgeWrap,
  hoverText,
  hoverTitle,
}) => {
  const { other } = useMantineTheme()
  return (
    <HoverCard position='right-start' shadow='md' width={other.widths.sm} zIndex={zIndex.popover}>
      <HoverCard.Target>
        {noBadgeWrap ? (
          badge
        ) : (
          <Badge color={color} variant='filled'>
            {badge}
          </Badge>
        )}
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Group wrap='nowrap'>
          <ThemeIcon color={color} size='lg'>
            {icon}
          </ThemeIcon>
          <Stack gap='xs'>
            <Title order={3} c={color}>
              {hoverTitle}
            </Title>
            <Text c='dimmed' size='sm' component='div'>
              {hoverText}
            </Text>
          </Stack>
        </Group>
      </HoverCard.Dropdown>
    </HoverCard>
  )
}

const limitDecimals = (num: number) => Number(num.toFixed(1))

const displayNumberExceed = (num: ZNumberExceed | undefined, measure: string): string => {
  const _num = num ?? { count: 0, exceeds: false }
  if (_num.exceeds) return `over ${limitDecimals(_num.count)} ${pluralize(measure, _num.count)}`
  return `${limitDecimals(_num.count)} ${pluralize(measure, _num.count)}`
}

const DocumentCountBadgeComp: React.FC<{
  docCount?: ZNumberExceed
  relationCount?: ZNumberExceed
  corpName?: string | null
}> = ({ relationCount, docCount, corpName }) => {
  const legalHours: ZNumberExceed = {
    // assume each doc and relation takes 2 minutes to review
    count: (docCount?.count ?? 0) / 30 + (relationCount?.count ?? 0) / 30,
    exceeds: docCount?.exceeds || relationCount?.exceeds || false,
  }

  return (
    <BadgeHoverCard
      color='primary'
      icon={<IconClockEdit size='lg' />}
      badge={enhanceCount(docCount)}
      // Number and toFixed correctly round to at most 2 decimal places
      hoverTitle={`Aerial saved ${displayNumberExceed(legalHours, 'hour')} in legal fees`}
      hoverText={
        <>
          <p>
            Aerial processed {displayNumberExceed(docCount, 'document')} and{' '}
            {displayNumberExceed(relationCount, 'relation')} in {corpName}, saving on average{' '}
            {displayNumberExceed(legalHours, 'hour')} in legal fees.
          </p>
          <p>
            Aerial automates required document organization, saving legal fees and allowing lawyers
            to focus on what companies value - providing genuine legal advice.
          </p>
        </>
      }
    />
  )
}

export const DocumentCountBadge: React.FC = () => {
  const docQueryResults = hooks
    .trpc()
    // staleTime and cacheTime to reduce additional calls to the server
    .docs.countAll.useQueryWithCorp(undefined, {
      staleTime: Infinity,
      cacheTime: Infinity,
    })
  const relationQueryResults = hooks
    .trpc()
    // staleTime and cacheTime to reduce additional calls to the server
    .relations.countAll.useQueryWithCorp(undefined, {
      staleTime: Infinity,
      cacheTime: Infinity,
    })
  const corpResult = hooks.trpc().corp.read.useQueryWithCorp()
  const corpName = corpResult.data?.name.value

  return (
    <LoadingErrorComp queryResult={docQueryResults} loaderSize='xs' variant='tooltip'>
      {docQueryResults.data && (
        <AnimatedCountBadge
          value={docQueryResults.data}
          renderBadge={(docCount) => (
            <DocumentCountBadgeComp
              docCount={docCount}
              relationCount={relationQueryResults.data}
              corpName={corpName}
            />
          )}
        />
      )}
    </LoadingErrorComp>
  )
}

export const RedFlagBadge: React.FC = () => {
  const currentCorpResults = useCurrentCorpAuth()
  const isInvestor = currentCorpResults.data?.level === 'investor'

  const queryResults = hooks
    .trpc()
    // staleTime and cacheTime to reduce additional calls to the server
    .redFlags.get.all.useQueryWithCorp(
      { dismissed: false, limit: 1 },
      {
        staleTime: Infinity,
        cacheTime: Infinity,

        enabled: !!currentCorpResults.data && !isInvestor,
      }
    )

  const totalActiveRedFlags = queryResults.data?.count ?? { count: 0, exceeds: false }

  return (
    <LoadingErrorComp
      queryResult={{
        ...queryResults,
        isLoading: isInvestor ? false : queryResults.isLoading || currentCorpResults.isLoading,
      }}
      loaderSize='xs'
      variant='tooltip'
    >
      {isInvestor ? (
        <InvestorRedFlagExplanationHoverCard />
      ) : totalActiveRedFlags.count === 0 ? (
        <BadgeHoverCard
          color='go'
          icon={<IconCircleCheck size='lg' />}
          badge={
            <ThemeIcon color='go'>
              <IconCircleCheck />
            </ThemeIcon>
          }
          noBadgeWrap
          hoverTitle='Aerial found no Red Flags!'
          hoverText={redFlagExplanation}
        />
      ) : (
        <AnimatedCountBadge
          value={totalActiveRedFlags}
          renderBadge={({ count }) => (
            <BadgeHoverCard
              color='urgent'
              icon={<ActiveRedFlagIcon size='lg' />}
              badge={count}
              hoverTitle={`Aerial found ${count} Red ${pluralize('Flags', count)}`}
              hoverText={redFlagExplanation}
            />
          )}
        />
      )}
    </LoadingErrorComp>
  )
}
