import type { UseMutationResult } from '@tanstack/react-query'
import { FirebaseError } from 'firebase/app'
import React from 'react'
import promptConfirmAsync from '~/client/components/util/prompt-confirm-async'
import { auth } from '~/client/lib/firebase'
import { hooks } from '~/client/lib/hooks/dependency-injection/interface'
import { getErrorMessageOpt } from '~/common/util'
import { zenvCommon } from '~/common/zenv-common'

/**
 * Prompts user to re-authenticate if necessary (e.g. because they are adding MFA.)
 */
export const useOnRequireRecentLogin = (): {
  onRequireRecentLogin: (err: unknown) => Promise<void>
} => {
  const { mutateAsync: revokeTokens } = hooks.trpc().user.revokeTokens.useMutation()

  const onRequireRecentLogin = React.useCallback(
    async (err: unknown) => {
      if (err instanceof FirebaseError) {
        if (err.code === 'auth/requires-recent-login') {
          const confirm = await promptConfirmAsync({
            title: 'Re-authenticate',
            subtitle: 'You need to re-authenticate to add Two-Factor Auth',
            confirmText: 'Re-authenticate',
            buttonsColorVariant: 'encourage',
          })
          if (confirm) {
            if (zenvCommon.NEXT_PUBLIC_ENABLE_TOKEN_REVOKE) await revokeTokens()
            await auth.signOut()
            return
          }
        }
      }
      throw err
    },
    [revokeTokens]
  )

  return { onRequireRecentLogin }
}

export const handleMFAError = <TInput, TOutput>(
  fn: (input: TInput) => Promise<TOutput>,
  onError: (err: unknown) => Promise<void>
): ((input: TInput) => Promise<TOutput | undefined>) => {
  return async (input: TInput) => {
    try {
      return await fn(input)
    } catch (err) {
      await onError(err)
      return undefined
    }
  }
}

export const getMFAError = (error: unknown): string | undefined => {
  if (
    error instanceof Error &&
    'code' in error &&
    error.code === 'auth/invalid-verification-code'
  ) {
    return 'Invalid verification code'
  }

  return getErrorMessageOpt(error)
}

export type AuthUseMutationResult<TVariables> = UseMutationResult<
  void,
  unknown,
  TVariables,
  unknown
>
