import { useLocalStorage } from '@mantine/hooks'
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
  MultiFactorAssertion,
  MultiFactorResolver,
  MultiFactorUser,
  PhoneInfoOptions,
} from 'firebase/auth'
import React from 'react'
import { auth } from '~/client/lib/firebase'
import { hooks } from '~/client/lib/hooks/dependency-injection/interface'
import type { AuthUseMutationResult } from './mfa'
import { handleMFAError, useOnRequireRecentLogin } from './mfa'
import { useRecaptchaStore, useRecaptchaVerifier } from './recaptcha-verifier'

const useMultiFactorSessionQuery = (multifactorUser?: MultiFactorUser) => {
  const { data } = useQuery({
    queryKey: ['getMultiFactorSessionQuery'],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () => multifactorUser!.getSession(),
    enabled: !!multifactorUser,
  })
  return data
}

/**
 * Handles multi-factor authentication for phone and provides primitives.
 * @returns
 */
const usePhoneAuthState = () => {
  const multifactorUser = hooks.useAuthStore((state) => state.multiFactorUser)
  useRecaptchaVerifier()
  // verification Id is tied to SMS verification session and should be persisted across refresh
  const [verificationId, setVerificationId, removeVerificationId] = useLocalStorage({
    key: 'verificationId',
  })
  const loginResolver = hooks.useAuthStore((state) => state.loginResolver)
  const setLoginResolver = hooks.useAuthStore((state) => state.setLoginResolver)

  const multiFactorSession = useMultiFactorSessionQuery(multifactorUser)

  const enrollMFUser = React.useCallback(
    (multiFactorAssertion: MultiFactorAssertion) => {
      return multifactorUser?.enroll(multiFactorAssertion, 'phone number') ?? Promise.resolve()
    },
    [multifactorUser]
  )

  const verifyPhoneInfo = React.useCallback(
    async (data: PhoneInfoOptions) => {
      // get recaptcha verifier from store, which will have the updated value
      // even in the handle redirect result `useEffect`.
      const { recaptcha } = useRecaptchaStore.getState()
      if (!recaptcha) {
        throw new Error('No recaptcha verifier')
      }
      setVerificationId(await auth.verifyPhoneNumber(data, recaptcha))
    },
    [setVerificationId]
  )

  const generatePhoneMFAssertion = React.useCallback(
    (code: string) => {
      if (!verificationId) {
        throw new Error('No verification id')
      }
      return auth.generatePhoneMFAssertion(verificationId, code)
    },
    [verificationId]
  )

  const sendVerificationPhoneCode = async (_loginResolver?: MultiFactorResolver | undefined) => {
    const resolver = _loginResolver ?? loginResolver
    if (!resolver) {
      throw new Error('No multifactor resolver')
    }
    await verifyPhoneInfo({
      multiFactorHint: resolver.hints[0],
      session: resolver.session,
    })
  }

  const sendEnrollPhoneCode = React.useCallback(
    async (phoneNumber: string) => {
      await verifyPhoneInfo({ phoneNumber, session: multiFactorSession })
    },
    [verifyPhoneInfo, multiFactorSession]
  )

  const enrollPhone = React.useCallback(
    async (code: string) => {
      const assertion = await generatePhoneMFAssertion(code)
      await enrollMFUser(assertion)
      // clean up local storage on success
      removeVerificationId()
    },
    [generatePhoneMFAssertion, enrollMFUser, removeVerificationId]
  )

  const verifyPhone = React.useCallback(
    async (code: string) => {
      if (!loginResolver) {
        throw new Error('No multifactor resolver')
      }
      const assertion = await generatePhoneMFAssertion(code)
      await loginResolver.resolveSignIn(assertion)
      setLoginResolver(undefined)
      // clean up local storage on success
      removeVerificationId()
    },
    [loginResolver, generatePhoneMFAssertion, setLoginResolver, removeVerificationId]
  )

  return {
    sendEnrollPhoneCode,
    enrollPhone,
    sendVerificationPhoneCode,
    verifyPhone,
  }
}

export interface UsePhoneAuthRtn {
  sendEnrollPhoneCodeMutation: AuthUseMutationResult<string>
  enrollPhoneMutation: AuthUseMutationResult<string>
  sendVerificationPhoneCodeMutation: AuthUseMutationResult<MultiFactorResolver | undefined>
  verifyPhoneMutation: AuthUseMutationResult<string>
  sendVerificationPhoneCode: (resolver: MultiFactorResolver) => Promise<void>
}

export const usePhoneAuth = (): UsePhoneAuthRtn => {
  const { sendEnrollPhoneCode, enrollPhone, sendVerificationPhoneCode, verifyPhone } =
    usePhoneAuthState()
  const { onRequireRecentLogin } = useOnRequireRecentLogin()

  const options = { meta: { noErrorNotification: true } }

  const sendEnrollPhoneCodeMutation = useMutation(
    handleMFAError(sendEnrollPhoneCode, onRequireRecentLogin)
  )
  const enrollPhoneMutation = useMutation(
    handleMFAError(enrollPhone, onRequireRecentLogin),
    options
  )
  const sendVerificationPhoneCodeMutation = useMutation(
    handleMFAError(sendVerificationPhoneCode, onRequireRecentLogin)
  )
  const verifyPhoneMutation = useMutation(
    handleMFAError(verifyPhone, onRequireRecentLogin),
    options
  )

  return {
    sendEnrollPhoneCodeMutation,
    enrollPhoneMutation,
    sendVerificationPhoneCodeMutation,
    verifyPhoneMutation,
    sendVerificationPhoneCode,
  }
}
