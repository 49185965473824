import { Button, Menu, Table, ThemeIcon } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconLink, IconUpload } from '@tabler/icons-react'
import { useRouter } from 'next/router'
import React from 'react'
import { useDocDetailViewStore } from '~/client/components/doc-detail/state'
import { NoRedFlags, RedFlagRow, useCustomRedFlagDetailModal } from '~/client/components/red-flags'
import { nestedClickArea } from '~/client/components/util'
import { SkeletonRows } from '~/client/components/util/skeleton-rows'
import { TableHeaderRightAligned } from '~/client/components/util/table-cell-right-aligned'
import type { CorpAutofill } from '~/client/lib/hooks/relation-autofill'
import type {
  EnhancedCorp,
  EnhancedRelation,
  RedFlagInfo,
  RelationAutofill,
} from '~/common/enhance'
import { isCustomRedFlag, typeRedFlagRuleMap } from '~/common/red-flags'
import { type ZDocType } from '~/common/schema'
import type { ZAllAugmentedCustomRedFlag, ZAugmentedRecordsRedFlag } from '~/common/schema/red-flag'

interface RelationRedFlagCommonProps {
  data: EnhancedRelation | EnhancedCorp
  autofill: RelationAutofill | CorpAutofill
  openSearchModal: (type?: ZDocType) => void
}

interface FixRedFlagProps extends RelationRedFlagCommonProps {
  defaultOpen?: boolean
  redFlag: ZAugmentedRecordsRedFlag
  allowedTypes: ZDocType[]
}

const FixRedFlag: React.FC<FixRedFlagProps> = ({
  data,
  redFlag,
  defaultOpen,
  allowedTypes,
  openSearchModal,
  autofill,
}) => {
  const [opened, { open, close }] = useDisclosure(defaultOpen ?? false)
  const openDocDetail = useDocDetailViewStore((state) => state.openModal)

  const linkOptions =
    'type' in data
      ? ({
          type: 'relation',
          cryptId: data.cryptId,
          relationDisplay: data.display,
        } as const)
      : ({ type: 'corp' } as const)

  const docAutoFill = {
    parties: [],
    types: [],
    titles: [],
    emails: [],
    ...autofill,
    dates: autofill.startDates.map((date) => date.value).filter(Boolean),
  }

  const docType = typeRedFlagRuleMap.get(redFlag.type)?.docType

  return (
    <Menu
      opened={opened}
      onClose={close}
      data-testid='red-flag-fix-menu'
      position='bottom-end'
      shadow='md'
      width={150}
    >
      <Menu.Target>
        <Button onClick={() => open()}>Fix</Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          data-testid='link-document'
          onClick={() => openSearchModal(docType)}
          leftSection={
            <ThemeIcon>
              <IconLink />
            </ThemeIcon>
          }
        >
          Link
        </Menu.Item>
        <Menu.Item
          data-testid='add-document'
          onClick={() =>
            openDocDetail({
              allowedTypes,
              linkOptions,
              preselectedType: docType,
              autofill: docAutoFill,
            })
          }
          leftSection={
            <ThemeIcon>
              <IconUpload />
            </ThemeIcon>
          }
        >
          Upload
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

const FixCustomButton: React.FC<{
  redFlag: ZAllAugmentedCustomRedFlag
  openRedFlag: () => void
}> = ({ redFlag, openRedFlag }) => {
  const { query } = useRouter()

  React.useEffect(() => {
    if (query.redFlag === redFlag.cryptId.idStr) openRedFlag()
    // we do not add `openRedFlag` to the deps here to avoid the effect
    // from running when we don't want it to
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.redFlag, redFlag.cryptId.idStr])

  return <Button style={{ visibility: 'hidden' }}>Fix</Button>
}

interface RedFlagSectionProps extends RelationRedFlagCommonProps {
  redFlagInfo: RedFlagInfo
}

export const RedFlagsSection: React.FC<RedFlagSectionProps> = ({
  data,
  redFlagInfo,
  openSearchModal,
  autofill,
}) => {
  const { query } = useRouter()
  const { modal, openCustomRedFlagModal } = useCustomRedFlagDetailModal()
  const isRelation = 'type' in data
  const allowedTypes = data.supportedTypes

  return (
    <>
      {redFlagInfo.redFlags.length > 0 ? (
        <Table highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Table.Th w='7%' />
              <Table.Th>Flag</Table.Th>
              <TableHeaderRightAligned>
                {isRelation && (
                  <Button
                    data-testid='add-red-flag-btn'
                    onClick={() =>
                      openCustomRedFlagModal({
                        mode: 'new',
                        props: { primaryCryptId: data.cryptId, type: 'CUSTOM_RELATION' },
                      })
                    }
                  >
                    Add
                  </Button>
                )}
              </TableHeaderRightAligned>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {redFlagInfo.redFlags.map((redFlag) => (
              <RedFlagRow
                maxWidth={450}
                key={redFlag.cryptId.idStr}
                redFlag={redFlag}
                onClick={
                  isCustomRedFlag(redFlag)
                    ? nestedClickArea.avoid(() => openCustomRedFlagModal({ mode: 'edit', redFlag }))
                    : undefined
                }
                fixButton={
                  isCustomRedFlag(redFlag) ? (
                    <FixCustomButton
                      redFlag={redFlag}
                      openRedFlag={() => openCustomRedFlagModal({ mode: 'edit', redFlag })}
                    />
                  ) : (
                    <FixRedFlag
                      defaultOpen={query.redFlag === redFlag.cryptId.idStr}
                      redFlag={{ ...redFlag, type: redFlag.type }}
                      data={data}
                      allowedTypes={allowedTypes}
                      openSearchModal={openSearchModal}
                      autofill={autofill}
                    />
                  )
                }
              />
            ))}
          </Table.Tbody>
        </Table>
      ) : (
        <NoRedFlags
          noIcon
          buttonLabel='Add'
          title='No Red Flags'
          subtitle={`No red flags for this ${isRelation ? 'relation' : 'corporation'}`}
          addRedFlag={() =>
            openCustomRedFlagModal({
              mode: 'new',
              props: { primaryCryptId: data.cryptId, type: 'CUSTOM_RELATION' },
            })
          }
        />
      )}
      {modal}
    </>
  )
}

export const RedFlagSkeleton: React.FC = () => (
  <Table>
    <Table.Tbody>
      <SkeletonRows
        types={['STATUS', 'NAME', ...new Array(7).fill('NONE'), 'DOCUMENTS']}
        rowCount={3}
      />
    </Table.Tbody>
  </Table>
)
