import { useDebouncedState } from '@mantine/hooks'
import type { UseInfiniteQueryResult } from '@tanstack/react-query'
import React from 'react'
import { hooks } from '~/client/lib/hooks'
import { nextPageParamOpts } from '~/client/lib/hooks/query'
import type { Trpc } from '~/client/lib/trpc'
import type { Paginated, ZAugmentedQueryCorpsItem } from '~/common/schema'

type SearchQueryResults = UseInfiniteQueryResult<Paginated<ZAugmentedQueryCorpsItem>>

interface QuerySearchProps
  extends Omit<Parameters<Trpc['searchCorps']['useInfiniteQuery']>[0], 'query'> {
  queryWithEmptyString: boolean
}

interface QuerySearchResult {
  query: string
  setQuery: (q: string) => void
  searchResults: SearchQueryResults
  // This only works when queryWithEmptyString is set to true
  hasNoCorp: boolean
}

export const useCorpSearch = ({
  queryWithEmptyString,
  ...params
}: QuerySearchProps): QuerySearchResult => {
  const [query, _setQuery] = useDebouncedState<string>('', 500)

  const searchResults: SearchQueryResults = hooks.trpc().searchCorps.useInfiniteQuery(
    { ...params, query },
    {
      // Avoid making unnecessary request and messing consent screen in test
      enabled: queryWithEmptyString || query.length > 0,
      keepPreviousData: true,
      ...nextPageParamOpts(),
    }
  )
  const trpcContext = hooks.trpc().useContext()

  const setQuery = React.useCallback(
    async (q: string) => {
      _setQuery(q)
      await trpcContext.searchCorps.cancel()
    },
    [_setQuery, trpcContext.searchCorps]
  )

  // Query the 1st page of corps with empty query,
  // to display Welcome Arial Modal correctly.
  const hasNoCorp =
    hooks
      .trpc()
      .searchCorps.useQuery(
        { limit: 1, direction: 1, sortField: '_id', query: '' },
        { enabled: queryWithEmptyString }
      ).data?.data.length === 0

  return {
    searchResults,
    query,
    setQuery,
    hasNoCorp,
  }
}
