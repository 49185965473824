import type { MantineStyleProp } from '@mantine/core'
import { Group, Text, ThemeIcon, Tooltip } from '@mantine/core'
import { IconSparkles } from '@tabler/icons-react'
import React from 'react'
import { LoadingErrorComp } from '~/client/components/util/error'
import { TextWithTooltip } from '~/client/components/util/text-with-tooltip'
import { hooks } from '~/client/lib/hooks'
import { theme } from '~/client/lib/theme'
import type { EnhancedRelation } from '~/common/enhance'
import { ZAugmentedCorp, getSchemaDisplayableFieldsInfo } from '~/common/schema'
import { typeAugmentedRelationMap } from '~/common/schema/relation'

const RelationAutofillIcon: React.FC = () => (
  <Tooltip withinPortal label='This relation appears to be connected to the document.'>
    <ThemeIcon>
      <IconSparkles size={24} />
    </ThemeIcon>
  </Tooltip>
)

const TooltipDisplay: React.FC<{ values: [string, string | null | undefined][] }> = ({
  values,
}) => {
  return (
    <>
      {values.map(([display, value]) => (
        <Text key={display}>{`${display}: ${value ?? ''}`}</Text>
      ))}
    </>
  )
}

const RelationTooltipContent: React.FC<{ relation: EnhancedRelation }> = ({ relation }) => {
  const relationSchema = typeAugmentedRelationMap[relation.type]
  if (!relationSchema.tooltipContentFn) {
    return <>{relation.display}</>
  }

  const displayValues = relationSchema.tooltipContentFn(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    relation as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    relationSchema.shape as any
  )
  return <TooltipDisplay values={displayValues} />
}

const CorpTooltipContent: React.FC = () => {
  const corpQueryResult = hooks.trpc().corp.read.useQueryWithCorp()
  const corp = corpQueryResult.data
  if (!corp) {
    return <>Corporation</>
  }

  const displayValues = getSchemaDisplayableFieldsInfo(ZAugmentedCorp).map(
    ({ label, displayFn }): [string, string | null | undefined] => [label, displayFn(corp)]
  )
  return <TooltipDisplay values={displayValues} />
}

interface CommonProps {
  showTooltip?: boolean
  isAutofill?: boolean
  style?: MantineStyleProp
}

interface BaseNameDisplayProps extends CommonProps {
  tooltipLabel: React.ReactNode
  display: React.ReactNode
}

const BaseNameDisplay: React.FC<BaseNameDisplayProps> = ({
  isAutofill,
  showTooltip,
  display,
  tooltipLabel,
  style,
}) => {
  return (
    <Group gap='xs'>
      <TextWithTooltip
        label={tooltipLabel}
        position='top-start'
        tooltipDisabled={!showTooltip}
        maw={theme.other.widths.sm}
        w='fit-content'
        style={style}
      >
        {display}
      </TextWithTooltip>
      {isAutofill && <RelationAutofillIcon />}
    </Group>
  )
}

interface RelationNameDisplayProps extends CommonProps {
  relation: EnhancedRelation
}

export const RelationNameDisplay: React.FC<RelationNameDisplayProps> = ({ relation, ...props }) => {
  return (
    <BaseNameDisplay
      display={relation.display}
      tooltipLabel={<RelationTooltipContent relation={relation} />}
      {...props}
    />
  )
}

export const OrgInfoNameDisplay: React.FC<CommonProps> = (props) => {
  const corp = hooks.useCurrentCorp()
  return (
    <BaseNameDisplay
      tooltipLabel={<CorpTooltipContent />}
      display={
        <LoadingErrorComp queryResult={corp} variant='tooltip' loaderSize='sm'>
          1.1 {corp.data?.name.value ?? 'Corporation'}
        </LoadingErrorComp>
      }
      {...props}
    />
  )
}
