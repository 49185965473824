import { z } from 'zod'

export const ZLaunchPlan = z.literal('Launch')
export type ZLaunchPlan = z.infer<typeof ZLaunchPlan>

export const ZPaidPlan = z.enum(['Grow', 'Scale'])
export type ZPaidPlan = z.infer<typeof ZPaidPlan>

export const ZSubscriptionPlan = ZPaidPlan.or(ZLaunchPlan)
export type ZSubscriptionPlan = z.infer<typeof ZSubscriptionPlan>
