import type { CryptId } from '@cryptid-module'
import { getCorpMissingDocsData, mkIndexedDocs } from '~/common/doc'
import { filterRecordsRedFlag } from '~/common/red-flags'
import type { WithIndexNumber, ZAugmentedDoc, ZDocType } from '~/common/schema'
import { ZAugmentedCorp } from '~/common/schema'
import type { ZAllAugmentedRecordsRedFlag } from '~/common/schema/red-flag'
import type { RedFlagInfo } from './util'
import { getActiveStatus, getSourceCryptIds } from './util'

/**
 * Stub document to make it easy to identify missing docs on front end
 */
export const getCorpSourceCryptIds = (corp: ZAugmentedCorp): CryptId[] => {
  return getSourceCryptIds(corp, ZAugmentedCorp.shape)
}

export interface EnhancedCorp extends ZAugmentedCorp {
  sourceCryptIds: CryptId[]
  docs: (WithIndexNumber<ZAugmentedDoc> & { key: string })[]
  isActive: boolean
  display: string
  supportedTypes: ZDocType[]
}

export const enhanceCorp = (corp: ZAugmentedCorp): EnhancedCorp => {
  const sourceCryptIds = getCorpSourceCryptIds(corp)
  const docs = corp.docOpts.filter(Boolean)
  const enhancedDocs = mkIndexedDocs(docs).map((doc) => ({
    ...doc,
    key: doc.cryptId.idStr,
  }))
  const isActive = getActiveStatus(corp)

  return {
    ...corp,
    docs: enhancedDocs,
    sourceCryptIds,
    isActive,
    display: ZAugmentedCorp.displayFn(corp.name.value),
    supportedTypes: ZAugmentedCorp.supportedTypes,
  }
}

export const getCorpRedFlagInfo = (
  corp: EnhancedCorp,
  redFlags: ZAllAugmentedRecordsRedFlag[]
): RedFlagInfo => {
  const { missingDocs, enhancedMissingDocs, typesWithState } = getCorpMissingDocsData(
    filterRecordsRedFlag(redFlags)
  )
  const missingWithDocs = [...enhancedMissingDocs, ...corp.docs]
  const isComplete = missingDocs.length === 0

  return {
    redFlags,
    isComplete,
    missingDocs,
    typesWithState,
    missingWithDocs,
  }
}
