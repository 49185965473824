import { Button, Center, Group, Notification, Stack, Text } from '@mantine/core'
import { IconX } from '@tabler/icons-react'
import React from 'react'
import { useDocDetailState } from '~/client/components/doc-detail/state'
import { MAX_FILE_SIZE_MB } from '~/client/lib/doc-upload'
import { useCurrentCorpAuth } from '~/client/lib/hooks'

export const DropError: React.FC = () => {
  const dropError = useDocDetailState((state) => state.dropError)
  const setDropError = useDocDetailState((state) => state.setDropError)

  return (
    <>
      {dropError && (
        <Center>
          <Notification
            icon={<IconX size={18} />}
            color='danger'
            title='Upload Error'
            onClose={() => setDropError(undefined)}
            data-testid='upload-error'
          >
            {dropError}
          </Notification>
        </Center>
      )}
    </>
  )
}

const UploadIconAndText: React.FC<{ inlineIcon?: boolean }> = ({ inlineIcon, children }) => {
  if (inlineIcon) {
    return <Group gap='xl'>{children}</Group>
  }
  return <>{children}</>
}

export const UploadFiles: React.FC<{
  browseText: string
  icon: React.ReactNode
  title: string
  inlineIcon?: boolean
  isLoading?: boolean
}> = ({ browseText, icon, title, inlineIcon, isLoading }) => {
  const { data: auth } = useCurrentCorpAuth()

  return (
    <Stack justify='center' align='center' p='xl' h='100%' gap='lg'>
      <UploadIconAndText inlineIcon={inlineIcon}>
        {icon}
        <Stack align={inlineIcon ? undefined : 'center'} gap='sm'>
          <Text c={isLoading ? 'dimmed' : undefined} fz={24}>
            {title}
          </Text>
          <Text size='md' c='dimmed'>
            {`${MAX_FILE_SIZE_MB}MB Maximum File Size`}
          </Text>
        </Stack>
      </UploadIconAndText>
      <Text size='lg' c='dimmed'>
        or
      </Text>
      <Button size='md' disabled={isLoading || auth?.level === 'investor'}>
        {browseText}
      </Button>
    </Stack>
  )
}
