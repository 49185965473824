import { Button, Flex, Group, Table } from '@mantine/core'
import Router from 'next/router'
import React from 'react'
import { sortBy } from 'underscore'
import { RedFlagStatusIcon } from '~/client/components/red-flags/red-flag-icon'
import type { RedFlagsProps } from '~/client/components/red-flags/red-flags-list'
import { useGetRedFlagPrimaryObjectUrl } from '~/client/components/red-flags/util'
import { nestedClickArea } from '~/client/components/util'
import { LoadingErrorComp } from '~/client/components/util/error'
import { Pagination } from '~/client/components/util/pagination'
import { SkeletonRows } from '~/client/components/util/skeleton-rows'
import { SortHeader, useTrinarySort } from '~/client/components/util/sort'
import { TableHeaderRightAligned } from '~/client/components/util/table-cell-right-aligned'
import { TextWithTooltip } from '~/client/components/util/text-with-tooltip'
import { hooks, usePagination } from '~/client/lib/hooks'
import { theme } from '~/client/lib/theme'
import { isCustomRedFlag } from '~/common/red-flags'
import type { ZAugmentedRedFlag } from '~/common/schema/red-flag'

export const RedFlagRow: React.FC<{
  redFlag: ZAugmentedRedFlag
  maxWidth?: number
  compact?: boolean
  fixButton?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLTableRowElement>
}> = ({ redFlag, compact, maxWidth, fixButton, onClick }) => {
  const dismissMutation = hooks.trpc().redFlags.dismiss.useMutationWithCorp()
  const reFlagMutation = hooks.trpc().redFlags.removeDismissal.useMutationWithCorp()

  return (
    <Table.Tr onClick={onClick} style={{ cursor: 'pointer' }}>
      <Table.Td align='center' {...(compact ? { px: 0 } : {})}>
        <Flex justify='center'>
          <RedFlagStatusIcon state={redFlag.dismissed ? 'dismissed' : 'missing'} />
        </Flex>
      </Table.Td>
      <Table.Td {...(compact ? { pr: 0, pl: 'xs' } : {})}>
        <TextWithTooltip maw={maxWidth ?? theme.other.widths.sm}>{redFlag.display}</TextWithTooltip>
      </Table.Td>
      <Table.Td align='right' {...(compact ? { px: 0 } : { pr: 0 })}>
        <Group
          {...(compact ? { gap: 'xs' } : { w: theme.other.widths.xs })}
          justify='end'
          wrap='nowrap'
        >
          {redFlag.dismissed ? (
            <Button
              {...(compact ? { size: 'compact-sm' } : {})}
              className={nestedClickArea.cssClass}
              onClick={() => reFlagMutation.mutateAsync({ cryptId: redFlag.cryptId })}
              loading={reFlagMutation.isLoading}
              data-testid='re-flag-red-flag-btn'
              variant='subtle'
              color='gray'
            >
              Re-flag
            </Button>
          ) : (
            <Button
              {...(compact ? { size: 'compact-sm' } : {})}
              className={nestedClickArea.cssClass}
              onClick={() => dismissMutation.mutateAsync({ cryptId: redFlag.cryptId })}
              loading={dismissMutation.isLoading}
              data-testid='dismiss-red-flag-btn'
              variant='subtle'
              color='gray'
            >
              Dismiss
            </Button>
          )}
          {fixButton}
        </Group>
      </Table.Td>
    </Table.Tr>
  )
}

interface RedFlagTableProps extends RedFlagsProps {
  dismissed: boolean
  noRedFlags: React.ReactElement | null
}

export const RedFlagsTable: React.FC<RedFlagTableProps> = ({
  dismissed,
  noRedFlags,
  openCustomRedFlagModal,
}) => {
  const pagination = usePagination()
  const queryResult = hooks
    .trpc()
    .redFlags.get.all.useQueryWithCorp({ ...pagination.getQueryParams(), dismissed })
  const getRedFlagPrimaryObjectUrl = useGetRedFlagPrimaryObjectUrl()
  const sortState = useTrinarySort<'flag' | 'default'>({
    sortField: 'default',
    direction: 1,
  })

  const redFlags = queryResult.data?.data ?? []
  pagination.setTotalItems(queryResult.data?.count)

  const { sortField } = sortState
  const sortedRedFlags =
    sortField === 'default'
      ? redFlags
      : sortBy(redFlags, (item) => {
          switch (sortField) {
            case 'flag':
              return item.display
          }
        })

  if (sortState.direction === -1) sortedRedFlags.reverse()

  return !queryResult.isLoading && (queryResult.data?.count.count ?? 0) === 0 ? (
    noRedFlags
  ) : (
    <>
      <Table highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            <Table.Th w='7%' />
            <SortHeader sortField='flag' sortState={sortState}>
              Flag
            </SortHeader>
            {queryResult.isLoading &&
              new Array(7).fill(null).map((_, index) => <Table.Th key={index} />)}
            <TableHeaderRightAligned>
              <Button
                data-testid='add-red-flag-btn'
                onClick={() => openCustomRedFlagModal({ mode: 'new', props: { type: 'CUSTOM' } })}
              >
                Add
              </Button>
            </TableHeaderRightAligned>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <LoadingErrorComp
            queryResult={queryResult}
            skeleton={
              <SkeletonRows types={['STATUS', 'NAME', ...new Array(7).fill('NONE'), 'BUTTONS']} />
            }
            fullTableWidth
          >
            {sortedRedFlags.map((redFlag) => {
              const primaryObjectUrl = getRedFlagPrimaryObjectUrl(redFlag)

              return (
                <RedFlagRow
                  key={redFlag.cryptId.idStr}
                  redFlag={redFlag}
                  fixButton={
                    <Button style={isCustomRedFlag(redFlag) ? { visibility: 'hidden' } : undefined}>
                      Fix
                    </Button>
                  }
                  onClick={
                    redFlag.type === 'CUSTOM'
                      ? nestedClickArea.avoid(() =>
                          openCustomRedFlagModal({ mode: 'edit', redFlag })
                        )
                      : primaryObjectUrl
                        ? nestedClickArea.avoid(() => Router.push(primaryObjectUrl))
                        : undefined
                  }
                />
              )
            })}
          </LoadingErrorComp>
        </Table.Tbody>
      </Table>
      <Pagination styles={{ mt: 'sm' }} {...pagination} queryResult={queryResult} />
    </>
  )
}
