import { z } from 'zod'
import {
  AugmentedMetadataDate,
  AugmentedMetadataNumber,
  AugmentedMetadataPercentage,
  AugmentedMetadataPrice2,
  ZParty,
  withDisplaySchema,
} from '~/common/schema'
import { util } from '~/common/schema/relation/util'

const safeAugmentedShape = {
  type: z.literal('SAFE'),
  party: withDisplaySchema(ZParty, 'party', 'Equity Holder'),
  investment: withDisplaySchema(AugmentedMetadataPrice2.optional(), 'price2', 'Investment'),
  valuationCap: withDisplaySchema(AugmentedMetadataNumber.optional(), 'number', 'Valuation Cap'),
  discount: withDisplaySchema(AugmentedMetadataPercentage.optional(), 'percentage', 'Discount'),
  startDate: withDisplaySchema(AugmentedMetadataDate.optional(), 'date', 'Issue Date'),
  endDate: withDisplaySchema(
    AugmentedMetadataDate.optional(),
    'date',
    'Conversion or Termination Date'
  ),
}

export const { ZUpdate: ZUpdateSafe, ZAugmented: ZAugmentedSafe } = util.createSchemas(
  {
    updateShape: safeAugmentedShape,
    augmentedShape: safeAugmentedShape,
    defaultValue: { party: { name: '' } },
  },
  {
    requiredTypes: [
      'SIMPLE_AGREEMENT_FOR_FUTURE_EQUITY', // Primary Document
      'BOARD_CONSENT_AND_MINUTES',
    ],
    optionalTypes: ['MISCELLANEOUS', 'STOCKHOLDER_CONSENT', 'SIDE_LETTER', 'ADVISOR_AGREEMENT'],
    display: 'Simple Agreement for Future Equity',
    displayFn: (obj) => `SAFE ${obj.party.name}`,
    identifyingField: 'party.name',
    tooltipContentFn: (obj, shape) => [
      [shape.party.displaySchema.display, ZParty.display(obj.party)],
      [shape.startDate.displaySchema.display, AugmentedMetadataDate.display(obj.startDate?.value)],
      [shape.endDate.displaySchema.display, AugmentedMetadataDate.display(obj.endDate?.value)],
      [
        shape.investment.displaySchema.display,
        AugmentedMetadataPrice2.display(obj.investment?.value),
      ],
      [
        shape.valuationCap.displaySchema.display,
        AugmentedMetadataNumber.display(obj.valuationCap?.value),
      ],
      [
        shape.discount.displaySchema.display,
        AugmentedMetadataPercentage.display(obj.discount?.value),
      ],
    ],
  }
)
export interface ZUpdateSafe extends z.infer<typeof ZUpdateSafe> {}
export interface ZAugmentedSafe extends z.infer<typeof ZAugmentedSafe> {}
