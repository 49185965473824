// Import the functions you need from the SDKs you need
import type { FirebaseApp, FirebaseOptions } from 'firebase/app'
import { FirebaseError, deleteApp, getApp, initializeApp } from 'firebase/app'
import { type AppCheck, ReCaptchaV3Provider, initializeAppCheck } from 'firebase/app-check'
import { FirebaseAuth } from '~/client/lib/firebase-auth'
import { parseJsonBase64 } from '~/common/util'
import { zenvCommon } from '~/common/zenv-common'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// eslint-disable-next-line custom-rules/no-bad-casting-in-declaration
export const firebaseConfig = parseJsonBase64(
  zenvCommon.NEXT_PUBLIC_FIREBASE_CONFIG_BASE64
) as FirebaseOptions

// We set the `authDomain` dynamically to the same domain the app is hosted to
// prevent cross domain issues that prevent us from being able to use `signInWithRedirect`
// https://github.com/firebase/firebase-js-sdk/issues/7824
const authDomain = (() => {
  // We cannot get the hostname when rendering on the server
  if (typeof window === 'undefined') return undefined
  const { hostname, port } = window.location
  if (hostname === 'localhost') return `localhost:${port}`
  return hostname
})()

/**
 * Initializes the Firebase app and replaces any duplicate app instances caused by Hot Module Replacement (HMR).
 * HMR allows for live reloading of modules during development, but it can create duplicate instances of the Firebase app.
 * To prevent `app/duplicate-app` errors, we replace the duplicate app instances with a new one.
 */
const initializeFirebaseAppReplaceDuplicate = (options: FirebaseOptions): FirebaseApp => {
  try {
    return initializeApp(options)
  } catch (err) {
    if (err instanceof FirebaseError && err.code === 'app/duplicate-app') {
      // eslint-disable-next-line no-console
      console.warn('Firebase app already initialized')
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      deleteApp(getApp())

      const app = initializeApp(options)
      // eslint-disable-next-line no-console
      console.log('Firebase app re-initialized')
      return app
    }
    throw err
  }
}

export const app = initializeFirebaseAppReplaceDuplicate({
  ...firebaseConfig,
  authDomain,
})

let appCheck: AppCheck | undefined

/** initialize Firebase App Check. Can only be called in a browser environment */
export const initAppCheck = (): void => {
  if (zenvCommon.NEXT_PUBLIC_ENABLE_APP_CHECK) {
    appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(zenvCommon.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY),
      isTokenAutoRefreshEnabled: true,
    })
  }
}

export const getAppCheck = (): AppCheck => {
  if (!appCheck) throw new Error('App check not initialized')
  return appCheck
}

// Upon app load, the Firebase SDK always fetches the fresh idToken (just in case
// it's expired) & custom claims upon auth initialization process by doing one
// the followings.
// 1. Call account:look up with cached idToken if it's not expired, and fetch
//    the latest custom claims attached
// 2. Refresh the token first if it's expired, and then do the #1. After this
//    initialization process, `auth.user` object will be available to us.
export const auth = new FirebaseAuth(app)
