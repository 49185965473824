import type { InputBaseProps, PopoverWidth } from '@mantine/core'
import { CloseButton, Combobox, InputBase, ScrollArea, useCombobox } from '@mantine/core'
import React from 'react'
import { zIndex } from '~/client/components/z-index'

interface SelectCreatable extends Omit<InputBaseProps, 'w'> {
  selectFirstOptionOnChange?: boolean
  creatable: boolean
  clearable: boolean
  items: string[]
  onChange: (value: string | null) => void
  onFocus?: () => void
  onBlur?: () => void
  value?: string | null
  width?: Exclude<PopoverWidth, null>
}

export const SelectCreatable: React.FC<SelectCreatable> = ({
  items,
  onChange,
  value,
  width,
  creatable,
  selectFirstOptionOnChange,
  clearable,
  ...inputProps
}) => {
  // Most of this code was copied from https://mantine.dev/combobox/?e=SelectCreatable
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })
  const [search, setSearch] = React.useState(value ?? '')
  const [created, setCreated] = React.useState<string>()
  const selectData = React.useMemo(() => {
    const allItems = [...items, ...(created ? [created] : [])]
    const lowerSearch = search.toLowerCase().trim()
    const filtered = allItems.filter((item) => item.toLowerCase().includes(lowerSearch))
    return filtered.sort()
  }, [created, search, items])
  const exactOptionMatch = selectData.some((item) => item === search)

  React.useEffect(() => {
    // https://mantine.dev/combobox/?e=AutocompleteSelectFirstOption
    combobox.selectFirstOption()
  }, [value, selectFirstOptionOnChange, combobox])

  const showClearButton = !!value && clearable

  return (
    <Combobox
      store={combobox}
      // We should set `withinPortal` to false, because clicking an option will close the parent popover
      // (inline edit popover).
      // https://github.com/mantinedev/mantine/issues/2144#issuecomment-1217173512
      withinPortal={false}
      onOptionSubmit={(val) => {
        if (val === '$create') {
          setCreated(search)
          onChange(search)
        } else {
          setSearch(val)
          onChange(val)
        }
        combobox.closeDropdown()
      }}
      width={width}
      zIndex={zIndex.popover}
    >
      <Combobox.Target>
        <InputBase
          rightSection={
            showClearButton ? (
              <CloseButton
                size='sm'
                onClick={() => {
                  onChange(null)
                  setSearch('')
                }}
              />
            ) : (
              <Combobox.Chevron />
            )
          }
          rightSectionPointerEvents={showClearButton ? 'all' : 'none'}
          value={search}
          onChange={(event) => {
            combobox.openDropdown()
            combobox.updateSelectedOptionIndex()
            setSearch(event.currentTarget.value)
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => {
            combobox.openDropdown()
            inputProps.onFocus?.()
          }}
          onBlur={() => {
            combobox.closeDropdown()
            setSearch(value || '')
            inputProps.onBlur?.()
          }}
          w={width}
          {...inputProps}
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          <ScrollArea.Autosize mah={250}>
            {selectData.map((item) => (
              <Combobox.Option value={item} key={item}>
                {item}
              </Combobox.Option>
            ))}
            {search && !exactOptionMatch && creatable && (
              <Combobox.Option value='$create'>
                Custom law firm: &quot;{search}&quot;
              </Combobox.Option>
            )}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
