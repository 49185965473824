import { z } from 'zod'
import { ZLawFirm } from '~/common/law-firms'
import { ZAugmentedDoc } from '~/common/schema/doc'
import {
  AugmentedMetadataDate,
  AugmentedMetadataNumber,
  AugmentedMetadataString,
} from '~/common/schema/metadata'
import { ZCryptId } from '~/common/schema/util'
import { withDisplaySchema } from '~/common/schema/with-display-schema'
import type { ZMongoDoc } from '~/server/mongo/schema/doc'

export const partnershipTierNames = ['GOLD', 'PLATINUM', 'DIAMOND'] as const
export const ZPartnershipTier = z.enum(partnershipTierNames)
export type ZPartnershipTier = z.infer<typeof ZPartnershipTier>

const requiredTypes: ZMongoDoc['type'][] = [
  'ARTICLES_OF_INCORPORATION', // Primary Document
  'IRS_EIN_ASSIGNMENT_LETTER',
  'CORPORATE_BYLAWS',
  'BOARD_CONSENT_AND_MINUTES',
  'STOCKHOLDER_CONSENT',
  'ANNUAL_REPORT',
  'INCORPORATOR_CONSENT',
]

const optionalTypes: ZMongoDoc['type'][] = [
  'MISCELLANEOUS',
  'CERTIFICATE_GOOD_STANDING',
  'BENEFICIAL_OWNERSHIP_INFORMATION_REPORT',
]

export const ZAugmentedCorp = Object.assign(
  z.object({
    name: withDisplaySchema(AugmentedMetadataString, 'string', 'Formal Company Name'),
    startDate: withDisplaySchema(AugmentedMetadataDate.optional(), 'date', 'Founding Date'),
    ein: withDisplaySchema(AugmentedMetadataString.optional(), 'string', 'Employer ID Number'),
    authorizedShares: withDisplaySchema(
      AugmentedMetadataNumber.optional(),
      'number',
      'Authorized Shares'
    ),
    lawFirm: withDisplaySchema(ZLawFirm, 'lawFirm', 'Law Firm'),
    cryptId: ZCryptId,
    docCryptIds: ZCryptId.array(),
    docOpts: ZAugmentedDoc.optional().array(),
    createdBy: z.string().email().optional(),
    partnershipTier: ZPartnershipTier.optional(),
  }),
  {
    type: 'CORP' as const,
    display: 'Corporation',
    requiredTypes,
    optionalTypes,
    supportedTypes: [...requiredTypes, ...optionalTypes],
    displayFn: (corpName: string | null | undefined, withIndex = true): string =>
      `${withIndex ? '1.1 ' : ''}Corporation ${corpName}`,
  }
)

export interface ZAugmentedCorp extends z.infer<typeof ZAugmentedCorp> {}

export const ZUpdateCorp = ZAugmentedCorp.omit({
  createdBy: true,
  partnershipTier: true,
  // docOpts are computed from docCryptIds, and are not necessary for updates.
  docOpts: true,
})
export interface ZUpdateCorp extends z.infer<typeof ZUpdateCorp> {}

export const ZCorpWithProcessing = ZAugmentedCorp.omit({
  cryptId: true,
  docCryptIds: true,
  docOpts: true,
}).extend({
  processingDocs: z.number(),
  cryptId: ZCryptId,
})
export interface ZCorpWithProcessing extends z.infer<typeof ZCorpWithProcessing> {}

export const ZCorpsWithProcessingDocs = z.object({
  corps: ZCorpWithProcessing.array(),
  exceeds: z.boolean(),
})
export interface ZCorpsWithProcessingDocs extends z.infer<typeof ZCorpsWithProcessingDocs> {}

export const ZCorpSpecialPurpose = z.literal('demo') // Will be an enum in the future
export type ZCorpSpecialPurpose = z.infer<typeof ZCorpSpecialPurpose>
