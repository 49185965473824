import { Table } from '@mantine/core'
import React from 'react'
import type { DetailTableProps } from '~/client/components/relation/detail/types'
import { MetadataRow } from '~/client/components/util/metadata'
import type { ZUpdateLocalCorporation, ZUpdateStateCorporation } from '~/common/schema/relation'
import { ZAugmentedLocalCorporation, ZAugmentedStateCorporation } from '~/common/schema/relation'

export const StateMetadataTable: React.FC<
  DetailTableProps<ZUpdateStateCorporation, ZAugmentedStateCorporation>
> = ({ data, update, size }) => (
  <Table striped>
    <Table.Tbody>
      <MetadataRow
        displayProps={ZAugmentedStateCorporation.shape.state.mkDisplayProps({
          data,
          update,
          property: 'state',
        })}
        size={size}
      />
    </Table.Tbody>
  </Table>
)

export const LocalMetadataTable: React.FC<
  DetailTableProps<ZUpdateLocalCorporation, ZAugmentedLocalCorporation>
> = ({ data, update, size }) => (
  <Table striped>
    <Table.Tbody>
      <MetadataRow
        displayProps={ZAugmentedLocalCorporation.shape.jurisdiction.mkDisplayProps({
          data,
          update,
          property: 'jurisdiction',
        })}
        size={size}
      />
    </Table.Tbody>
  </Table>
)
