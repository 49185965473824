/* eslint-disable prefer-arrow/prefer-arrow-functions,func-style */

/**
 * Takes any datetime date and returns the date (as datetime) in UTC
 * This removes time of day and timezone data regardless of the timezone data was entered (i.e. via DatePicker)
 * NB: depending on your timezone, `fromUTCDate(date)` may be the day before `date`.
 * @param date: Date - input datetime which may have a time or timezone set
 */
export function toUTCDate(date: Date): Date
export function toUTCDate(date: Date | null): Date | null
export function toUTCDate(date: null | undefined): null | undefined
export function toUTCDate(date: Date | null | undefined): Date | null | undefined
export function toUTCDate(date: Date | null | undefined): Date | null | undefined {
  if (!date) return date
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
}
export default toUTCDate

export function fromUTCDate(date: Date): Date
export function fromUTCDate(date: Date | null): Date | null
export function fromUTCDate(date: null | undefined): null | undefined
export function fromUTCDate(date: Date | null | undefined): Date | null | undefined
export function fromUTCDate(date: Date | null | undefined): Date | null | undefined {
  if (!date) return date
  // some dates were set in PT and ET midnight
  // this will still work for those dates because their midnights occur after GMT
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
}
