import * as React from 'react'
import { NameEmail } from '~/client/components/util'
import type { EnhancedRelation } from '~/common/enhance'
import { ZAugmentedRelation } from '~/common/schema/relation'

interface RelationPartyDisplayProps {
  relation: EnhancedRelation
  isInactive?: boolean
}

export const RelationPartyDisplay: React.FC<RelationPartyDisplayProps> = ({
  relation,
  isInactive,
}) => <NameEmail {...ZAugmentedRelation.partyNameEmailWithIndex(relation)} inactive={isInactive} />
