import { MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { theme } from '~/client/lib/theme'
import './global.css'

export const Theme: React.FC = ({ children }) => {
  return (
    /* TODO: add proper dark mode support */
    <MantineProvider forceColorScheme='light' defaultColorScheme='light' theme={theme}>
      <ModalsProvider>{children}</ModalsProvider>
    </MantineProvider>
  )
}
