import { z } from 'zod'
import {
  AugmentedMetadataDate,
  AugmentedMetadataNumber,
  AugmentedMetadataPrice4,
  ZCryptId,
  ZParty,
  withDisplaySchema,
} from '~/common/schema'
import { util } from '~/common/schema/relation/util'
import { ZAugmentedValuation } from './util'

const optionUpdateShape = {
  type: z.literal('OPTION'),
  party: withDisplaySchema(ZParty, 'party', 'Equity Holder'),
  shares: withDisplaySchema(AugmentedMetadataNumber.optional(), 'number', 'Shares'),
  strikePrice: withDisplaySchema(AugmentedMetadataPrice4.optional(), 'price4', 'Strike Price'),
  startDate: withDisplaySchema(AugmentedMetadataDate.optional(), 'date', 'Issue Date'),
  endDate: withDisplaySchema(
    AugmentedMetadataDate.optional(),
    'date',
    'Conversion or Termination Date'
  ),
  valuationCryptId: ZCryptId.nullish(),
}

const { valuationCryptId: _, ..._optionAugmentedShape } = optionUpdateShape
const valuationOmitFields = { corpCryptId: true, docCryptIds: true, docOpts: true } as const

const optionAugmentedShape = {
  ..._optionAugmentedShape,
  valuation: ZAugmentedValuation.omit(valuationOmitFields).optional(),
}

export const { ZUpdate: ZUpdateOption, ZAugmented: ZAugmentedOption } = util.createSchemas(
  {
    updateShape: optionUpdateShape,
    augmentedShape: optionAugmentedShape,
    defaultValue: { party: { name: '' } },
  },
  {
    requiredTypes: [
      'BOARD_CONSENT_AND_MINUTES', // Primary Document
    ],
    optionalTypes: [
      'STOCK_OPTION_GRANT',
      'MISCELLANEOUS',
      'STOCKHOLDER_CONSENT',
      'ADVISOR_AGREEMENT',
    ],
    display: 'Stock Option',
    displayFn: (obj) => `Stock Options ${obj.party.name}`,
    identifyingField: 'party.name',
    tooltipContentFn: (obj, shape) => [
      [shape.party.displaySchema.display, ZParty.display(obj.party)],
      [shape.startDate.displaySchema.display, AugmentedMetadataDate.display(obj.startDate?.value)],
      [shape.shares.displaySchema.display, AugmentedMetadataNumber.display(obj.shares?.value)],
      [
        shape.strikePrice.displaySchema.display,
        AugmentedMetadataPrice4.display(obj.strikePrice?.value),
      ],
    ],
  }
)
export interface ZUpdateOption extends z.infer<typeof ZUpdateOption> {}
export interface ZAugmentedOption extends z.infer<typeof ZAugmentedOption> {}
