import type { ComboboxItem, OptionsFilter } from '@mantine/core'

export const mkSelectFilter =
  (filterFn: (search: string, item: ComboboxItem) => boolean): OptionsFilter =>
  ({ options, search }) =>
    options.flatMap((option) =>
      'group' in option
        ? // group with empty items will not be displayed on list
          { ...option, items: option.items.filter((item) => filterFn(search, item)) }
        : filterFn(search, option)
          ? option
          : []
    )
