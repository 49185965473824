import { Box, Table } from '@mantine/core'
import Router from 'next/router'
import React from 'react'
import {
  RelationDateDisplay,
  RelationMiniDocDisplay,
  RelationNameDisplay,
  RelationStatusDisplay,
} from '~/client/components/relation/display'
import { RelationDetailAnchorCell, RelationDetailMenuCell } from '~/client/components/relation/util'
import { ignoreSelectionsAndNestedClicks } from '~/client/components/util'
import { InvestorRedFlagExplanationHoverCard } from '~/client/components/util/red-flags'
import {
  hoverAppearInstantClasses,
  relationRowColorInActiveClass,
  relationRowCursorClass,
  relationStickyCellRow,
  relationTableStickyCell,
} from '~/client/lib/css-util.css'
import { useCurrentCorpAuth } from '~/client/lib/hooks'
import type { EnhancedRelation, RedFlagInfo } from '~/common/enhance'
import { statusStyle } from './util'

interface RelationRowProps {
  item: EnhancedRelation
  redFlagInfo: RedFlagInfo | undefined
  nameComp?: React.ReactNode
  docsComp?: React.ReactNode
  noRedFlags?: boolean
  noStartDate?: boolean
  noEndDate?: boolean
  /** If noMenu is set, the row will only have `<IconDiagonalArrows />` that takes users to the relation.
   * If it is not set, the row will have an icon that has a menu dropdown to open the relation or delete it.
   */
  noMenu?: boolean
  'data-testid'?: string
  children?: React.ReactNode
  stickyCellClassName?: string
}

export const RelationRow: React.FC<RelationRowProps> = ({
  children,
  item,
  redFlagInfo,
  nameComp,
  docsComp,
  noRedFlags,
  noStartDate,
  noEndDate,
  noMenu,
  stickyCellClassName = relationTableStickyCell,
  'data-testid': dataTestId,
}) => {
  const { data: auth } = useCurrentCorpAuth()
  const noStartOrEndDate = !item.startDate?.value && !item.endDate?.value

  return (
    <Box
      component={Table.Tr}
      className={`${hoverAppearInstantClasses.hover} ${
        item.isActive ? '' : relationRowColorInActiveClass
      } ${relationRowCursorClass} ${relationStickyCellRow}`}
      onClick={ignoreSelectionsAndNestedClicks(() => Router.push(item.url))}
      data-testid={dataTestId}
    >
      {!noRedFlags && (
        <Table.Td
          style={{ ...statusStyle, zIndex: 5 }}
          pos='sticky'
          className={stickyCellClassName}
          left={0}
        >
          {auth?.level === 'investor' ? (
            <InvestorRedFlagExplanationHoverCard />
          ) : (
            redFlagInfo && <RelationStatusDisplay item={item} redFlagInfo={redFlagInfo} />
          )}
        </Table.Td>
      )}
      <Table.Td
        pos='sticky'
        style={{ zIndex: 5 }}
        className={stickyCellClassName}
        left={noRedFlags ? 0 : 44}
      >
        {nameComp ?? <RelationNameDisplay relation={item} />}
      </Table.Td>
      {!noStartDate && (
        <Table.Td>
          <RelationDateDisplay
            date={item.startDate?.value}
            isActive={item.isActive}
            noStartOrEndDate={noStartOrEndDate}
          />
        </Table.Td>
      )}
      {!noEndDate && (
        <Table.Td>
          <RelationDateDisplay
            date={item.endDate?.value}
            isActive={item.isActive}
            noStartOrEndDate={noStartOrEndDate}
          />
        </Table.Td>
      )}
      {children}
      <Table.Td>
        {docsComp ?? <RelationMiniDocDisplay redFlagInfo={redFlagInfo} relation={item} />}
      </Table.Td>
      {noMenu ? (
        <RelationDetailAnchorCell url={item.url} className={hoverAppearInstantClasses.appear} />
      ) : (
        <RelationDetailMenuCell relation={item} className={hoverAppearInstantClasses.appear} />
      )}
    </Box>
  )
}
