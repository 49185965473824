import type { ZAugmentedUser } from '~/common/schema/user'
import { hooks } from './dependency-injection/interface'

/**
 * Returns the current user's entry in the user collection if exists
 * Otherwise it creates and entry and returns it.
 * It also contains firebase auth information
 * @returns {ZUser}
 */
export const useCurrentUser = (): { data?: ZAugmentedUser; isLoading: boolean } => {
  const email = hooks.useAuthStore(({ user }) => user?.email)

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return hooks.trpc().user.getOrCreate.useQuery({ email: email! }, { enabled: !!email })
}
export type UseCurrentUser = typeof useCurrentUser
