import { Skeleton, Title } from '@mantine/core'

export const PageTitle: React.FC<{ title: string; isSkeleton?: boolean }> = ({
  title,
  isSkeleton = false,
}) => (
  <Title order={1} ta='left' mb='sm'>
    <Skeleton visible={isSkeleton} w='fit-content'>
      {title}
    </Skeleton>
  </Title>
)
