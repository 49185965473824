import { z } from 'zod'

export const lawFirmsList = [
  'Akerman',
  'Akin',
  'Allen & Overy',
  'Allen Matkins',
  'Alston & Bird',
  'ArentFox Schiff',
  'Arnold & Porter',
  'Atkinson, Andelson, Loya, Ruud & Romo',
  'Baker Botts',
  'Baker Donelson',
  'Baker McKenzie',
  'BakerHostetler',
  'Ballard Spahr',
  'Barnes & Thornburg',
  'Best Best & Krieger',
  'Bird, Marella, Boxer, Wolpert, Nessim, Drooks, Lincenberg & Rhow',
  'Blank Rome',
  'Boies Schiller Flexner',
  'Bracewell',
  'Bradley',
  'Brownstein',
  'Bryan Cave Leighton Paisner',
  'Buchalter, A Professional Corp.',
  'Burke, Williams & Sorensen',
  'Burr & Forman',
  'Butler Snow',
  'Cadwalader',
  'Cahill Gordon & Reindel',
  'Carlton Fields',
  'Chapman',
  'Choate',
  'Clark Hill',
  'Cleary Gottlieb',
  'Clifford Chance',
  'Clyde & Co',
  'Constangy, Brooks, Smith & Prophete',
  'Cooley',
  'Covington',
  'Covington & Burling',
  'Cox, Castle & Nicholson',
  "Cozen O'Connor",
  'Cravath',
  'Crowell & Moring',
  'Daugherty Lordan',
  'Davis Polk',
  'Davis Wright Tremaine',
  'Debevoise & Plimpton',
  'Dechert',
  'Dentons',
  'Dickinson Wright',
  'Dinsmore',
  'DLA Piper',
  'Dorsey',
  'Duane Morris',
  'Dykema',
  'Epstein Becker Green',
  'Eversheds Sutherland',
  'Faegre Drinker',
  'Fenwick',
  'Finnegan',
  'Fish & Richardson',
  'Fisher Phillips',
  'Foley & Lardner',
  'Foley Hoag',
  'FordHarrison',
  'Fox Rothschild',
  'Frandzel Robins Bloom & Csato',
  'Fredrikson',
  'Freeman Freeman & Smiley',
  'Freshfields',
  'Fried Frank',
  'Frost Brown Todd',
  'Gibbs Giden Locher Turner Senet & Wittbrodt',
  'Gibson Dunn',
  'Glaser Weil Fink Howard Avchen & Shapiro',
  'Goodwin',
  'Goulston & Storrs',
  'Greenberg Glusker',
  'Greenberg Traurig',
  'Greenspoon Marder',
  'Gunderson Dettmer Stough Villeneuve Franklin & Hachigian',
  'Hahn & Hahn',
  'Haynes Boone',
  'Hemar, Rousso & Heald',
  'Hill Farrer & Burrill',
  'Hogan Lovells',
  'Holland & Hart',
  'Holland & Knight',
  'Honigman',
  'Hooper, Lundy & Bookman',
  'Hueston Hennigan',
  'Hughes Hubbard',
  'Hunton Andrews Kurth',
  'Husch Blackwell',
  'Ice Miller',
  'Irell & Manella',
  'Jackson Lewis',
  'Jackson Walker',
  'Jeffer Mangels Butler & Mitchell',
  'Jenner & Block',
  'Jones Day',
  'K&L Gates',
  'Katten Muchin Rosenman',
  'Kellogg Hansen',
  'Kennedys',
  'Kilpatrick Townsend',
  'King & Spalding',
  'Kirkland & Ellis',
  'Knobbe Martens',
  'Kramer Levin',
  'Kutak Rock',
  'Lagerlof',
  'Larson',
  'Latham & Watkins',
  'Lewis Brisbois',
  'Lewis Roca',
  'Linklaters',
  'Littler',
  'Locke Lord',
  'Loeb & Loeb',
  'Lowenstein Sandler',
  'Manatt',
  'Manning Kass',
  'Mayer Brown',
  'McCarter & English',
  'McDermott',
  'McGuireWoods',
  'Michelman & Robinson',
  'Milbank',
  'Miller Barondess',
  'Mintz',
  'Moore & Van Allen',
  'Morgan Lewis',
  'Morrison Foerster',
  'Munger, Tolles & Olson',
  'Musick, Peeler & Garrett',
  'Nelson Hardiman',
  'Nixon Peabody',
  'Norton Rose Fulbright',
  'Nossaman',
  "O'Melveny & Myers",
  'Ogletree Deakins',
  'Orrick',
  'O’Melveny',
  'Paul Hastings',
  'Paul, Weiss',
  'Perkins Coie',
  'Pillsbury',
  'Polsinelli',
  'Proskauer',
  'Quarles',
  'Quinn Emanuel',
  'Raines Feldman',
  'Reed Smith',
  'Robins Kaplan',
  'Ropes & Gray',
  'Russ August & Kabat',
  'Schulte Roth & Zabel',
  'Seyfarth',
  'Shearman & Sterling',
  'Shegerian & Associates',
  'Sheppard Mullin',
  'Sidley Austin',
  'Simpson Thacher',
  'Skadden',
  'Sklar Kirsh',
  'Snell & Wilmer',
  'Squire Patton Boggs',
  'Steptoe & Johnson',
  'Stinson',
  'Stoel Rives',
  'Stubbs Alderton & Markiles',
  'Sullivan & Cromwell',
  'Susman Godfrey',
  'Taft',
  'Thompson Coburn',
  'Thompson Hine',
  'Troutman Pepper',
  'Tucker Ellis',
  'Tyson & Mendes',
  'Vedder Price',
  'Venable',
  'Vinson & Elkins',
  'Vorys',
  'Wachtell Lipton',
  'Waymaker',
  'Weil',
  'White & Case',
  'Wiley',
  'Williams & Connolly',
  'Willkie',
  'WilmerHale',
  'Wilson Elser',
  'Wilson Sonsini',
  'Winstead',
  'Winston & Strawn',
  'Withersworldwide',
  'Womble Bond Dickinson',
  'Wood Smith Henning & Berman',
  // Firms from corps on production
  'Martin Davis Law, PLLC',
  'PAG law, PLLC',
  'Foundation Law Group LLP',
  'Stoel Rives LLP',
  'Iuuri.Legal',
]

const _ZLawFirm = z.string().nullish()
export type ZLawFirm = z.infer<typeof _ZLawFirm>
export const ZLawFirm = Object.assign(_ZLawFirm, {
  display: (v: ZLawFirm | null | undefined) => v,
})
