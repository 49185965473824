import { IconFlag, IconFlagOff } from '@tabler/icons-react'
import { corpRedFlagRules } from './corp'
import { relationRedFlagRules } from './relation'

export * from './utils'
export { type CorpRedFlagInstance } from './corp'
export {
  type RelationRedFlagInstance,
  getRelationDocRules,
  relationRedFlagRuleMap,
  relationRedFlagRuleTypeMap,
} from './relation'

export { corpRedFlagRules, relationRedFlagRules }
export const allRedFlagRules = [...relationRedFlagRules, ...corpRedFlagRules]
export const redFlagTypes = allRedFlagRules.map((rule) => rule.type)
export type RedFlagType = (typeof redFlagTypes)[number]

export const typeRedFlagRuleMap = new Map(allRedFlagRules.map((rule) => [rule.type, rule]))

export const redFlagExplanation = `
Red Flags are issues with your legal documents that may jeopardize fundraising or acquisition.
Some Red Flags may be time-sensitive. You should quickly resolve the issue or dismiss it.
Speak to your lawyer if you have any questions or concerns about the issues.
`
export const investorRedFlagExplanation = `
With investor permissions, you do not have access to the company's real-time red flags reports. Red Flags are issues with a company's legal documents that may jeopardize fundraising or acquisition. Aerial automatically flags these issues for companies in realtime before they can blow up deals during due diligence.`

// This needs to be declared in ~/common because it's used in routes
export const ActiveRedFlagIcon = IconFlag
export const DismissedRedFlagIcon = IconFlagOff
