/**
 * Custom MultiSelect component with
 * 1. Creatable functionality
 * 2. Custom value renderer
 * 3. Custom option renderer
 * Refactored from
 * https://github.com/mantinedev/mantine/blob/00f03c412db02f3973058466d8f674f797e06149/src/mantine-core/src/components/MultiSelect/MultiSelect.tsx
 *
 * Design notes:
 * https://www.notion.so/MultiSelectCreatable-Design-Notes-67bb8b66c07d453ca8ac2bc85799baa2?pvs=4
 */
import {
  Combobox,
  type ComboboxItem,
  type ComboboxLikeProps,
  type ComboboxProps,
  type ElementProps,
  Pill,
  type PillProps,
  PillsInput,
  type PillsInputFieldProps,
  type PillsInputProps,
  type __CloseButtonProps,
  getOptionsLockup,
  getParsedComboboxData,
  useCombobox,
} from '@mantine/core'
import { useUncontrolled } from '@mantine/hooks'
import React, { forwardRef } from 'react'
import { filterPickedValues } from '~/client/components/multi-select-creatable/util'
import { nestedClickArea } from '~/client/components/util'
import { objGetOrThrow } from '~/common/util'
import { OptionsDropdown, type OptionsDropdownProps } from './options-dropdown'
import type { CustomComboboxData } from './types'

interface ClearButtonProps extends __CloseButtonProps, ElementProps<'button'> {}
export interface PillCompProps extends PillProps {
  item: ComboboxItem
}
interface PillsInputCustomProps
  extends Omit<PillsInputProps, 'onClick' | 'disabled' | 'multiline'> {
  'data-testid'?: string
}
export interface MultiSelectCreatableProps
  extends Omit<ComboboxLikeProps, 'withScrollArea' | 'selectFirstOptionOnChange' | 'data'>,
    Pick<
      OptionsDropdownProps,
      | 'creatable'
      | 'getCreateLabel'
      | 'createOptionGroup'
      | 'optionComp'
      | 'hidden'
      | 'nothingFoundMessage'
      | 'scrollbarType'
    > {
  /** className applied to the mantine-Popover-dropdown component */
  dropdownClassName?: string

  /** Data used to generate options. Includes our custom defined props */
  data?: CustomComboboxData

  /** Controlled component value */
  value?: string[]
  /** Default value for uncontrolled component */
  defaultValue?: string[]
  /** Called whe value changes */
  onChange?: (value: string[]) => void

  /** Controlled search value */
  searchValue?: string
  /** Default search value */
  defaultSearchValue?: string
  /** Called when search changes */
  onSearchChange?: (value: string) => void

  /** pill item renderer function */
  pillComp?: React.FC<PillCompProps>

  /** Props to be applied to PillsInput Control in ComboBox */
  pillsInputProps?: PillsInputCustomProps
  /** Props to be applied to PillsInputField Control in ComboBox */
  pillsInputFieldProps?: Pick<
    PillsInputFieldProps,
    'onFocus' | 'onBlur' | 'onKeyDown' | 'disabled' | 'placeholder'
  >
  disabled?: boolean
  /** Determines whether the clear button should be displayed in the right section when the component has value, `false` by default */
  clearable?: boolean
  /** Props passed down to the clear button */
  clearButtonProps?: ClearButtonProps

  // creatable, getCreateLabel is defined in `OptionDropdownProps`
  onCreate?: (value: string) => void

  /** Whether the item value should be added to the values state or not.*/
  shouldSetValue?: (value: string) => boolean

  /**
   * Optional element to be displayed between pills in the input
   */
  pillsSeparator?: React.ReactNode
}

const DefaultPillComp: React.FC<PillCompProps> = ({ item, ...rest }) => (
  <Pill {...rest}>{item.label}</Pill>
)

export const MultiSelectCreatable = forwardRef<HTMLInputElement, MultiSelectCreatableProps>(
  (
    {
      data,
      comboboxProps,
      value,
      defaultValue,
      onChange,
      searchValue,
      defaultSearchValue,
      onSearchChange,
      disabled,
      pillsInputProps,
      pillsInputFieldProps,
      clearable,
      clearButtonProps,
      dropdownClassName,
      scrollbarType,
      pillsSeparator,
      ...props
    },
    ref
  ) => {
    const parsedData = React.useMemo(() => getParsedComboboxData(data), [data])
    // Retain map of {value: ComboboxItem}
    const optionsLockup = React.useMemo(() => getOptionsLockup(parsedData), [parsedData])
    const combobox = useCombobox({
      opened: props.dropdownOpened,
      defaultOpened: props.defaultDropdownOpened,
      onDropdownOpen: props.onDropdownOpen,
      onDropdownClose: () => {
        props.onDropdownClose?.()
        combobox.resetSelectedOption()
      },
    })

    const [_value, setValue] = useUncontrolled({
      value,
      defaultValue,
      finalValue: [],
      onChange,
    })

    const [_searchValue, setSearchValue] = useUncontrolled({
      value: searchValue,
      defaultValue: defaultSearchValue,
      finalValue: '',
      onChange: onSearchChange,
    })

    React.useEffect(() => {
      // we need to wait for options to render before we can select first one
      combobox.selectFirstOption()
    }, [_value, combobox])

    const handleInputKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      pillsInputFieldProps?.onKeyDown?.(event)

      if (event.key === 'Backspace' && _searchValue.length === 0 && _value.length > 0) {
        setValue(_value.slice(0, _value.length - 1))
      }
    }

    const Comp = props.pillComp ?? DefaultPillComp
    const values = _value.map((v, index) => (
      <>
        <Comp
          key={`${v}-${index}`}
          withRemoveButton
          onRemove={() => setValue(_value.filter((i) => v !== i))}
          item={objGetOrThrow(optionsLockup, v)}
        />
        {index !== _value.length - 1 && pillsSeparator}
      </>
    ))

    const clearButton = clearable && _value.length > 0 && !disabled && (
      <Combobox.ClearButton
        size={pillsInputProps?.size}
        {...clearButtonProps}
        onClear={() => {
          setValue([])
          setSearchValue('')
        }}
      />
    )

    const onOptionSubmit: ComboboxProps['onOptionSubmit'] = (val, _, event) => {
      nestedClickArea.avoid(() => {
        setSearchValue('')
        const shouldSetValue = props.shouldSetValue?.(val) !== false
        if (val === '$create') {
          props.onCreate?.(_searchValue.trim())
          if (shouldSetValue) setValue([..._value, _searchValue.trim()])
          return
        }
        props.onOptionSubmit?.(val)
        if (shouldSetValue) setValue([..._value, val])
      })(event)
    }

    const filteredData = React.useMemo(
      () => filterPickedValues({ data: parsedData, value: _value }),
      [parsedData, _value]
    )

    return (
      <Combobox {...comboboxProps} store={combobox} onOptionSubmit={onOptionSubmit}>
        <Combobox.DropdownTarget>
          <PillsInput
            {...pillsInputProps}
            disabled={disabled}
            rightSection={
              pillsInputProps?.rightSection ||
              clearButton || (
                <Combobox.Chevron size={pillsInputProps?.size} error={pillsInputProps?.error} />
              )
            }
            rightSectionPointerEvents={
              pillsInputProps?.rightSectionPointerEvents || clearButton ? 'all' : 'none'
            }
            multiline
            onClick={() => combobox.openDropdown()}
          >
            <Pill.Group disabled={disabled}>
              {values}
              <Combobox.EventsTarget>
                <PillsInput.Field
                  ref={ref}
                  placeholder={pillsInputFieldProps?.placeholder}
                  onFocus={(event) => {
                    pillsInputFieldProps?.onFocus?.(event)
                    combobox.closeDropdown()
                    setSearchValue('')
                  }}
                  onBlur={(event) => {
                    pillsInputFieldProps?.onBlur?.(event)
                    combobox.closeDropdown()
                    setSearchValue('')
                  }}
                  value={_searchValue}
                  onChange={(event) => {
                    setSearchValue(event.currentTarget.value)
                    combobox.openDropdown()
                    combobox.updateSelectedOptionIndex()
                  }}
                  onKeyDown={handleInputKeydown}
                  disabled={disabled}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>
        <OptionsDropdown
          data={filteredData}
          hidden={disabled}
          filter={props.filter}
          search={_searchValue.trim()}
          limit={props.limit}
          value={_value}
          maxDropdownHeight={props.maxDropdownHeight}
          optionComp={props.optionComp}
          creatable={props.creatable}
          getCreateLabel={props.getCreateLabel}
          createOptionGroup={props.createOptionGroup}
          className={dropdownClassName}
          scrollbarType={scrollbarType}
        />
      </Combobox>
    )
  }
)
