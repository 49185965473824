import { useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { invalidateQueries } from '~/client/lib/util'

// Only use BroadcastChannel in the browser
const invalidationChannel =
  typeof BroadcastChannel !== 'undefined' ? new BroadcastChannel('invalidations') : undefined

/**
 * Send a message to other tabs/windows to enqueue invalidating cache
 * Doesn't invalidate the current window
 */
export const broadcastInvalidation = (): void => {
  invalidationChannel?.postMessage('')
}

/**
 * Invalidate the full query cache on window focus if a mutation
 * was performed when the window was not focused
 * Solution for when users work on 2 separate tabs at the same time
 * Similar to refetchOnWindowFocus react-query config option (which
 * didn't work, because it doesn't refetch non-stale queries)
 */
export const useInvalidateOnFocus = (): void => {
  const queryClient = useQueryClient()
  // Needs to be a ref to avoid full-page re-renders
  const invalidationQueuedRef = React.useRef(false)

  React.useEffect(() => {
    const invalidateOnFocus = async () => {
      if (invalidationQueuedRef.current) {
        await invalidateQueries(queryClient)
      }
      invalidationQueuedRef.current = false
    }
    const enqueueInvalidation = () => {
      invalidationQueuedRef.current = true
    }

    window.addEventListener('focus', invalidateOnFocus)
    invalidationChannel?.addEventListener('message', enqueueInvalidation)
    return () => {
      window.removeEventListener('focus', invalidateOnFocus)
      invalidationChannel?.removeEventListener('message', enqueueInvalidation)
    }
  }, [queryClient])
}
