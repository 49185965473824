import { Text } from '@mantine/core'
import React from 'react'
import { NextLinkOpt } from '~/client/components/util/link'
import {
  useExitInvestorPreview,
  useInvestorPreviewStore,
} from '~/client/lib/hooks/investor-preview'
import { theme } from '~/client/lib/theme'
import { getOrThrowBranchConfig } from '~/common/deployment-per-branch-config'
import { zenvCommon } from '~/common/zenv-common'

const height = 24

interface RibbonPropsHidden {
  hidden: true
}

interface RibbonPropsVisible {
  // Defining `hidden in both types allows us to check for it on the union.
  // eslint-disable-next-line react/no-unused-prop-types
  hidden: false
  message: string | JSX.Element
  color: string
}

type RibbonProps = RibbonPropsHidden | RibbonPropsVisible

const RibbonRaw: React.FC<RibbonPropsVisible> = ({ message, color }) => (
  <Text
    style={{
      width: '100%',
      zIndex: 1000,
      position: 'fixed',
      backgroundColor: color,
      color: 'white',
      height,
      textAlign: 'center',
    }}
    px='sm'
    truncate='end'
  >
    {message}
  </Text>
)

/**
 * This is a hook that returns the Ribbon props if we need to display it.
 * It is done this way because the App's `Header` needs to know if we want to
 * display the ribbon or not.
 *
 * This approach allows us to use hooks inside this hook to determine the need
 * to show the ribbon, like when the user is in investor preview mode.
 *
 **/
const useAppRibbonProps = (): RibbonProps => {
  const isInvestorPreview = useInvestorPreviewStore((state) => state.isInvestorPreview)
  const { exitInvestorPreview } = useExitInvestorPreview()

  if (isInvestorPreview) {
    return {
      hidden: false,
      message: (
        <>
          You are in investor view.{' '}
          <NextLinkOpt onClick={exitInvestorPreview} c='white' fw='600'>
            Return to normal view
          </NextLinkOpt>
        </>
      ),
      color: theme.colors.primary[7],
    }
  }

  // Reusing NEXT_PUBLIC_SENTRY_ENVIRONMENT to be DRY.
  switch (zenvCommon.NEXT_PUBLIC_SENTRY_ENVIRONMENT) {
    case 'development':
      return { hidden: true }
    case 'test':
      return { hidden: true }
    case 'staging':
      // Enabling MFA will prevent users from signing in on preview branches due to the hostname
      // not being whitelisted.
      return {
        hidden: false,
        message:
          '[DO NOT ENABLE MFA] You are on a staging or preview branch. This is connected to the staging database and you are free to edit. This message will not appear in the production app.',
        color: 'orange',
      }
    case 'production': {
      // This ribbon is the only difference between canary and production, so no separate .env.canary
      // Checking for not production to err towards over-showing ribbon over inadvertently hiding it.
      const productionHosts = getOrThrowBranchConfig('production').aliases
      const isCanary =
        typeof window !== 'undefined' && !productionHosts.includes(window.location.host)
      if (isCanary) {
        return {
          hidden: false,
          message:
            'Careful: you are on canary. All changes are to the production database. This message will not appear in the production app.',
          color: 'red',
        }
      }
      return { hidden: true }
    }
  }
}

export const Ribbon: React.FC = () => {
  const props = useAppRibbonProps()

  if (props.hidden) return null

  return <RibbonRaw {...props} />
}

export const useRibbonHeight = (): number => {
  const props = useAppRibbonProps()
  if (props.hidden) return 0
  return height
}
