import { Select } from '@mantine/core'
import * as React from 'react'
import { inlineInputWidth } from '~/client/components/util/inline/util'
import type { AugmentedMetadataBoolean } from '~/common/schema'
import { AugmentedMetadataUsePool } from '~/common/schema'
import type { InlineCommonProps } from './base'
import { InlineBaseInput } from './base'

interface InlineBooleanInputProps extends InlineCommonProps<AugmentedMetadataBoolean> {}
export const InlineBooleanInput: React.FC<InlineBooleanInputProps> = ({
  update,
  initialValue,
  docs,
  alignment,
  noWrap,
}) => {
  return (
    <InlineBaseInput
      initialValues={initialValue}
      schema={AugmentedMetadataUsePool.pickValueSchema}
      update={update && (({ metadata }) => update(metadata))}
      display={AugmentedMetadataUsePool.display(initialValue?.value)}
      docs={docs}
      alignment={alignment}
      valueIsFilled={!!initialValue?.value}
      noWrap={noWrap}
      dropOverContent={({ formGetInputProps }) => (
        <Select
          selectFirstOptionOnChange
          {...formGetInputProps('value')}
          data-testid='boolean-select'
          clearButtonProps={{ 'aria-label': 'Clear Value' }}
          value={AugmentedMetadataUsePool.display(formGetInputProps('value').value)}
          onChange={(value) =>
            formGetInputProps('value').onChange(AugmentedMetadataUsePool.parseValue(value))
          }
          clearable
          data={AugmentedMetadataUsePool.selectOptions}
          styles={{
            input: { width: inlineInputWidth },
          }}
          // Combobox popover should set `withinPortal` to false, because
          // clicking an option will close the parent popover (inline edit
          // popover).
          // https://github.com/mantinedev/mantine/issues/2144#issuecomment-1217173512
          comboboxProps={{ withinPortal: false }}
        />
      )}
    />
  )
}
