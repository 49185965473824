import Router from 'next/router'
import React from 'react'

/** It's required to throw something in order for this to work. */
const throwMessage =
  'Route change was aborted due to unsaved changes. This error can be safely ignored'

export const confirmLeaveDefaultMessage = 'Changes you made may not be saved.'

interface UseConfirmLeaveProps {
  /** If there should be a confirmation when leaving page. */
  condition: () => boolean
  /**
   * Text shown by the browser's confirm popup on refresh, go back etc.
   * @default 'Changes you made may not be saved.'
   */
  browserMessage?: string
  /**
   * Called when `condition` is `true` and the route is about to be changed.
   *
   * The user shall choose if he is going to proceed with the route change (`true`)
   * or to keep on the current route (`false`).
   *
   * throwMessage is thrown right after this function is called, without awaiting, to
   * cancel the route change.
   *
   * For quick and simple usage, you can use:
   *
   * async () => confirm(confirmLeaveDefaultMessage)
   */
  promptClose: () => Promise<boolean>
}

/**
 * Asks for user confirmation when there are unsaved changes and a route
 * change or refresh request etc is made.
 *
 * Based on https://stackoverflow.com/a/65338027
 * Further discussion https://github.com/vercel/next.js/discussions/32231
 *
 * This file is kinda hacky but there is no official way to cancel route changes in Next, only
 * the throw solution found by devs. Those 2 links below elaborate further if you want to know
 * more. I don't think it's useful to read them, though, as it's just a situation of Next's
 * team not implementing a proper way to do it since 2017.
 */
export const useConfirmLeave = ({
  condition,
  browserMessage = confirmLeaveDefaultMessage,
  promptClose,
}: UseConfirmLeaveProps): void => {
  const beforeUnload = React.useCallback(
    (e: BeforeUnloadEvent) => {
      if (condition()) {
        e.returnValue = browserMessage
        return browserMessage // Gecko + Webkit, Safari, Chrome etc.
      }
    },
    [browserMessage, condition]
  )

  /** Prevents multiple prompts to be created. */
  const ignoreEvents = React.useRef(false)

  // Note that Next won't wait for the Promise to fulfill.
  // To cancel the route change, we need to throw during the sync part.
  const routeChangeStart = React.useCallback(
    (url: string) => {
      if (!condition()) return
      if (ignoreEvents.current) return

      ignoreEvents.current = true

      // Using .then() will keep it alive after the cancelRouteChange() throws.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      promptClose().then(async (doChangeRoute) => {
        if (doChangeRoute) {
          await Router.push(url)
        } else {
          // Turn events on again if user chose to keep screen.
          ignoreEvents.current = false
        }
      })
      // Cancel progress bar using the approach from
      // https://github.com/vercel/next.js/discussions/34071#discussioncomment-3821231
      Router.events.emit('routeChangeError', throwMessage, url, {
        shallow: false,
      })
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw throwMessage
    },
    [condition, promptClose]
  )

  // Browser event handler
  React.useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload)
    return () => window.removeEventListener('beforeunload', beforeUnload)
  }, [beforeUnload])

  // NextJS route event handler
  React.useEffect(() => {
    Router.events.on('routeChangeStart', routeChangeStart)
    return () => Router.events.off('routeChangeStart', routeChangeStart)
  }, [routeChangeStart])
}
