import { MiniObject } from '~/client/components/util/doc'
import type { ComputedMetadataRowProps } from '~/client/components/util/metadata/util'
import { MetadataValueDisplay } from '~/client/components/util/metadata/util'
import { ZAugmentedRelation } from '~/common/schema/relation'

interface MiniObjectMetadataRowProps extends ComputedMetadataRowProps {
  source: ZAugmentedRelation | undefined
}

export const MiniObjectMetadataRow: React.FC<MiniObjectMetadataRowProps> = ({
  source,
  ...props
}) => (
  <MetadataValueDisplay
    {...props}
    source={
      source ? <MiniObject name={ZAugmentedRelation.displayFn(source)} href={source.url} /> : null
    }
  />
)
