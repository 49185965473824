import { Button, Code, Group, Stack, Text, ThemeIcon, Title } from '@mantine/core'
import { closeAllModals, openModal } from '@mantine/modals'
import { IconAlertOctagon } from '@tabler/icons-react'
import Router from 'next/router'
import { CopyableTooltip } from '~/client/components/util'
import { hoverAppearDelay250msClasses } from '~/client/lib/css-util.css'

const firewallModalId = 'modal-firewall-error'

const FirewallMessage: React.FC<{ firewallErrorDetails: string }> = ({ firewallErrorDetails }) => {
  return (
    <Stack className={hoverAppearDelay250msClasses.hover} align='center' gap='xl'>
      <Text data-testid='network-error-modal-text'>
        A firewall appears to be intercepting communications with our server. Please check your
        internet connection and reload the page. Alternatively, ask your IT department to &quot;
        <i>
          put the domains <a href='https://aerialops.io/'>aerialops.io</a> and{' '}
          <a href='https://app.aerialops.io/'>app.aerialops.io</a> on the Firewall safe list.
        </i>
        &quot;{' '}
      </Text>
      <Code block w='100%'>
        <Group justify='space-between'>
          {firewallErrorDetails}
          <CopyableTooltip textStringOrRef={firewallErrorDetails} />
        </Group>
      </Code>
      <Button
        onClick={() => {
          // specifying an id for this modal causes an error, so we won't do closeModal(id)
          closeAllModals()
          Router.reload()
        }}
      >
        Reload Page
      </Button>
    </Stack>
  )
}

export const openFirewallErrorModal = (firewallErrorDetails: string): void =>
  openModal({
    // This ensures that only one modal will be opened
    // for all the firewall errors
    modalId: firewallModalId,
    title: (
      <Group>
        <Title order={2}>Firewall Issue Detected</Title>
        <ThemeIcon color='danger'>
          <IconAlertOctagon />
        </ThemeIcon>
      </Group>
    ),
    children: <FirewallMessage firewallErrorDetails={firewallErrorDetails} />,
    centered: true,
    size: 'xl',
    overlayProps: {
      backgroundOpacity: 0.5,
      color: 'gray',
      blur: 3,
    },
    closeButtonProps: {
      'aria-label': 'Close',
    },
    closeOnEscape: true,
    closeOnClickOutside: true,
  })
