import { Box, Button, Collapse, Group, Stack, Text, TextInput, Title } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { IconBuilding, IconChevronDown, IconChevronRight } from '@tabler/icons-react'
import Router from 'next/router'
import React, { useState } from 'react'
import { z } from 'zod'
import { BaseModal } from '~/client/components/modals'
import { NextLinkOpt } from '~/client/components/util'
import { hooks } from '~/client/lib/hooks'
import { mkCorpRoute } from '~/common/util'
import LogoStars from '~/public/logo-stars.svg'
import { FormModal } from './form-modal'

const ZNewCorpForm = z.object({
  name: z.string().min(1).max(100),
})

interface UseNewCorpRtn {
  openModal: () => void
  opened: boolean
  closeModal: () => void
}

/**
 * The modal state is managed from outside the modal
 * @returns
 */
export const useNewCorp = (): UseNewCorpRtn => {
  const [opened, setOpened] = useState(false)
  const openModal = React.useCallback(() => setOpened(true), [setOpened])
  const closeModal = React.useCallback(() => setOpened(false), [setOpened])

  return { openModal, opened, closeModal }
}

export interface NewCorpModalProps {
  opened: boolean
  closeModal: () => void
}

/**
 * Hook for state inside the modal
 * @param closeModal
 * @returns
 */
const useNewCorpHook = (closeModal: () => void) => {
  const authUser = hooks.useAuthStore((state) => state.user)

  const form = useForm({
    validate: zodResolver(ZNewCorpForm),
    initialValues: { name: '' },
  })

  const createCorp = hooks.trpc().corp.create.useMutation()

  const onSubmit = form.onSubmit(async (values) => {
    // this should never happen since the button is loading until we get the user
    if (!authUser?.email) return

    const corp = await createCorp.mutateAsync({ name: values.name })

    const corpCryptId = corp.cryptId

    closeModal()
    form.reset()
    await Router.push(mkCorpRoute(corpCryptId, 'dashboard'))
  })

  return {
    isSubmitDisabled: !authUser,
    isLoading: createCorp.isLoading,
    form,
    onSubmit,
  }
}

/**
 * Standard New Corp Modal
 * @param param0
 * @returns
 */
export const NewCorpModal: React.FC<NewCorpModalProps> = ({ opened, closeModal }) => {
  const { form, ...newCorpProps } = useNewCorpHook(closeModal)
  return (
    <FormModal
      title='New Corp'
      icon={<IconBuilding />}
      submitButtonLabel='Create'
      opened={opened}
      onClose={closeModal}
      {...newCorpProps}
    >
      <TextInput
        autoFocus
        placeholder='Your Corp Name'
        {...form.getInputProps('name')}
        label='Name'
        data-testid='new-corp-name-input'
      />
    </FormModal>
  )
}

/**
 * For the first corp a user creates, this welcomes them to Aerial
 * Functionally equivalent to NewCorpModal
 * @param param0
 * @returns
 */
export const WelcomeNewCorpModal: React.FC<NewCorpModalProps> = ({ opened, closeModal }) => {
  const { form, onSubmit, isSubmitDisabled, isLoading } = useNewCorpHook(closeModal)
  const [show, setShow] = React.useState(false)
  return (
    <BaseModal size='lg' opened={opened} onClose={closeModal}>
      <form onSubmit={onSubmit}>
        <Stack m='xl' gap='xl'>
          <Stack mx='auto' w={400}>
            <Title order={2} lh={1} mx='auto'>
              Welcome to Aerial!
            </Title>
            <Box w={100} h={100} mx='auto'>
              <LogoStars alt='Aerial Logo' />
            </Box>

            <Text ta='center' c='dimmed'>
              Aerial is a platform for managing your corporate information and legal documents.
            </Text>
          </Stack>

          <Stack gap='xs'>
            <Text>
              As a first step, let&apos;s create a Company to store your company&apos;s documents
              and information. What&apos;s your company&apos;s name?
            </Text>
            <TextInput
              placeholder='Your Company Name'
              {...form.getInputProps('name')}
              label='Name'
            />
            <Button disabled={isSubmitDisabled} type='submit' loading={isLoading}>
              Get Started
            </Button>

            <Stack gap={0}>
              <NextLinkOpt onClick={() => setShow(!show)}>
                <Group gap={2}>
                  <Text size='sm'>Your company is already on Aerial?</Text>
                  {show ? <IconChevronDown size={14} /> : <IconChevronRight size={14} />}
                </Group>
              </NextLinkOpt>
              <Collapse in={show}>
                <Text size='sm' component='span'>
                  Contact your company&apos;s Aerial admin for access.
                </Text>
              </Collapse>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </BaseModal>
  )
}
