import type { CryptId } from '@cryptid-module'
import superjson from 'superjson'
import { uniq } from 'underscore'
import { getRelationMissingDocsData, mkIndexedDocs } from '~/common/doc'
import { filterRecordsRedFlag } from '~/common/red-flags'
import type { WithIndexNumber, ZAugmentedDoc, ZDocType, ZParty } from '~/common/schema'
import type { ZAllAugmentedRecordsRedFlag } from '~/common/schema/red-flag'
import { ZAugmentedRelation, typeAugmentedRelationMap } from '~/common/schema/relation'
import { enhanceDoc } from './doc'
import type { AutofillMetadataDate, RedFlagInfo } from './util'
import {
  getActiveStatus,
  getSourceCryptIds,
  getStartDateAutofill,
  orderRelationAutofillDocs,
} from './util'

/**
 * Stub document to make it easy to identify missing docs on front end
 */
export const getRelationSourceCryptIds = (relation: ZAugmentedRelation): CryptId[] => {
  return getSourceCryptIds(relation, typeAugmentedRelationMap[relation.type].shape)
}

export interface RelationAutofill {
  parties: ZParty[]
  startDates: AutofillMetadataDate[]
}

/**
 * Enhancing AugmentedRelation with derived convenience properties on the client
 */
export type EnhancedRelation<R extends ZAugmentedRelation = ZAugmentedRelation> = R & {
  sourceCryptIds: CryptId[]
  docs: (WithIndexNumber<ZAugmentedDoc> & { key: string })[]
  isActive: boolean
  display: string
  autofill: RelationAutofill
  supportedTypes: ZDocType[]
}

const filterEmptyParty = (obj: ZAugmentedDoc | undefined): ZParty | undefined => {
  if (!obj || !('party' in obj)) return undefined
  const { party } = obj
  if (!party || (!party.name && !party.email)) {
    return undefined
  }
  return party
}

const getAutofillData = (docOpts: ZAugmentedDoc[]): RelationAutofill => {
  const parties = uniq(
    docOpts.map((doc) => filterEmptyParty(doc)).filter(Boolean),
    superjson.stringify
  )

  return { parties, startDates: getStartDateAutofill(docOpts) }
}

interface EnhanceRelationProps {
  now?: Date
  currentDocCryptIdForAutofill?: CryptId
}

export const enhanceRelation = <R extends ZAugmentedRelation = ZAugmentedRelation>(
  relation: R,
  { now = new Date(), currentDocCryptIdForAutofill }: EnhanceRelationProps = {}
): EnhancedRelation<R> => {
  const sourceCryptIds = getRelationSourceCryptIds(relation)

  const docs = relation.docOpts.filter(Boolean)
  const enhancedDocs = mkIndexedDocs(docs).map((doc) => {
    if ('indexNumber' in relation) return enhanceDoc(doc, ZAugmentedRelation.mkIndexList(relation))
    return { ...doc, key: doc.cryptId.idStr }
  })

  const isActive = getActiveStatus(relation, now)
  const display = ZAugmentedRelation.displayFn(relation)

  const autofill = getAutofillData(
    orderRelationAutofillDocs(relation.type, relation.docOpts, currentDocCryptIdForAutofill)
  )

  return Object.assign(relation, {
    sourceCryptIds,
    docs: enhancedDocs,
    isActive,
    display,
    autofill,
    supportedTypes: typeAugmentedRelationMap[relation.type].supportedTypes,
  })
}

export const getRelationRedFlagInfo = <R extends ZAugmentedRelation = ZAugmentedRelation>(
  relation: EnhancedRelation<R>,
  redFlags: ZAllAugmentedRecordsRedFlag[]
): RedFlagInfo => {
  const { missingDocs, enhancedMissingDocs, typesWithState } = getRelationMissingDocsData(
    relation,
    filterRecordsRedFlag(redFlags)
  )
  const missingWithDocs = [...enhancedMissingDocs, ...relation.docs]
  const isComplete = missingDocs.length === 0

  return {
    redFlags,
    isComplete,
    missingDocs,
    typesWithState,
    missingWithDocs,
  }
}
