import { usePagination as useMantinePagination } from '@mantine/hooks'
import type { PaginationParams as MantinePaginationParams } from '@mantine/hooks/lib/use-pagination/use-pagination'
import React from 'react'
import type { PageSize } from '~/client/components/util/pagination'
import type { ZNumberExceed } from '~/common/number-exceed'
import type { ZPagination } from '~/common/pagination'

export interface UsePaginationParams
  extends Omit<MantinePaginationParams, 'total' | 'page' | 'onChange'> {
  defaultPageSize?: PageSize
}

export interface UsePagination extends ReturnType<typeof useMantinePagination> {
  totalItems: ZNumberExceed
  /**
   * Can be safely called without an useEffect.
   *
   * @default 1
   * */
  setTotalItems: (v?: ZNumberExceed) => void
  pageSize: PageSize
  setPageSize: (v: PageSize) => void
  activePage: number
  setActivePage: (v: number) => void
  totalPages: number
  /**
   * Use this function to pass pagination param to useQuery.
   * This avoids passing unnecessary props, which fixes an issue on
   * storybook and prevents unnecessary UX updates
   */
  getQueryParams: () => Required<Pick<ZPagination, 'limit' | 'skip'>>
}

/**
 * An extension of mantine's `usePagination` hook with extra data such as
 * `pageSize` and `totalPages`.
 */
export const usePagination = (params?: UsePaginationParams): UsePagination => {
  // use 1 as default, to show pagination controls when loading data
  const [totalItems, _setTotalItems] = React.useState({ count: 1, exceeds: false })
  const [pageSize, setPageSize] = React.useState<PageSize>(params?.defaultPageSize ?? 10)
  const [activePage, setActivePage] = React.useState(0)
  const pagination = useMantinePagination({ total: totalItems.count, ...params })
  const totalPages = Math.ceil(totalItems.count / pageSize)

  const getQueryParams = React.useCallback(
    () => ({
      skip: activePage * pageSize,
      limit: pageSize,
    }),
    [activePage, pageSize]
  )

  const setTotalItems = React.useCallback(
    (value: ZNumberExceed = { count: 1, exceeds: false }) => {
      const { count, exceeds } = value
      // For some weird reason, setState in Storybook wasn't being ignored if new === current, causing infinite loop.
      if (count !== totalItems.count || exceeds !== totalItems.exceeds)
        _setTotalItems({ count, exceeds })
    },
    [totalItems]
  )

  return {
    ...pagination,
    totalItems,
    setTotalItems,
    pageSize,
    setPageSize,
    activePage,
    setActivePage,
    totalPages,
    getQueryParams,
  }
}
