import { useCorpCryptId } from '~/client/lib/hooks'
import { mkAdobeSignOAuthUrl } from '~/common/integration/adobe-sign'
import { mkDocusignIndividualConsentUrl } from '~/common/integration/docusign'
import type { ZIntegrationOAuthRedirectData } from '~/common/integration/oauth'
import type { ZIntegrationType } from '~/common/schema/integration'

export const integrationModalQueryParameter: string = 'integrationModal'

export const mkIntegrationAddUrlAndSetStateCookie = (
  type: ZIntegrationType,
  data: ZIntegrationOAuthRedirectData
): string => {
  const stateNonce = crypto.randomUUID()

  const cookieConfig = [
    `${stateNonce}=${JSON.stringify(data)}`,
    'Secure', // Require HTTPs
    'SameSite=Lax', // Lax to send the cookie even if the user is coming from a different site
    'Max-Age=3600', // 1 hour in seconds
    'path=/', // Make sure the cookie set on the onboarding modal applies to the entire site
  ]
  document.cookie = cookieConfig.join('; ')

  switch (type) {
    case 'docusign':
      return mkDocusignIndividualConsentUrl(stateNonce)
    case 'adobeSign':
      return mkAdobeSignOAuthUrl({
        stateNonce,
        askForAccountScope: !!data.adobeSignHasAccountScope,
      })
  }
}

export const useMkIntegrationAddUrlAndSetStateCookieWithCorp = (
  type: ZIntegrationType
): {
  mkIntegrationAddUrlAndSetStateCookie: (
    data: Omit<
      ZIntegrationOAuthRedirectData,
      'clientSuccessRedirectRoute' | 'clientErrorRedirectRoute'
    >
  ) => string
} => {
  const { mkCurrentCorpRoute } = useCorpCryptId()

  const clientSuccessRedirectRoute = mkCurrentCorpRoute(
    'integrations',
    `?${integrationModalQueryParameter}=${type}`
  )
  const clientErrorRedirectRoute = mkCurrentCorpRoute('integrations')

  return {
    mkIntegrationAddUrlAndSetStateCookie: (data) =>
      mkIntegrationAddUrlAndSetStateCookie(type, {
        ...data,
        clientErrorRedirectRoute,
        clientSuccessRedirectRoute,
      }),
  }
}
