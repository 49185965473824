import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { analytics } from '~/client/components/monitoring'
import { useAuthStore } from '~/client/lib/hooks/auth'

export const useAnalyticsTrackUserAndPage = (): void => {
  const router = useRouter()
  const user = useAuthStore((state) => state.user)

  useEffect(() => {
    // Only identify the user if it is logged in
    // Otherwise, this will reset the user id and we will lose the user's
    // previous data on the marketing website.
    // We handle logouts in the `useHandleUserChange` hook
    if (user) {
      analytics.identify(user)
    }
  }, [user])

  // Track the first page view manually, as the router change event will not be
  // fired on the first page load.
  useEffect(() => {
    const fullPath = window.location.pathname + window.location.search + window.location.hash
    analytics.page(undefined, fullPath)
  }, [])

  // If the user opens a page with a bookmark or by typing the url, window.focus won't be triggered
  // Manually trigger this event because we need this data to consistently detect when sessions start
  // on Mixpanel
  useEffect(() => {
    if (document.hasFocus()) analytics.trackEventSuccess('WINDOW_FOCUS')
  }, [])

  useEffect(() => {
    const trackPageChange = (to: string) => analytics.page(router.asPath, to)
    const trackCopy = () => analytics.trackEventSuccess('COPY')
    const trackWindowFocus = () => analytics.trackEventSuccess('WINDOW_FOCUS')
    const trackWindowUnfocus = () => analytics.trackEventSuccess('WINDOW_UNFOCUS')

    router.events.on('hashChangeStart', trackPageChange)
    router.events.on('routeChangeStart', trackPageChange)
    document.addEventListener('copy', trackCopy)
    window.addEventListener('focus', trackWindowFocus)
    window.addEventListener('blur', trackWindowUnfocus)
    return () => {
      router.events.off('routeChangeStart', trackPageChange)
      router.events.off('hashChangeStart', trackPageChange)
      document.removeEventListener('copy', trackCopy)
      window.removeEventListener('focus', trackWindowFocus)
      window.removeEventListener('blur', trackWindowUnfocus)
    }
  }, [router.asPath, router.events])
}
