import { Box, Group, Loader, Switch, ThemeIcon, Tooltip } from '@mantine/core'
import { IconLink, IconUnlink } from '@tabler/icons-react'
import React from 'react'
import { theme } from '~/client/lib/theme'

export interface ToggleLinkButtonProps {
  isLinked: boolean
  onClick: () => void
  isLoading?: boolean
  linkType: 'relation' | 'document' | 'corporation'
  unlinkWarningInTooltip?: boolean
}

export const LinkSwitchWithTooltip: React.FC<ToggleLinkButtonProps> = ({
  isLinked,
  onClick,
  isLoading,
  linkType,
  unlinkWarningInTooltip,
}) => {
  const switchTooltipLabel = (() => {
    if (unlinkWarningInTooltip && !isLinked)
      return `Link this ${linkType} and unlink the current one`
    return isLinked ? `Unlink ${linkType}` : `Link ${linkType}`
  })()

  return (
    <Group style={{ flexWrap: 'nowrap', gap: theme.spacing.xs }}>
      {isLoading ? (
        <Loader size='sm' m={2} />
      ) : (
        <Tooltip label={isLinked ? `Linked ${linkType}` : `Unlinked ${linkType}`}>
          <ThemeIcon color={isLinked ? 'primary' : 'gray'}>
            {isLinked ? <IconLink /> : <IconUnlink />}
          </ThemeIcon>
        </Tooltip>
      )}
      <Tooltip label={switchTooltipLabel}>
        {/* Tooltip doesn't show properly without the extra Box */}
        {/* Also, cy.click() doesn't work on Switch */}
        <Box data-testid='link-doc-switch'>
          <Switch onClick={onClick} disabled={isLoading} checked={isLinked} />
        </Box>
      </Tooltip>
    </Group>
  )
}
