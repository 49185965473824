/* eslint-disable no-console */
import * as Sentry from '@sentry/nextjs'
import { zenvCommon } from '~/common/zenv-common'

const isDevOrTest = ['development', 'test'].includes(zenvCommon.NODE_ENV)

const logError = (message: string, error: unknown): void => {
  console.error(`%c${message}`, 'color: red; font-weight: bold;')
  console.dir(error, { colors: true, depth: null })
}

interface GenericErrorHandlerOptions {
  error: unknown
  captureContext?: { [key: string]: unknown }
  message?: string
}

interface IErrorWithSentryId extends Error {
  sentryEventId: string
}

export class GenericErrorHandler {
  static captureException = ({
    error,
    captureContext,
    message,
  }: GenericErrorHandlerOptions): string => {
    if (isDevOrTest) {
      logError(message ?? 'Generic error:', error)
      return 'devOrTestId123'
    }
    return Sentry.captureException(error, captureContext)
  }

  /**
   * Raises an error and reports it to sentry, while storing it's ID to be able
   * show it to users.
   */
  static createAndCapture = (
    message: string,
    options?: ErrorOptions,
    captureContext?: GenericErrorHandlerOptions['captureContext']
  ): IErrorWithSentryId => {
    const error = new Error(message, options)
    const sentryEventId = this.captureException({ error, message, captureContext })
    return Object.assign(error, { sentryEventId })
  }
}
