import { Text } from '@mantine/core'
import React from 'react'
import { theme } from '~/client/lib/theme'
import type {
  AugmentedMetadataNumber,
  AugmentedMetadataPercentage,
  AugmentedMetadataPrice2,
  AugmentedMetadataPrice4,
} from '~/common/schema'

interface RelationPriceDisplayProps {
  schema:
    | typeof AugmentedMetadataNumber
    | typeof AugmentedMetadataPrice2
    | typeof AugmentedMetadataPrice4
    | typeof AugmentedMetadataPercentage
  value: number | null | undefined
}

export const RelationPriceDisplay: React.FC<RelationPriceDisplayProps> = ({ schema, value }) => {
  // Not using if (!value) because 0 is considered falsy
  if (value === null || value === undefined)
    return (
      <Text c={theme.colors.gray[5]} ta='center'>
        &mdash;
      </Text>
    )

  return (
    <Text ta='right' style={{ textWrap: 'nowrap' }}>
      {schema.display(value)}
    </Text>
  )
}
