import { z } from 'zod'
import { AugmentedMetadataDate } from '~/common/schema/metadata'
import { ZParty } from '~/common/schema/party'
import { withDisplaySchema } from '~/common/schema/with-display-schema'
import type { NonEmptyArray } from '~/common/util-types'
import { util } from './util'

export const employeeShape = {
  type: z.literal('EMPLOYEE'),
  party: withDisplaySchema(ZParty, 'party', 'Employee'),
}

export const { ZUpdate: ZUpdateEmployee, ZAugmented: ZAugmentedEmployee } = util.createSchemas(
  {
    updateShape: employeeShape,
    augmentedShape: employeeShape,
    defaultValue: { party: { name: '' } },
  },
  {
    requiredTypes: [
      'OFFER_LETTER', // Primary Document
      'PROPRIETARY_INFORMATION_AND_INVENTION_ASSIGNMENT',
    ],
    optionalTypes: ['EMPLOYMENT_AGREEMENT', 'MISCELLANEOUS'],
    display: 'Employee',
    displayFn: (obj) => `Employee ${obj.party.name}`,
    tooltipContentFn: (obj, shape) => [
      [shape.party.displaySchema.display, ZParty.display(obj.party)],
      [shape.startDate.displaySchema.display, AugmentedMetadataDate.display(obj.startDate?.value)],
      [shape.endDate.displaySchema.display, AugmentedMetadataDate.display(obj.endDate?.value)],
    ],
    identifyingField: 'party.name',
  }
)

export interface ZUpdateEmployee extends z.infer<typeof ZUpdateEmployee> {}
export interface ZAugmentedEmployee extends z.infer<typeof ZAugmentedEmployee> {}

export const officerShape = {
  type: z.literal('OFFICER'),
  party: withDisplaySchema(ZParty, 'party', 'Officer'),
}

export const { ZUpdate: ZUpdateOfficer, ZAugmented: ZAugmentedOfficer } = util.createSchemas(
  {
    updateShape: officerShape,
    augmentedShape: officerShape,
    defaultValue: { party: { name: '' } },
  },
  {
    requiredTypes: [
      'BOARD_CONSENT_AND_MINUTES', // Primary Document
      'INDEMNIFICATION_AGREEMENT',
    ],
    optionalTypes: ['MISCELLANEOUS'],
    display: 'Corporate Officer',
    displayFn: (obj) => `Officer ${obj.party.name}`,
    identifyingField: 'party.name',
    tooltipContentFn: (obj, shape) => [
      [shape.party.displaySchema.display, ZParty.display(obj.party)],
      [shape.startDate.displaySchema.display, AugmentedMetadataDate.display(obj.startDate?.value)],
      [shape.endDate.displaySchema.display, AugmentedMetadataDate.display(obj.endDate?.value)],
    ],
  }
)

export interface ZUpdateOfficer extends z.infer<typeof ZUpdateOfficer> {}
export interface ZAugmentedOfficer extends z.infer<typeof ZAugmentedOfficer> {}

export const directorShape = {
  type: z.literal('DIRECTOR'),
  party: withDisplaySchema(ZParty, 'party', 'Director'),
}

export const { ZUpdate: ZUpdateDirector, ZAugmented: ZAugmentedDirector } = util.createSchemas(
  {
    updateShape: directorShape,
    augmentedShape: directorShape,
    defaultValue: { party: { name: '' } },
  },
  {
    requiredTypes: [
      'STOCKHOLDER_CONSENT', // Primary Document
      'INDEMNIFICATION_AGREEMENT',
    ],
    optionalTypes: ['BOARD_CONSENT_AND_MINUTES', 'MISCELLANEOUS'],
    display: 'Board Director',
    displayFn: (obj) => `Director ${obj.party.name}`,
    identifyingField: 'party.name',
    tooltipContentFn: (obj, shape) => [
      [shape.party.displaySchema.display, ZParty.display(obj.party)],
      [shape.startDate.displaySchema.display, AugmentedMetadataDate.display(obj.startDate?.value)],
      [shape.endDate.displaySchema.display, AugmentedMetadataDate.display(obj.endDate?.value)],
    ],
  }
)

export interface ZUpdateDirector extends z.infer<typeof ZUpdateDirector> {}
export interface ZAugmentedDirector extends z.infer<typeof ZAugmentedDirector> {}

export const advisorShape = {
  type: z.literal('ADVISOR'),
  party: withDisplaySchema(ZParty, 'party', 'Advisor'),
}

export const { ZUpdate: ZUpdateAdvisor, ZAugmented: ZAugmentedAdvisor } = util.createSchemas(
  {
    updateShape: advisorShape,
    augmentedShape: advisorShape,
    defaultValue: { party: { name: '' } },
  },
  {
    requiredTypes: [
      'ADVISOR_AGREEMENT', // Primary Document
      'BOARD_CONSENT_AND_MINUTES',
    ],
    optionalTypes: ['MISCELLANEOUS'],
    display: 'Advisor',
    displayFn: (obj) => `Advisor ${obj.party.name}`,
    identifyingField: 'party.name',
    tooltipContentFn: (obj, shape) => [
      [shape.party.displaySchema.display, ZParty.display(obj.party)],
      [shape.startDate.displaySchema.display, AugmentedMetadataDate.display(obj.startDate?.value)],
      [shape.endDate.displaySchema.display, AugmentedMetadataDate.display(obj.endDate?.value)],
    ],
  }
)

export interface ZUpdateAdvisor extends z.infer<typeof ZUpdateAdvisor> {}
export interface ZAugmentedAdvisor extends z.infer<typeof ZAugmentedAdvisor> {}

const personnelSchemas = [
  ZAugmentedEmployee,
  ZAugmentedOfficer,
  ZAugmentedDirector,
  ZAugmentedAdvisor,
] as const

export type ZodPersonnel = (typeof personnelSchemas)[number]

export type ZodUpdatePersonnel = ZUpdateEmployee | ZUpdateOfficer | ZUpdateDirector | ZUpdateAdvisor

const _ZPersonnelType = z.enum(
  personnelSchemas.map((option) => option.type) as NonEmptyArray<ZodPersonnel['type']>
)

export type ZPersonnelType = z.infer<typeof _ZPersonnelType>

export const ZPersonnelType = Object.assign(_ZPersonnelType, {
  isType: (s: string): s is ZPersonnelType => {
    return _ZPersonnelType.options.includes(s)
  },
})
