import { CryptId } from '@cryptid-module'
import superjson from 'superjson'

const registerSuperJson = (): typeof superjson => {
  superjson.registerCustom<CryptId[], string[]>(
    {
      isApplicable: (v): v is CryptId[] =>
        Array.isArray(v) && v.every((item) => item instanceof CryptId),
      serialize: (v) => v.map((item) => item.idStr),
      deserialize: (v) => v.map((item) => new CryptId(item)),
    },
    'c.a'
  )
  superjson.registerCustom<CryptId, string>(
    {
      isApplicable: (v): v is CryptId => v instanceof CryptId,
      serialize: (v) => v.idStr,
      deserialize: (v) => new CryptId(v),
    },
    'c'
  )
  return superjson
}

export const superjsonWithRegistration = registerSuperJson()
