import { FirebaseError } from 'firebase/app'
import type { MultiFactorError, MultiFactorResolver } from 'firebase/auth'
import React from 'react'
import { auth } from '~/client/lib/firebase'
import { hooks } from '~/client/lib/hooks/dependency-injection/interface'
import { atOrThrow } from '~/common/util'
import { type UseAuthInteractionsRtn, useAuthInteractions } from './interactions'
import { type UsePhoneAuthRtn, usePhoneAuth } from './phone-auth'
import type { UseTotpAuth } from './totp-auth'
import { useTotpAuth } from './totp-auth'

interface UseAuthRtn extends UseAuthInteractionsRtn {
  /**Phone authentication state object */
  phoneAuth: Pick<
    UsePhoneAuthRtn,
    | 'sendEnrollPhoneCodeMutation'
    | 'enrollPhoneMutation'
    | 'sendVerificationPhoneCodeMutation'
    | 'verifyPhoneMutation'
  >
  totpAuth: UseTotpAuth
}

interface OnMFARequiredRtn {
  onMFARequired: (err: unknown) => Promise<void>
}

export const useOnMFARequired = ({
  sendVerificationPhoneCode,
}: {
  sendVerificationPhoneCode: (resolver: MultiFactorResolver) => Promise<void>
}): OnMFARequiredRtn => {
  const setLoginResolver = hooks.useAuthStore((state) => state.setLoginResolver)

  const onMFARequired = React.useCallback(
    async (err: unknown) => {
      if (err instanceof FirebaseError) {
        if (err.code === 'auth/multi-factor-auth-required') {
          const _loginResolver = await auth.getMultiFactorResolver(err as MultiFactorError)

          // The hints array will only have one item because we don't allow users
          // to set multiple MFA options
          const requiredFactor = atOrThrow(_loginResolver.hints, 0)
          if (requiredFactor.factorId === 'phone') await sendVerificationPhoneCode(_loginResolver)

          // store the login resolver for retries
          setLoginResolver(_loginResolver)
          return
        }
      }
      throw err
    },
    [sendVerificationPhoneCode, setLoginResolver]
  )

  return { onMFARequired }
}

export const useAuthMethods = (): UseAuthRtn => {
  // We use prop drilling because usePhoneAuth does not have a unstated-next provider
  const { sendVerificationPhoneCode, ...phoneAuth } = usePhoneAuth()
  const totpAuth = useTotpAuth()
  const { onMFARequired } = useOnMFARequired({ sendVerificationPhoneCode })

  const authMethods = useAuthInteractions(onMFARequired)

  return {
    ...authMethods,
    phoneAuth,
    totpAuth,
  }
}

export type UseAuth = typeof useAuthMethods
