import { Badge, HoverCard, Text } from '@mantine/core'
import { useClipboard } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import React, { type CSSProperties } from 'react'
import { getShareUrl } from '~/client/lib/util'
import type { ZAugmentedDoc } from '~/common/schema'

export const PrimaryBadge: React.FC = ({ children }) => {
  return (
    <Badge color='primary' data-testid='active-badge'>
      {children}
    </Badge>
  )
}

export const HoverOnClippedText: React.FC<{
  textStyle: CSSProperties
  text: string
}> = ({ textStyle, text }) => {
  const [clippedText, setClippedText] = React.useState(false)
  const textRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (textRef.current && textRef.current.scrollWidth > textRef.current.offsetWidth)
      setClippedText(true)
  }, [textRef])

  return (
    <HoverCard shadow='md' width='400px'>
      <HoverCard.Target>
        <Text style={textStyle} ref={textRef}>
          {text}
        </Text>
      </HoverCard.Target>
      {clippedText && (
        <HoverCard.Dropdown style={{ position: 'fixed' }}>
          <Text size='sm'>{text}</Text>
        </HoverCard.Dropdown>
      )}
    </HoverCard>
  )
}

export const useDocShare = (): ((doc: ZAugmentedDoc) => Promise<void>) => {
  const { copy } = useClipboard()

  const share = async (doc: ZAugmentedDoc) => {
    if (typeof window === 'undefined') {
      throw new Error('Share API can only be used in an event handler')
    }
    const shareData: ShareData = { title: doc.title, url: doc.url }
    if (typeof window.navigator.canShare !== 'undefined' && window.navigator.canShare(shareData)) {
      await window.navigator.share(shareData)
    } else {
      copy(getShareUrl(doc))
      showNotification({ message: 'Link copied' })
    }
  }

  return share
}
