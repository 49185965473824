import { Menu, Text, ThemeIcon, UnstyledButton } from '@mantine/core'
import { IconArrowBigRightLines, IconLogout, IconUser, IconUserCheck } from '@tabler/icons-react'
import NextLink from 'next/link'
import React from 'react'
import { HeaderButton } from '~/client/components/layout/header-button'
import { useMultiDocModalStore } from '~/client/components/multi-doc-drop/state'
import { zIndex } from '~/client/components/z-index'
import { hooks, useCurrentCorpAuth } from '~/client/lib/hooks'
import { theme } from '~/client/lib/theme'

interface MenuItemProps {
  icon: React.ReactNode
  href?: string
  text: string
  disabled?: boolean
  subText?: string
  onClick?: () => void | Promise<void>
}

const MenuItem: React.FC<MenuItemProps> = ({ icon, href, text, subText, onClick, ...props }) => {
  const disabled = props.disabled || (!href && !onClick)

  return (
    <Menu.Item
      {...props}
      leftSection={
        <ThemeIcon color={disabled ? theme.colors.inactive[7] : 'primary'} size='md'>
          {icon}
        </ThemeIcon>
      }
      onClick={onClick && (() => onClick())}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      component={href ? NextLink : (UnstyledButton as any)}
      target={href?.startsWith('mailto:') ? '_blank' : undefined}
      href={href}
      disabled={disabled}
    >
      <Text c={disabled ? 'inactive' : 'primary'}>{text}</Text>
      {subText && (
        <Text c={disabled ? 'inactive' : 'primary'} size='md'>
          {subText}
        </Text>
      )}
    </Menu.Item>
  )
}

export const AccountMenu: React.FC = ({ children }) => {
  const { logout } = hooks.useAuthMethods()
  const userData = hooks.useCurrentUser()
  const user = hooks.useAuthStore((state) => state.user)
  const { data: auth } = useCurrentCorpAuth()
  const updateModalState = useMultiDocModalStore((state) => state.updateState)
  const corp = hooks.useCorpCryptIdSafe()

  const accountItems: MenuItemProps[] = React.useMemo(() => {
    const commonTopItems: MenuItemProps[] = [
      {
        icon: <IconUser />,
        href: '/account',
        text: 'Account',
        subText: user?.email ?? undefined,
      },
    ]
    const corpItems: MenuItemProps[] = [
      {
        icon: <IconArrowBigRightLines />,
        onClick: () => updateModalState('onboarding'),
        text: 'Onboarding',
        disabled: auth?.level === 'investor',
      },
    ]
    const superAdminItems: MenuItemProps[] = [
      {
        icon: <IconUserCheck />,
        href: '/super-admin',
        text: 'Super Admin Page',
      },
    ]

    const commonBottomItems: MenuItemProps[] = [
      {
        icon: <IconLogout />,
        text: 'Logout',
        onClick: () => logout(),
      },
    ]
    return [
      ...commonTopItems,
      ...(corp ? corpItems : []),
      ...(userData.data?.superAdmin ? superAdminItems : []),
      ...commonBottomItems,
    ]
  }, [user?.email, auth, corp, userData.data?.superAdmin, updateModalState, logout])

  return (
    <Menu
      position='bottom-end'
      shadow='lg'
      trigger='click'
      data-testid='user-menu'
      withinPortal
      zIndex={zIndex.headerDropdown}
      offset={0}
    >
      <Menu.Target>
        <HeaderButton>{children}</HeaderButton>
      </Menu.Target>
      <Menu.Dropdown p={0}>
        {accountItems.map((item) => (
          <MenuItem {...item} key={item.text} />
        ))}
      </Menu.Dropdown>
    </Menu>
  )
}
