import type { ErrorEvent, EventHint } from '@sentry/nextjs'
import { isEmpty, isError, omit } from 'underscore'

export const SENTRY_SENTINEL = 'SENTRY_LOGGER '

export enum SentrySender {
  CLIENT = 'client',
  SERVER = 'server',
}

export const SENTRY_PROJECT_TAG = 'aerial-app'

// Keys that are common to Error objects
const nativeErrorKeys = [
  'name',
  'message',
  'stack',
  'line',
  'column',
  'fileName',
  'lineNumber',
  'columnNumber',
  'toJSON',
  // `cause` is not always enumerable using Object.entries()/Object.keys()
  // method, also we need to check recursively the `cause` property
  'cause',
  // We exclude `sentryEventId` because it's assigned
  // in `GenericErrorHandler.createAndCapture` method.
  'sentryEventId',
]

/**
 * This method gets the extra data by recursively visiting error.`cause`
 * @param error - Current error object
 * @param depth - Maximum depth of `cause`
 * @param prefix - Current prefix of the key
 * @param currentDepth - Current depth of `cause`
 * @returns
 */
const _getErrorExtraData = (
  error: Error,
  depth: number,
  prefix: string,
  currentDepth: number
): Record<string, unknown> | null => {
  const extraErrorInfo: Record<string, unknown> = {}
  Object.entries(omit(error, ...nativeErrorKeys)).forEach(([key, value]) => {
    // For the errors set with key other than `cause`, we just need brief
    // description of the error
    extraErrorInfo[`${prefix}${key}`] = isError(value) ? value.toString() : value
  })

  // Recursively add extra data for the `cause`
  // Errors thrown by external apis are wrapped with custom errors
  // created by our app, assigned to `cause` property
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/cause
  if (error.cause && isError(error.cause) && currentDepth < depth) {
    const causeExtra = _getErrorExtraData(error.cause, depth, `${prefix}cause.`, currentDepth + 1)
    if (causeExtra) {
      Object.assign(extraErrorInfo, causeExtra)
    }
  }
  return isEmpty(extraErrorInfo) ? null : extraErrorInfo
}

export const enrichEventWithErrorExtra = (event: ErrorEvent, hint: EventHint): ErrorEvent => {
  const errorExtra =
    hint.originalException && isError(hint.originalException)
      ? _getErrorExtraData(hint.originalException, 3, '', 0)
      : null
  return {
    ...event,
    extra: errorExtra ? { ...event.extra, ...errorExtra } : event.extra,
  }
}
