import { Box, Container, Group, Skeleton, Table, ThemeIcon, Tooltip } from '@mantine/core'
import { IconAlertOctagon } from '@tabler/icons-react'
import React from 'react'
import { analytics } from '~/client/components/monitoring'
import { CopyableTooltip } from '~/client/components/util/tooltip'
import { metadataRowHoverClasses } from '~/client/lib/css-util.css'
import { theme } from '~/client/lib/theme'
import { Random } from '~/common/random'

const random = new Random()

export interface BaseMetadataRowProps {
  display: React.ReactNode
  isLoading?: boolean
  alignment?: 'end' | 'start'
}

interface MetadataRowSkeletonProps extends Omit<BaseMetadataRowProps, 'isLoading'> {
  noSource?: boolean
}

export const MetadataRowSkeleton: React.FC<MetadataRowSkeletonProps> = ({
  noSource = false,
  alignment = 'end',
  display,
}) => {
  return (
    <Table.Tr>
      <Table.Td style={{ maxWidth: theme.other.widths.xs }}>{display}</Table.Td>
      <Table.Td colSpan={noSource ? 2 : undefined}>
        <Box style={{ display: 'flex', justifyContent: alignment }}>
          <Skeleton height={28} width={`${random.randomInt(60) + (noSource ? 40 : 25)}%`} />
        </Box>
      </Table.Td>
      {!noSource && (
        <Table.Td>
          <Skeleton height={35} width={`${random.randomInt(25) + 55}%`} />
        </Table.Td>
      )}
    </Table.Tr>
  )
}

export interface ComputedMetadataRowProps extends BaseMetadataRowProps {
  value: string | undefined | null
  warning: string | undefined
}

interface MetadataValueDisplayProps extends ComputedMetadataRowProps {
  source: JSX.Element | null
}

export const MetadataValueDisplay: React.FC<MetadataValueDisplayProps> = ({
  display,
  value,
  warning,
  source,
  isLoading,
  alignment = 'end',
}) => {
  if (isLoading) {
    return <MetadataRowSkeleton display={display} />
  }

  return (
    <Table.Tr className={metadataRowHoverClasses.hover}>
      <Table.Td style={{ maxWidth: theme.other.widths.xs }}>{display}</Table.Td>
      <Table.Td data-testid='metadata-field-value'>
        <Box style={{ display: 'flex', justifyContent: alignment }}>
          <Group
            align='center'
            style={{
              gap: theme.spacing.xs,
              whiteSpace: 'pre-wrap' /* Allow newlines for string children */,
              order: alignment === 'start' ? 0 : 1,
              flexWrap: 'nowrap',
            }}
          >
            <Group style={{ gap: theme.spacing.xs, flexWrap: 'nowrap' }}>
              {value && (
                <Container p={0} m={0} className={metadataRowHoverClasses.appear}>
                  <CopyableTooltip
                    textStringOrRef={value}
                    onCopy={() => analytics.trackEventSuccess('COPY_METADATA')}
                  />
                </Container>
              )}
              {warning && (
                <Tooltip multiline label={warning}>
                  <ThemeIcon color='urgent'>
                    <IconAlertOctagon />
                  </ThemeIcon>
                </Tooltip>
              )}
            </Group>
            {value}
          </Group>
        </Box>
      </Table.Td>
      <Table.Td>{source}</Table.Td>
    </Table.Tr>
  )
}
