import promptConfirmAsync from './prompt-confirm-async'

interface DeletePromptConfirmOpts {
  itemName: string
  dataTestId?: string
}

export const deletePromptConfirm = async ({
  dataTestId,
  itemName,
}: DeletePromptConfirmOpts): Promise<boolean> =>
  promptConfirmAsync({
    title: `Delete ${itemName}?`,
    subtitle: `Are you sure you want to delete this ${itemName}? This action is irreversible.`,
    confirmText: 'Delete',
    confirmProps: {
      'data-testid': dataTestId,
    },
    buttonsColorVariant: 'discourage',
  })
