import { Group, Radio, Skeleton, Table } from '@mantine/core'
import React from 'react'
import { isEqual } from 'underscore'
import { statusStyle } from '~/client/components/relation/base-table'
import { theme } from '~/client/lib/theme'
import { Random } from '~/common/random'

const random = new Random()

type SkeletonRowType =
  | 'AVATAR'
  | 'STATUS'
  | 'NAME'
  | 'DATE'
  | 'DOCUMENTS'
  | 'DEFAULT'
  | 'NONE'
  | 'CHECKBOX'
  | 'RADIO'
  | 'TYPE'
  | 'BUTTONS'

const cellSkeletonMap = (key: number): Record<SkeletonRowType, JSX.Element> => ({
  AVATAR: (
    <Table.Td key={key}>
      {/* Default size for Mantine's Avatar component */}
      <Skeleton circle height={39} width={39} />
    </Table.Td>
  ),
  STATUS: (
    <Table.Td key={key} style={statusStyle}>
      <Skeleton circle height={28} mr={16} />
    </Table.Td>
  ),
  NAME: (
    <Table.Td key={key} style={{ width: '40%' }}>
      <Skeleton height={28} width={`${random.randomInt(50) + 50}%`} />
    </Table.Td>
  ),
  DATE: (
    <Table.Td key={key}>
      <Skeleton height={28} width='60%' />
    </Table.Td>
  ),
  DOCUMENTS: (
    <Table.Td key={key} style={{ width: '30%' }}>
      {/* 36 is the height of the button */}
      <Skeleton height={36} width={`${random.randomInt(25) + 75}%`} />
    </Table.Td>
  ),
  DEFAULT: (
    <Table.Td key={key}>
      <Skeleton height={28} />
    </Table.Td>
  ),
  NONE: <Table.Td key={key} />,
  CHECKBOX: (
    <Table.Td key={key}>
      <Skeleton height={28} width={28} />
    </Table.Td>
  ),
  RADIO: (
    <Table.Td key={key}>
      <Radio
        styles={{ labelWrapper: { width: '100%' } }}
        disabled
        label={<Skeleton height={20} width={`${random.randomInt(25) + 75}%`} />}
      />
    </Table.Td>
  ),
  TYPE: (
    <Table.Td key={key} style={{ width: 280 }}>
      <Skeleton radius={theme.radius.xl} height={20} width={random.randomInt(140) + 100} />
    </Table.Td>
  ),
  BUTTONS: (
    <Table.Td key={key} pr={0}>
      <Group justify='end'>
        <Skeleton height={30} width={80} />
        <Skeleton height={30} width={80} />
      </Group>
    </Table.Td>
  ),
})

const _SkeletonRows: React.FC<{ types: SkeletonRowType[]; rowCount?: number }> = ({
  types,
  rowCount = 10,
}) => (
  <>
    {Array.from({ length: rowCount }).map((_, outer) => (
      <Table.Tr key={outer}>{types.map((type, inner) => cellSkeletonMap(inner)[type])}</Table.Tr>
    ))}
  </>
)

// Using a memo here to avoid recalculating random widths on each re-render
// isEqual is the underscore function for deep equality
export const SkeletonRows = React.memo(_SkeletonRows, isEqual)
