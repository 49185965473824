import Router from 'next/router'
import { create } from 'zustand'
import type { DocDetailProp } from '~/client/components/doc-detail/state'

export type DocDetailViewStateAndProp =
  | {
      state: 'closed'
      prop: null
    }
  | {
      state: 'modal' | 'fullscreen' | 'processingWizardModal'
      prop: DocDetailProp
    }

export interface DocDetailViewStore {
  /**
   * Main piece of data. It's better to access its data locally (via props or Container) to avoid boilerplate with
   * discriminated unions. We assume that only a single type of doc detail is active at once
   */
  docDetailViewState: DocDetailViewStateAndProp
  setDocDetailViewState: (state: DocDetailViewStateAndProp) => void
  /**
   * Open single document editing/upload in a modal. Takes care of adding query params
   * @param modalProp
   */
  openModal: (modalProp?: DocDetailProp) => void
  /**
   * Open single document editing for processing wizard in a modal.
   * @param modalProp
   */
  openProcessingWizardModal: (modalProp?: DocDetailProp) => void
  /**
   * Reset doc detail to the closed state. Takes care of removing query params. Shouldn't be called in fullscreen details
   * @param skipRouteChange Used to avoid infinite loops when reacting to route change events
   */
  closeModal: (options?: { skipRouteChange?: boolean }) => void
}

const replaceDocDetailQuery = (docDetail: string | undefined) => {
  // Remove docDetail from the query params to avoid it having an empty value in the url
  const { docDetail: _, ...otherQueries } = Router.query

  return Router.replace(
    {
      query: {
        ...otherQueries,
        ...(docDetail && { docDetail }),
      },
    },
    undefined,
    { shallow: true, scroll: false }
  )
}

export const useDocDetailViewStore = create<DocDetailViewStore>()((set) => ({
  docDetailViewState: { state: 'closed', prop: null },
  setDocDetailViewState: (state: DocDetailViewStateAndProp) => set({ docDetailViewState: state }),
  openModal: (modalProp = {}) => {
    const queryValue = modalProp.doc?.cryptId.idStr ?? 'new'
    // Make sure this logic only runs on the client
    // Otherwise there are some errors in jest and possibly hydration errors
    Router.ready(async () => {
      await replaceDocDetailQuery(queryValue)
      // The only case we don't want auto-populate is when a user opens
      // an already existing document in any view except processing
      const enablePopulateForm =
        // enablePopulateForm when opening the modal in processing
        (modalProp.allowedTypes?.length === 1 && modalProp.allowedTypes[0] === 'PROCESSING') ||
        // or when uploading a document to any view.
        !modalProp.doc

      set({
        docDetailViewState: { state: 'modal', prop: { enablePopulateForm, ...modalProp } },
      })
    })
  },
  openProcessingWizardModal: (modalProp = {}) => {
    set({
      docDetailViewState: {
        prop: {
          allowAllTypes: true,
          allowedTypes: ['PROCESSING'],
          enablePopulateForm: true,
          ...modalProp,
        },
        state: 'processingWizardModal',
      },
    })
  },
  closeModal: ({ skipRouteChange } = {}) => {
    Router.ready(async () => {
      if (!skipRouteChange) await replaceDocDetailQuery(undefined)
      set({
        docDetailViewState: { state: 'closed', prop: null },
      })
    })
  },
}))
