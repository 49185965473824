import type { TextareaProps } from '@mantine/core'
import { Textarea } from '@mantine/core'
import * as React from 'react'
import { inlineInputWidth } from '~/client/components/util/inline/util'
import type { AutofillMetadataString } from '~/client/lib/hooks/relation-autofill'
import { AugmentedMetadataString } from '~/common/schema'
import type { InlineCommonProps } from './base'
import { InlineBaseInput } from './base'

interface InlineStringInputProps extends TextareaProps, InlineCommonProps<AugmentedMetadataString> {
  autofillData?: AutofillMetadataString[]
}

export const InlineStringInput: React.FC<InlineStringInputProps> = ({
  update,
  initialValue,
  docs,
  alignment,
  noWrap,
  autofillData,
  ...props
}) => {
  return (
    <InlineBaseInput
      initialValues={initialValue}
      schema={AugmentedMetadataString.pickValueSchema}
      update={update && (({ metadata }) => update(metadata))}
      display={AugmentedMetadataString.display(initialValue?.value)}
      docs={docs}
      alignment={alignment}
      valueIsFilled={!!initialValue?.value}
      noWrap={noWrap}
      autofillData={autofillData}
      autofillDisplayFn={(data) => data.value}
      dropOverContent={({ formGetInputProps }) => (
        <Textarea
          {...props}
          {...formGetInputProps('value')}
          styles={{
            input: { width: inlineInputWidth },
          }}
          minRows={1}
          maxRows={4}
          autosize
        />
      )}
    />
  )
}
