// eslint-disable-next-line custom-rules/no-use-debounced-value
import { useDebouncedValue } from '@mantine/hooks'
import type { UseInfiniteQueryResult } from '@tanstack/react-query'
import * as React from 'react'
import { hooks } from '~/client/lib/hooks'
import { nextPageParamOpts } from '~/client/lib/hooks/query'
import { splitTypeAndTextQueries } from '~/client/lib/hooks/search/'
import type { SearchQueryObj } from '~/client/lib/query-params'
import type { ZNumberExceed } from '~/common/number-exceed'
import { type Paginated, type ZAugmentedDocWithHighlights, type ZDocType } from '~/common/schema'
import { isDocFilterEmpty } from '~/common/util'

/**
 * Debounced search queries.  Manages debounced state. Also returned an
 * immediately updated state for displaying in controlled inputs
 * @param queries from URL
 * @returns
 */
export const useDocSearchState = (
  queryObj: SearchQueryObj = { queries: [] }
): {
  debouncedValue: SearchQueryObj
  setValue: (newValue: React.SetStateAction<SearchQueryObj>) => void
  value: SearchQueryObj
} => {
  const [value, setValue] = React.useState<SearchQueryObj>(queryObj)
  const [debouncedValue] = useDebouncedValue<SearchQueryObj>(value, 500)
  React.useEffect(() => {
    setValue(queryObj)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryObj)])

  return { debouncedValue, setValue, value }
}

export type DocSearchResults = UseInfiniteQueryResult<Paginated<ZAugmentedDocWithHighlights>>

interface UseDocSearch {
  noQuery: boolean
  docTypes: ZDocType[]
  queryObj: SearchQueryObj
  debouncedQueryObj: SearchQueryObj
  setQueryObj: (value: React.SetStateAction<SearchQueryObj>) => Promise<void>
  searchResults: DocSearchResults
}

export const useDocSearch = (queryObj: SearchQueryObj = { queries: [] }): UseDocSearch => {
  const { value, setValue, debouncedValue } = useDocSearchState(queryObj)

  const { texts, docFilters } = splitTypeAndTextQueries(debouncedValue)

  const searchResults = hooks
    .trpc()
    .docs.search.useInfiniteQueryWithCorp(
      { filters: docFilters, query: texts, limit: 10 },
      { keepPreviousData: true, ...nextPageParamOpts() }
    )

  const trpcContext = hooks.trpc().useContext()
  const setQueryObj = React.useCallback(
    async (v: React.SetStateAction<SearchQueryObj>) => {
      setValue(v)
      await trpcContext.docs.search.cancel()
    },
    [setValue, trpcContext.docs.search]
  )

  const noQuery = isDocFilterEmpty(docFilters) && texts.length === 0

  return {
    setQueryObj,
    queryObj: value,
    debouncedQueryObj: debouncedValue,
    docTypes: docFilters.type ?? [],
    searchResults,
    noQuery,
  }
}

export const mkDocsAndCount = (
  searchResults: DocSearchResults
): { docs: ZAugmentedDocWithHighlights[]; count: ZNumberExceed } => {
  const docs = searchResults.data?.pages.map((page) => page.data).flat() ?? []
  const count = searchResults.data?.pages[0]?.count ?? { count: 0, exceeds: false }
  return { docs, count }
}
