import {
  Badge,
  Button,
  Center,
  Group,
  Notification,
  Stack,
  Tabs,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core'
import { showNotification, updateNotification } from '@mantine/notifications'
import { IconCircleCheck } from '@tabler/icons-react'
import React from 'react'
import { useCustomRedFlagDetailModal } from '~/client/components/red-flags/custom-red-flag-detail'
import { RedFlagsTable } from '~/client/components/red-flags/red-flags-table'
import { NextLinkOpt } from '~/client/components/util'
import { AnimatedCountBadge } from '~/client/components/util/animated-count-badge'
import { LoadingErrorComp } from '~/client/components/util/error'
import { hooks, useCorpCryptId } from '~/client/lib/hooks'
import { theme } from '~/client/lib/theme'
import { enhanceCount } from '~/common/enhance'
import { ActiveRedFlagIcon, DismissedRedFlagIcon } from '~/common/red-flags'
import { RedFlagsListView, type RedFlagsProps } from './red-flags-list'

export const NoRedFlags: React.FC<{
  title?: string
  noIcon?: boolean
  subtitle?: string
  buttonLabel?: string
  addRedFlag?: () => void
}> = ({ title, noIcon, subtitle, buttonLabel, addRedFlag }) => {
  return (
    <Center my={128}>
      <Stack align='center'>
        {!noIcon && (
          <ThemeIcon color='go' display='contents'>
            <IconCircleCheck size={100} />
          </ThemeIcon>
        )}
        <Title order={2}>{title ?? 'No Red Flags!'}</Title>
        <Text fz='lg'>{subtitle ?? 'All red flags have been solved. You are good to go! 🎉'}</Text>
        {addRedFlag && <Button onClick={addRedFlag}>{buttonLabel ?? 'Add Red Flag'}</Button>}
      </Stack>
    </Center>
  )
}

export const RedFlags: React.FC<RedFlagsProps> = (props) => {
  const { mkCurrentCorpRoute } = useCorpCryptId()
  const { data } = hooks.trpc().docs.count.useQueryWithCorp({ types: ['PROCESSING'] })
  const navigationTabs = hooks.useNavigationTabs({
    tabs: ['active', 'dismissed', 'list'],
    defaultTab: 'active',
  })

  const queryResult = hooks.trpc().redFlags.get.all.useQueryWithCorp({ dismissed: false, limit: 1 })

  const totalActiveRedFlags = queryResult.data?.count ?? { count: 0, exceeds: false }

  return (
    <Stack>
      {data && data.count > 0 && (
        <Notification
          loading
          title={`Aerial is processing ${enhanceCount(data)} documents`}
          bg='white'
          maw={theme.other.widths.sm}
          style={{
            alignSelf: 'center',
          }}
          withCloseButton={false}
        >
          <Text size='sm'>
            Your red flags may be resolved by the documents that Aerial is{' '}
            <NextLinkOpt href={mkCurrentCorpRoute('processing')}>still processing</NextLinkOpt>. We
            will sort and categorize these shortly. You can also resolve red flags by uploading the
            requested documents.
          </Text>
        </Notification>
      )}
      <Tabs {...navigationTabs}>
        <Tabs.List>
          <Tabs.Tab value='active' data-testid='active-tab'>
            <Group gap='sm'>
              <ThemeIcon c='urgent'>
                <ActiveRedFlagIcon />
              </ThemeIcon>
              Active
              <LoadingErrorComp queryResult={queryResult} loaderSize='sm' variant='tooltip'>
                <AnimatedCountBadge
                  value={totalActiveRedFlags}
                  renderBadge={({ count }) => (
                    <Badge data-testid='red-flag-count-badge' color='urgent' variant='filled'>
                      {count}
                    </Badge>
                  )}
                />
              </LoadingErrorComp>
            </Group>
          </Tabs.Tab>

          <Tabs.Tab value='dismissed' data-testid='dismissed-tab'>
            <Group gap='sm'>
              <ThemeIcon c='go'>
                <DismissedRedFlagIcon />
              </ThemeIcon>
              Dismissed
            </Group>
          </Tabs.Tab>
          <Tabs.Tab value='list'>List</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value='active' mt='lg'>
          <RedFlagsTable
            dismissed={false}
            openCustomRedFlagModal={props.openCustomRedFlagModal}
            noRedFlags={
              <NoRedFlags
                title='No Active Red Flags!'
                addRedFlag={() =>
                  props.openCustomRedFlagModal({ mode: 'new', props: { type: 'CUSTOM' } })
                }
                subtitle='All active red flags have been solved. You are good to go! 🎉'
              />
            }
          />
        </Tabs.Panel>
        <Tabs.Panel value='dismissed' mt='lg'>
          <RedFlagsTable
            dismissed
            openCustomRedFlagModal={props.openCustomRedFlagModal}
            noRedFlags={
              <Center my={128}>
                <Stack align='center'>
                  <Text fz='lg'>No dismissed red flags</Text>
                  <Button
                    onClick={() =>
                      props.openCustomRedFlagModal({ mode: 'new', props: { type: 'CUSTOM' } })
                    }
                  >
                    Add Red Flag
                  </Button>
                </Stack>
              </Center>
            }
          />
        </Tabs.Panel>
        <Tabs.Panel value='list' mt='lg'>
          <RedFlagsListView {...props} />
        </Tabs.Panel>
      </Tabs>
    </Stack>
  )
}

interface UseRedFlagsRtn extends RedFlagsProps {
  noRedFlags: boolean
  modal: React.ReactNode
}

export const useRedFlags = (): UseRedFlagsRtn => {
  const { modal, openCustomRedFlagModal } = useCustomRedFlagDetailModal()
  const redFlagsQueryResult = hooks.trpc().redFlags.get.all.useQueryWithCorp({ limit: 1 })

  const noRedFlags = !!redFlagsQueryResult.data && redFlagsQueryResult.data.count.count === 0

  return {
    modal,
    noRedFlags,
    openCustomRedFlagModal,
  }
}

export const RedFlagsComp: React.FC = () => {
  const { noRedFlags, modal, ...redFlagsProps } = useRedFlags()
  const { corpCryptId } = useCorpCryptId()
  const { mutateAsync } = hooks.trpc().redFlags.compute.useMutationWithCorp()

  const computeRedFlags = React.useCallback(async () => {
    const notificationId = 'redFlagNotification'
    showNotification({
      id: notificationId,
      loading: true,
      title: 'Updating red flags',
      message: 'Red flags are being recomputed',
      autoClose: false,
      withCloseButton: false,
      'data-testid': 'updating-red-flags-notification',
    })

    await mutateAsync()
    updateNotification({
      id: notificationId,
      title: 'Red flags updated!',
      message: 'Red flags have been updated successfully',
      color: 'go',
      loading: false,
      autoClose: 5000,
      withCloseButton: true,
    })
    // we only want to re-compute when `corpCryptId` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [corpCryptId])

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    computeRedFlags()
  }, [computeRedFlags])

  return (
    <>
      {noRedFlags ? (
        <NoRedFlags
          addRedFlag={() =>
            redFlagsProps.openCustomRedFlagModal({ mode: 'new', props: { type: 'CUSTOM' } })
          }
        />
      ) : (
        <RedFlags {...redFlagsProps} />
      )}
      {modal}
    </>
  )
}

export { RedFlagRow } from './red-flags-table'
export { useRedFlagCompute } from './compute-red-flags'
export { RedFlagStatusIcon } from './red-flag-icon'
export { useCustomRedFlagDetailModal } from './custom-red-flag-detail'
