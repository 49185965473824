import React from 'react'
import { EditableRelation } from '~/client/components/editable-relation'
import type { DetailEditableRelationProps } from '~/client/components/relation/detail/types'
import type { ZAugmentedOption, ZUpdateOption } from '~/common/schema/relation'

export const OptionEditableRelation: React.FC<
  DetailEditableRelationProps<ZUpdateOption, ZAugmentedOption>
> = ({ data, update }) => (
  <EditableRelation
    relation={data.valuation}
    type='VALUATION'
    update={(valuationCryptId) => update({ valuationCryptId })}
  />
)
