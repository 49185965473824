import type { CryptId } from '@cryptid-module'
import { Box, Group, Overlay, Stack, useMantineTheme } from '@mantine/core'
import React from 'react'
import type { DocCardProp, DocCardStateProps } from '~/client/components/doc-card'
import { useDocDetailViewStore } from '~/client/components/doc-detail/state'
import { ignoreSelectionsAndNestedClicks } from '~/client/components/util'
import { ImageWithPlaceholder } from '~/client/components/util/image-with-placeholder'
import { boxShadowHover, hoverAppearDelay150msClasses } from '~/client/lib/css-util.css'
import { hooks, useCorpCryptId } from '~/client/lib/hooks'
import type { OptimisticOptions } from '~/client/lib/hooks/optimistic-update'
import {
  optimisticMutationOptions,
  optimisticMutationOptionsInfinite,
} from '~/client/lib/hooks/optimistic-update'
import { useDownloadOrGenerateThumbnail } from '~/client/lib/hooks/s3'
import { theme } from '~/client/lib/theme'
import type { ZAugmentedCorp } from '~/common/schema'
import type { ZAugmentedRelation } from '~/common/schema/relation'
import { DocActions } from './doc-actions'
import { ImagePlaceholder } from './image-placeholder'

const docCardDimensions = (() => {
  const base = {
    imageMargin: 16,
    imageWidth: 200,
    imageHeight: 175,
    borderRadius: 16,
  }
  return {
    ...base,
    imageDivWidth: base.imageWidth + 2 * base.imageMargin,
    minHeight: base.imageHeight,
  }
})()

const DocImage: React.FC<DocCardProp> = ({ doc, isLoading }) => {
  const imageBackground = useMantineTheme().colors.gray[2]
  const thumbResult = useDownloadOrGenerateThumbnail(doc)

  return (
    <ImageWithPlaceholder
      w='100%'
      h='100%'
      imageProps={{
        src: thumbResult.objectUrl,
        style: {
          height: docCardDimensions.imageHeight,
          padding: docCardDimensions.imageMargin,
          paddingBottom: 0, // Padding in all sides but bottom
        },
      }}
      placeholder={
        <Stack w='100%' h='100%' bg={imageBackground} align='center' justify='center'>
          <ImagePlaceholder doc={doc} isLoading={isLoading || thumbResult.isLoading} />
        </Stack>
      }
    />
  )
}

// Remove the deleted doc from docCryptId and docOpts
const updateRelationOrCorp = <T extends ZAugmentedRelation | ZAugmentedCorp>(
  item: T,
  docCryptId: CryptId
) => ({
  ...item,
  docCryptIds: item.docCryptIds.filter((cryptId) => !cryptId.equals(docCryptId)),
  docOpts: item.docOpts.filter((docOpt) => !docOpt || !docOpt.cryptId.equals(docCryptId)),
})

const useOptimisticUpdateOptions = (
  docState?: DocCardStateProps
  // TS merges the return types of this function instead of treating each
  // one separately, which gives a type error
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): OptimisticOptions<{ cryptId: CryptId }, any> | undefined => {
  const utils = hooks.trpc().useContext()
  const { corpCryptId } = useCorpCryptId()

  if (docState?.byTypeProps)
    return optimisticMutationOptionsInfinite(
      utils.docs.byType,
      { corpCryptId, ...docState.byTypeProps }, // Copy the array to make it not readonly
      (input: { cryptId: CryptId }, oldData) => {
        if (!oldData) return oldData

        // Remove the deleted doc and update the count
        return {
          ...oldData,
          pages: oldData.pages.map((page) => ({
            ...page,
            data: page.data.filter((docResult) => !docResult.cryptId.equals(input.cryptId)),
            count: { ...page.count, count: page.count.count - 1 },
          })),
        }
      }
    )

  if (docState?.linkOptions?.type === 'relation')
    return optimisticMutationOptions(
      utils.relations.byCryptIds,
      { cryptIds: [docState.linkOptions.cryptId], corpCryptId },
      (input: { cryptId: CryptId }, oldData) => {
        if (!oldData) return oldData
        const relation = oldData[0]
        if (!relation) return []
        return [updateRelationOrCorp(relation, input.cryptId)]
      }
    )

  if (docState?.linkOptions?.type === 'corp')
    return optimisticMutationOptions(
      utils.corp.read,
      { corpCryptId },
      (input: { cryptId: CryptId }, oldData) => {
        if (!oldData) return oldData
        return updateRelationOrCorp(oldData, input.cryptId)
      }
    )
}

/**
 * The ImageFrame component defines the size of the card and contains the image
 * on the left.
 */
export const ImageFrame = React.forwardRef<HTMLDivElement, React.PropsWithChildren<DocCardProp>>(
  ({ doc, docState, isLoading, children }, ref) => {
    const openDocDetail = useDocDetailViewStore((state) => state.openModal)
    const allowAllTypes =
      doc && !docState?.sourceCryptIds?.some((cryptId) => cryptId.equals(doc.cryptId))

    const imageBackground = useMantineTheme().colors.gray[2]

    const cardClickHandler = () => {
      // It's unclear what should happen when `DocCard` area is clicked and
      // current document is invalid. Since there are `Upload` and `Link` buttons in
      // that case, do nothing.
      if (!doc) {
        return
      }
      openDocDetail({ doc, allowAllTypes, ...docState })
    }

    const optimisticUpdateOptions = useOptimisticUpdateOptions(docState)
    const deleteDoc = hooks.trpc().doc.delete.useMutationWithCorp(optimisticUpdateOptions)

    return (
      <Group
        ref={ref}
        onClick={ignoreSelectionsAndNestedClicks(cardClickHandler)}
        className={`${hoverAppearDelay150msClasses.hover} ${boxShadowHover}`}
        align='stretch'
        gap={0}
        h={docCardDimensions.minHeight}
        pos='relative'
        w={theme.other.widths.md}
        bg='white'
        style={{
          cursor: doc ? 'pointer' : 'default',
          overflow: 'clip',
          borderRadius: docCardDimensions.borderRadius,
          border: `1px solid ${theme.colors.gray[2]}`,
        }}
      >
        <Box
          bg={imageBackground}
          flex='none'
          pos='relative'
          w={docCardDimensions.imageDivWidth}
          mih={docCardDimensions.minHeight}
          style={{
            overflow: 'hidden',
          }}
        >
          {doc && (
            <Box
              className={hoverAppearDelay150msClasses.appear}
              pos='absolute'
              w='100%'
              h='100%'
              display='flex'
              style={{ zIndex: 1, justifyContent: 'center' }}
            >
              <Overlay
                style={{
                  // Needed for Safari. For alternatives and tradeoffs, see https://github.com/aerialops/aerial-app/pull/1389
                  borderTopLeftRadius: docCardDimensions.borderRadius,
                  borderBottomLeftRadius: docCardDimensions.borderRadius,
                }}
                backgroundOpacity={0.6}
                color='#000'
                zIndex={2}
                blur={1}
              />
              <Group style={{ zIndex: 3 }} gap='xl'>
                <DocActions doc={doc} docState={docState} deleteDoc={deleteDoc} />
              </Group>
            </Box>
          )}
          <DocImage doc={doc} isLoading={isLoading} />
        </Box>
        {children}
      </Group>
    )
  }
)
