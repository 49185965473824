/* eslint-disable @typescript-eslint/no-loop-func */
import type { ZPagination } from '~/common/pagination'
import type { Paginated } from '~/common/schema'
import { zenvCommon } from './zenv-common'

export const exhaustPaginated = async <
  Item,
  QueryParams extends Omit<ZPagination, 'direction'> = Omit<ZPagination, 'direction'>,
>(
  queryFn: (_: QueryParams) => Promise<Paginated<Item> | Paginated<Item>>,
  input: QueryParams,
  isInfiniteQuery: boolean = true
): Promise<Item[]> => {
  const limit = Math.min(zenvCommon.NEXT_PUBLIC_PAGE_QUERY_LIMIT, input.limit)
  const queryParams: QueryParams = { ...input, limit, skip: undefined, cursor: undefined }
  const result: Item[] = []
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, no-constant-condition
  while (true) {
    // eslint-disable-next-line no-await-in-loop
    const { data, count: _count, nextCursor } = await queryFn(queryParams)
    const count = typeof _count === 'number' ? _count : _count.count
    result.push(...data)
    if (!data.length || (isInfiniteQuery && !nextCursor) || (count > 0 && result.length >= count)) {
      break
    }
    if (isInfiniteQuery) {
      queryParams.cursor = nextCursor
    } else {
      queryParams.skip = result.length
    }
  }
  return result
}
