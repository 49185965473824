import { z } from 'zod'
import { ZCryptId } from '~/common/schema'

export const ZIntegrationBase = z.object({
  lastSuccessfulQuery: z.date().optional(),
  /* The user email which is used to uniquely identify the user in Aerial. This
     may not be the same email used to log into the integration provider's site.
     Useful to validate the user still has access to the corp they integrated
     with */
  userEmail: z.string().email(),
})
export type ZIntegrationBase = z.infer<typeof ZIntegrationBase>

export const ZAugmentedIntegrationBase = ZIntegrationBase.extend({
  cryptId: ZCryptId,
  corpCryptId: ZCryptId,
})
export type ZAugmentedIntegrationBase = z.infer<typeof ZAugmentedIntegrationBase>
