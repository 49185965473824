import { AppShell, Box, Burger, Group, Text, useMantineTheme } from '@mantine/core'
import { IconChevronDown } from '@tabler/icons-react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'
import { SearchInput } from '~/client/components/layout/search-input'
import { useLayoutStore } from '~/client/components/layout/state'
import { AvatarWithLoader } from '~/client/components/util/avatar-with-loader'
import { useRibbonHeight } from '~/client/components/util/ribbon'
import { hooks } from '~/client/lib/hooks'
import { useCurrentUserProfilePhotoURL } from '~/client/lib/hooks/profile-photo'
import type { Device } from '~/client/lib/hooks/responsive'
import { useDeviceByWidth } from '~/client/lib/hooks/responsive'
import { theme } from '~/client/lib/theme'
import LogoStars from '~/public/logo-stars.svg'
import WordLogo from '~/public/word-logo.svg'
import { AccountMenu } from './account-menu'
import { CompanyMenu } from './company-menu'

interface CorpSpecificElements {
  CorpMenu: React.ReactNode
  Search: React.ReactNode
  ToggleNavbar: React.ReactNode
}

const ToggleNavbarButton: React.FC = () => {
  const { toggleNavbar, isNavbarOpen } = useLayoutStore()
  const { colors } = useMantineTheme()
  return (
    <Burger opened={isNavbarOpen} onClick={toggleNavbar} pl='sm' color={colors.gray[5]} size={20} />
  )
}

const useCorpSpecificElements = (device: Device, noCorpUrl?: boolean): CorpSpecificElements => {
  const router = useRouter()

  return React.useMemo(() => {
    if (noCorpUrl) {
      return {
        CorpMenu: null,
        Search: null,
        ToggleNavbar: null,
      }
    }

    return {
      CorpMenu: <CompanyMenu />,
      Search: device === 'desktop' && (
        <Box style={{ flexGrow: 1 }}>
          {/* Restarts `SearchInput` lifecycle when route changes. This is to ensure changes in the url is always reflected in the search input during search */}
          <SearchInput key={router.asPath} />
        </Box>
      ),

      ToggleNavbar: (device === 'mobile' || device === 'tablet') && <ToggleNavbarButton />,
    }
  }, [device, noCorpUrl, router.asPath])
}

const Logo: React.FC = () => {
  const { device } = useDeviceByWidth()

  return (
    <>
      {device === 'desktop' ? (
        <Box data-testid='logo' w={theme.other.navbarWidth}>
          <NextLink href='/'>
            {/* marginTop=1 as fine tuning. */}
            <Box h={29} mt={1}>
              <WordLogo alt='Aerial Logo' />
            </Box>
          </NextLink>
        </Box>
      ) : (
        <Box w={20} h={20} mt={1} ml='xl' mr='sm'>
          <LogoStars alt='Aerial Logo' />
        </Box>
      )}
    </>
  )
}

export const appHeaderHeight = 64

export interface AppHeaderProps {
  noCorpUrl?: boolean
}

export const AppHeader: React.FC<AppHeaderProps> = ({ noCorpUrl }) => {
  const user = hooks.useAuthStore((state) => state.user)
  const photoURL = useCurrentUserProfilePhotoURL()
  const { device } = useDeviceByWidth()
  const displayName = user?.displayName ?? user?.email
  const { CorpMenu, Search, ToggleNavbar } = useCorpSpecificElements(device, noCorpUrl)
  const ribbonHeight = useRibbonHeight()

  return (
    // css hack to change the height and padding-top of Header when we need to
    // display a ribbon, this is needed to display the ribbon above mantine's
    // AppShell without messing with it's internal styles
    <AppShell.Header pt={ribbonHeight} data-testid='app-header'>
      <Group
        align='center'
        gap={0}
        justify={noCorpUrl ? 'space-between' : 'flex-start'}
        wrap='nowrap'
      >
        {ToggleNavbar}
        <Logo />

        <Group gap='sm' flex='auto' justify={noCorpUrl ? 'flex-end' : 'flex-start'} wrap='nowrap'>
          {CorpMenu}
          {Search}

          <Box w={device === 'desktop' ? undefined : '100%'}>
            <Group gap='lg' justify='flex-end' wrap='nowrap'>
              <AccountMenu>
                <Group gap='sm' justify='flex-end' wrap='nowrap'>
                  <Group gap='xs' wrap='nowrap'>
                    {device === 'desktop' && <Text py='xs'>{displayName ?? 'Loading'}</Text>}
                    <IconChevronDown size={14} />
                  </Group>
                  <AvatarWithLoader isLoading={!user} photoUrl={photoURL} />
                </Group>
              </AccountMenu>
            </Group>
          </Box>
        </Group>
      </Group>
    </AppShell.Header>
  )
}
