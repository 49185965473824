import React from 'react'
import { hooks } from '~/client/lib/hooks'
import { useCorpCryptIdSafe, useCurrentCorpAuth } from '~/client/lib/hooks/corp'
import { useReloadCallback } from '~/client/lib/hooks/reload-callback'
import { trpc } from '~/client/lib/trpc'

/**
 * Re-computes red flags on page reload (not on load). Use this only on _app.tsx
 * not on the corp page wrapper component as we only want this to run on reload
 * not on first corp page visits.
 */
export const useRedFlagCompute = (): void => {
  const [firstLoad, setFirstLoad] = React.useState(true)
  const authStatus = hooks.useAuthStatus()
  const { corpCryptId } = useCorpCryptIdSafe() ?? {}
  const currentCorpResults = useCurrentCorpAuth()
  const { mutate } = trpc.redFlags.compute.useMutation()
  const permissionsLevel = currentCorpResults.data?.level

  const computeRedFlags = React.useCallback(() => {
    // We only run this on a corp page and when the user is authenticated.
    // We use `firstLoad` to make sure this doesn't run again when `corpCryptId`
    // or `authStatus` changes
    if (
      corpCryptId &&
      authStatus === 'AUTHENTICATED' &&
      firstLoad &&
      permissionsLevel &&
      permissionsLevel !== 'investor'
    ) {
      mutate({ corpCryptId })
      setFirstLoad(false)
    }
  }, [corpCryptId, authStatus, firstLoad, mutate, permissionsLevel])

  useReloadCallback(computeRedFlags)
}
