import React from 'react'
import type { SortRenderer } from '~/client/components/relation/base-table'
import {
  RelationHeaders,
  RelationRow,
  RelationTable,
} from '~/client/components/relation/base-table'
import type { EnhancedRelation, RedFlagInfo } from '~/common/enhance'
import {
  type ZAugmentedLocalCorporation,
  type ZAugmentedStateCorporation,
  ZStateLocalType,
} from '~/common/schema/relation'

export const StateLocalComp: React.FC = () => {
  const renderHeaders: SortRenderer = (props) => (
    <RelationHeaders
      noRedFlags
      noEndDate
      noStartDate
      baseHeaderName={{ name: 'Jurisdiction' }}
      relationTypes={['STATE', 'LOCAL']}
      {...props}
    />
  )

  const renderRows = (
    data: {
      enhancedRelation: EnhancedRelation<ZAugmentedStateCorporation | ZAugmentedLocalCorporation>
      redFlagInfo: RedFlagInfo | undefined
    }[]
  ) => (
    <>
      {data.map((relationData) => (
        <RelationRow
          noRedFlags
          noEndDate
          noStartDate
          redFlagInfo={relationData.redFlagInfo}
          key={relationData.enhancedRelation.cryptId.idStr}
          item={relationData.enhancedRelation}
        />
      ))}
    </>
  )

  return (
    <RelationTable
      noStatus
      relationTypes={['STATE', 'LOCAL']}
      renderHeaders={renderHeaders}
      renderRows={renderRows}
      noDataMsg='No State or Local records found'
      downloadOptions={{
        sheetTitle: ZStateLocalType.display,
        headers: ['Jurisdiction', 'Number of Documents'],
        rowProvider: (r) => [r.display, r.docs.length],
      }}
    />
  )
}
