import { create } from 'zustand'

export interface LayoutStore {
  isNavbarOpen: boolean
  toggleNavbar: () => void
}

export const useLayoutStore = create<LayoutStore>((set) => ({
  isNavbarOpen: false,
  toggleNavbar: () => set((state) => ({ isNavbarOpen: !state.isNavbarOpen })),
}))
