import type { CardProps } from '@mantine/core'
import { Box, Card, Center, Loader } from '@mantine/core'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useDocDetailState } from '~/client/components/doc-detail/state'
import { ErrorDisplayComp } from '~/client/components/util/error'
import { theme } from '~/client/lib/theme'
import { checkPdfCorrupt } from '~/common/pdfjs'
import { SingleDocDrop } from './single-doc-drop'

const useCheckPdfCorrupted = () => {
  const pdfDataUrl = useDocDetailState((state) => state.pdfDataUrl)
  return useQuery({
    queryFn: () => checkPdfCorrupt({ url: pdfDataUrl }),
    queryKey: ['check-pdf-document', pdfDataUrl],
    enabled: !!pdfDataUrl,
  })
}

export const PdfIframe: React.FC<{
  url?: string
  showToolbar?: boolean
  title: string
  padding: CardProps['p']
}> = ({ url, showToolbar, title, padding }) => {
  const queryResult = useCheckPdfCorrupted()

  if (!url)
    return (
      <Box
        w='100%'
        h='100%'
        display='flex'
        bg={theme.colors.gray[3]}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: theme.radius.sm,
        }}
      >
        <Loader />
      </Box>
    )

  if (queryResult.data?.isCorrupted)
    return (
      <Card p={padding} withBorder h='100%'>
        <Center h='100%'>
          <Card title='Error'>
            <ErrorDisplayComp error='Invalid PDF. Please delete this document and re-upload it again' />
          </Card>
        </Center>
      </Card>
    )

  return (
    <Card
      p={padding}
      withBorder
      h='100%'
      bg='#505255'
      style={{
        overflow: 'clip',
      }}
    >
      <iframe
        // For some reason, React skips re-rendering this iframe when the url changes
        // Use key to force it
        key={url}
        // extra parameters to format pdf viewer
        src={`${url}#view=FitH&navpanes=0&scrollbar=1&toolbar=${showToolbar ? 1 : 0}`}
        title={title}
        width='100%'
        height='100%'
        style={{ border: 0 }}
      />
    </Card>
  )
}

export const DocDetailIFrame: React.FC = () => {
  const pdfDataUrl = useDocDetailState((state) => state.pdfDataUrl)
  const isLoadingPDF = useDocDetailState((state) => state.isLoadingPDF)

  if (!pdfDataUrl && !isLoadingPDF) {
    return (
      <Box h='100%'>
        <SingleDocDrop />
      </Box>
    )
  }

  return <PdfIframe padding={4} url={pdfDataUrl} showToolbar title='PDF View' />
}
