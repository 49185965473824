import { CryptId } from '@cryptid-module'
import { z } from 'zod'
import { fromUTCDate } from '~/common/date-utc'
import { AugmentedMetadataDate } from '~/common/schema/metadata'

export const ZEmailLowerCase = z
  .string()
  .email()
  .transform((e): string => e.toLowerCase())

export type ZEmailLowerCase = z.infer<typeof ZEmailLowerCase>

export const ZCryptId = z.instanceof(CryptId)
export type ZCryptId = z.infer<typeof ZCryptId>

const _ZImpreciseDate = z.object({
  date: z.date(),
  isMonth: z.boolean().optional(),
})

export const ZImpreciseDate = Object.assign(_ZImpreciseDate, {
  display: (v: ZImpreciseDate | null | undefined) => {
    if (!v) return v
    return v.isMonth
      ? fromUTCDate(v.date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })
      : AugmentedMetadataDate.display(v.date)
  },
})
export type ZImpreciseDate = z.infer<typeof _ZImpreciseDate>
