import type { PDFDocument } from '@cantoo/pdf-lib'

/** load a PDF into `pdf-lib` with default options that allow parsing encrypted
 * files that do not have a password (like the ones that come from Adobe Acrobat
 * Sign) */
export const loadPDFLibDocument = async (
  pdf: string | Uint8Array | ArrayBuffer
): Promise<PDFDocument> => {
  const pdfLib = await import('@cantoo/pdf-lib')
  return pdfLib.PDFDocument.load(pdf, {
    throwOnInvalidObject: true,
    password: '',
    ignoreEncryption: true,
  })
}
