import { Box, Button, Group, Stack, ThemeIcon, Title } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { IconArrowBack } from '@tabler/icons-react'
import Router from 'next/router'
import React from 'react'
import type { IntegrationType } from '~/client/components/integration/integration-info'
import {
  defaultBlurbForCategoryMap,
  integrationInformation,
  integrationsCategoryTypeMap,
} from '~/client/components/integration/integration-info'
import { analytics } from '~/client/components/monitoring'
import promptConfirmAsync from '~/client/components/util/prompt-confirm-async'
import { hooks, useCorpCryptId } from '~/client/lib/hooks'
import { useMkIntegrationAddUrlAndSetStateCookieWithCorp } from '~/client/lib/integration'
import { ZIntegrationType } from '~/common/schema/integration'
import { IntegrationCard } from './integration-card'

export const AddIntegrationCard: React.FC<{
  type: ZIntegrationType
  openInNewTab?: boolean
  onButtonClick?: () => void
  isOnboardingIntegrations?: boolean
}> = ({ type, openInNewTab = false, onButtonClick, isOnboardingIntegrations }) => {
  const [loading, setLoading] = React.useState(false)
  const { mkIntegrationAddUrlAndSetStateCookie } =
    useMkIntegrationAddUrlAndSetStateCookieWithCorp(type)

  const onClickConnect = async () => {
    const integrationAddUrl = mkIntegrationAddUrlAndSetStateCookie({
      isOnboardingIntegrations,
    })
    onButtonClick?.()

    if (openInNewTab) {
      window.open(integrationAddUrl)
      return
    }
    // Loading the Docusign page can take a while, and it looks like our site is
    // unresponsive if we don't have a loading indicator
    setLoading(true)
    await Router.push(integrationAddUrl)
  }
  return (
    <IntegrationCard
      title=''
      variant='add'
      integrationType={type}
      isLoadingButton={loading}
      onClickButton={onClickConnect}
      blurb={integrationInformation[type].blurb}
    />
  )
}

const BetaIntegrationCard: React.FC<{ name: IntegrationType }> = ({ name }) => {
  const { title, category } = integrationInformation[name]
  const [disabled, setDisabled] = React.useState(false)
  const sendRequestIntegrationEmail = hooks.trpc().sendRequestIntegrationEmail.useMutationWithCorp()

  const onClickConnect = async () => {
    const ok = await promptConfirmAsync({
      title: 'Join Beta Program',
      subtitle: `Would you like to request joining our Beta group for the ${title} integration`,
      confirmText: 'Join',
      buttonsColorVariant: 'encourage',
    })
    if (!ok) return

    analytics.trackEventSuccess('BETA_INTEGRATION_REQUEST', {
      integrationTitle: title,
      integrationCategory: category,
    })

    await sendRequestIntegrationEmail.mutateAsync({ integrationName: title })

    showNotification({
      title: 'Application submitted',
      message: `You successfully requested to join the beta program for the ${title} integration`,
      color: 'primary',
    })
    setDisabled(true)
  }
  return (
    <IntegrationCard
      title=''
      variant='beta'
      integrationType={name}
      onClickButton={onClickConnect}
      disabledButton={disabled}
      isLoadingButton={sendRequestIntegrationEmail.isLoading}
      beta
      blurb={defaultBlurbForCategoryMap[integrationInformation[name].category]}
    />
  )
}

export const IntegrationAddGrid: React.FC<{ isFirstIntegration?: boolean }> = ({
  isFirstIntegration,
}) => {
  const { mkCurrentCorpRoute } = useCorpCryptId()
  return (
    <Stack>
      <Box>
        {!isFirstIntegration && (
          <Button
            leftSection={
              <ThemeIcon>
                <IconArrowBack />
              </ThemeIcon>
            }
            onClick={() => Router.push(mkCurrentCorpRoute('integrations'))}
          >
            Back to connected integrations
          </Button>
        )}
      </Box>
      <Group gap='xl'>
        {Object.entries(integrationsCategoryTypeMap).map(([category, integrations]) => {
          return (
            <Stack key={category}>
              <Title order={3}>{category}</Title>
              <Group gap='xl'>
                {integrations.map((integration) => {
                  // Parsing just to satisfy typing
                  const parsedType = ZIntegrationType.safeParse(integration)
                  return integrationInformation[integration].isImplemented && parsedType.success ? (
                    <AddIntegrationCard key={integration} type={parsedType.data} />
                  ) : (
                    <BetaIntegrationCard name={integration} key={integration} />
                  )
                })}
              </Group>
            </Stack>
          )
        })}
      </Group>
    </Stack>
  )
}
