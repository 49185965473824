import { Stack, Text } from '@mantine/core'
import React from 'react'
import { MultiDocDrop } from '~/client/components/multi-doc-drop'
import { FilesUploaded } from '~/client/components/multi-doc-drop/upload-result'
import { StepContainer } from '~/client/components/onboarding-modal/util'

export const DocumentUploadStep: React.FC = () => {
  const [hasUploaded, setHasUploaded] = React.useState(false)
  return (
    <StepContainer
      title='Upload other documents'
      subtitle='Aerial automatically cross-references documents to flag potential issues'
      stepCompleted={hasUploaded}
      dataTestId='onboarding-document-upload-step'
    >
      <Text size='lg'>
        Upload other documents like Employment Agreements, Contractors Agreements, Customer
        Agreements, NDAs, etc…
      </Text>
      <Stack px='xl' mih='400px' w='100%' justify='center'>
        {hasUploaded ? (
          <FilesUploaded onUploadMoreClick={() => setHasUploaded(false)} withCloseButton={false} />
        ) : (
          <MultiDocDrop
            onAfterDocUpload={() => {
              setHasUploaded(true)
            }}
            navigateToDocStatusAfterUpload={false}
          />
        )}
      </Stack>
    </StepContainer>
  )
}
