import React from 'react'
import { SkeletonRows } from '~/client/components/util/skeleton-rows'

// this is dependent on the order of the columns in the table, and relies on the `RelationRow` component for this
const getRelationCellSkeleton = (colNumber: number, length: number) => {
  switch (colNumber) {
    case 0:
      return 'STATUS'
    case 1:
      return 'NAME'
    case 2:
      return 'DATE'
    case 3:
      return 'DATE'
    case length - 1:
      return 'DOCUMENTS'
    default:
      return 'DEFAULT'
  }
}

const getRelationRowTypes = (headerCellsAmount: number, noStatus?: boolean) => {
  const colSkip = noStatus ? 1 : 0
  return Array.from({ length: headerCellsAmount }, (_, key) =>
    getRelationCellSkeleton(key + colSkip, headerCellsAmount)
  )
}

export const RelationRowsSkeleton: React.FC<{
  rows?: number
  noStatus?: boolean
  headerRef: React.RefObject<HTMLTableSectionElement>
}> = ({ rows = 1, noStatus, headerRef }) => {
  const [skeletonRows, setSkeletonRows] = React.useState<React.ReactNode | null>(null)
  React.useEffect(() => {
    const headerRow = headerRef.current?.firstElementChild
    if (!headerRow) return
    const headerCellsAmount = headerRow.childElementCount - 1
    setSkeletonRows(<SkeletonRows types={getRelationRowTypes(headerCellsAmount, noStatus)} />)
  }, [headerRef, noStatus, rows])
  return <>{skeletonRows}</>
}
