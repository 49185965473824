import type { CryptId } from '@cryptid-module'
import { ActionIcon, Menu, Table, ThemeIcon } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { IconArrowsDiagonal, IconDotsVertical, IconTrash } from '@tabler/icons-react'
import type { UseMutationResult } from '@tanstack/react-query'
import type { inferProcedureInput, inferProcedureOutput } from '@trpc/server'
import NextLink from 'next/link'
import React from 'react'
import { nestedClickArea } from '~/client/components/util'
import { DocSearchModal } from '~/client/components/util/doc'
import { deletePromptConfirm } from '~/client/components/util/prompt'
import { hooks, useCorpCryptId } from '~/client/lib/hooks'
import { optimisticMutationOptions } from '~/client/lib/hooks/optimistic-update'
import { optimisticSetLink } from '~/client/lib/util'
import type { EnhancedCorp, EnhancedRelation } from '~/common/enhance'
import type { ZDocType } from '~/common/schema'
import type { ZAugmentedRelation } from '~/common/schema/relation'
import { ZAugmentedCommon, typeAugmentedRelationMap } from '~/common/schema/relation'
import type { AppRouter } from '~/common/trpc/app-router'

interface TInput extends inferProcedureInput<AppRouter['relation']['delete']> {}
type TOutput = inferProcedureOutput<AppRouter['relation']['delete']>
type OnSuccess = (data: TOutput, variables: TInput) => void

export type UseSetLinkMutationResult = UseMutationResult<
  void,
  unknown,
  { docCryptId: CryptId; linkValue: boolean }
>

export const useRelationSetLink = (data: EnhancedRelation): UseSetLinkMutationResult => {
  const { corpCryptId } = useCorpCryptId()
  const utils = hooks.trpc().useContext()

  const relationCryptId = data.cryptId
  const setLink = hooks.trpc().relation.setLink.useMutationWithCorp({
    ...optimisticMutationOptions(
      utils.relations.byCryptIds,
      { cryptIds: [data.cryptId], corpCryptId },
      (input, oldData) => {
        if (!oldData) return oldData
        const relationIndex = oldData.findIndex((rel) => rel.cryptId.equals(input.relationCryptId))
        const relation = oldData[relationIndex]
        if (relationIndex === -1 || !relation) return oldData

        return [
          ...oldData.slice(0, relationIndex),
          optimisticSetLink(relation, input.docCryptId, input.linkValue),
          ...oldData.slice(relationIndex + 1),
        ]
      }
    ),
  })
  const mutationWithRelation = React.useCallback(
    ({ docCryptId, linkValue }) => {
      return setLink.mutateAsync({ docCryptId, linkValue, relationCryptId })
    },
    [setLink, relationCryptId]
  )

  const setLinkWithRelation: UseSetLinkMutationResult = {
    ...setLink,
    mutateAsync: mutationWithRelation,
    mutate: mutationWithRelation,
  }

  return setLinkWithRelation
}

interface UseDeleteRelationRtn {
  deleteRelationPrompt: (
    relationCryptId: CryptId,
    type: ZAugmentedRelation['type']
  ) => Promise<void>
  isLoading: boolean
  isError: boolean
}

/**
 * Delete relation by its `_id`
 * @param onSuccess
 */
export const useDeleteRelation = (onSuccess?: OnSuccess): UseDeleteRelationRtn => {
  const { mutateAsync, isLoading, isError } = hooks.trpc().relation.delete.useMutationWithCorp({
    onSuccess: (data, variables) => {
      showNotification({ message: 'Relation Deleted' })
      onSuccess?.(data, variables)
    },
  })

  const deleteRelationPrompt = async (
    relationCryptId: CryptId,
    type: ZAugmentedRelation['type']
  ) => {
    const confirm = await deletePromptConfirm({
      dataTestId: 'confirm-relation-delete',
      itemName: typeAugmentedRelationMap[type].display,
    })
    if (confirm) await mutateAsync({ cryptId: relationCryptId })
  }

  return {
    deleteRelationPrompt,
    isLoading,
    isError,
  }
}

interface RelationDetailAnchorCellProps extends React.ComponentProps<typeof Table.Td> {
  url: string
}
export const RelationDetailAnchorCell: React.FC<RelationDetailAnchorCellProps> = ({
  url,
  ...props
}) => (
  <Table.Td {...props} ta='right'>
    <ActionIcon ml='auto' component={NextLink} href={url}>
      <IconArrowsDiagonal />
    </ActionIcon>
  </Table.Td>
)

interface RelationDetailMenuCellProps extends React.ComponentProps<typeof Table.Td> {
  relation: Pick<EnhancedRelation, 'url' | 'type' | 'cryptId'>
}
export const RelationDetailMenuCell: React.FC<RelationDetailMenuCellProps> = ({
  relation,
  ...props
}) => {
  const { deleteRelationPrompt } = useDeleteRelation()

  return (
    <Table.Td {...props} ta='right'>
      <Menu>
        <Menu.Target>
          <ActionIcon ml='auto' className={nestedClickArea.cssClass}>
            <IconDotsVertical />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            leftSection={
              <ThemeIcon color='primary'>
                <IconArrowsDiagonal />
              </ThemeIcon>
            }
            component={NextLink}
            href={relation.url}
          >
            Open Relation
          </Menu.Item>
          <Menu.Item
            className={nestedClickArea.cssClass}
            onClick={() => deleteRelationPrompt(relation.cryptId, relation.type)}
            leftSection={
              <ThemeIcon color='danger' display='contents'>
                <IconTrash />
              </ThemeIcon>
            }
          >
            Delete Relation
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Table.Td>
  )
}

export const useDocSearchModal = (
  data: EnhancedRelation | EnhancedCorp,
  setLink: UseSetLinkMutationResult
): { openSearchModal: (type?: ZDocType) => void; modal: React.ReactNode } => {
  const [searchModalOpen, setSearchModalOpen] = React.useState(false)
  const [docType, setDocType] = React.useState<ZDocType>()

  const openSearchModal = (type?: ZDocType) => {
    setDocType(type)
    setSearchModalOpen(true)
  }

  const allowedTypes =
    'type' in data
      ? typeAugmentedRelationMap[data.type].supportedTypes
      : ZAugmentedCommon.supportedTypes

  const modal = (
    <DocSearchModal
      setLink={setLink}
      docCryptIds={data.docCryptIds}
      open={searchModalOpen}
      setOpen={setSearchModalOpen}
      allowedDocTypes={docType ? [docType] : allowedTypes}
    />
  )

  return { openSearchModal, modal }
}
