import { Table, Text } from '@mantine/core'
import { RelationNameDisplay, RelationTypeBadgeDisplay } from '~/client/components/relation/display'
import { NextLinkOpt } from '~/client/components/util'
import { LoadingErrorComp } from '~/client/components/util/error'
import { SkeletonRows } from '~/client/components/util/skeleton-rows'
import { hooks } from '~/client/lib/hooks'
import type { EnhancedRelation } from '~/common/enhance'
import { enhanceRelation } from '~/common/enhance'

const useAssociatedRelations = (relation: EnhancedRelation) => {
  const party = 'party' in relation ? relation.party : undefined
  const enabled = !!party?.name

  const queryResult = hooks.trpc().relation.getAssociatedRelations.useQueryWithCorp(
    {
      relationCryptId: relation.cryptId,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      party: party!,
    },
    { enabled }
  )
  return { ...queryResult, isLoading: queryResult.isLoading && enabled }
}

export const AssociatedRelationsTable: React.FC<{ relation: EnhancedRelation }> = ({
  relation,
}) => {
  const queryResult = useAssociatedRelations(relation)
  const associatedRelations = queryResult.data ?? []

  return (
    <Table striped>
      <Table.Thead>
        <Table.Tr>
          <Table.Th w='50%'>Name</Table.Th>
          <Table.Th>Type</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        <LoadingErrorComp
          queryResult={queryResult}
          skeleton={<SkeletonRows types={['NAME', 'TYPE']} rowCount={3} />}
          fullTableWidth
        >
          {associatedRelations.length === 0 && (
            <Table.Tr>
              <Table.Td colSpan={2}>
                <Text c='dimmed' ta='center' my='xl' size='lg'>
                  No associated relations found
                </Text>
              </Table.Td>
            </Table.Tr>
          )}
          {associatedRelations
            .map((associatedRelation) => enhanceRelation(associatedRelation))
            .map((associatedRelation) => (
              <Table.Tr key={associatedRelation.cryptId.idStr}>
                <Table.Td>
                  <NextLinkOpt href={associatedRelation.url}>
                    <RelationNameDisplay relation={associatedRelation} />
                  </NextLinkOpt>
                </Table.Td>
                <Table.Td>
                  <RelationTypeBadgeDisplay type={associatedRelation.type} />
                </Table.Td>
              </Table.Tr>
            ))}
        </LoadingErrorComp>
      </Table.Tbody>
    </Table>
  )
}
