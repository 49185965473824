import { max } from 'underscore'

class AgreementData {
  version: number
  date: Date
  constructor(version: number, date: Date) {
    this.version = version
    this.date = date
  }
  get tosPath(): string {
    return `/agreement/${this.version}/tos.pdf`
  }
  get privacyPath(): string {
    return `/agreement/${this.version}/privacy.pdf`
  }
  get legalDisclaimerPath(): string | undefined {
    // Legal disclaimer was added in version 2
    if (this.version === 1) {
      return undefined
    }
    return `/agreement/${this.version}/legal-disclaimer.pdf`
  }
}

export const allAgreements: AgreementData[] = [
  new AgreementData(1, new Date(2023, 0, 3)),
  new AgreementData(2, new Date(2024, 2, 8)),
]

export const CURRENT_AGREEMENT_VERSION = max(allAgreements.map((a) => a.version))

export const currentAgreement = (() => {
  const _currentAgreement = allAgreements.find(
    (agreement) => agreement.version === CURRENT_AGREEMENT_VERSION
  )
  if (!_currentAgreement) {
    throw Error('Cannot find current version')
  }
  return _currentAgreement
})()
