import type { SelectProps } from '@mantine/core'
import {
  ActionIcon,
  Avatar,
  Button,
  Group,
  HoverCard,
  Loader,
  Select,
  Table,
  Text,
  ThemeIcon,
} from '@mantine/core'
import { IconCheck, IconCircleMinus, IconInfoCircle } from '@tabler/icons-react'
import React, { useCallback } from 'react'
import { RoleHoverExplanations } from '~/client/components/util/permission-explanation'
import promptConfirmAsync from '~/client/components/util/prompt-confirm-async'
import { zIndex } from '~/client/components/z-index'
import { hoverAppearInstantClasses } from '~/client/lib/css-util.css'
import { hooks, useCorpCryptId } from '~/client/lib/hooks'
import { optimisticMutationOptions } from '~/client/lib/hooks/optimistic-update'
import { useProfilePhotoObjectURL } from '~/client/lib/hooks/profile-photo'
import { theme } from '~/client/lib/theme'
import type { ZAugmentedRole, ZAugmentedRoleWithCreatedAt } from '~/common/schema'
import {
  ZAugmentedUser,
  ZPermissionsLevel,
  permissionsLevels,
  roleExplanations,
} from '~/common/schema'
import { NameEmail } from './util'
import { MutationStatusIcon } from './util/mutation-status-icon'

export const RoleSelectDropdown: React.FC<SelectProps> = ({ ...props }) => (
  <Select
    miw={210}
    searchable
    allowDeselect={false}
    data={permissionsLevels.map((level) => ({
      label: roleExplanations[level].name,
      value: level,
    }))}
    styles={{ dropdown: { zIndex: zIndex.popover } }}
    renderOption={(item) => {
      const typesafeLevel = ZPermissionsLevel.parse(item.option.value)
      return (
        <Group wrap='nowrap' justify='space-between' w='100%'>
          <Text style={{ textWrap: 'nowrap' }}>{item.option.label}</Text>
          {item.checked ? (
            <ThemeIcon color='go' display='contents'>
              <IconCheck size={18} />
            </ThemeIcon>
          ) : (
            <RoleHoverExplanations level={typesafeLevel}>
              <ThemeIcon color='primary' display='contents'>
                <IconInfoCircle size={18} className={hoverAppearInstantClasses.appear} />
              </ThemeIcon>
            </RoleHoverExplanations>
          )}
        </Group>
      )
    }}
    withScrollArea={false}
    {...props}
  />
)

interface RoleSelectProps {
  userRole: ZAugmentedRole
  denyEditingSelf: boolean
  readonly?: boolean
}

const RoleSelect: React.FC<RoleSelectProps> = ({ userRole, denyEditingSelf, readonly }) => {
  const utils = hooks.trpc().useContext()
  const { corpCryptId } = useCorpCryptId()
  const roleUpdate = hooks.trpc().role.update.useMutationWithCorp({
    ...optimisticMutationOptions(utils.role.list, { corpCryptId }, (input, oldData) => {
      if (!oldData) return []
      const changedIndex = oldData.findIndex((r) => r.userEmail === input.userEmail)
      const updatedRole = oldData[changedIndex]
      const newData = [...oldData]
      if (!updatedRole) throw new Error('role not in oldData')
      newData[changedIndex] = { ...updatedRole, ...input }
      return newData
    }),
  })

  const promptConfirmRemoveAdmin = React.useCallback(
    async (isAdmin: boolean) => {
      if (!isAdmin) return true
      const confirm = await promptConfirmAsync({
        title: 'Remove Admin Status?',
        subtitle: (
          <>
            Are you sure you want to remove admin status from <b>{userRole.userEmail}</b>? Doing so
            will remove any integrations they connected to the corp.
          </>
        ),
        confirmText: 'Remove',
        confirmProps: { 'data-testid': 'confirm-user-revoke-admin' },
        buttonsColorVariant: 'discourage',
      })
      return confirm
    },
    [userRole.userEmail]
  )

  const onAdminStatusChange = React.useCallback(
    async (rawLevel: string) => {
      const level = ZPermissionsLevel.parse(rawLevel)
      if (level === userRole.level) return
      const confirm = await promptConfirmRemoveAdmin(userRole.level === 'admin')
      if (!confirm) return
      roleUpdate.mutate({ ...userRole, level })
    },
    [promptConfirmRemoveAdmin, roleUpdate, userRole]
  )

  return (
    <HoverCard shadow='md' position='top' disabled={!denyEditingSelf}>
      <HoverCard.Target>
        <Group wrap='nowrap'>
          <RoleSelectDropdown
            disabled={readonly || roleUpdate.isLoading}
            value={userRole.level}
            onChange={(v) => onAdminStatusChange(v ?? '')}
            data-testid='role-select'
          />
          <RoleHoverExplanations level={userRole.level}>
            <MutationStatusIcon mutation={roleUpdate} size={18}>
              <ThemeIcon color='primary' display='contents'>
                <IconInfoCircle size={18} className={hoverAppearInstantClasses.appear} />
              </ThemeIcon>
            </MutationStatusIcon>
          </RoleHoverExplanations>
        </Group>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Text>There are no other admins. Create another admin to change your role.</Text>
      </HoverCard.Dropdown>
    </HoverCard>
  )
}

const DeleteRoleButton: React.FC<{
  userRole: ZAugmentedRole
  denyEditing: boolean
  denyEditingSelf: boolean
  otherUserDisplay: string
}> = ({ userRole, denyEditing, denyEditingSelf, otherUserDisplay }) => {
  // Use optimistic update when delete a role
  const utils = hooks.trpc().useContext()
  const { corpCryptId } = useCorpCryptId()
  const { data } = hooks.useCurrentCorp()

  const { mutate: deleteRoleMutate } = hooks.trpc().role.delete.useMutationWithCorp({
    ...optimisticMutationOptions(
      utils.role.list,
      { corpCryptId },
      (_, oldData) => oldData?.filter((x) => x.userEmail !== userRole.userEmail)
    ),
  })

  const onRoleDelete = useCallback(
    async (role: ZAugmentedRole) => {
      const confirm = await promptConfirmAsync({
        title: 'Remove User Permissions?',
        subtitle: (
          <>
            Are you sure you want to remove <b>{otherUserDisplay}</b> from <b>{data?.name.value}</b>
            ? Doing so will remove any integrations they connected to the corp.
          </>
        ),
        confirmText: 'Remove',
        confirmProps: { 'data-testid': 'confirm-user-remove' },
        buttonsColorVariant: 'discourage',
      })
      if (confirm) {
        return deleteRoleMutate(role)
      }
    },
    [deleteRoleMutate, data, otherUserDisplay]
  )

  return (
    <HoverCard shadow='md' position='top' disabled={denyEditing && !denyEditingSelf}>
      <HoverCard.Target>
        <ActionIcon
          color={denyEditing ? theme.colors.gray[5] : 'danger'}
          className={hoverAppearInstantClasses.appear}
          data-testid='permissions-remove-user'
          onClick={() => !denyEditing && onRoleDelete(userRole)}
          style={{ ...(denyEditing ? { cursor: 'not-allowed' } : {}) }}
        >
          <IconCircleMinus />
        </ActionIcon>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Text>
          {denyEditingSelf
            ? 'There are no other admins. Create another admin to remove yourself.'
            : `Remove ${otherUserDisplay} from ${data?.name.value}`}
        </Text>
      </HoverCard.Dropdown>
    </HoverCard>
  )
}

export const Role: React.FC<{
  userRole: ZAugmentedRoleWithCreatedAt
  adminCount: number
  isNew: boolean
}> = ({ adminCount, userRole, isNew }) => {
  const user = hooks.useAuthStore((state) => state.user)
  const isOwnUser = user?.email === userRole.userEmail
  const denyEditingSelf = isOwnUser && adminCount < 2
  const denyEditing = denyEditingSelf || !user

  const { userEmail } = userRole

  // user may not exist in firebase, if is a new invite to the app
  const queryResult = hooks.trpc().userAdmin.getFirebaseUser.useQueryWithCorp({ email: userEmail })
  const sendInviteEmail = hooks.trpc().sendInviteEmail.useMutationWithCorp()

  const { displayName, photoURL } = queryResult.data ?? {}
  const photoObjectURL = useProfilePhotoObjectURL(photoURL)
  const otherUserDisplay = ZAugmentedUser.displayFn(displayName, userEmail)

  // Loading has completed successfully and we know the user has not signed up for Aerial
  // We only display the reminder button in this case
  const noSignup = !queryResult.isLoading && !queryResult.data && !queryResult.isError
  const emailSuccess = sendInviteEmail.isSuccess || isNew
  const name =
    displayName || isOwnUser ? `${displayName ?? ''} ${isOwnUser ? '(me)' : ''}` : undefined

  return (
    <Table.Tr
      className={hoverAppearInstantClasses.hover}
      style={{ backgroundColor: denyEditing || isOwnUser ? theme.colors.gray[0] : undefined }}
      data-testid='permissions-user-row'
    >
      <Table.Td style={{ paddingRight: 0 }}>
        <Avatar src={photoObjectURL} radius='xl' />
      </Table.Td>
      <Table.Td>
        <NameEmail name={name} email={userEmail} />
      </Table.Td>
      <Table.Td>
        <RoleSelect userRole={userRole} readonly={denyEditing} denyEditingSelf={denyEditingSelf} />
      </Table.Td>
      <Table.Td>{userRole.createdAt.toLocaleDateString()}</Table.Td>
      <Table.Td align='right'>
        {noSignup ? (
          <Button
            disabled={sendInviteEmail.isLoading || emailSuccess}
            onClick={() => sendInviteEmail.mutateAsync({ email: userEmail })}
            rightSection={
              sendInviteEmail.isLoading ? (
                <Loader size='sm' />
              ) : emailSuccess ? (
                <ThemeIcon color='go'>
                  <IconCheck />
                </ThemeIcon>
              ) : null
            }
          >
            {emailSuccess ? 'Invitation Sent' : 'Resend Invitation'}
          </Button>
        ) : null}
      </Table.Td>
      <Table.Td align='right'>
        <DeleteRoleButton
          denyEditing={denyEditing}
          denyEditingSelf={denyEditingSelf}
          otherUserDisplay={otherUserDisplay}
          userRole={userRole}
        />
      </Table.Td>
    </Table.Tr>
  )
}
