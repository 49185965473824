import { appendPath, mkUrlWithSearchParams } from '~/common/util'
import { zenvCommon } from '~/common/zenv-common'

export const DOCUSIGN_SCOPE = ['signature', 'impersonation']

/** Create the docusign consent URL. state should be a random nonce to prevent CSRF
 * attacks.
 * See https://auth0.com/docs/secure/attack-protection/state-parameters#use-the-stored-url-to-redirect-users
 * We cannot use the ApiClient in the docusign SDK as it only works in node. */
export const mkDocusignIndividualConsentUrl = (stateNonce: string): string => {
  const redirectUri = appendPath(
    zenvCommon.NEXT_PUBLIC_REDIRECT_URL,
    zenvCommon.NEXT_PUBLIC_DOCUSIGN_CALLBACK_PATH
  )

  // The logout endpoint will prompt an immediate login.
  // https://developers.docusign.com/platform/auth/reference/logout/
  // This enables users to pick which user they want to use for each integration
  // (instead of always using the one that Docusign remembered )
  return mkUrlWithSearchParams(`https://${zenvCommon.NEXT_PUBLIC_DOCUSIGN_AUTH_HOST}/logout`, {
    response_type: 'code',
    scope: DOCUSIGN_SCOPE.join(' '),
    client_id: zenvCommon.NEXT_PUBLIC_DOCUSIGN_INTEGRATION_KEY,
    redirect_uri: redirectUri,
    state: stateNonce,
  })
}

// This needs to be imported by the server when throwing the error, and by the
// client to prevent showing a notification when the error is thrown.
export const duplicateDocusignAccountOtherCorpErrorMsg =
  'This Docusign account is already connected to a different corp'

export const duplicateDocusignAccountSameCorpErrorMsg =
  'This Docusign account is already connected to this Corp. Please refresh the page'
