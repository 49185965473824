import { z } from 'zod'
import { ZDateFilter, ZStringFilter } from '~/common/schema/filters'
import { ZRelationTypeValues } from '~/common/schema/relation/augmented'

const ZTypeFilter = Object.assign(ZRelationTypeValues.array(), { type: 'type' as const })

export const ZRelationFilters = z.object({
  party: ZStringFilter.optional(),
  type: ZTypeFilter.optional(),
  endDate: ZDateFilter.optional(),
  startDate: ZDateFilter.optional(),
})

export interface ZRelationFilters extends z.infer<typeof ZRelationFilters> {}
