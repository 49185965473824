import { Box, Button, Loader, Menu } from '@mantine/core'
import Router from 'next/router'
import React from 'react'
import type { SortHeaderProps } from '~/client/components/util/sort'
import { hooks, useCorpCryptId, useCurrentCorpAuth } from '~/client/lib/hooks'
import type { ZRelationPaginationSortField } from '~/common/pagination'
import type { ZRelationTypeValues } from '~/common/schema/relation'
import { typeAugmentedRelationMap } from '~/common/schema/relation'
import type { NonEmptyArray } from '~/common/util-types'

export interface RelationSort
  extends Omit<SortHeaderProps<ZRelationPaginationSortField>, 'sortField'> {}
export type SortRenderer = (sort: RelationSort) => React.ReactNode

export const AddRelation: React.FC<{
  options: NonEmptyArray<ZRelationTypeValues>
}> = ({ options }) => {
  const { data: auth } = useCurrentCorpAuth()
  const [isLoading, setIsLoading] = React.useState(false)
  const relationCreate = hooks.trpc().relation.create.useMutationWithCorp()
  const { corpCryptId } = useCorpCryptId()

  const createRelation = async (type: ZRelationTypeValues) => {
    setIsLoading(true)

    const relation = typeAugmentedRelationMap[type].empty(corpCryptId)
    const { url } = await relationCreate.mutateAsync({ relation })

    setIsLoading(false)
    await Router.push(url)
  }
  const sortedOptions = React.useMemo(
    () =>
      options.sort((a, b) =>
        typeAugmentedRelationMap[a].display.localeCompare(typeAugmentedRelationMap[b].display)
      ),
    [options]
  )

  const multiple = options.length > 1
  const buttonIsLoading = isLoading
  const disabled = buttonIsLoading || auth?.level === 'investor'

  return (
    <Box pr={0} ta='right'>
      {multiple ? (
        <Menu shadow='md' position='bottom-end'>
          <Menu.Target>
            <Button size='sm' disabled={disabled} data-testid='add-relation-btn'>
              {buttonIsLoading ? <Loader size='sm' /> : 'Add'}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {sortedOptions.map((option) => (
              <Menu.Item key={option} onClick={() => createRelation(option)}>
                {typeAugmentedRelationMap[option].display}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      ) : (
        <Button
          size='sm'
          onClick={() => createRelation(options[0])}
          disabled={disabled}
          data-testid='add-relation-btn'
        >
          {buttonIsLoading ? <Loader size='sm' /> : 'Add'}
        </Button>
      )}
    </Box>
  )
}

export const statusStyle = { paddingRight: 0 }
