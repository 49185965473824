import { useMutation } from '@tanstack/react-query'
import { openDB } from 'idb'
import React from 'react'
import { ClientNotifiableError } from '~/client/components/monitoring/'
import { Random } from '~/common/random'

const mkStorageErrorString = (storageType: string): string =>
  `Oops! It seems like there was an issue accessing some important information needed to run the app. Please check your browser settings to ensure that '${storageType}' is enabled.`

const useCheckLocalStorageAccess = (): void => {
  // Use a mutation to handle the error and display it to the user
  const { mutate } = useMutation(() => {
    try {
      const localStorageKey = '__check_localstorage_access__'
      localStorage.setItem(localStorageKey, localStorageKey)
      localStorage.removeItem(localStorageKey)
      return Promise.resolve()
    } catch (error) {
      throw new ClientNotifiableError(mkStorageErrorString('localStorage'), {
        cause: error,
      })
    }
  }, {})

  React.useEffect(() => {
    mutate()
  }, [mutate])
}

const useCheckIndexedDBAccess = (): void => {
  // Use a mutation to handle the error and display it to the user
  const { mutate } = useMutation(async () => {
    try {
      const indexedDBKey = '__check_indexed_db_access__'
      const db = await openDB(indexedDBKey, 1, {
        upgrade: (dbPreUpgrade) => {
          dbPreUpgrade.createObjectStore(indexedDBKey)
        },
      })
      // Generate a random key to avoid any potential key conflicts if user
      // refreshes the page. It's probably not necessary since we're using a
      // transaction but there are no downsides to being extra cautious.
      const randomKey = new Random().randomString(64)
      // use a transaction to prevent issues if the user refreshes the page
      const tx = db.transaction(indexedDBKey, 'readwrite')
      await tx.store.put(indexedDBKey, randomKey)
      await tx.store.delete(randomKey)
      tx.commit()
      await tx.done
    } catch (error) {
      throw new ClientNotifiableError(mkStorageErrorString('IndexedDB'), {
        cause: error,
      })
    }
  }, {})
  React.useEffect(() => {
    mutate()
  }, [mutate])
}

/** Checks `localStorage` and `indexedDB` access on the browser, and notifies
 * the user if we encounter any issues */
export const useCheckStorageAccess = (): void => {
  useCheckLocalStorageAccess()
  useCheckIndexedDBAccess()
}
