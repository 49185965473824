import { Button, Group, Stack, Text, Title } from '@mantine/core'
import { closeAllModals, openModal } from '@mantine/modals'
import { useRouter } from 'next/router'
import { hooks } from '~/client/lib/hooks'
import { usePrefillEmailQueryParam } from '~/client/lib/query-params'
import { mkUrlWithPrefillEmail } from '~/common/util'

const SwitchAccountsModal: React.FC = () => {
  const userEmail = hooks.useAuthStore((state) => state.user?.email)
  const urlEmail = usePrefillEmailQueryParam()
  const { logout } = hooks.useAuthMethods()
  const router = useRouter()

  return (
    <Stack>
      <Text>
        You are currently logged in as{' '}
        <Text span fw='bold'>
          {userEmail}
        </Text>
        .
      </Text>
      <Text>
        Would you like to switch to{' '}
        <Text span fw='bold'>
          {urlEmail}
        </Text>
        ?
      </Text>
      <Group justify='flex-end'>
        <Button
          onClick={async () => {
            await logout()
            closeAllModals()
            await router.push(mkUrlWithPrefillEmail('/login', urlEmail))
          }}
        >
          Switch User
        </Button>
      </Group>
    </Stack>
  )
}

export const openSwitchAccountsModal = (): void =>
  openModal({
    title: <Title order={3}>Switching accounts</Title>,
    children: <SwitchAccountsModal />,
    centered: true,
    size: 'md',
    overlayProps: {
      backgroundOpacity: 0.5,
      color: 'gray',
      blur: 3,
    },
    closeButtonProps: {
      'aria-label': 'Close',
    },
    closeOnEscape: true,
    closeOnClickOutside: true,
  })
