import { z } from 'zod'
import { ZCryptId, ZEmailLowerCase } from './util'

export const permissionsLevels = ['admin', 'read-write', 'investor', 'attorney'] as const
export const ZPermissionsLevel = z.enum(permissionsLevels)
export type ZPermissionsLevel = z.infer<typeof ZPermissionsLevel>

export interface PermissionExplanation {
  allowed?: string[]
  partiallyAllowed?: string[]
  disallowed?: string[]
  summary?: string
}

interface PermissionsLevelWithExplanation {
  level: ZPermissionsLevel
  name: string
  explanation: PermissionExplanation
}

export const roleExplanations: Record<ZPermissionsLevel, PermissionsLevelWithExplanation> = {
  admin: {
    level: 'admin',
    name: 'Admin',
    explanation: {
      allowed: [
        'Can read and modify company, documents, and relations',
        'Can create, read and dismiss red flags',
        'Can add and remove integrations and user permissions',
      ],
    },
  },
  'read-write': {
    level: 'read-write',
    name: 'Team Member',
    explanation: {
      allowed: [
        'Can read and modify company, documents, and relations',
        'Can create, read and dismiss red flags',
      ],
      disallowed: ['No access to integrations or user permissions'],
    },
  },
  investor: {
    level: 'investor',
    name: 'Investor',
    explanation: {
      partiallyAllowed: ['Can read but not modify company, documents, and relations'],
      disallowed: ['No access to red flags', 'No access to integrations or user permissions'],
    },
  },
  attorney: {
    level: 'attorney',
    name: 'Attorney',
    explanation: {
      summary: 'Same as Team Member',
      allowed: [
        'Can read and modify company, documents, and relations',
        'Can create, read and dismiss red flags',
      ],
      disallowed: ['No access to integrations or user permissions'],
    },
  },
}

export const ZRole = z.object({ userEmail: ZEmailLowerCase, level: ZPermissionsLevel })

export const ZAugmentedRole = ZRole.extend({ corpCryptId: ZCryptId })

export interface ZAugmentedRole extends z.infer<typeof ZAugmentedRole> {}

export const ZAugmentedRoleWithCreatedAt = ZAugmentedRole.extend({
  createdAt: z.date(),
})

export interface ZAugmentedRoleWithCreatedAt extends z.infer<typeof ZAugmentedRoleWithCreatedAt> {}
