import { type FirebaseApp, FirebaseError } from 'firebase/app'
import type { TotpSecret } from 'firebase/auth'
import {
  type Auth,
  type MultiFactorAssertion,
  type MultiFactorError,
  type MultiFactorResolver,
  type MultiFactorUser,
  type NextOrObserver,
  type OAuthProvider,
  type PhoneInfoOptions,
  type RecaptchaVerifier,
  type Unsubscribe,
  type User,
  type UserCredential,
} from 'firebase/auth'
import { partition } from 'underscore'
import type { AuthProviderId } from '~/client/lib/wrong-auth-provider-error'
import {
  WrongAuthProviderError,
  ZExternalProviderId,
  isSupportedAuthProvider,
} from '~/client/lib/wrong-auth-provider-error'

const setupProvider = (provider: OAuthProvider) => {
  // Allows reading basic profile info (like email)
  // https://learn.microsoft.com/en-us/entra/identity-platform/scopes-oidc
  provider.addScope('user.read')
  return provider
}

export const attemptedProviderSessionStorageKey = 'attemptedProvider'

interface CatchWrongProviderEmailPassword {
  email: string
  error: unknown
  attemptedProvider: 'password'
}

interface CatchWrongProviderExternal {
  error: unknown
}

export class FirebaseAuth {
  // This is meant to be private but it needs to be used in trpc.front.ts
  readonly _loadAuthPromise: Promise<Auth>

  constructor(app: FirebaseApp) {
    this._loadAuthPromise = import('firebase/auth').then(({ getAuth }) => getAuth(app))
  }

  onIdTokenChanged = (nextOrObserver: NextOrObserver<User>): Unsubscribe => {
    let unsubscribe: Unsubscribe | undefined

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this._loadAuthPromise.then((auth) => {
      unsubscribe = auth.onIdTokenChanged(nextOrObserver)
    })

    return () => unsubscribe?.()
  }

  signOut = async (): Promise<void> => {
    const auth = await this._loadAuthPromise
    return auth.signOut()
  }

  createUserWithEmailAndPassword = async (
    email: string,
    password: string
  ): Promise<UserCredential> => {
    const auth = await this._loadAuthPromise
    const { createUserWithEmailAndPassword } = await import('firebase/auth')
    return createUserWithEmailAndPassword(auth, email, password)
  }

  signInWithEmailAndPassword = async (email: string, password: string): Promise<UserCredential> => {
    const auth = await this._loadAuthPromise
    const { signInWithEmailAndPassword } = await import('firebase/auth')
    return signInWithEmailAndPassword(auth, email, password).catch(async (error) => {
      throw await this.mkWrongAuthProviderError({
        email,
        error,
        attemptedProvider: 'password',
      })
    })
  }

  signInWithExternalProvider = async (
    providerName: ZExternalProviderId,
    email?: string
  ): Promise<UserCredential | undefined> => {
    const auth = await this._loadAuthPromise
    const { signInWithRedirect, GoogleAuthProvider, OAuthProvider } = await import('firebase/auth')
    const provider =
      providerName === 'google.com'
        ? new GoogleAuthProvider()
        : setupProvider(new OAuthProvider(providerName))

    return signInWithRedirect(
      auth,
      provider.setCustomParameters({
        ...(email && { login_hint: email }),
      })
    )
  }

  getMultiFactorResolver = async (error: MultiFactorError): Promise<MultiFactorResolver> => {
    const auth = await this._loadAuthPromise
    const { getMultiFactorResolver } = await import('firebase/auth')
    return getMultiFactorResolver(auth, error)
  }

  verifyPhoneNumber = async (
    data: string | PhoneInfoOptions,
    recaptchaVerifier: RecaptchaVerifier
  ): Promise<string> => {
    const auth = await this._loadAuthPromise
    const { PhoneAuthProvider } = await import('firebase/auth')
    const phoneAuthProvider = new PhoneAuthProvider(auth)
    return phoneAuthProvider.verifyPhoneNumber(data, recaptchaVerifier)
  }

  disableAppVerification = async (): Promise<void> => {
    const auth = await this._loadAuthPromise
    auth.settings.appVerificationDisabledForTesting = true
  }

  createRecaptchaVerifier = async (): Promise<RecaptchaVerifier> => {
    const auth = await this._loadAuthPromise
    const { RecaptchaVerifier } = await import('firebase/auth')
    return new RecaptchaVerifier(auth, 'recaptcha', { size: 'invisible' })
  }

  forceTokenRefresh = async (): Promise<void> => {
    const auth = await this._loadAuthPromise
    await auth.currentUser?.getIdToken(true)
  }

  multiFactor = async (user: User): Promise<MultiFactorUser> => {
    const { multiFactor } = await import('firebase/auth')
    return multiFactor(user)
  }

  generatePhoneMFAssertion = async (
    verificationId: string,
    code: string
  ): Promise<MultiFactorAssertion> => {
    const { PhoneAuthProvider, PhoneMultiFactorGenerator } = await import('firebase/auth')
    const credential = PhoneAuthProvider.credential(verificationId, code)
    return PhoneMultiFactorGenerator.assertion(credential)
  }

  generateTotpSecret = async (multiFactorUser: MultiFactorUser): Promise<TotpSecret> => {
    const { TotpMultiFactorGenerator } = await import('firebase/auth')
    const multiFactorSession = await multiFactorUser.getSession()
    return TotpMultiFactorGenerator.generateSecret(multiFactorSession)
  }

  generateEnrollTotpAssertion = async (
    secret: TotpSecret,
    code: string
  ): Promise<MultiFactorAssertion> => {
    const { TotpMultiFactorGenerator } = await import('firebase/auth')
    return TotpMultiFactorGenerator.assertionForEnrollment(secret, code)
  }

  verifyTotp = async (id: string, code: string): Promise<MultiFactorAssertion> => {
    const { TotpMultiFactorGenerator } = await import('firebase/auth')
    return TotpMultiFactorGenerator.assertionForSignIn(id, code)
  }

  /** Identify if the error can be caused by using a wrong provider. Will be a
   * false positive if the user inputs the wrong password in email signIn. */
  mightBeWrongAuthProviderError = (error: unknown): error is FirebaseError => {
    return (
      error instanceof FirebaseError &&
      (error.code === 'auth/account-exists-with-different-credential' || // when user attempted to sign in with google, facebook, ...
        error.code === 'auth/wrong-password') // when user attempted to sign in with email/password but email might be associated with google login
    )
  }

  private _mkEmailAttemptedProvider = (
    input: CatchWrongProviderExternal | CatchWrongProviderEmailPassword,
    error: FirebaseError
  ): { email: string; attemptedProvider: AuthProviderId } => {
    if ('email' in input) {
      return { email: input.email, attemptedProvider: input.attemptedProvider }
    }
    const email = error.customData?.email
    if (!email || typeof email !== 'string') throw error
    // Fetch value from sessionStorage, as with the usage of `signInWithRedirect` we are
    // not able to pass the value to the function.
    const attemptedProvider = ZExternalProviderId.parse(
      window.sessionStorage.getItem(attemptedProviderSessionStorageKey)
    )

    return { email, attemptedProvider }
  }

  /**
   * Creates a more detailed error when user attempted to sign in with wrong
   * provider, or returns the original error if the cause was not related.
   */
  mkWrongAuthProviderError = async (
    input: CatchWrongProviderExternal | CatchWrongProviderEmailPassword
  ): Promise<Error> => {
    const { error } = input
    if (!(error instanceof Error)) throw new Error('Could not identify error', { cause: error })
    if (!this.mightBeWrongAuthProviderError(error)) return error

    const { email, attemptedProvider } = this._mkEmailAttemptedProvider(input, error)

    const auth = await this._loadAuthPromise
    const { fetchSignInMethodsForEmail } = await import('firebase/auth')
    const otherSignInMethods = (await fetchSignInMethodsForEmail(auth, email)).filter(
      (method) => method !== attemptedProvider
    )
    if (otherSignInMethods.length === 0) return error

    // eslint-disable-next-line custom-rules/no-bad-casting-in-declaration
    const [supported, unsupported] = partition(otherSignInMethods, isSupportedAuthProvider) as [
      AuthProviderId[],
      string[],
    ]
    return new WrongAuthProviderError(email, attemptedProvider, supported, unsupported)
  }

  getRedirectResult = async (): Promise<UserCredential | null> => {
    const auth = await this._loadAuthPromise
    const { getRedirectResult } = await import('firebase/auth')

    return getRedirectResult(auth)
  }

  unlink = async (user: User, providerId: AuthProviderId): Promise<User> => {
    const { unlink } = await import('firebase/auth')

    return unlink(user, providerId)
  }
}
