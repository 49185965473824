import { useRouter } from 'next/router'
import React from 'react'
import { create } from 'zustand'
import { analytics } from '~/client/components/monitoring'
import { useCorpCryptId } from '~/client/lib/hooks/corp'

const analyticsInvestorPreviewKey = 'isInvestorPreview'
const investorPreviewQueryParam = 'investorPreview'
interface InvestorPreviewStore {
  isInvestorPreview: boolean
  setInvestorPreview: (value: boolean) => void
}

/** Store the investor preview state in a store, as user navigation will remove the query parameter
 * from the URL which will end the preview mode otherwise */
export const useInvestorPreviewStore = create<InvestorPreviewStore>((set) => ({
  isInvestorPreview: false,
  setInvestorPreview: (value) => set(() => ({ isInvestorPreview: value })),
}))

export const useInvestorPreviewQueryParams = (): void => {
  const router = useRouter()
  const setInvestorPreview = useInvestorPreviewStore((state) => state.setInvestorPreview)
  React.useEffect(() => {
    const { [investorPreviewQueryParam]: investorPreview, ...otherQueryParams } = router.query
    // if query parameter is not in the URL, we should not alter the state.
    if (typeof investorPreview !== 'string') return

    const isInvestorPreview = investorPreview === '1'
    setInvestorPreview(isInvestorPreview)

    // We track the investor preview start event with the page view containing
    // the query parameter.
    // We also want to track which actions users take while in preview mode,
    // which is done by registering the property and sending it on every event.
    if (isInvestorPreview) {
      analytics.register({ [analyticsInvestorPreviewKey]: true })
    }
    if (!isInvestorPreview) {
      analytics.unregister(analyticsInvestorPreviewKey)
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.replace(
      {
        query: otherQueryParams,
      },
      undefined,
      { shallow: true, scroll: false }
    )
  }, [router, setInvestorPreview])
}

/** Exit the investor preview by adding a query parameter.
 * This is done this way to keep all the analytics code in the same place, as we
 * don't want to handle state in multiple different hooks.
 */
export const useExitInvestorPreview = (): { exitInvestorPreview: () => Promise<void> } => {
  const router = useRouter()

  return {
    exitInvestorPreview: async () => {
      await router.replace(
        {
          query: { ...router.query, [investorPreviewQueryParam]: '0' },
        },
        undefined,
        { shallow: true, scroll: false }
      )
    },
  }
}

export const useMkInvestorPreviewUrl = (): { mkInvestorPreviewUrl: () => string } => {
  const { mkCurrentCorpRoute } = useCorpCryptId()
  // Redirect users to dashboard, as this is going to be used in the
  // `/permissions` page, and investors do not have access to that page.
  return {
    mkInvestorPreviewUrl: () => mkCurrentCorpRoute('dashboard', `?${investorPreviewQueryParam}=1`),
  }
}
