import type { CryptId } from '@cryptid-module'
import { Badge, Button, Center, Group, Stack, Text } from '@mantine/core'
import { IconUpload } from '@tabler/icons-react'
import type { inferProcedureInput } from '@trpc/server'
import React from 'react'
import type { DocDetailProp } from '~/client/components/doc-detail/state'
import type { UseSetLinkMutationResult } from '~/client/components/relation'
import { nestedClickArea } from '~/client/components/util'
import { hooks } from '~/client/lib/hooks'
import { useIntersectionObserver } from '~/client/lib/hooks/intersection-observer'
import { s3FileUrlQueryCacheConfig } from '~/client/lib/hooks/s3'
import type { DocAutofill } from '~/common/enhance'
import type { ZAugmentedDocWithHighlights, ZDocType } from '~/common/schema'
import { docTypeStr } from '~/common/schema'
import type { AppRouter } from '~/common/trpc/app-router'
import { DocCardData } from './data'
import { ImageFrame } from './image-frame'
import { SkeletonContent } from './skeleton'

export interface DocCardStateProps extends Pick<DocDetailProp, 'allowedTypes' | 'linkOptions'> {
  setLink?: UseSetLinkMutationResult
  upload?: (type: ZDocType) => void
  sourceCryptIds?: CryptId[]
  autofill?: DocAutofill
  byTypeProps?: Omit<inferProcedureInput<AppRouter['docs']['byType']>, 'corpCryptId'>
}

/**
 * Doc Card without a document and with upload and link buttons.
 */
interface MissingDocCardProp {
  type: ZDocType
  upload: (type: ZDocType) => void
  link: (type: ZDocType) => void
}
export const MissingDocCard: React.FC<MissingDocCardProp> = ({ type, upload, link }) => (
  <ImageFrame>
    <Stack flex={1} h='100%' p='16px' align='stretch'>
      <Group justify='space-between'>
        <Text>
          <b>{docTypeStr(type)}</b>
        </Text>
        <Badge color='urgent'>Missing</Badge>
      </Group>
      <Center flex='auto'>
        <Group>
          <Button
            className={nestedClickArea.cssClass}
            color='urgent'
            leftSection={<IconUpload />}
            onClick={() => upload(type)}
            data-testid='upload-doc-modal-btn'
          >
            Upload
          </Button>
          <Button
            className={nestedClickArea.cssClass}
            color='urgent'
            onClick={() => link(type)}
            data-testid='link-doc-modal-btn'
          >
            Link
          </Button>
        </Group>
      </Center>
    </Stack>
  </ImageFrame>
)

interface UsePreloadFileUrlProps {
  ref: React.RefObject<Element>
  enabled?: boolean
  cryptId?: CryptId
}

/**
 * Caches the doc.fileUrl so that doc modals open faster and
 * don't have to wait for batched suggestions queries.
 * This only caches retrieving the signed s3
 * url, not downloading it (it'd be too expensive)
 * @param enabled
 * @param cryptId
 * @param observerEntry The IntersectionObserver entry so that
 * queries only run when the element is visible.
 */
const usePreloadFileUrl = ({ enabled, cryptId, ref }: UsePreloadFileUrlProps) => {
  // threshold = 0.1 only preloads when the element is 10% visible
  const entry = useIntersectionObserver(ref, { threshold: 0.1 })

  hooks.trpc().doc.fileUrl.useQueryWithCorp(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    { cryptId: cryptId! },
    {
      ...s3FileUrlQueryCacheConfig,
      enabled: !!enabled && !!cryptId && !!entry?.isIntersecting,
    }
  )
}

export interface DocCardProp {
  doc?: ZAugmentedDocWithHighlights
  isLoading?: boolean
  docState?: DocCardStateProps
}

export const DocCard: React.FC<DocCardProp> = (props) => {
  const ref = React.useRef<HTMLDivElement>(null)
  usePreloadFileUrl({ ref, enabled: !props.isLoading, cryptId: props.doc?.cryptId })

  return (
    <ImageFrame ref={ref} {...props}>
      {props.isLoading ? <SkeletonContent /> : <DocCardData doc={props.doc} />}
    </ImageFrame>
  )
}
