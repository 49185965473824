import { Table } from '@mantine/core'
import React from 'react'
import type { DetailTableProps } from '~/client/components/relation/detail/types'
import { MetadataRow } from '~/client/components/util/metadata'
import type { ZUpdateFundraising } from '~/common/schema/relation'
import { ZAugmentedFundraising } from '~/common/schema/relation'

export const FundraisingMetadataTable: React.FC<
  DetailTableProps<ZUpdateFundraising, ZAugmentedFundraising>
> = ({ data, update, size }) => (
  <Table striped>
    <Table.Tbody>
      <MetadataRow
        displayProps={ZAugmentedFundraising.shape.round.mkDisplayProps({
          data,
          update,
          property: 'round',
        })}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedFundraising.shape.startDate.mkDisplayProps({
          data,
          update,
          property: 'startDate',
          autofillData: data.autofill.startDates,
        })}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedFundraising.shape.shares.mkDisplayProps({
          data,
          update,
          property: 'shares',
        })}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedFundraising.shape.sharePrice.mkDisplayProps({
          data,
          update,
          property: 'sharePrice',
        })}
        size={size}
      />
    </Table.Tbody>
  </Table>
)
