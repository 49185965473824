import { z } from 'zod'

export const ZCardBrand = z
  .enum(['visa', 'mastercard', 'amex', 'discover', 'diners', 'unionpay'])
  .optional()
export type ZCardBrand = z.infer<typeof ZCardBrand>

const ZCardPaymentMethod = z.object({
  type: z.literal('card'),
  last4: z.string(),
  /** https://docs.stripe.com/api/payment_methods/object#payment_method_object-card-brand */
  brand: ZCardBrand,
})
type ZCardPaymentMethod = z.infer<typeof ZCardPaymentMethod>

// We want to support bank transfers in the future, and we will make it a union then.
export const ZPaymentMethod = ZCardPaymentMethod
export type ZPaymentMethod = z.infer<typeof ZPaymentMethod>
