import * as Sentry from '@sentry/nextjs'
import React from 'react'
import { useCorpCryptIdSafe } from '~/client/lib/hooks/corp'

export const useCorpCryptIdTag = (): void => {
  const corpCryptIdResult = useCorpCryptIdSafe()
  React.useEffect(() => {
    if (!corpCryptIdResult) {
      Sentry.setTag('corpCryptId', undefined)
      return
    }
    Sentry.setTag('corpCryptId', corpCryptIdResult.corpCryptId.idStr)
  }, [corpCryptIdResult])
}

export type UseCorpCryptIdTag = typeof useCorpCryptIdTag
