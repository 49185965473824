import { Notifications } from '@mantine/notifications'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { DocDetailStateProvider } from '~/client/components/doc-detail/state'
import { zIndex } from '~/client/components/z-index'
import type { Hooks } from '~/client/lib/hooks'
import { ImplementationProvider } from '~/client/lib/hooks'
import { defaultHooksMock } from '~/client/lib/hooks-mock'
import { Theme } from './theme'

export const mockQueryClient = new QueryClient()

export const TestProvider: React.FC<{
  /** Is merged with defaultHooksMock */
  hooksMock?: Partial<Hooks>
}> = ({ hooksMock, children }) => (
  /* eslint-disable-next-line react/jsx-pascal-case */
  <ImplementationProvider.__UNSAFE_Partial
    implementation={{
      ...defaultHooksMock,
      ...hooksMock,
    }}
  >
    <QueryClientProvider client={mockQueryClient}>
      <Theme>
        <Notifications zIndex={zIndex.notification} />
        <DocDetailStateProvider>{children}</DocDetailStateProvider>
      </Theme>
    </QueryClientProvider>
  </ImplementationProvider.__UNSAFE_Partial>
)
