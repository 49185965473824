import type { ZImpreciseDate } from '~/common/schema'
import { AugmentedDocImpreciseDate } from '~/common/schema'

interface DisplayDateProp {
  dateImprecise: ZImpreciseDate | undefined
}

export const DisplayDate: React.FC<DisplayDateProp> = ({ dateImprecise }) => {
  if (!dateImprecise) {
    return null
  }

  return (
    <>
      Date: <b>{AugmentedDocImpreciseDate.display(dateImprecise)}</b>
    </>
  )
}
