import { Skeleton, Text, ThemeIcon } from '@mantine/core'
import { IconFileAlert } from '@tabler/icons-react'
import React from 'react'
import { theme } from '~/client/lib/theme'
import type { ZAugmentedDoc } from '~/common/schema'

interface ImagePlaceholderProps {
  doc?: ZAugmentedDoc
  isLoading?: boolean
}

export const ImagePlaceholder: React.FC<ImagePlaceholderProps> = ({ doc, isLoading }) => {
  if (isLoading) return <Skeleton width='100%' height='100%' />
  if (!doc)
    return (
      <ThemeIcon color='urgent' size={40}>
        <IconFileAlert size={40} />
      </ThemeIcon>
    )
  // If doc exists and has no thumbnail
  return <Text c={theme.colors.gray[6]}>No Thumbnail</Text>
}
