import { Badge } from '@mantine/core'
import React from 'react'
import type { ZRelationTypeValues } from '~/common/schema/relation'
import { typeAugmentedRelationMap } from '~/common/schema/relation'

interface CommonDisplayProps {
  isInactive?: boolean
  compact?: boolean
}
const BaseBadge: React.FC<{ isInactive?: boolean; badgeText: string }> = ({
  isInactive,
  badgeText,
}) => <Badge color={isInactive ? 'inactive' : 'primary'}>{badgeText}</Badge>
interface RelationTypeBadgeDisplayProps extends CommonDisplayProps {
  type: ZRelationTypeValues
}

export const RelationTypeBadgeDisplay: React.FC<RelationTypeBadgeDisplayProps> = ({
  type,
  compact,
  isInactive,
}) => (
  <BaseBadge
    badgeText={compact ? type : typeAugmentedRelationMap[type].display}
    isInactive={isInactive}
  />
)

export const OrgInfoTypeBadgeDisplay: React.FC<CommonDisplayProps> = ({ compact, isInactive }) => (
  <BaseBadge badgeText={compact ? 'CORP' : 'Organizational Info'} isInactive={isInactive} />
)
