import type { UseQueryResult } from '@tanstack/react-query'
import { TRPCClientError } from '@trpc/client'
import { useAuthStore } from '~/client/lib/hooks/auth'
import { hooks } from '~/client/lib/hooks/dependency-injection/interface'
import { useJwtStore } from '~/client/lib/hooks/jwt-store'
import { ZCorpAuthJwtPayload, decodeJwtPayloadSafe } from '~/common/jwt-payload'
import { zenvCommon } from '~/common/zenv-common'

/**
 * (This hook is not exported outside of the hooks folder, as we want to wrap it
 * in another hook that allows users to preview other roles, i.e. useCurrentCorpAuth)
 *
 * Fetches the current Corp Auth JWT and keeps it up to date.
 *
 * Uses a useQuery to refresh whenever corpCryptId or Firebase idToken changes.
 * Works as long as the corp auth and Firebase idToken tokens has the same
 * expiration (1 hour).
 *
 * If authorization fails, the endpoint will return `{ success: false }` rather
 * than throw an exception. This is to prevent the tRPC client from retrying
 * when encountering the exception.
 */
export const useCurrentCorpJWT = (): UseQueryResult<ZCorpAuthJwtPayload | undefined, unknown> => {
  const { corpCryptId } = hooks.useCorpCryptIdSafe() ?? {}
  const user = useAuthStore((state) => state.user)
  const { email, emailVerified } = user ?? {}
  return hooks.trpc().corpAuthJwt.useQuery(
    {
      //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      corpCryptId: corpCryptId!,
      //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      email: email!,
      //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      emailVerified: emailVerified!,
    },
    {
      // Do not remove from cache to make error reports easier to debug.
      // Removing it can cause us to report no JWT and we would not be able to
      // identify if we did not issue it, or we just removed it from cache.
      cacheTime: Infinity,
      // Invalidate the query 20 seconds before the JWT expires to refetch a valid one.
      staleTime: (zenvCommon.NEXT_PUBLIC_CORP_AUTH_JWT_EXPIRATION_SECONDS - 20) * 1_000,
      meta: { noGlobalInvalidation: true },
      enabled: !!corpCryptId && !!email && !!emailVerified,
      // We use placeholderData here because
      // 1. placeholderData is not cached  (but initialData is cached)
      // 2. from #1, this will return the latest available corpAuthJwt token
      //    while it's fetching new corpAuthJwt token from server
      placeholderData: () => ({
        success: true,
        jwt: useJwtStore.getState().corpAuthJwt ?? '',
      }),
      select: (_data) => {
        if (!_data.success) {
          // This does not trigger tRPC request retry logic, so it's safe to
          // throw the error here
          throw new TRPCClientError('No permission to corp')
        }
        if (_data.jwt) {
          // Make sure jwt is not empty string
          useJwtStore.setState({ corpAuthJwt: _data.jwt })
        }
        return decodeJwtPayloadSafe(ZCorpAuthJwtPayload, _data.jwt)
      },
    }
  )
}
