import type { CryptId } from '@cryptid-module'
import type { UseFormReturnType } from '@mantine/form'
// eslint-disable-next-line custom-rules/no-use-debounced-value
import { useDebouncedValue } from '@mantine/hooks'
import type { ZDocForm } from '~/client/components/doc-detail/state'
import { hooks } from '~/client/lib/hooks/dependency-injection/interface'
import type { AutocheckResult } from '~/common/autocheck'
import { computeAutoCheck } from '~/common/autocheck'
import type { DocAutofill } from '~/common/enhance'

interface AutocheckProps {
  form: UseFormReturnType<ZDocForm>
  autofill?: DocAutofill
  autofillWithHiddenSuggestions?: DocAutofill
  enabled: boolean
  docCryptId: CryptId | undefined
}

export const useAutocheck = ({
  enabled,
  form,
  autofill,
  autofillWithHiddenSuggestions,
  docCryptId,
}: AutocheckProps): AutocheckResult => {
  const { data: dismissed } = hooks.trpc().autocheck.list.useQuery(
    {
      docCryptId,
      dismissed: true,
      limit: 50,
    },
    { enabled: !!docCryptId && enabled }
  )

  // Wait for the user to finish typing
  const [values] = useDebouncedValue(form.values, 200, {
    leading: false, // Immediately update on first change
  })

  if (!enabled || !autofillWithHiddenSuggestions || !autofill || !dismissed)
    return {
      autocheckAllOk: true,
      autocheckFieldResults: {
        title: 'ok',
        party: 'ok',
        email: 'ok',
        date: 'ok',
        type: 'ok',
      },
    }

  return computeAutoCheck({
    values,
    autofill,
    autofillWithHiddenSuggestions,
    dismissedFields: dismissed.data.map((issue) => issue.metadataField),
  })
}
