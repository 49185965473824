import type { AppShellProps } from '@mantine/core'
import { AppShell, useMantineTheme } from '@mantine/core'
import React from 'react'
import { appHeaderHeight } from '~/client/components/layout/header'
import { useLayoutStore } from '~/client/components/layout/state'
import { useRibbonHeight } from '~/client/components/util/ribbon'

export interface AerialAppShellProps extends Omit<AppShellProps, 'header' | 'navbar'> {
  header: React.ReactNode
  nav?: React.ReactNode
}

export const AerialAppShell: React.FC<AerialAppShellProps> = ({
  header,
  nav,
  children,
  ...props
}) => {
  const { other } = useMantineTheme()
  const { isNavbarOpen } = useLayoutStore()
  const ribbonHeight = useRibbonHeight()

  return (
    <AppShell
      data-testid='app-shell'
      header={{ height: appHeaderHeight + ribbonHeight }}
      navbar={
        nav
          ? { width: other.navbarWidth, breakpoint: 'md', collapsed: { mobile: !isNavbarOpen } }
          : undefined
      }
      padding='xl'
      {...props}
    >
      {header}
      {nav || null}
      <AppShell.Main>{children}</AppShell.Main>
    </AppShell>
  )
}
