import { useRouter } from 'next/router'
import React from 'react'
import { ClientNotifiableError } from '~/client/components/monitoring/client-notifiable-error'
import { notifyError } from '~/client/components/monitoring/handle-error'

export const useQueryParamErrorConsumer = (): void => {
  const router = useRouter()
  React.useEffect(() => {
    const { sentryEventId, errorMessage, ...otherQueryParams } = router.query
    if (typeof sentryEventId === 'string' && typeof errorMessage === 'string') {
      notifyError({
        sentryEventId,
        error: new ClientNotifiableError(errorMessage),
      })
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.replace(
        {
          query: otherQueryParams,
        },
        undefined,
        { shallow: true, scroll: false }
      )
    }
  }, [router])
}
