const mkSentryUrls = () => {
  const sentryDSN = process.env.NEXT_PUBLIC_SENTRY_DSN
  // Undefined values will be caught by zenv. Returning on empty allows this to
  // work on development environments.
  if (!sentryDSN) return []

  const [_fullString, _publicKey, host, projectId] = sentryDSN.match(
    /https:\/\/(.*?)@(.*?)\.ingest\.sentry\.io\/(.*)/
  )
  if (!_fullString || !_publicKey || !host || !projectId) {
    throw new Error('Could not parse Sentry DSN')
  }
  // wildcard subdomain matching is required, but we also need to match with no subdomain
  return [
    `https://*.${host}.ingest.sentry.io/api/${projectId}/`,
    `https://*.${host}.ingest.sentry.io/${projectId}/`,
    `https://${host}.ingest.sentry.io/api/${projectId}/`,
    `https://${host}.ingest.sentry.io/${projectId}/`,
  ]
}

const connectSources = [
  // We cannot match to blob:https://URL as those result in invalid directives.
  'blob:',
  'https://*.googleapis.com/',
  'https://*.google.com/',
  process.env.DATA_API_URL,
  process.env.MOCK_S3_ENDPOINT ?? '',
  `https://${process.env.NEXT_PUBLIC_AWS_BUCKET}.s3.${process.env.NEXT_PUBLIC_AWS_REGION}.amazonaws.com/`,
  ...mkSentryUrls(),
  'https://api-js.mixpanel.com/',
  'https://www.gstatic.com/',
  'https://graph.microsoft.com/',
  'https://lh3.googleusercontent.com',
  'https://*.stripe.com',
].filter(Boolean)

// Enable unsafe-eval on development as it is required for it to work
const enableUnsafeEvalScriptSrc =
  process.env.NEXT_PUBLIC_ENABLE_UNSAFE_EVAL_SCRIPT_SRC === 'true' ? "'unsafe-eval'" : ''

const mkScriptSrc = ({ unsafeScriptInline }) => {
  const sources = [
    "'self'",
    enableUnsafeEvalScriptSrc,
    unsafeScriptInline ? "'unsafe-inline'" : '',
    ...connectSources,
  ]
    .filter(Boolean)
    .join(' ')

  return `script-src ${sources}`
}

/**
 *  @param {Object} options
 *  @param {boolean} [options.unsafeScriptInline]
 *  @returns {string}
 */
const mkCspValues = ({ unsafeScriptInline } = {}) =>
  [
    // Sensible Content Security Policy values taken from
    // https://trezy.gitbook.io/next-safe/configuration-options/contentsecuritypolicy
    "base-uri 'none'",
    `connect-src 'self' ${connectSources.join(' ')}`,
    "font-src 'self'",
    "form-action 'self'",
    "frame-ancestors 'self'",
    "manifest-src 'self'",
    "media-src 'self'",
    "object-src 'none'",
    // 'unsafe-inline' is not the strictest policy we could set, but needed to style our page.
    // Without it, some styles cannot be applied, as we use inline styles in our app. This
    // limitation also includes third party integrations like the LiveChat widget.
    "style-src 'self' 'unsafe-inline'",
    mkScriptSrc({ unsafeScriptInline }),
  ]
    .filter(Boolean)
    .join('; ')

module.exports = { mkCspValues, mkScriptSrc }
