import { z } from 'zod'
import {
  AugmentedMetadataDate,
  AugmentedMetadataNumber,
  AugmentedMetadataPrice2,
  AugmentedMetadataUsePool,
  ZParty,
  withDisplaySchema,
} from '~/common/schema'
import { util } from '~/common/schema/relation/util'

const commonAugmentedShape = {
  type: z.literal('COMMON'),
  party: withDisplaySchema(ZParty, 'party', 'Equity Holder'),
  shares: withDisplaySchema(AugmentedMetadataNumber.optional(), 'number', 'Shares'),
  investment: withDisplaySchema(AugmentedMetadataPrice2.optional(), 'price2', 'Investment'),
  sharesFromPool: withDisplaySchema(
    AugmentedMetadataUsePool.optional(),
    'pool',
    'Shares From Plan'
  ),
  startDate: withDisplaySchema(AugmentedMetadataDate.optional(), 'date', 'Issue Date'),
  endDate: withDisplaySchema(AugmentedMetadataDate.optional(), 'date', 'Cancellation Date'),
}

export const { ZUpdate: ZUpdateCommon, ZAugmented: ZAugmentedCommon } = util.createSchemas(
  {
    updateShape: commonAugmentedShape,
    augmentedShape: commonAugmentedShape,
    defaultValue: { party: { name: '' } },
  },
  {
    requiredTypes: [
      'COMMON_STOCK_PURCHASE_AGREEMENT', // Primary Document
      'SECTION_83B_ELECTION_FORM',
      'BOARD_CONSENT_AND_MINUTES',
    ],
    optionalTypes: ['MISCELLANEOUS', 'STOCKHOLDER_CONSENT', 'ADVISOR_AGREEMENT'],
    display: 'Common Stock',
    displayFn: (obj) => `Common Shares ${obj.party.name}`,
    identifyingField: 'party.name',
    tooltipContentFn: (obj, shape) => [
      [shape.party.displaySchema.display, ZParty.display(obj.party)],
      [shape.startDate.displaySchema.display, AugmentedMetadataDate.display(obj.startDate?.value)],
      [shape.endDate.displaySchema.display, AugmentedMetadataDate.display(obj.endDate?.value)],
      [shape.shares.displaySchema.display, AugmentedMetadataNumber.display(obj.shares?.value)],
      [
        shape.investment.displaySchema.display,
        AugmentedMetadataPrice2.display(obj.investment?.value),
      ],
      [
        shape.sharesFromPool.displaySchema.display,
        AugmentedMetadataUsePool.display(obj.sharesFromPool?.value),
      ],
    ],
  }
)
export interface ZUpdateCommon extends z.infer<typeof ZUpdateCommon> {}
export interface ZAugmentedCommon extends z.infer<typeof ZAugmentedCommon> {}
