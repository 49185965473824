import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'
import { FirebaseError } from 'firebase/app'
import Router from 'next/router'
import React from 'react'
import { z } from 'zod'
import { auth } from '~/client/lib/firebase'
import { attemptedProviderSessionStorageKey } from '~/client/lib/firebase-auth'
import { DisposableEmailError, useEmailIsDisposable } from '~/client/lib/hooks/auth/disposable'
import { hooks } from '~/client/lib/hooks/dependency-injection/interface'
import type { ZExternalProviderId } from '~/client/lib/wrong-auth-provider-error'
import { zenvCommon } from '~/common/zenv-common'

export const ZEmailPassword = z.object({
  email: z.string().email(),
  password: z.string().min(8),
})
export interface ZEmailPassword extends z.infer<typeof ZEmailPassword> {}

export type LoginMutation = UseMutationResult<void, unknown, string | undefined, unknown>

export interface UseAuthInteractionsRtn {
  logout: (options?: { hardReload?: boolean }) => Promise<void>
  emailSignupMutation: UseMutationResult<void, unknown, ZEmailPassword, unknown>
  emailLoginMutation: UseMutationResult<void, unknown, ZEmailPassword, unknown>
  useExternalProviderMutation: (provider: ZExternalProviderId) => LoginMutation
}

// convenience function to coerce return type to void; hard to avoid with
// finicky useMutation typing
const noOp = (): void => {}

export const useAuthInteractions = (
  onFirebaseError: (err: unknown) => Promise<void>
): UseAuthInteractionsRtn => {
  const { emailIsDisposable } = useEmailIsDisposable()
  const { mutateAsync: revokeTokens } = hooks.trpc().user.revokeTokens.useMutation()

  const logout = React.useCallback(
    async ({ hardReload } = {}) => {
      // WARNING: do not place logic to clear user data here.  Instead, put it in
      // auth.onIdTokenChanged.  This is more robust.
      if (zenvCommon.NEXT_PUBLIC_ENABLE_TOKEN_REVOKE) await revokeTokens()
      await auth.signOut()

      // Performing a hard-navigation to avoid errors from Firebase calls completing
      // in the next page
      if (hardReload) window.location.href = '/login'
      else await Router.push('/login')
    },
    [revokeTokens]
  )

  const emailLoginMutation = useMutation(({ email, password }: ZEmailPassword) =>
    auth.signInWithEmailAndPassword(email, password).catch(onFirebaseError).then(noOp)
  )

  const sendVerificationEmailIfUnverifiedMutation = hooks
    .trpc()
    .sendVerificationEmailIfUnverified.useMutation()
  const sendNewAccountCreatedEmails = hooks.trpc().sendNewAccountCreatedEmails.useMutation()

  const useExternalProviderMutation = (provider: ZExternalProviderId) =>
    useMutation(
      async (email?: string) => {
        try {
          // Store the provider in the session storage to handle
          // wrongAuthProvider errors. as redirect sign in cannot be handled here.
          // Don't use localStorage, as we don't need to persist this if the tab is closed
          window.sessionStorage.setItem(attemptedProviderSessionStorageKey, provider)
          // The execution will break out of this function due to the redirect, and
          // the result is handled in a useEffect calling `getRedirectResult`.
          await auth.signInWithExternalProvider(provider, email)
        } catch (error) {
          if (error instanceof FirebaseError) {
            await onFirebaseError(error)
            return
          }
          // throw if error is not from firebase
          throw error
        }
      },
      { meta: { noErrorNotification: true } }
    )

  const emailSignupMutation = useMutation<void, unknown, ZEmailPassword>({
    mutationFn: async ({ email, password }) => {
      const disposableEmail = await emailIsDisposable(email)
      if (disposableEmail) throw new DisposableEmailError()

      try {
        await auth.createUserWithEmailAndPassword(email, password)
      } catch (error) {
        // if account already exists, continue processing
        if (error instanceof FirebaseError && error.code === 'auth/email-already-in-use') {
          await emailLoginMutation.mutateAsync({ email, password })
          return
        }
        // Indicates login successful but MFA authentication is required; this
        // error is handled elsewhere
        if (error instanceof FirebaseError && error.code === 'auth/multi-factor-auth-required') {
          throw error
        }
        // Continue throwing for error reporting and user notification
        throw error
      }

      await sendVerificationEmailIfUnverifiedMutation.mutateAsync()
      await sendNewAccountCreatedEmails.mutateAsync()
      await emailLoginMutation.mutateAsync({ email, password })
    },
  })
  return {
    logout,
    emailSignupMutation,
    emailLoginMutation,
    useExternalProviderMutation,
  }
}
