import type { UseFormReturnType } from '@mantine/form'
import type { LooseKeys } from '@mantine/form/lib/types'
import React from 'react'
import type { ZDocForm } from '~/client/components/doc-detail/state'
import { useDocDetailState } from '~/client/components/doc-detail/state'
import type { DocAutofill } from '~/common/enhance'
import { getObjectPropertyByString, objectKeysTypesafe } from '~/common/util'

interface PopulateField {
  autofillKey: keyof DocAutofill
  autofill: DocAutofill
  form: UseFormReturnType<ZDocForm, (values: ZDocForm) => ZDocForm>
  hasPreselectedType: boolean
}

interface FillIfAppropriate extends Pick<PopulateField, 'form' | 'hasPreselectedType'> {
  // Provides autocompletion for `keyof ZDocForm` while allowing values like `party.name`
  formField: LooseKeys<ZDocForm>
  autofillValue: NonNullable<DocAutofill[keyof DocAutofill]>[number]
}
const fillIfAppropriate = ({
  form,
  formField,
  autofillValue,
  hasPreselectedType,
}: FillIfAppropriate) => {
  // we don't want to override if the user already interacted with the field
  const isFieldTouched = form.isTouched(formField)
  const formValue = getObjectPropertyByString(form.values, formField)
  const isFieldFilled = formField === 'type' ? hasPreselectedType : !!formValue
  if (isFieldFilled || isFieldTouched) return

  form.setFieldValue(formField, autofillValue)
}

const populateField = ({ autofillKey, autofill, ...props }: PopulateField) => {
  const autofillValue = autofill[autofillKey][0]
  if (autofillValue === undefined) return

  switch (autofillKey) {
    case 'titles': {
      return fillIfAppropriate({ formField: 'title', autofillValue, ...props })
    }
    case 'parties': {
      const partyName = autofill[autofillKey][0]?.name
      if (!partyName) return
      return fillIfAppropriate({ formField: 'party.name', autofillValue: partyName, ...props })
    }
    case 'dates': {
      return fillIfAppropriate({ formField: 'dateImprecise.date', autofillValue, ...props })
    }
    case 'types': {
      return fillIfAppropriate({ formField: 'type', autofillValue, ...props })
    }
    case 'emails': {
      return fillIfAppropriate({ formField: 'party.email', autofillValue, ...props })
    }
  }
}
/** Will fill the form with the first value from the autofill, if enabled */
export const usePopulateFormWithAutofill = (): void => {
  const enablePopulateForm = useDocDetailState((state) => state.enablePopulateForm)
  const hasPreselectedType = useDocDetailState((state) => !!state.preselectedType)
  const form = useDocDetailState((state) => state.form)
  const autofill = useDocDetailState((state) => state.autofill)

  React.useEffect(() => {
    // Populate with first autofill value
    if (enablePopulateForm && autofill) {
      const autofillKeys = objectKeysTypesafe(autofill)

      autofillKeys.forEach((autofillKey) => {
        populateField({ autofillKey, autofill, form, hasPreselectedType })
      })
    }
  }, [autofill, enablePopulateForm, form, hasPreselectedType])
}
