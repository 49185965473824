import { hooks } from '~/client/lib/hooks'
import { mkSafeFileName } from '~/client/lib/util'
import { mkDocFileName } from '~/common/doc'
import type { EnhancedDoc } from '~/common/enhance'
import type { ZAugmentedCorp, ZMinimalDoc } from '~/common/schema'
import { docTypeStr } from '~/common/schema'
import { FetchError, fetchRetry } from '~/common/util'

export interface ZipFile {
  fileName: string
  content: Blob
  onSuccess?: () => void
  onError?: () => void
}

export type ZipFilesMap = Map<string, ZipFile[]>

export const addZipFile = (filesMap: ZipFilesMap, folder: string, file: ZipFile): void => {
  const prev = filesMap.get(folder) ?? []
  filesMap.set(folder, [...prev, file]) // Adds to the file array
}

export const countDocsInFolders = (
  filesMap: ZipFilesMap
): { countsMap: Map<string, number>; rootCount: number } => {
  // Keep track of how many documents each folder contains. It uses the folder's name as key (this is safe because of the unique indexes)
  const countsMap = new Map<string, number>()
  let rootCount = 0
  filesMap.forEach((files, fullPath) => {
    const parts = fullPath.split('/')
    const filesCount = files.filter((file) => file.fileName.endsWith('.pdf')).length // Only count PDF files

    // Increment the document count to the folder and all its parents
    parts.forEach((subPath) => {
      const prev = countsMap.get(subPath) ?? 0
      countsMap.set(subPath, prev + filesCount)
    })
    rootCount += filesCount // Do the same thing for the root folder
  })
  return { countsMap, rootCount }
}

export const getPathWithCounts = (
  folderCounts: Map<string, number>,
  folderPath: string
): string => {
  return folderPath
    .split('/')
    .map((subPath) => `${subPath} (${folderCounts.get(subPath)})`)
    .join('/')
}

export const useZipDownload = (
  currentCorp?: ZAugmentedCorp
): { download: (blob?: Blob, corp?: ZAugmentedCorp) => Promise<void> } => {
  const data = hooks.useZipFileStore((state) => state.data)

  const download = async (blob?: Blob, corp = currentCorp) => {
    const FileSaver = await import('file-saver')
    const file = blob ?? data

    if (file) {
      FileSaver.default(
        file,
        mkSafeFileName(
          // fr-CA locale uses YYYY-MM-DD format
          `${corp?.name.value}-aerial-data-room-${new Date().toLocaleDateString('fr-CA')}.zip`
        ),
        { autoBom: false }
      )
    }
  }

  return { download }
}

export interface DownloadFileCallback {
  docCryptIdStr?: string
  callback: () => Promise<void>
}

interface AddDocsToFolderProps {
  docs: (EnhancedDoc<ZMinimalDoc> | undefined)[]
  filesMap: ZipFilesMap
  folderName: string
  relationDisplay?: string
  relationLink?: string
}

export const useAddDocsToFolder = (): {
  addDocsToFolder: (input: AddDocsToFolderProps) => DownloadFileCallback[]
} => {
  const addFailedDocument = hooks.useZipFileStore((state) => state.addFailedDocument)
  const addSuccessfulDocument = hooks.useZipFileStore((state) => state.addSuccessfulDocument)
  const fetchFileUrl = hooks.trpc().doc.fileUrl.useFetchWithCorp()

  const addDocsToFolder = ({
    docs,
    filesMap,
    folderName,
    relationDisplay,
    relationLink,
  }: AddDocsToFolderProps): DownloadFileCallback[] => {
    // If we returned Promises directly, the downloads would start before we could limit them
    const prepareCallback = (doc?: ZMinimalDoc) => async () => {
      if (!doc) return
      const typeString = docTypeStr(doc.type)
      const mkProcessedDocStatus = (tooltip?: string) => ({
        documentDisplay: typeString,
        documentLink: doc.url,
        relationDisplay,
        relationLink,
        tooltip,
      })
      // Use try so that one bad file does not stop the entire download process
      try {
        const { file: fileUrl } = await fetchFileUrl({ cryptId: doc.cryptId })
        const fileResult = await fetchRetry(fileUrl)

        const file = await fileResult.blob()
        const fileName = mkDocFileName(doc)

        addZipFile(filesMap, folderName, {
          fileName,
          content: file,
          onSuccess: () => addSuccessfulDocument(mkProcessedDocStatus()),
          onError: () => addFailedDocument(mkProcessedDocStatus('Unable to add document to zip')),
        })
      } catch (error) {
        if (error instanceof FetchError) {
          return addFailedDocument(mkProcessedDocStatus('Unable to fetch document'))
        }

        return addFailedDocument(mkProcessedDocStatus('Unable to add document for unknown reasons'))
      }
    }

    return docs.map((doc) => {
      return {
        docCryptIdStr: doc?.cryptId.idStr,
        callback: prepareCallback(doc),
      }
    })
  }

  return { addDocsToFolder }
}
