import { z } from 'zod'

const _externalProviderIds = ['google.com', 'microsoft.com'] as const
export const ZExternalProviderId = z.enum(_externalProviderIds)
export type ZExternalProviderId = z.infer<typeof ZExternalProviderId>

const _availableProviderIds = [..._externalProviderIds, 'password'] as const
export type AuthProviderId = (typeof _availableProviderIds)[number]

export const isSupportedAuthProvider = (provider: string): provider is AuthProviderId => {
  return _availableProviderIds.includes(provider)
}

export class WrongAuthProviderError extends Error {
  readonly name = 'WrongAuthProviderError'
  constructor(
    public readonly email: string,
    public readonly attemptedProvider: AuthProviderId,
    public readonly supportedProviders: AuthProviderId[],
    public readonly unsupportedProviders: string[]
  ) {
    super()
  }

  get message(): string {
    switch (this.supportedProviders.at(0)) {
      case 'google.com':
        return `This email address is associated with a Google login`
      case 'microsoft.com':
        return `This email address is associated with a Microsoft login`
      case 'password':
        // We are keeping this case to be complete,
        // but this can't open email/password login will be migrated to
        // 3rd party login (like google.com) automatically when the user used
        // 3rd party login with the same email address
        return `${this.email} is associated with an email/password login`
      default:
        return `${this.email} is associated with an unsupported login service`
    }
  }

  get data(): object {
    return {
      email: this.email,
      attemptedProvider: this.attemptedProvider,
      supportedProviders: this.supportedProviders.join(','),
      unsupported: this.unsupportedProviders.join(','),
    }
  }
}
