import type { Icon } from '@tabler/icons-react'
import { IconLayoutDashboard, IconSettingsAutomation, IconShare } from '@tabler/icons-react'
import { ActiveRedFlagIcon } from '~/common/red-flags'

interface PathInfo {
  name: string
  path: string
  icon: Icon
  disabled?: boolean
}

interface SubPath extends Omit<PathInfo, 'icon' | 'rightSection'> {}

interface PathContainer {
  name: string
  icon: Icon
  paths: readonly SubPath[]
}

type WithoutIndexRoute = PathInfo | PathContainer

export const openAddUserModalQueryKey = 'add-user'

export const adminAndSettingsRoutes = {
  name: 'Admin & Settings',
  icon: IconSettingsAutomation,
  paths: [
    { name: 'Plan and Billing', path: 'company-and-billing' },
    { name: 'Permissions', path: 'permissions' },
    { name: 'Integrations', path: 'integrations' },
    { name: 'History', path: 'history', disabled: true },
    { name: 'Download Data Room', path: 'data-room' },
  ],
} as const satisfies Readonly<PathContainer>

const _withoutIndexRoutes = [
  {
    path: 'dashboard',
    name: 'Dashboard',
    icon: IconLayoutDashboard,
  },
  {
    path: 'red-flags',
    name: 'Red Flags',
    icon: ActiveRedFlagIcon,
  },
  adminAndSettingsRoutes,
  {
    path: `permissions?${openAddUserModalQueryKey}=1`,
    name: 'Share Data Room',
    icon: IconShare,
  },
] as const satisfies Readonly<WithoutIndexRoute[]>

/** Adds a default `disabled: false` to all paths in a route */
const mkRoutesWithDisabledProp = (routes: typeof _withoutIndexRoutes) => {
  return routes.map((route) => {
    if ('paths' in route) {
      return {
        ...route,
        paths: route.paths.map((path) => {
          if ('disabled' in path) {
            return path
          }
          return {
            ...path,
            disabled: false,
          }
        }),
      }
    }
    return route
  })
}

export const withoutIndexRoutes = mkRoutesWithDisabledProp(_withoutIndexRoutes)

export const withoutIndexesPaths = withoutIndexRoutes.flatMap((route) => {
  if ('path' in route) {
    return [route.path]
  }
  return route.paths.map((path) => path.path)
})

export type WithoutIndexesPath = (typeof withoutIndexesPaths)[number]

export type WithoutIndexesNames = (typeof withoutIndexRoutes)[number]['name']

export type WithoutIndexesRouteData = SubPath | PathInfo

// eslint-disable-next-line custom-rules/prefer-map-to-object-from-entries, custom-rules/no-bad-casting-in-declaration
export const withoutIndexesRouteMap = Object.fromEntries(
  withoutIndexRoutes.flatMap((route: WithoutIndexRoute) => {
    if ('path' in route) {
      return [[route.path, route]]
    }
    return route.paths.map((subpath) => [subpath.path, subpath])
  })
) as Record<WithoutIndexesPath, WithoutIndexesRouteData>
