import { Button, Group } from '@mantine/core'
import { IconUpload } from '@tabler/icons-react'
import React from 'react'
import { useMultiDocModalStore, useMultiDocStore } from './state'

export interface UploadResultButtonsProps {
  /** Callback to be called when user clicks "Upload more". Defaults to changing
   * modal state to doc-drop, but can be overwritten. Will always reset file statuses. */
  onUploadMoreClick?: () => void
  withCloseButton?: boolean
}

export const UploadResultButtons: React.FC<UploadResultButtonsProps> = ({
  onUploadMoreClick,
  withCloseButton = true,
}) => {
  const globalUploadStatus = useMultiDocStore((state) => state.globalUploadStatus)
  const closeModal = useMultiDocModalStore((state) => state.closeModal)
  const updateModalState = useMultiDocModalStore((state) => state.updateState)

  const setFileStatuses = useMultiDocStore((state) => state.setFileStatuses)

  const disabled = globalUploadStatus === 'uploading'
  const onUploadMore = () => {
    setFileStatuses([])

    if (onUploadMoreClick) {
      onUploadMoreClick()
      return
    }
    updateModalState('doc-drop')
  }

  return (
    <Group gap='md'>
      <Button
        color='primary'
        onClick={onUploadMore}
        leftSection={<IconUpload />}
        disabled={disabled}
        data-testid='upload-more-button'
      >
        Upload More
      </Button>
      {withCloseButton && (
        <Button
          color='primary'
          data-testid='close-upload-modal'
          onClick={() => closeModal()}
          disabled={disabled}
        >
          Close
        </Button>
      )}
    </Group>
  )
}
