import type {
  CancelOptions,
  InfiniteData,
  InvalidateOptions,
  InvalidateQueryFilters,
  Query,
  SetDataOptions,
  Updater,
} from '@tanstack/react-query'
import { type TRPCClientError } from '@trpc/client'
import { type QueryKeyKnown } from '@trpc/react-query/dist/internals/getArrayQueryKey'
import type {
  AnyQueryProcedure,
  DeepPartial,
  inferProcedureInput,
  inferProcedureOutput,
} from '@trpc/server'
import type { inferTransformedProcedureOutput } from '@trpc/server/shared'

export interface OptimisticOptions<TMutationInput, TOldData> {
  onMutate: (input: TMutationInput) => Promise<{ oldData?: TOldData }>
  onError: (err: unknown, input: TMutationInput, ctx?: { oldData?: TOldData }) => void
}

export const optimisticMutationOptions = <TProcedure extends AnyQueryProcedure, TMutationInput>(
  queryUtils: QueryProcedureUtilsLike<TProcedure>,
  queryParams: inferProcedureInput<TProcedure> extends DeepPartial<inferProcedureInput<TProcedure>>
    ? inferProcedureInput<TProcedure> | undefined
    : undefined,
  dataUpdater: (
    mutationInput: TMutationInput,
    oldData?: inferProcedureOutput<TProcedure> | undefined
  ) => inferProcedureOutput<TProcedure> | undefined
): OptimisticOptions<TMutationInput, inferProcedureOutput<TProcedure>> => {
  return {
    onMutate: async (input) => {
      await queryUtils.cancel(queryParams)
      const oldData = queryUtils.getData(queryParams)
      queryUtils.setData(queryParams, (data) => dataUpdater(input, data))
      return { oldData }
    },
    onError: (err, input, ctx) => {
      queryUtils.setData(queryParams, ctx?.oldData)
    },
    // There is no need to refetch here, as we invalidate queries if it succeeds
  }
}

export const optimisticMutationOptionsInfinite = <
  TProcedure extends AnyQueryProcedure,
  TMutationInput,
>(
  queryUtils: QueryProcedureUtilsLike<TProcedure>,
  queryParams: inferProcedureInput<TProcedure> extends DeepPartial<inferProcedureInput<TProcedure>>
    ? inferProcedureInput<TProcedure> | undefined
    : undefined,
  infiniteDataUpdater: (
    mutationInput: TMutationInput,
    oldData?: InfiniteData<inferProcedureOutput<TProcedure>> | undefined
  ) => InfiniteData<inferProcedureOutput<TProcedure>> | undefined
): OptimisticOptions<TMutationInput, InfiniteData<inferProcedureOutput<TProcedure>>> => {
  return {
    onMutate: async (input) => {
      await queryUtils.cancel(queryParams)
      const oldData = queryUtils.getInfiniteData(queryParams)
      queryUtils.setInfiniteData(queryParams, (data) => infiniteDataUpdater(input, data))
      return { oldData }
    },
    onError: (err, input, ctx) => {
      queryUtils.setInfiniteData(queryParams, ctx?.oldData)
    },
    // There is no need to refetch here, as we invalidate queries if it succeeds
  }
}

interface InvalidateFilters<TProcedure extends AnyQueryProcedure>
  extends Omit<InvalidateQueryFilters, 'predicate'> {
  predicate?: (
    query: Query<
      inferProcedureInput<TProcedure>,
      TRPCClientError<TProcedure>,
      inferProcedureInput<TProcedure>,
      QueryKeyKnown<
        inferProcedureInput<TProcedure>,
        inferProcedureInput<TProcedure> extends { cursor?: unknown } | void ? 'infinite' : 'query'
      >
    >
  ) => boolean
}

// Since there are no exported type, Partially copied from
// https://github.com/trpc/trpc/blob/89db07832a7c1ef8d1d9905100a59d83603a197e/packages/react-query/src/shared/proxy/utilsProxy.ts#L37-L186
interface QueryProcedureUtilsLike<TProcedure extends AnyQueryProcedure> {
  /**
   * @link https://tanstack.com/query/v4/docs/reference/QueryClient#queryclientinvalidatequeries
   */
  invalidate(
    input?: DeepPartial<inferProcedureInput<TProcedure>>,
    filters?: InvalidateFilters<TProcedure>,
    options?: InvalidateOptions
  ): Promise<void>

  /**
   * @link https://tanstack.com/query/v4/docs/reference/QueryClient#queryclientcancelqueries
   */
  cancel(input?: inferProcedureInput<TProcedure>, options?: CancelOptions): Promise<void>

  /**
   * @link https://tanstack.com/query/v4/docs/reference/QueryClient#queryclientsetquerydata
   */
  setData(
    /**
     * The input of the procedure
     */
    input: inferProcedureInput<TProcedure>,
    updater: Updater<
      inferTransformedProcedureOutput<TProcedure> | undefined,
      inferTransformedProcedureOutput<TProcedure> | undefined
    >,
    options?: SetDataOptions
  ): void

  /**
   * @link https://tanstack.com/query/v4/docs/reference/QueryClient#queryclientsetquerydata
   */
  setInfiniteData(
    input: inferProcedureInput<TProcedure>,
    updater: Updater<
      InfiniteData<inferTransformedProcedureOutput<TProcedure>> | undefined,
      InfiniteData<inferTransformedProcedureOutput<TProcedure>> | undefined
    >,
    options?: SetDataOptions
  ): void

  /**
   * @link https://tanstack.com/query/v4/docs/reference/QueryClient#queryclientgetquerydata
   */
  getData(
    input?: inferProcedureInput<TProcedure>
  ): inferTransformedProcedureOutput<TProcedure> | undefined

  /**
   * @link https://tanstack.com/query/v4/docs/reference/QueryClient#queryclientgetquerydata
   */
  getInfiniteData(
    input?: inferProcedureInput<TProcedure>
  ): InfiniteData<inferTransformedProcedureOutput<TProcedure>> | undefined
}
