import { z } from 'zod'
import {
  ZAugmentedCommon,
  ZAugmentedConvertibleNote,
  ZAugmentedOption,
  ZAugmentedPreferred,
  ZAugmentedSafe,
  ZAugmentedWarrant,
} from '~/common/schema/relation/equity-types'
import type { NonEmptyArray } from '~/common/util-types'
import { util } from './util'

export const ZAugmentedEquity = util.annotateDisplay(
  z.union([
    ZAugmentedPreferred,
    ZAugmentedCommon,
    ZAugmentedSafe,
    ZAugmentedConvertibleNote,
    ZAugmentedOption,
    ZAugmentedWarrant,
  ]),
  'Cap Table'
)
export type ZAugmentedEquity = z.infer<typeof ZAugmentedEquity>

export const ZAugmentedSafesAndConvertibles = z.union([ZAugmentedSafe, ZAugmentedConvertibleNote])

export type ZAugmentedSafesAndConvertibles = z.infer<typeof ZAugmentedSafesAndConvertibles>

const _ZEquityType = z.enum(
  ZAugmentedEquity.options.map((option) => option.type) as NonEmptyArray<ZAugmentedEquity['type']>
)
export type ZEquityType = z.infer<typeof _ZEquityType>

export const ZEquityType = Object.assign(_ZEquityType, {
  isType: (s: string): s is ZEquityType => {
    return _ZEquityType.options.includes(s)
  },
})
