import type { CryptId } from '@cryptid-module'
import React, { useMemo } from 'react'
import { hooks, useCurrentCorpAuth } from '~/client/lib/hooks'
import type { SuggestionWithoutSource } from '~/common/autocheck'
import { mkAutofillWithHiddenSuggestions, mkUserFacingAutofill } from '~/common/autocheck'
import type { DataApi } from '~/common/data-api'
import type { DocAutofill } from '~/common/enhance'
import type { ZAugmentedDoc } from '~/common/schema'

export interface SuggestionLoadingStates {
  types: boolean
  dates: boolean
  emails: boolean
  titles: boolean
  parties: boolean
}

interface UseSuggestions {
  data?: SuggestionWithoutSource
  loadingStates: SuggestionLoadingStates
}

export const useSuggestions = (
  docCryptId: CryptId | undefined,
  // This is Required to avoid cases where we add a new suggestion type
  // but forget to update usages
  options: Required<DataApi.SuggestionQuery>
): UseSuggestions => {
  const { data: auth } = useCurrentCorpAuth()
  const queryParams = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    docCryptId: docCryptId!,
  }

  const cryptIdDefined = !!docCryptId
  const isInvestor = auth?.level === 'investor'

  const typesEnabled = cryptIdDefined && !!options.types && !isInvestor
  const datesEnabled = cryptIdDefined && !!options.dates && !isInvestor
  const emailsEnabled = cryptIdDefined && !!options.emails && !isInvestor
  const titlesEnabled = cryptIdDefined && !!options.titles && !isInvestor
  const partiesEnabled = cryptIdDefined && !!options.parties && !isInvestor

  // We do not want to invalidate these queries after a document gets saved as
  // the results will not change
  const queryOptions = {
    // store these up to a day or until browser refresh
    cacheTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 24,
    meta: { noGlobalInvalidation: true },
  } as const

  const typesQuery = hooks.trpc().doc.suggestions.types.byUrl.useQueryWithCorp(queryParams, {
    enabled: typesEnabled,
    ...queryOptions,
  })
  const datesQuery = hooks.trpc().doc.suggestions.dates.byUrl.useQueryWithCorp(queryParams, {
    enabled: datesEnabled,
    ...queryOptions,
  })
  const emailsQuery = hooks.trpc().doc.suggestions.emails.byUrl.useQueryWithCorp(queryParams, {
    enabled: emailsEnabled,
    ...queryOptions,
  })
  const titlesQuery = hooks.trpc().doc.suggestions.titles.byUrl.useQueryWithCorp(queryParams, {
    enabled: titlesEnabled,
    ...queryOptions,
  })
  const partiesQuery = hooks.trpc().doc.suggestions.parties.byUrl.useQueryWithCorp(queryParams, {
    enabled: partiesEnabled,
    ...queryOptions,
  })

  const data = useMemo(
    () => ({
      types: typesQuery.data ?? [],
      dates: datesQuery.data ?? [],
      emails: emailsQuery.data ?? [],
      titles: titlesQuery.data ?? [],
      parties: partiesQuery.data ?? [],
    }),
    [typesQuery.data, datesQuery.data, emailsQuery.data, titlesQuery.data, partiesQuery.data]
  )

  const loadingStates = useMemo(
    () => ({
      // We have to check if the query is enabled otherwise it will load indefinitely
      types: typesEnabled && typesQuery.isLoading,
      dates: datesEnabled && datesQuery.isLoading,
      emails: emailsEnabled && emailsQuery.isLoading,
      titles: titlesEnabled && titlesQuery.isLoading,
      parties: partiesEnabled && partiesQuery.isLoading,
    }),
    [
      typesQuery.isLoading,
      datesQuery.isLoading,
      emailsQuery.isLoading,
      titlesQuery.isLoading,
      partiesQuery.isLoading,
      typesEnabled,
      datesEnabled,
      emailsEnabled,
      titlesEnabled,
      partiesEnabled,
    ]
  )

  return { data, loadingStates }
}

interface UseAutofill {
  autofill?: DocAutofill
  autofillWithHiddenSuggestions?: DocAutofill
  suggestionsLoadingStates: UseSuggestions['loadingStates']
}

export const useAutofill = (
  initialAutofill: DocAutofill | undefined,
  docCryptId: CryptId | undefined,
  options: Required<DataApi.SuggestionQuery>,
  docTypesFilter: (type: ZAugmentedDoc['type']) => boolean
): UseAutofill => {
  const { data, loadingStates } = useSuggestions(docCryptId, options)
  const autofillWithHiddenSuggestions = React.useMemo(
    () =>
      data &&
      mkAutofillWithHiddenSuggestions({ suggestions: data, initialAutofill, docTypesFilter }),
    [data, initialAutofill, docTypesFilter]
  )

  const autofill = React.useMemo(
    () => data && mkUserFacingAutofill({ suggestions: data, initialAutofill, docTypesFilter }),
    [data, initialAutofill, docTypesFilter]
  )

  return {
    autofill,
    suggestionsLoadingStates: loadingStates,
    autofillWithHiddenSuggestions,
  }
}
