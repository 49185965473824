import { ActionIcon, CopyButton, Tooltip } from '@mantine/core'
import { IconCheck, IconCopy } from '@tabler/icons-react'
import React from 'react'

export const CopyableTooltip: React.FC<{
  textStringOrRef: React.RefObject<HTMLElement> | string
  onCopy?: () => void
  disabled?: boolean
}> = ({ textStringOrRef, onCopy, disabled }) => {
  const value =
    typeof textStringOrRef === 'string' ? textStringOrRef : textStringOrRef.current?.innerText

  if (disabled)
    return (
      <ActionIcon component='span' color='inactive'>
        <IconCopy />
      </ActionIcon>
    )

  return (
    <CopyButton value={value ?? ''} timeout={2000}>
      {({ copied, copy }) => (
        <Tooltip
          label={copied ? 'Copied!' : 'Copy to clipboard'}
          color={copied ? 'primary' : undefined}
        >
          <ActionIcon
            onClick={() => {
              copy()
              onCopy?.()
            }}
            component='span'
            color={copied ? 'go' : 'primary'}
          >
            {copied ? <IconCheck /> : <IconCopy />}
          </ActionIcon>
        </Tooltip>
      )}
    </CopyButton>
  )
}
