import type { TabsProps } from '@mantine/core'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

type TabValues = string | null
interface UseNavigationTabsRtn extends Pick<TabsProps, 'value' | 'onChange'> {}

export const useNavigationTabs = <T extends string>({
  tabs,
  defaultTab,
}: {
  tabs: [T, T, ...T[]]
  defaultTab: T
}): UseNavigationTabsRtn => {
  if (!tabs.includes(defaultTab))
    throw new Error(`Default tab must be included in tabs array: ${defaultTab} not in [${tabs}]`)

  const router = useRouter()
  const value = React.useMemo(() => {
    const { tab } = router.query
    if (Array.isArray(tab)) throw new Error("Tab can't be an array")
    return tabs.includes(tab as T) ? tab : defaultTab
  }, [defaultTab, router.query, tabs])

  const onChange = React.useCallback(
    (tab: TabValues, { replace }: { replace?: boolean } = {}) => {
      const urlObject = {
        pathname: router.pathname,
        query: { ...router.query, tab },
      }
      const options = { shallow: true }
      return replace
        ? router.replace(urlObject, undefined, options)
        : router.push(urlObject, undefined, options)
    },
    [router]
  )

  // add tab to URL if not present
  useEffect(() => {
    if (router.isReady && !router.query.tab) {
      // Use replace here to avoid repeatedly setting the route when the user presses BACK in the browser
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onChange(defaultTab, { replace: true })
    }
  }, [router.query.tab, router.isReady, defaultTab, onChange])

  return { onChange, value }
}

export type UseNavigationTabs = typeof useNavigationTabs
