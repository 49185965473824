import { Button, Group, HoverCard, List, Stack, Text, ThemeIcon, Title } from '@mantine/core'
import { IconAlertOctagon, IconCheck, IconEye, IconX } from '@tabler/icons-react'
import NextLink from 'next/link'
import { zIndex } from '~/client/components/z-index'
import { useMkInvestorPreviewUrl } from '~/client/lib/hooks/investor-preview'
import type { ZPermissionsLevel } from '~/common/schema'
import { roleExplanations } from '~/common/schema'

const PermissionItems: React.FC<{ items?: string[]; icon: React.ReactNode; color: string }> = ({
  items,
  icon,
  color,
}) => (
  <>
    {items?.map((description, i) => (
      <List.Item
        key={i}
        icon={
          <Group>
            {/* 'contents' to avoid a margin on the left */}
            <ThemeIcon color={color} display='contents'>
              {icon}
            </ThemeIcon>
          </Group>
        }
      >
        {description}
      </List.Item>
    ))}
  </>
)

export const PermissionExplanationList: React.FC<{ level: ZPermissionsLevel }> = ({ level }) => {
  const { name, explanation } = roleExplanations[level]
  const { allowed, partiallyAllowed, disallowed, summary } = explanation
  const iconSize = 16
  const { mkInvestorPreviewUrl } = useMkInvestorPreviewUrl()
  return (
    <Stack gap='xs'>
      <Title order={6}>{name} Permissions</Title>
      {summary && <Text c='gray'>{summary}</Text>}
      <List spacing={3} c='gray'>
        <PermissionItems items={allowed} color='go' icon={<IconCheck size={iconSize} />} />
        <PermissionItems
          items={partiallyAllowed}
          color='urgent'
          icon={<IconAlertOctagon size={iconSize} />}
        />
        <PermissionItems items={disallowed} color='danger' icon={<IconX size={iconSize} />} />
      </List>
      {level === 'investor' && (
        <Button
          variant='outline'
          color='blue'
          component={NextLink}
          href={mkInvestorPreviewUrl()}
          target='_blank'
          leftSection={<IconEye />}
          // Do not propagate the click to the dropdown parent, otherwise the
          // investor role will be applied when the preview button is clicked.
          // We have to use `e.stopPropagation()` as the logic to close the
          // dropdown is handled by Mantine.
          // eslint-disable-next-line custom-rules/no-stop-propagation
          onClick={(e) => e.stopPropagation()}
        >
          Preview Investor View
        </Button>
      )}
    </Stack>
  )
}

export const RoleHoverExplanations: React.FC<{ level: ZPermissionsLevel }> = ({
  level,
  children,
}) => {
  return (
    // Do not render within a portal, otherwise if the parent is a dropdown it
    // will close before the click goes through (as the click was done outside
    // the dropdown)
    <HoverCard width={400} shadow='md' zIndex={zIndex.modal + 1} withinPortal={false} keepMounted>
      <HoverCard.Target>
        {/* A Stack is needed for the hover to work properly */}
        <Stack>{children}</Stack>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <PermissionExplanationList level={level} />
      </HoverCard.Dropdown>
    </HoverCard>
  )
}
