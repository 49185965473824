import * as Sentry from '@sentry/nextjs'
import { useQueryClient } from '@tanstack/react-query'
import type { IdTokenResult, ParsedToken, User } from 'firebase/auth'
import React from 'react'
import { analytics } from '~/client/components/monitoring'
import { clearIndexDbAndLocalStorage } from '~/client/lib/clear'
import { auth } from '~/client/lib/firebase'
import { useAuthStore } from '~/client/lib/hooks/auth'
import { hooks } from '~/client/lib/hooks/dependency-injection/interface'
import { useJwtStore } from '~/client/lib/hooks/jwt-store'
import { isWithinDays } from '~/common/util'
import { zenvCommon } from '~/common/zenv-common'

/** The Firebase Auth Token with the custom claims type. */
export interface AuthToken extends Omit<IdTokenResult, 'claims'> {
  claims: ParsedToken
}

export type AuthStatus =
  | 'UNAUTHENTICATED'
  | 'AWAITING_EMAIL_VERIFY'
  | 'AWAITING_PHONE_VERIFY'
  | 'AWAITING_TOTP_VERIFY'
  | 'AWAITING_ENROLL_MFA'
  | 'AWAITING_PASSWORD_RESET'
  | 'AUTHENTICATED'

export const useAuthStatus = (): AuthStatus => {
  const user = hooks.useAuthStore((state) => state.user)
  const token = hooks.useAuthStore((state) => state.token)
  const enrollMFA = hooks.useAuthStore((state) => state.enrollMFA)
  const passwordInfo = hooks.useAuthStore((state) => state.passwordInfo)
  const mfaVerificationRequired = hooks.useAuthStore(
    (state) => state.loginResolver?.hints[0]?.factorId
  )

  const userExists = !!user && !!token
  const userVerified = !!user?.emailVerified
  const mfaRegistered =
    token?.signInSecondFactor === 'phone' || token?.signInSecondFactor === 'totp'
  const awaitingEnrollMfa = !mfaRegistered && enrollMFA
  const awaitingPasswordReset =
    zenvCommon.NEXT_PUBLIC_ENABLE_COMPULSORY_PASSWORD_RESET && passwordInfo?.isPasswordUser
      ? !isWithinDays(passwordInfo.passwordUpdatedAt, 90)
      : false

  if (!userExists && !mfaVerificationRequired) return 'UNAUTHENTICATED'
  if (!userExists && mfaVerificationRequired === 'phone') return 'AWAITING_PHONE_VERIFY'
  if (!userExists && mfaVerificationRequired === 'totp') return 'AWAITING_TOTP_VERIFY'
  if (userExists && !userVerified) return 'AWAITING_EMAIL_VERIFY'
  if (userExists && userVerified && awaitingPasswordReset) return 'AWAITING_PASSWORD_RESET'
  if (userExists && userVerified && awaitingEnrollMfa) return 'AWAITING_ENROLL_MFA'
  if (userExists && userVerified && !awaitingPasswordReset && !awaitingEnrollMfa)
    return 'AUTHENTICATED'
  throw Error('To satisfy bad TS inference; not possible.')
}

export type UseAuthStatus = typeof useAuthStatus

export const useHandleUserChange = (): void => {
  const setUser = useAuthStore((state) => state.setUser)
  const setEnrollMFA = useAuthStore((state) => state.setEnrollMFA)
  const setLoginResolver = useAuthStore((state) => state.setLoginResolver)
  const queryClient = useQueryClient()

  const handleLogin = React.useCallback(
    async (user: User) => {
      // Identify the user in sentry as early as possible to maximize the duration.
      Sentry.setUser({ id: user.uid, email: user.email ?? '', name: user.displayName })
      await setUser(user)
    },
    [setUser]
  )

  const handleLogout = React.useCallback(async () => {
    await setUser(null)
    clearIndexDbAndLocalStorage()
    queryClient.removeQueries()
    setEnrollMFA(true)
    setLoginResolver(undefined)
    useJwtStore.setState({}, true) // will replace all values with undefined

    // Stop identifying the user as late as possible to maximize the duration.
    Sentry.setUser(null)
    analytics.identify(null)
  }, [queryClient, setEnrollMFA, setLoginResolver, setUser])

  React.useEffect(() => {
    return auth.onIdTokenChanged(async (_user) => {
      // this handles all logouts, even if the user did not perform an action to do so
      // all the cache must be cleared for security
      if (!_user) await handleLogout()
      else await handleLogin(_user)
    })
  }, [handleLogin, handleLogout])
}
