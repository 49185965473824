import { Center, Stepper } from '@mantine/core'
import React from 'react'
import {
  onboardingModalSteps,
  useMultiDocModalStore,
} from '~/client/components/multi-doc-drop/state'
import { AddLawyerStep } from '~/client/components/onboarding-modal/add-lawyer'
import { ConnectIntegrationsStep } from '~/client/components/onboarding-modal/connect-integrations'
import { DocumentUploadStep } from '~/client/components/onboarding-modal/document-upload'
import { DoneStep } from '~/client/components/onboarding-modal/done'
import { stepperCustomColorsClass } from '~/client/components/onboarding-modal/stepper.css'
import {
  StepContainer,
  useNavigateToOnboardingStep,
} from '~/client/components/onboarding-modal/util'
import { ValueProps } from '~/client/components/onboarding-modal/welcome-value-props'
import { theme } from '~/client/lib/theme'

export const OnboardingModal: React.FC = () => {
  const modalState = useMultiDocModalStore((state) => state.state)
  const setOnboardingShown = useMultiDocModalStore((state) => state.setOnboardingShown)
  React.useEffect(() => {
    // Set the onboarding shown on component mount event
    setOnboardingShown(true)
  }, [setOnboardingShown])

  const navigateToOnboardingStep = useNavigateToOnboardingStep()
  // not part of the onboarding flow
  if (modalState === 'closed' || modalState === 'doc-drop' || modalState === 'doc-status') {
    return null
  }

  const activeStep = onboardingModalSteps.indexOf(modalState)
  return (
    <Stepper
      active={activeStep}
      onStepClick={navigateToOnboardingStep}
      allowNextStepsSelect={false}
      className={stepperCustomColorsClass}
      styles={{
        stepBody: {
          maxWidth: '13rem',
        },
        steps: {
          padding: theme.spacing.md,
          borderBottom: `1px solid ${theme.colors.gray[2]}`,
        },
        content: {
          flex: 1,
          padding: theme.spacing.md,
        },
        root: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
        stepDescription: {
          marginBottom: 0,
        },
      }}
    >
      <Stepper.Step label='Welcome!' description='Why use Aerial?'>
        <Center h='100%'>
          <StepContainer
            title='Welcome to Aerial'
            subtitle='Become Investor Ready with Aerial’s AI'
            stepCompleted
            withBackButton={false}
            dataTestId='onboarding-welcome-step'
          >
            <ValueProps />
          </StepContainer>
        </Center>
      </Stepper.Step>
      <Stepper.Step label='Add your lawyer' description='Save money on legal fees'>
        <AddLawyerStep />
      </Stepper.Step>
      <Stepper.Step label='Connect to eSignature' description='Automatically add documents'>
        <ConnectIntegrationsStep />
      </Stepper.Step>
      <Stepper.Step label='Upload other documents' description='Get real-time red flags'>
        <DocumentUploadStep />
      </Stepper.Step>
      <Stepper.Completed>
        <DoneStep />
      </Stepper.Completed>
    </Stepper>
  )
}
