import {
  ActionIcon,
  Button,
  Center,
  Group,
  Input,
  Loader,
  Skeleton,
  Text,
  Title,
  Transition,
} from '@mantine/core'
import { IconMail, IconPhone, IconUserPlus } from '@tabler/icons-react'
import dynamic from 'next/dynamic'
import React, { useState } from 'react'
import 'react-phone-number-input/style.css'
import { WelcomeValueProps } from '~/client/components/onboarding-modal/welcome-value-props'
import { NextLinkOpt } from '~/client/components/util'
import { hooks } from '~/client/lib/hooks'
import { getMFAError } from '~/client/lib/hooks/auth'
import { usePrefillEmailQueryParam } from '~/client/lib/query-params'
import { theme } from '~/client/lib/theme'
import { mkUrlWithPrefillEmail } from '~/common/util'
import {
  AuthenticationFrame,
  EmailPasswordComp,
  GoogleSignInButton,
  MicrosoftSignInButton,
} from './util'
import { VerifyPhoneComp } from './verifications'

const PhoneInput = dynamic(() => import('react-phone-number-input'), {
  loading: () => <Skeleton width={360} height={36} />,
})

const CreateAccountComp: React.FC = () => {
  const [opened, setOpened] = useState<boolean>(false)

  const { emailSignupMutation } = hooks.useAuthMethods()

  return (
    <>
      <Button
        size='lg'
        onClick={() => setOpened((open) => !open)}
        data-testid='email-signup-button'
      >
        <Group>
          <ActionIcon component='span' size={32}>
            <IconMail />
          </ActionIcon>
          <span>Email Signup</span>
        </Group>
      </Button>
      <Transition mounted={opened} transition='scale-y' duration={400}>
        {(style) => (
          <div style={{ ...style }}>
            <EmailPasswordComp
              emailLoginSignup={emailSignupMutation}
              passwordLabel='New Password'
              submitLabel='Sign up'
            />
          </div>
        )}
      </Transition>
    </>
  )
}

export const Signup: React.FC = () => {
  const prefillEmail = usePrefillEmailQueryParam()
  hooks.trpc().public.ping.useQuery() // warm up trpc serverless function

  return (
    <Group mih='100vh' data-testid='signup' align='center' w='90vw' m='auto' justify='space-evenly'>
      <Center>
        <AuthenticationFrame
          height={theme.other.widths.md}
          title='Sign up for Aerial'
          icon={<IconUserPlus />}
        >
          <GoogleSignInButton label='Google Signup' email={prefillEmail} />
          <MicrosoftSignInButton label='Microsoft Signup' email={prefillEmail} />
          <CreateAccountComp />
          <NextLinkOpt
            href={mkUrlWithPrefillEmail('/login', prefillEmail)}
            data-testid='login-link'
          >
            Login with existing account
          </NextLinkOpt>
        </AuthenticationFrame>
      </Center>
      <WelcomeValueProps />
    </Group>
  )
}

export const PhoneForm: React.FC<{
  onSuccess: () => void
  phoneNumber: string
  setPhoneNumber: (value: string) => void
  onBack?: () => void
}> = ({ onSuccess, phoneNumber, setPhoneNumber, onBack }) => {
  const skipEnrollMFA = hooks.useAuthStore((state) => state.skipEnrollMFA)
  const { phoneAuth } = hooks.useAuthMethods()

  return (
    <div data-testid='phone-form'>
      <AuthenticationFrame title='Two-Factor Auth' icon={<IconPhone />}>
        <Title order={4}>Secure Client Data</Title>
        <Text>
          You&apos;ll receive a 6-digit verification code by text message to your phone to secure
          your login.
        </Text>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            phoneAuth.sendEnrollPhoneCodeMutation.mutate(phoneNumber, { onSuccess })
          }}
        >
          <Input.Wrapper
            label='Phone Number'
            error={getMFAError(phoneAuth.sendEnrollPhoneCodeMutation.error)}
            data-testid='add-number-input'
          >
            <PhoneInput
              focusInputOnCountrySelection
              defaultCountry='US'
              inputComponent={Input}
              onChange={(value) => setPhoneNumber(value ?? '')}
              value={phoneNumber}
            />
          </Input.Wrapper>
          <Group mt='lg' justify='flex-end'>
            {onBack && (
              <Button type='button' variant='subtle' color='gray' onClick={onBack}>
                Back
              </Button>
            )}
            {phoneAuth.sendEnrollPhoneCodeMutation.isLoading && <Loader size={25} />}
            <Button
              type='submit'
              disabled={!phoneNumber || phoneAuth.sendEnrollPhoneCodeMutation.isLoading}
              data-testid='add-number-btn'
            >
              Next
            </Button>
          </Group>
        </form>
        <Text c='dimmed' size='xs'>
          Text or data rates may apply. See <NextLinkOpt href='/terms'>terms</NextLinkOpt> for
          details. You can{' '}
          <NextLinkOpt data-testid='skip-MFA' onClick={() => skipEnrollMFA()}>
            skip
          </NextLinkOpt>{' '}
          this step.
        </Text>
      </AuthenticationFrame>
    </div>
  )
}

export const EnrollPhoneAuth: React.FC<{ onBack?: () => void }> = ({ onBack }) => {
  hooks.trpc().public.ping.useQuery() // warm up trpc serverless function
  const { phoneAuth } = hooks.useAuthMethods()
  const [phoneNumber, setPhoneNumber] = useState('')

  const [step, setStep] = React.useState<'PHONE' | 'CODE'>('PHONE')

  switch (step) {
    case 'PHONE':
      return (
        <PhoneForm
          onSuccess={() => setStep('CODE')}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          onBack={onBack}
        />
      )
    case 'CODE':
      return (
        <VerifyPhoneComp
          submitMutation={phoneAuth.enrollPhoneMutation}
          retryMutation={phoneAuth.sendEnrollPhoneCodeMutation}
          retryMutationInput={phoneNumber}
          skipable
          prompt={
            <>
              Please enter the 6-digit code sent by text message to <b>{phoneNumber}</b> to verify
              your login.
            </>
          }
        />
      )
  }
}
