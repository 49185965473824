import { Box } from '@mantine/core'
import dynamic from 'next/dynamic'
import React from 'react'
import { enhanceCount } from '~/common/enhance'
import type { ZNumberExceed } from '~/common/number-exceed'

const Slide = dynamic(() => import('react-awesome-reveal').then((mod) => mod.Slide), { ssr: false })
const Fade = dynamic(() => import('react-awesome-reveal').then((mod) => mod.Fade), { ssr: false })

interface AnimatedCountBadgeProps {
  value: ZNumberExceed
  renderBadge: (number: ZNumberExceed) => React.ReactNode
}

export const AnimatedCountBadge: React.FC<AnimatedCountBadgeProps> = ({ value, renderBadge }) => {
  const [currValue, setCurrValue] = React.useState<ZNumberExceed>(value)
  const [prevValue, setPrevValue] = React.useState<ZNumberExceed>()

  const direction = React.useMemo(() => {
    if (!prevValue) return undefined
    if (currValue.count > prevValue.count) return 'up'
    if (currValue.count < prevValue.count) return 'down'
    return currValue.exceeds ? 'up' : 'down'
  }, [prevValue, currValue])

  // Mantine's usePrevious() hook didn't work because it returned the value of the previous
  // *render* so currValue would be equal prevValue sometimes, messing the animation
  React.useEffect(() => {
    if (value.count !== currValue.count || value.exceeds !== currValue.exceeds) {
      setPrevValue(currValue)
      setCurrValue(value)
    }
  }, [value, currValue])

  // These animation components take a few frames to be visible (probably because they
  // have to load a lot of CSS). This is noticeable and might be annoying for the user.
  // A workaround is to keep the animations rendered but hidden while showing just the
  // normal badge
  const firstAnimationOccurred = !!prevValue
  return (
    <Box pos='relative'>
      <Box hidden={!firstAnimationOccurred}>
        <Box pos='absolute' top={0} left={0}>
          {/* react-awesome-reveal animations only trigger on mount and on visibility change */}
          {/* Using triggerOnce because we don't want the animation to run again if the badge leaves the screen */}
          {/* Also using a key so that the component re-mounts when the number changes, triggering the animation */}
          <Slide triggerOnce direction={direction} key={enhanceCount(prevValue)} reverse>
            <Fade triggerOnce reverse>
              {prevValue && renderBadge(prevValue)}
            </Fade>
          </Slide>
        </Box>

        <Slide triggerOnce direction={direction} key={enhanceCount(currValue)}>
          <Fade triggerOnce>{renderBadge(currValue)}</Fade>
        </Slide>
      </Box>

      <Box hidden={firstAnimationOccurred}>{renderBadge(currValue)}</Box>
    </Box>
  )
}
