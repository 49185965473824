import type { CryptId } from '@cryptid-module'
import type { FileWithPath } from '@mantine/dropzone'
import { PDF_MIME_TYPE } from '@mantine/dropzone'
import type { UploadState } from '~/client/components/multi-doc-drop/state'

export const ZIP_MIME_TYPES = ['application/zip', 'application/x-zip-compressed']
export const MAX_FILE_SIZE_MB = 20
const megabyteToByte = (mb: number) => mb * 1024 * 1024

export interface AcceptedFile {
  status: 'accepted'
  file: File
  path: string
}

export interface UploadingFile {
  status: 'uploading'
  file: File
  path: string
  uploadState: UploadState
  uploadStatePercent: number
  completed?: boolean
}

export interface DuplicateFile {
  status: 'duplicate'
  file: File
  path: string
  duplicateDocCryptId?: CryptId
  sha256: string
}

/**
 * These reasons are the error codes returned by the react-dropzone library that we use.
 * They also allow arbitrary strings for error codes so we support 'unknown'
 */
export type RejectedFileReason = 'file-too-large' | 'file-invalid-type' | 'unknown'

export interface RejectedFile {
  status: 'rejected'
  reason: RejectedFileReason
  path: string
  message?: string // used if reason: 'unknown', otherwise, we prefer to use our own reasons
}

export const coarseRejectionReason = (reason: string | undefined): RejectedFileReason => {
  switch (reason) {
    case 'file-too-large':
      return 'file-too-large'
    case 'file-invalid-type':
      return 'file-invalid-type'
    default:
      return 'unknown'
  }
}

export const errorStatus = (file: RejectedFile): string => {
  switch (file.reason) {
    case 'file-too-large':
      return 'File is too large'
    case 'file-invalid-type':
      return 'Invalid file format'
    case 'unknown':
      if (file.message) {
        return `Unable to upload File: ${file.message}`
      }
      return 'Unable to upload File'
  }
}

/**
 * The status of a file that has been dropped into the dropzone.
 */
export type FileStatus = AcceptedFile | RejectedFile
/**
 * The status of a dropped file that is being uploaded or is rejected.
 */
export type FileUploadingStatus = RejectedFile | UploadingFile | DuplicateFile

/**
 * Determines if file has a PDF mime type based on its signature
 *
 * Based on: https://github.com/Stuk/jszip/issues/626#issuecomment-952163972
 */
export const hasPDFMimeType = async (file: File | Blob): Promise<boolean> => {
  return new Promise((resolve) => {
    const fileReader = new FileReader()

    fileReader.onloadend = (evt) => {
      if (evt.target?.readyState === FileReader.DONE && evt.target.result instanceof ArrayBuffer) {
        const uint = new Uint8Array(evt.target.result)
        const bytes: string[] = []
        // this is the way to iterate over a Uint8Array, map() does not work
        uint.forEach((byte) => {
          bytes.push(byte.toString(16))
        })
        const hex = bytes.join('').toUpperCase()
        // Return if the file has the PDF signature
        resolve(hex === '25504446')
      }
    }

    fileReader.readAsArrayBuffer(file.slice(0, 4))
  })
}

const getFilePath = (file: FileWithPath) => file.path ?? file.name

export const rejectedFileTooLarge = (path: string): RejectedFile => ({
  status: 'rejected',
  reason: 'file-too-large',
  path,
})

export const rejectedFileInvalidType = (path: string): RejectedFile => ({
  status: 'rejected',
  reason: 'file-invalid-type',
  path,
})

const acceptedFile = (file: FileWithPath): AcceptedFile => ({
  status: 'accepted',
  file,
  path: getFilePath(file),
})

export const acceptIfPDFandSizeAllowed = async (file: FileWithPath): Promise<FileStatus> => {
  if (file.size > megabyteToByte(MAX_FILE_SIZE_MB)) {
    return rejectedFileTooLarge(getFilePath(file))
  }
  const isPDF = await hasPDFMimeType(file)
  if (!isPDF) {
    return rejectedFileInvalidType(getFilePath(file))
  }

  return acceptedFile(file)
}

export const extractZip = async (zipFile: File): Promise<FileStatus[]> => {
  const JSZip = (await import('jszip')).default
  const zip = await JSZip.loadAsync(zipFile)
  const files: (FileStatus | null)[] = await Promise.all(
    Object.entries(zip.files).map(async ([fileName, zipEntry]) => {
      if (fileName.includes('__MACOSX/')) return null // ignore mac specific files that could be anywhere in the .zip file
      if (fileName.includes('.DS_Store')) return null // ignore mac specific files
      if (fileName.endsWith('/')) return null // ignore folders, which are returned in zip archives

      const path = `${zipFile.name}/${fileName}`
      // Based on https://github.com/Stuk/jszip/issues/626#issuecomment-952163972
      if (fileName.toLowerCase().endsWith('.pdf')) {
        const blob = await zipEntry.async('blob')
        const file = new File([blob], path, {
          type: 'application/pdf',
        })
        return acceptIfPDFandSizeAllowed(file)
      }
      return { status: 'rejected', path, reason: 'unknown', message: 'Invalid file type' }
    })
  )
  return files.filter(Boolean)
}

const exportFile = async (file: FileWithPath): Promise<FileStatus[]> => {
  if (ZIP_MIME_TYPES.includes(file.type)) {
    const files = await extractZip(file)
    return [...files]
  }

  if (PDF_MIME_TYPE.includes(file.type)) {
    return [await acceptIfPDFandSizeAllowed(file)]
  }
  // we add this for correct typing, but the dropzone won't allow other files
  return [rejectedFileInvalidType(getFilePath(file))]
}

export const extractDroppedFiles = async (droppedFiles: FileWithPath[]): Promise<FileStatus[]> => {
  // if zip file exists, extract it's contents
  const fileArrays: FileStatus[][] = await Promise.all(
    droppedFiles.map(async (file) => {
      try {
        return await exportFile(file)
      } catch (e) {
        // An error can happen here if the ZIP file is invalid
        return [rejectedFileInvalidType(getFilePath(file))]
      }
    })
  )
  return fileArrays.flat(1)
}
