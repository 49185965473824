import Head from 'next/head'
import { meta } from '~/client/lib/branding'
import { zenvCommon } from '~/common/zenv-common'
import { mkScriptSrc } from '~/next.config.csp.connect-src'

/**
 * Add the CSP directives stripped from headers by Cypress on the meta tag
 * (https://docs.cypress.io/guides/references/experiments#Experimental-CSP-Allow-List)
 *
 * This is necessary to test CSP rules in Cypress, as we cannot enable
 * `script-src` with headers on Cypress without our tests failing (Cypress is aware of this,
 * and warns about it).
 *
 * Cypress will accept any CSP rules that come from the meta-tags:
 * https://docs.cypress.io/guides/guides/content-security-policy
 *
 * We should keep using headers to send CSP on our app as that is the best way to do it, as
 * meta tags do not have all the features that headers have:
 * https://stackoverflow.com/a/56255397
 */
const cypressCsp = mkScriptSrc({ unsafeScriptInline: false })

export const AppMeta: React.FC = () => {
  const { title, description, url } = meta
  return (
    <Head>
      <meta charSet='UTF-8' />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
      <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
      <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
      <link rel='manifest' href='/site.webmanifest' />
      <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#228be6' />
      {/* cspell:disable-next-line */}
      <meta name='msapplication-TileColor' content='#603cba' />
      <meta name='theme-color' content='#ffffff' />
      <link rel='shortcut icon' href='/favicon.ico' />
      <link rel='icon' type='image/png' sizes='192x192' href='/android-chrome-192x192.png' />
      <link rel='icon' type='image/png' sizes='512x512' href='/android-chrome-512x512.png' />
      <link rel='icon' type='image/png' sizes='96x96' href='/favicon-96x96.png' />

      {/* <!-- Primary Meta Tags --> */}
      <title>{title}</title>
      <meta name='title' content={title} />
      <meta name='description' content={description} />
      <link rel='canonical' href={url} />

      {/* <!-- Open Graph / Facebook --> */}
      <meta property='og:type' content='website' />
      <meta property='og:url' content={url} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      {/* {image && <meta property='og:image' content={image} />} */}

      {/* <!-- Twitter --> */}
      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:url' content={url} />
      <meta property='twitter:title' content={title} />
      <meta property='twitter:description' content={description} />
      {/* {image && <meta property='twitter:image' content={image} />} */}

      {/* Define CSP for cypress tests */}
      {zenvCommon.NEXT_PUBLIC_ENABLE_CY_SEND_META_TAG_CSP && (
        <meta httpEquiv='Content-Security-Policy' content={cypressCsp} />
      )}
    </Head>
  )
}
