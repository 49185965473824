import { Table } from '@mantine/core'
import React from 'react'
import type { DetailTableProps } from '~/client/components/relation/detail/types'
import { MetadataRow } from '~/client/components/util/metadata'
import type { ZUpdateValuation } from '~/common/schema/relation'
import { ZAugmentedValuation } from '~/common/schema/relation'

export const ValuationMetadataTable: React.FC<
  DetailTableProps<ZUpdateValuation, ZAugmentedValuation>
> = ({ data, update, size }) => (
  <Table striped>
    <Table.Tbody>
      <MetadataRow
        displayProps={ZAugmentedValuation.shape.startDate.mkDisplayProps({
          data,
          update,
          property: 'startDate',
          autofillData: data.autofill.startDates,
        })}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedValuation.shape.endDate.mkDisplayProps({
          data,
          update,
          property: 'endDate',
        })}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedValuation.shape.valuation.mkDisplayProps({
          data,
          update,
          property: 'valuation',
        })}
        size={size}
      />
    </Table.Tbody>
  </Table>
)
