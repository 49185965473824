import { Badge, Text } from '@mantine/core'
import React from 'react'
import { AugmentedMetadataDate } from '~/common/schema'

interface DisplayDateInTableProps {
  date: Date | undefined | null
  noStartOrEndDate: boolean
  isActive: boolean
}

/**
 * Used inside tables to display dates and returns null on some conditions
 */
export const RelationDateDisplay: React.FC<DisplayDateInTableProps> = ({
  noStartOrEndDate,
  date,
  isActive,
}) => {
  if (noStartOrEndDate) return null
  if (!date && !isActive) return null
  if (!date) return <Badge>Current</Badge>
  return <Text maw='fit-content'>{AugmentedMetadataDate.display(date)}</Text>
}
