/**
 * OptionsDropdown component for MultiSelectCreatable
 * Referenced from
 * https://github.com/mantinedev/mantine/blob/00f03c412db02f3973058466d8f674f797e06149/src/mantine-core/src/components/Combobox/OptionsDropdown/OptionsDropdown.tsx
 * Refactored into `util.ts` and `options-dropdown.tsx` files
 */
import type { ScrollAreaProps } from '@mantine/core'
import {
  Combobox,
  type ComboboxItem,
  type ComboboxLikeProps,
  type ComboboxParsedItem,
  ScrollArea,
  defaultOptionsFilter,
  isOptionsGroup,
} from '@mantine/core'
import React, { useEffect } from 'react'
import type { CustomComboboxItem, CustomComboboxParsedItemGroup } from './types'
import { isEmptyComboboxData, isExactMatch, validateOptions } from './util'

export interface OptionCompProps {
  item: ComboboxItem
}

interface OptionProps {
  data: CustomComboboxItem | CustomComboboxParsedItemGroup
  value: string[]
  optionComp?: React.FC<OptionCompProps>
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const Option: React.FC<OptionProps> = ({ data, value, optionComp: OptionComp }) => {
  if (!isOptionsGroup(data)) {
    return (
      <Combobox.Option value={data.value} disabled={data.disabled}>
        {data.display ? data.display : OptionComp ? <OptionComp item={data} /> : data.label}
      </Combobox.Option>
    )
  }
  const options = data.items.map((item) => (
    <Option data={item} value={value} key={item.value} optionComp={OptionComp} />
  ))
  return <Combobox.Group label={data.group}>{options}</Combobox.Group>
}

export interface OptionsDropdownProps
  extends Pick<ComboboxLikeProps, 'filter' | 'limit' | 'maxDropdownHeight'> {
  className?: string
  data: ComboboxParsedItem[]
  hidden?: boolean
  // Assume this search value is already trimmed
  search: string
  value: string[]
  optionComp?: React.FC<{ item: ComboboxItem }>
  nothingFoundMessage?: React.ReactNode
  creatable?: boolean
  getCreateLabel?: (label: string) => React.ReactNode
  createOptionGroup?: React.ReactNode
  scrollbarType?: ScrollAreaProps['type']
}

const mkCreateOption = (search: string, getCreateLabel?: (search: string) => React.ReactNode) => (
  <Combobox.Option value='$create'>
    {getCreateLabel?.(search) ?? `Add New Option "${search}"`}
  </Combobox.Option>
)

export const OptionsDropdown: React.FC<OptionsDropdownProps> = ({
  data,
  hidden,
  filter,
  search,
  limit,
  value,
  optionComp,
  maxDropdownHeight,
  nothingFoundMessage,
  creatable,
  getCreateLabel,
  createOptionGroup,
  className,
  scrollbarType,
}) => {
  useEffect(() => {
    validateOptions(data)
  }, [data])

  const filteredData = search
    ? (filter || defaultOptionsFilter)({
        options: data,
        search,
        limit: limit ?? Infinity,
      })
    : data

  const isEmpty = isEmptyComboboxData(filteredData)

  const options = filteredData.map((item) => (
    <Option
      data={item}
      key={isOptionsGroup(item) ? item.group : item.value}
      value={value}
      optionComp={optionComp}
    />
  ))

  const createSearchOption =
    creatable &&
    search.length > 0 &&
    !isExactMatch({
      data,
      search,
      value,
    }) ? (
      createOptionGroup ? (
        <Combobox.Group label={createOptionGroup}>
          {mkCreateOption(search, getCreateLabel)}
        </Combobox.Group>
      ) : (
        mkCreateOption(search, getCreateLabel)
      )
    ) : null

  return (
    <Combobox.Dropdown
      hidden={hidden || (isEmpty && !createSearchOption && !nothingFoundMessage)}
      className={className}
    >
      <Combobox.Options>
        <ScrollArea.Autosize mah={maxDropdownHeight ?? 250} type={scrollbarType} scrollbars='y'>
          {createSearchOption}
          {options}
        </ScrollArea.Autosize>
        {!!createSearchOption && isEmpty && nothingFoundMessage && (
          <Combobox.Empty>{nothingFoundMessage}</Combobox.Empty>
        )}
      </Combobox.Options>
    </Combobox.Dropdown>
  )
}
