import { appendPath, mkUrlWithSearchParams } from '~/common/util'
import { zenvCommon } from '~/common/zenv-common'

const ADOBE_SIGN_SELF_SCOPES = ['agreement_read:self', 'user_read:self']
const ADOBE_SIGN_ACCOUNT_SCOPES = ['agreement_read:account', 'user_read:self']

interface MkAdobeSignOAuthUrlProps {
  stateNonce: string
  customRedirectUri?: string
  askForAccountScope: boolean
}

export const mkAdobeSignOAuthUrl = ({
  askForAccountScope,
  stateNonce,
  customRedirectUri,
}: MkAdobeSignOAuthUrlProps): string => {
  const redirectUri =
    customRedirectUri ??
    appendPath(zenvCommon.NEXT_PUBLIC_REDIRECT_URL, zenvCommon.NEXT_PUBLIC_ADOBE_SIGN_CALLBACK_PATH)
  const scopes = askForAccountScope ? ADOBE_SIGN_ACCOUNT_SCOPES : ADOBE_SIGN_SELF_SCOPES

  return mkUrlWithSearchParams('https://secure.na1.adobesign.com/public/oauth/v2', {
    redirect_uri: redirectUri,
    response_type: 'code',
    client_id: zenvCommon.NEXT_PUBLIC_ADOBE_SIGN_APP_ID,
    scope: scopes.join(' '),
    state: stateNonce,
  })
}
