import type { CryptId } from '@cryptid-module'
import Router from 'next/router'
import { partition } from 'underscore'
import { z } from 'zod'
import { type StateCreator, create } from 'zustand'
import type { FileUploadingStatus, RejectedFile, UploadingFile } from '~/client/lib/doc-upload'
import { multiDocModalStateQueryParameter } from '~/common/query-parameter-key'

/** Using a state machine to model the upload state is overkill, so we model it as increasing numbers.
 * Some upload steps run in parallel so the order is not guaranteed.
 *
 * Steps are:
 * - Upload requested
 * - Check if PDF is corrupted
 * - Doc creation or update in our DB
 * - Upload PDF to S3
 * - Generate thumbnail
 * - Upload thumbnail to S3
 */
export const MAX_UPLOAD_STATE = 5
export type UploadState = 0 | 1 | 2 | 3 | 4 | 5
export type GlobalUploadStatus = 'uploading' | 'successful' | 'withErrors' | 'onlyErrors'

const incrementState = (state: UploadState): UploadState => {
  return Math.min(state + 1, MAX_UPLOAD_STATE) as UploadState
}
export const calcUploadPercent = (state: UploadState): number => {
  return (100 / MAX_UPLOAD_STATE) * state
}

const getGlobalUploadStatus = (fileStatuses: FileUploadingStatus[]) => {
  const [_duplicateFiles, nonDuplicateFiles] = partition(
    fileStatuses,
    (file) => file.status === 'duplicate'
  )
  const [rejectedFiles, acceptedFiles] = partition(
    nonDuplicateFiles,
    (file) => file.status === 'rejected'
  )
  const [uploadingFiles, uploadedFiles] = partition(
    acceptedFiles,
    (file) => (file as UploadingFile).uploadState < MAX_UPLOAD_STATE
  )

  if (uploadingFiles.length > 0) return 'uploading'
  if (rejectedFiles.length > 0 && uploadedFiles.length === 0) return 'onlyErrors'
  if (rejectedFiles.length > 0 && uploadedFiles.length > 0) return 'withErrors'
  return 'successful'
}

type StatusesMiddleware = (config: StateCreator<MultiDocStore>) => StateCreator<MultiDocStore>

const statusesMiddleware: StatusesMiddleware = (config) => (set, get, store) =>
  config(
    // Modify set() function to recompute fileStatuses on every change
    (...args) => {
      set(...args)
      set((state) => ({ fileStatuses: Array.from(state.fileStatusesMap.values()) }))
      set((state) => ({ globalUploadStatus: getGlobalUploadStatus(state.fileStatuses) }))
    },
    get,
    store
  )

interface MultiDocStore {
  fileStatusesMap: Map<string, FileUploadingStatus>

  fileStatuses: FileUploadingStatus[]
  globalUploadStatus: GlobalUploadStatus

  setFileStatuses: (files: FileUploadingStatus[]) => void
  getFileStatus: (path: string) => FileUploadingStatus
  incrementDocUploadState: (path: string) => void
  setDocErrorStatus: (path: string, errorMessage: string) => void
  setUploadingToDuplicateDoc: (path: string) => void
  setDuplicatesForUploadedFile: (sha256: string, cryptId: CryptId) => void
}

export const useMultiDocStore = create<MultiDocStore>()(
  statusesMiddleware((set, get) => ({
    fileStatusesMap: new Map(),
    fileStatuses: [],
    globalUploadStatus: 'successful',

    setFileStatuses: (files) => set({ fileStatusesMap: new Map(files.map((f) => [f.path, f])) }),

    getFileStatus: (path) => {
      const fileStatus = get().fileStatusesMap.get(path)
      if (!fileStatus) throw new Error(`No file status for ${path}`)
      return fileStatus
    },
    incrementDocUploadState: (path) =>
      set(({ fileStatusesMap: prevFileStatuses }) => {
        const nextFileStatuses = new Map(prevFileStatuses)
        const fileStatus = nextFileStatuses.get(path)
        if (fileStatus === undefined || fileStatus.status !== 'uploading') return {}

        const newUploadState = incrementState(fileStatus.uploadState)
        const newFileStatus = {
          ...fileStatus,
          uploadState: newUploadState,
          uploadStatePercent: calcUploadPercent(newUploadState),
          completed: newUploadState === MAX_UPLOAD_STATE,
        }
        nextFileStatuses.set(path, newFileStatus)
        return { fileStatusesMap: nextFileStatuses }
      }),
    setDocErrorStatus: (path, errorMessage) =>
      set(({ fileStatusesMap: prevFileStatuses }) => {
        const nextFileStatuses = new Map(prevFileStatuses)
        const fileStatus = nextFileStatuses.get(path)
        if (!fileStatus) throw new Error(`No file status for ${path}`)

        const newFileStatus: RejectedFile = {
          ...fileStatus,
          reason: 'unknown',
          message: errorMessage,
          status: 'rejected',
        }
        nextFileStatuses.set(path, newFileStatus)
        return { fileStatusesMap: nextFileStatuses }
      }),
    setUploadingToDuplicateDoc: (path) =>
      set(({ fileStatusesMap: prevFileStatuses }) => {
        const nextFileStatuses = new Map(prevFileStatuses)
        const fileStatus = nextFileStatuses.get(path)
        if (!fileStatus) throw new Error(`No file status for ${path}`)
        if (fileStatus.status !== 'duplicate') return {}

        const newFileStatus: UploadingFile = {
          ...fileStatus,
          status: 'uploading',
          uploadState: 0,
          uploadStatePercent: 0,
        }
        nextFileStatuses.set(path, newFileStatus)
        return { fileStatusesMap: nextFileStatuses }
      }),
    setDuplicatesForUploadedFile: (sha256: string, cryptId: CryptId) =>
      set(({ fileStatusesMap: prevFileStatuses }) => {
        const newEntries = Array.from(prevFileStatuses.entries(), ([key, value]) => {
          // Make files that are a duplicate from the same batch upload have a reference to the uploaded PDF
          if (
            value.status === 'duplicate' &&
            !value.duplicateDocCryptId &&
            value.sha256 === sha256
          ) {
            const newValue = { ...value, duplicateDocCryptId: cryptId }
            return [key, newValue] as const
          }

          return [key, value] as const
        })
        return { fileStatusesMap: new Map(newEntries) }
      }),
  }))
)

export const onboardingModalSteps = [
  'onboarding',
  'onboarding-lawyer',
  'onboarding-integrations',
  'onboarding-documents',
  'onboarding-done',
] as const
export type OnboardingModalStep = (typeof onboardingModalSteps)[number]

const multidocUploadSteps = ['doc-drop', 'doc-status'] as const

const multiDocModalState = [...onboardingModalSteps, ...multidocUploadSteps, 'closed'] as const

export const ZMultidocModalState = z.enum(multiDocModalState)
type ZMultiDocModalState = z.infer<typeof ZMultidocModalState>

export type MultiDocModalStateOpened = Exclude<ZMultiDocModalState, 'closed'>

interface MultiDocModalStore {
  isOnboardingShown: boolean
  stateAtOpen: MultiDocModalStateOpened
  state: ZMultiDocModalState
  setOnboardingShown: (isOnboardingShown: boolean) => void
  updateState: (state: MultiDocModalStateOpened) => void
  openModal: (state: MultiDocModalStateOpened) => void
  closeModal: () => void
}

const updateRouterMultiDocQuery = (state: ZMultiDocModalState) => {
  Router.ready(async () => {
    const { multidoc: _, ...queryRest } = Router.query
    await Router.replace(
      {
        pathname: Router.pathname,
        query:
          state !== 'closed'
            ? { ...queryRest, [multiDocModalStateQueryParameter]: state }
            : queryRest,
      },
      undefined,
      { shallow: true }
    )
  })
}

export const useMultiDocModalStore = create<MultiDocModalStore>((set) => ({
  isOnboardingShown: false,
  stateAtOpen: 'doc-drop',
  state: 'closed',
  openModal: (state: MultiDocModalStateOpened) => {
    set({ stateAtOpen: state, state })
    updateRouterMultiDocQuery(state)
  },
  updateState: (state: MultiDocModalStateOpened) => {
    set({ state })
    // When modal state is transited to 'doc-status', reset the stateAtOpen to
    // prevent showing `Onboarding Tutorial Dismissed` again, on next upload.
    if (state === 'doc-status') {
      set({ stateAtOpen: 'doc-drop' })
    }
    updateRouterMultiDocQuery(state)
  },
  setOnboardingShown: (isOnboardingShown: boolean) => {
    set({ isOnboardingShown })
  },
  closeModal: () => {
    set({ state: 'closed' })
    updateRouterMultiDocQuery('closed')
  },
}))
