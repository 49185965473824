import { Button, Group, Stack, Title, Tooltip } from '@mantine/core'
import { IconChevronLeft } from '@tabler/icons-react'
import Router from 'next/router'
import React from 'react'
import {
  onboardingModalSteps,
  useMultiDocModalStore,
} from '~/client/components/multi-doc-drop/state'
import { useCorpCryptId } from '~/client/lib/hooks'
import { multiDocModalStateQueryParameter } from '~/common/query-parameter-key'

export const useNavigateToOnboardingStep = (): ((stepIndex: number) => Promise<void>) => {
  const { mkCurrentCorpRoute } = useCorpCryptId()
  return async (stepIndex: number) => {
    const step = onboardingModalSteps[stepIndex] ?? 'onboarding'
    await Router.push(
      mkCurrentCorpRoute('dashboard', `?${multiDocModalStateQueryParameter}=${step}`)
    )
  }
}

export const useNavigateNextOnboardingStep = (): (() => Promise<void>) => {
  const modalState = useMultiDocModalStore((s) => s.state)
  const closeModal = useMultiDocModalStore((s) => s.closeModal)
  if (!onboardingModalSteps.includes(modalState)) {
    throw new Error(`Invalid onboarding modal state: ${modalState}`)
  }
  const navigateToOnboardingStep = useNavigateToOnboardingStep()
  return async () => {
    const currentStepIndex = onboardingModalSteps.indexOf(modalState)
    const isLastStep = currentStepIndex === onboardingModalSteps.length - 1

    if (isLastStep) {
      closeModal()
      return
    }
    await navigateToOnboardingStep(currentStepIndex + 1)
  }
}

const useNavigatePreviousOnboardingStep = (): (() => Promise<void>) => {
  const modalState = useMultiDocModalStore((s) => s.state)
  if (!onboardingModalSteps.includes(modalState)) {
    throw new Error(`Invalid onboarding modal state: ${modalState}`)
  }
  const navigateToOnboardingStep = useNavigateToOnboardingStep()
  return async () => {
    const currentStepIndex = onboardingModalSteps.indexOf(modalState)
    if (currentStepIndex === 0) {
      return
    }
    await navigateToOnboardingStep(currentStepIndex - 1)
  }
}

const BackButton: React.FC = () => {
  const navigatePreviousOnboardingStep = useNavigatePreviousOnboardingStep()
  return (
    <Button
      variant='subtle'
      leftSection={<IconChevronLeft />}
      onClick={() => navigatePreviousOnboardingStep()}
    >
      Back
    </Button>
  )
}

export const StepContainer: React.FC<{
  title: string
  subtitle: string
  stepCompleted?: boolean
  extraLargeTitle?: boolean
  withBackButton?: boolean
  nextButtonLoading?: boolean
  disableNextButton?: boolean
  disableNextButtonTooltip?: string
  dataTestId?: string
}> = ({
  children,
  title,
  subtitle,
  stepCompleted,
  extraLargeTitle,
  withBackButton = true,
  nextButtonLoading,
  disableNextButton,
  disableNextButtonTooltip,
  dataTestId,
}) => {
  const navigateNextOnboardingStep = useNavigateNextOnboardingStep()
  const modalState = useMultiDocModalStore((s) => s.state)

  if (!onboardingModalSteps.includes(modalState)) {
    throw new Error(`Invalid onboarding modal state: ${modalState}`)
  }

  const isLastStep = onboardingModalSteps.indexOf(modalState) === onboardingModalSteps.length - 1

  const buttonCopy = (() => {
    if (!stepCompleted) {
      return 'Skip'
    }
    if (isLastStep) {
      return 'Done'
    }
    return 'Next'
  })()

  return (
    <Stack
      align='center'
      gap={0}
      mih='100%'
      w='100%'
      justify='space-between'
      data-testid={dataTestId}
    >
      <Stack flex='1' align='center' gap='xl' mih='100%' h='100%' w='100%' justify='center'>
        <Stack align='center' gap='xs' w='100%'>
          <Title order={1} c='primary' size={extraLargeTitle ? 48 : undefined}>
            {title}
          </Title>
          <Title order={3} c='dimmed' size={extraLargeTitle ? 32 : undefined}>
            {subtitle}
          </Title>
        </Stack>
        {children && (
          <Stack align='center' gap='xl' w='100%'>
            {children}
          </Stack>
        )}
      </Stack>
      <Group justify={withBackButton ? 'space-between' : 'end'} w='100%'>
        {withBackButton && <BackButton />}
        <Tooltip label={disableNextButtonTooltip} disabled={!disableNextButton}>
          <Button
            onClick={() => navigateNextOnboardingStep()}
            variant={stepCompleted ? 'filled' : undefined}
            size='md'
            disabled={disableNextButton}
            loading={nextButtonLoading}
            data-testid='onboarding-modal-next-button'
          >
            {buttonCopy}
          </Button>
        </Tooltip>
      </Group>
    </Stack>
  )
}
