import type { CorpRedFlagType } from '~/common/red-flags/corp'
import { corpRedFlagTypes } from '~/common/red-flags/corp'
import type { RelationRedFlagType } from '~/common/red-flags/relation'
import { relationRedFlagTypes } from '~/common/red-flags/relation'
import type {
  ZAllAugmentedCustomRedFlag,
  ZAugmentedRecordsRedFlag,
  ZAugmentedRedFlag,
} from '~/common/schema/red-flag'

export const isCorpRedFlagType = (
  redFlagType: ZAugmentedRedFlag['type']
): redFlagType is CorpRedFlagType => corpRedFlagTypes.includes(redFlagType)

export const isRelationRedFlagType = (
  redFlagType: ZAugmentedRedFlag['type']
): redFlagType is RelationRedFlagType =>
  relationRedFlagTypes.includes(redFlagType) || redFlagType === 'CUSTOM_RELATION'

export const isCustomRedFlag = (
  redFlag: ZAugmentedRedFlag
): redFlag is ZAllAugmentedCustomRedFlag => {
  const customTypes: ZAugmentedRedFlag['type'][] = ['CUSTOM', 'CUSTOM_DOC', 'CUSTOM_RELATION']

  return customTypes.includes(redFlag.type)
}

export const filterRecordsRedFlag = (redFlags: ZAugmentedRedFlag[]): ZAugmentedRecordsRedFlag[] =>
  redFlags.filter((redFlag): redFlag is ZAugmentedRecordsRedFlag =>
    [...corpRedFlagTypes, ...relationRedFlagTypes].includes(redFlag.type)
  )
