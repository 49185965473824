import type { CryptId } from '@cryptid-module'
import { Button, Group, Popover, Stack } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import type { GetInputProps } from '@mantine/form/lib/types'
import * as React from 'react'
import type { z } from 'zod'
import { useDocDetailViewStore } from '~/client/components/doc-detail/state'
import { zIndex } from '~/client/components/z-index'
import { useCryptId } from '~/client/lib/hooks/crypt-id'
import { theme } from '~/client/lib/theme'
import type { AugmentedMetadata, ZAugmentedDoc } from '~/common/schema'
import { DocRefSelect, HoverEdit } from './util'

type AutofillProps<T> =
  | {
      /**
       * Suggestions for the user to fill out forms.
       * If autofillData is defined, autofillDisplayFn must also be defined (enforced by types)
       * */
      autofillData: T[] | undefined // This is undefined while loading
      autofillDisplayFn: (autofill: T) => React.ReactNode
    }
  | {
      autofillData?: undefined
      autofillDisplayFn?: undefined
    }

interface InlineBaseInputProps<
  Values extends Record<string, unknown>,
  ThisMetadata extends AugmentedMetadata & Values,
> {
  initialValues?: Values
  /**
   * Validates and transforms the form values. Parsed by zodResolver. `undefined` disables it.
   *
   * Beware that we omit the error message in the UI.
   */
  schema?: z.ZodSchema<Values>
  docs?: ZAugmentedDoc[]
  /** If undefined, the Edit button isn't clickable; the Popover won't open. */
  update?: (p: { values: Values; metadata: ThisMetadata }) => void
  /** How the data is shown. */
  display: string | null | undefined
  /** Component shown in DropOver when Edit icon pressed. */
  dropOverContent: (p: { formGetInputProps: GetInputProps<Values> }) => React.ReactNode
  /**
   * How the dropOverContent should be arranged
   * @default 'horizontal'
   */
  dropOverDirection?: 'horizontal' | 'vertical'
  valueIsFilled: boolean
  /**
   * How the action icons are positioned
   * @default 'start'
   */
  alignment?: 'end' | 'start'
  noWrap?: boolean
}

export const InlineBaseInput = <
  Values extends Record<string, unknown>,
  ThisMetadata extends AugmentedMetadata & Values,
  TAutofill extends Values,
>({
  initialValues,
  docs,
  update,
  display,
  dropOverContent,
  schema,
  dropOverDirection = 'horizontal',
  alignment = 'start',
  autofillData,
  autofillDisplayFn,
  valueIsFilled,
  noWrap,
}: InlineBaseInputProps<Values, ThisMetadata> & AutofillProps<TAutofill>): JSX.Element => {
  const [openPopover, setOpenPopover] = React.useState(false)
  // If the input is displayed inside a doc modal, preselect that doc as source (if the field is empty)
  const preselectedCryptId = useDocDetailViewStore(
    (state) => state.docDetailViewState.prop?.doc?.cryptId
  )
  // We manage form state inside `form` except for the sourceId, which is managed as `id` and `setId`.
  const [cryptId, setCryptId] = useCryptId(
    initialValues?.type === 'document'
      ? (initialValues.sourceCryptId as CryptId)
      : preselectedCryptId
  )

  const form = useForm<Values>({
    initialValues,
    validate: schema ? zodResolver(schema) : undefined,
    transformValues: schema?.parse,
  })

  const autofills = React.useMemo(
    () => autofillData && autofillData.map(autofillDisplayFn),
    [autofillData, autofillDisplayFn]
  )

  const getMetadata = (item: Values): ThisMetadata => {
    if ('sourceCryptId' in item && docs) {
      if (!docs.find((doc) => doc.cryptId.equals(item.sourceCryptId as CryptId))) {
        throw new Error(
          `SourceId couldn't be found: ${(item.sourceCryptId as CryptId).idStr} not in [${docs.map(
            (doc) => doc.cryptId.idStr
          )}]`
        )
      }
      return {
        value: item.value,
        type: 'document',
        sourceCryptId: item.sourceCryptId,
      } as ThisMetadata
    }
    return { value: item.value, type: 'edited' } as ThisMetadata
  }

  const onSubmit = form.onSubmit((values) => {
    // add sourceId to values if a document was selected as source
    const _values = cryptId ? { ...values, sourceCryptId: cryptId } : values
    const metadata: ThisMetadata = getMetadata(_values)
    update?.({ metadata, values: _values })
    setOpenPopover(false)
  })

  const onAutofill = (index: number) => {
    if (!autofillData) return
    const item = autofillData[index]
    if (!item) return
    if ('sourceId' in item) setCryptId(item.sourceCryptId as CryptId)
    const metadata: ThisMetadata = getMetadata(item)
    update?.({ metadata, values: item })
  }

  const onClick = update
    ? () => {
        setOpenPopover(true)
        // Ensures the input has the latest data when opening the popover.
        // We do not use useEffect as it would be visually noticeable as it would take one render to take effect.
        if (initialValues) form.setValues(initialValues)
      }
    : undefined

  const formGetInputProps = React.useCallback(
    (path: keyof Values) => ({
      ...form.getInputProps(path),
      // Remove the error message but keep the red outline
      error: !!form.errors[path as keyof typeof form.errors], // Type cast required
    }),
    [form]
  )

  const content = React.useMemo(() => {
    const buttons = (
      <>
        <Button color='gray' variant='subtle' size='sm' onClick={() => setOpenPopover(false)}>
          Cancel
        </Button>
        <Button type='submit' size='sm' data-testid='inline-metadata-save'>
          Save
        </Button>
      </>
    )

    if (dropOverDirection === 'horizontal')
      return (
        <Group style={{ gap: 6 }}>
          {dropOverContent({
            formGetInputProps,
          })}
          {docs?.[0] && <DocRefSelect docs={docs} cryptId={cryptId} setCryptId={setCryptId} />}
          {buttons}
        </Group>
      )

    // Else is 'vertical'
    return (
      <Stack style={{ gap: 10 }}>
        {dropOverContent({
          formGetInputProps,
        })}
        {docs?.[0] && <DocRefSelect docs={docs} cryptId={cryptId} setCryptId={setCryptId} />}
        <Group style={{ gap: 6, justifyContent: 'end' }}>{buttons}</Group>
      </Stack>
    )
  }, [dropOverDirection, dropOverContent, formGetInputProps, docs, cryptId, setCryptId])

  return (
    <Popover
      opened={openPopover}
      onClose={() => {
        setOpenPopover(false)
        // Reset changed values that may not be saved, so on Popover reopen, it has the real values.
        form.reset()
      }}
      position='bottom'
      transitionProps={{
        transition: 'fade',
        duration: 150,
      }}
      // this ensures the date picker inside the popover does not look weird
      // see https://github.com/mantinedev/mantine/issues/2144#issuecomment-1322610076
      withinPortal
      zIndex={zIndex.popover}
    >
      <Popover.Target>
        <HoverEdit
          isEmpty={!display}
          onClick={onClick}
          autofills={autofills}
          onAutofill={onAutofill}
          valueIsFilled={valueIsFilled}
          alignment={alignment}
          noWrap={noWrap}
        >
          {display}
        </HoverEdit>
      </Popover.Target>
      <Popover.Dropdown style={{ padding: theme.spacing.sm }}>
        <form onSubmit={onSubmit}>{content}</form>
      </Popover.Dropdown>
    </Popover>
  )
}

export interface InlineCommonProps<T> {
  initialValue?: T
  update?: (value: T) => void
  docs: ZAugmentedDoc[]
  alignment?: 'end' | 'start'
  noWrap?: boolean
}
