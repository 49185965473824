export const zIndex = {
  mobileOverlay: 10,
  get navbar(): number {
    return this.mobileOverlay + 1
  },
  get headerDropdown(): number {
    return this.navbar + 1
  },

  chat: 1000,
  get linkDocsModal(): number {
    return this.chat + 1
  },
  get linkRelationModal(): number {
    return this.linkDocsModal + 1
  },
  get modal(): number {
    return this.linkRelationModal + 1
  },
  get tooltip(): number {
    return this.modal + 1
  },
  get popover(): number {
    return this.modal + 1
  },
  get promptConfirm(): number {
    return this.modal + 1
  },
  get notification(): number {
    return this.promptConfirm + 1
  },

  initialLoader: 2000,
}
