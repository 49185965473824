import {
  Box,
  Button,
  Center,
  Group,
  Notification,
  SegmentedControl,
  Select,
  Skeleton,
  Stack,
  Text,
} from '@mantine/core'
import { useInputState } from '@mantine/hooks'
import { IconFileAlert, IconPlus, IconSortAscending, IconSortDescending } from '@tabler/icons-react'
import pluralize from 'pluralize'
import React from 'react'
import { z } from 'zod'
import { DocCard } from '~/client/components/doc-card'
import { useDocDetailViewStore } from '~/client/components/doc-detail/state'
import { DocView } from '~/client/components/doc-view/doc-view'
import { LoadingErrorComp } from '~/client/components/util/error'
import { LoadMoreInfiniteQuery } from '~/client/components/util/load-more-infinite-query'
import { hooks, useCurrentCorpAuth } from '~/client/lib/hooks'
import { nextPageParamOpts } from '~/client/lib/hooks/query'
import { theme } from '~/client/lib/theme'
import { enhanceCount, enhanceDoc } from '~/common/enhance'
import { ZDocPaginationSortField } from '~/common/pagination'
import type { ZDocType } from '~/common/schema'

/**
 * Simple view of docs of type `docTypes`
 * Enable `disableNoDocs` to not display <NoDocs/> when no docs are present
 */
interface DocTypeViewProps {
  docTypes: ZDocType[]
  allowAllTypes?: boolean
  disableNoDocs?: boolean
  parentIndexList: number[]
}

interface SortField {
  label: string
  value: ZDocPaginationSortField
}
const ZDirection = z.literal(1).or(z.literal(-1))
type ZDirection = z.infer<typeof ZDirection>

const sortOptions: SortField[] = [
  {
    label: 'Time Created',
    value: '_id' as const,
  },
  {
    label: 'Title',
    value: 'title' as const,
  },
  {
    label: 'Party Name',
    value: 'party.name' as const,
  },
  {
    label: 'Date',
    value: 'dateImprecise.date' as const,
  },
  {
    label: 'Type',
    value: 'type' as const,
  },
].sort((a, b) => a.label.localeCompare(b.label))

// NB: value must be a string but our directions are `1 | -1`.  This component massages those distinctions.
const DirectionComp: React.FC<{ direction: ZDirection; setDirection: (_: ZDirection) => void }> = ({
  direction,
  setDirection,
}) => (
  <SegmentedControl
    value={direction.toString()}
    onChange={(value) => setDirection(ZDirection.parse(parseInt(value, 10)))}
    data={[
      {
        value: '1',
        label: (
          <Center>
            <IconSortAscending size={16} />
            <Box ml='xs'>Asc</Box>
          </Center>
        ),
      },
      {
        value: '-1',
        label: (
          <Center>
            <IconSortDescending size={16} />
            <Box ml='xs'>Desc</Box>
          </Center>
        ),
      },
    ]}
  />
)

const docTypeViewSelectData = sortOptions.map((option) => ({
  ...option,
  label: `Sort by ${option.label}`,
}))

export const DocTypeView: React.FC<DocTypeViewProps> = ({
  docTypes,
  allowAllTypes,
  disableNoDocs,
  parentIndexList,
}) => {
  const { data: auth } = useCurrentCorpAuth()
  const [sortField, setSortField] = useInputState<ZDocPaginationSortField>('_id')
  const [direction, setDirection] = useInputState<ZDirection>(-1)
  const openDocDetail = useDocDetailViewStore((state) => state.openModal)

  const limit = 10
  const queryProps = {
    types: docTypes,
    limit,
    direction,
    sortField,
  }
  const queryResult = hooks
    .trpc()
    .docs.byType.useInfiniteQueryWithCorp(queryProps, nextPageParamOpts())
  const { data } = queryResult

  const enhancedDocs = React.useMemo(() => {
    const docs = data?.pages.map((page) => page.data).flat() ?? []
    return docs.map((doc) => enhanceDoc(doc, parentIndexList))
  }, [data?.pages, parentIndexList])
  const totalCount = data?.pages[0]?.count ?? { count: 0, exceeds: false }

  return (
    <Stack py='xl' gap='xl' maw={theme.other.widths.md}>
      <Group justify='space-between'>
        <Group gap='md'>
          <Select
            data={docTypeViewSelectData}
            value={sortField}
            onChange={(value) => setSortField(ZDocPaginationSortField.parse(value))}
            searchable
          />
          <DirectionComp direction={direction} setDirection={setDirection} />
        </Group>
        <Button
          disabled={auth?.level === 'investor'}
          data-testid='doc-type-view-btn-add'
          onClick={() => openDocDetail({ allowedTypes: docTypes, allowAllTypes })}
          leftSection={<IconPlus />}
        >
          Add Document
        </Button>
      </Group>
      <LoadingErrorComp
        queryResult={queryResult}
        skeleton={
          <>
            <Skeleton visible w='fit-content'>
              <Text>Displaying XX of XX documents</Text>
            </Skeleton>
            <Stack gap='xl'>
              {Array.from({ length: limit }).map((_, key) => (
                <DocCard key={key} isLoading />
              ))}
            </Stack>
          </>
        }
      >
        <Text>
          Displaying {enhancedDocs.length} of{' '}
          <span data-testid='doctypeview-span-total-count'>
            {enhanceCount(totalCount)} {pluralize('documents', totalCount.count)}
          </span>
        </Text>

        <Stack gap='xl'>
          {!enhancedDocs.length && !disableNoDocs && (
            <Center mb='lg'>
              <Notification
                icon={<IconFileAlert />}
                title='No Documents Uploaded!'
                bg='white'
                styles={{
                  icon: {
                    width: 42,
                    height: 42,
                  },
                }}
                withCloseButton={false}
              >
                Upload a document.
              </Notification>
            </Center>
          )}
          {/* We use this for optimistic mutations, so we need to have the input to identify the query key */}
          <DocView allowedTypes={docTypes} docs={enhancedDocs} byTypeProps={queryProps} />
          <LoadMoreInfiniteQuery query={queryResult} />
        </Stack>
      </LoadingErrorComp>
    </Stack>
  )
}
