import { z } from 'zod'
import { AugmentedMetadataString } from '~/common/schema/metadata'
import { ZImpreciseDate } from '~/common/schema/util'

export const AugmentedDocString = Object.assign(z.string(), {
  display: (v: string | null | undefined) => AugmentedMetadataString.display(v),
})
export const AugmentedDocImpreciseDate = Object.assign(
  ZImpreciseDate.describe('AugmentedDocImpreciseDate'), // Copy the object to avoid mutating the original
  {
    display: (v: ZImpreciseDate | null | undefined) => ZImpreciseDate.display(v),
  }
)
export type AugmentedDocImpreciseDate = z.infer<typeof AugmentedDocImpreciseDate>
