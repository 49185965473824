import { z } from 'zod'
import { ZAugmentedIntegrationBase } from '~/common/schema/integration/integration-base'
import { ZAugmentedSourceBase } from '~/common/schema/integration/source-base'

export const ZDocusignAccountInfo = z.object({
  userId: z.string(),
  accountId: z.string(),
  accountName: z.string(),
  baseUri: z.string(),
  /** ID that is shown to users in Docusign app which they can use to identify
   * different accounts with the same name.
   * Field is optional because there are some legacy integrations in the
   * DB without it. New integrations will always have it */
  externalAccountId: z.string().optional(),
})
export type ZDocusignAccountInfo = z.infer<typeof ZDocusignAccountInfo>

export const ZDocusignIntegration = ZDocusignAccountInfo.extend({
  type: z.literal('docusign'),
})
export type ZDocusignIntegration = z.infer<typeof ZDocusignIntegration>

export const ZAugmentedDocusignIntegration = ZAugmentedIntegrationBase.merge(ZDocusignIntegration)
export type ZAugmentedDocusignIntegration = z.infer<typeof ZAugmentedDocusignIntegration>

export const ZDocusignSource = z.object({
  type: z.literal('docusign'),
  envelopeId: z.string(),
})
export type ZDocusignSource = z.infer<typeof ZDocusignSource>

export const ZAugmentedDocusignSource = ZAugmentedSourceBase.merge(ZDocusignSource)
export type ZAugmentedDocusignSource = z.infer<typeof ZAugmentedDocusignSource>

// not stored in DB, but needed on both client and server
export const ZOAuthAccessTokens = z.object({ accessToken: z.string(), refreshToken: z.string() })
export type ZOAuthAccessTokens = z.infer<typeof ZOAuthAccessTokens>
