import { Button, Group, Stack, ThemeIcon, Title } from '@mantine/core'
import { BaseModal } from '~/client/components/modals'

interface FormModalProps {
  title: string
  icon?: React.ReactNode
  onClose: () => void
  onSubmit: () => void
  submitButtonLabel: string
  opened: boolean
  isLoading: boolean
  isSubmitDisabled?: boolean
}

export const FormModal: React.FC<FormModalProps> = ({
  children,
  title,
  icon,
  onClose,
  onSubmit,
  submitButtonLabel,
  opened,
  isLoading,
  isSubmitDisabled = false,
}) => {
  return (
    <BaseModal
      size='lg'
      opened={opened}
      onClose={onClose}
      title={
        <Group p='md'>
          {icon ? <ThemeIcon>{icon}</ThemeIcon> : null}
          <Title order={3} lh={1}>
            {title}
          </Title>
        </Group>
      }
    >
      <form onSubmit={onSubmit}>
        <Stack p='md' pt={0} gap='lg'>
          {children}
          <Group justify='flex-end'>
            <Button color='gray' variant='subtle' onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={isSubmitDisabled}
              type='submit'
              data-testid='form-modal-submit'
              loading={isLoading}
            >
              {submitButtonLabel}
            </Button>
          </Group>
        </Stack>
      </form>
    </BaseModal>
  )
}
