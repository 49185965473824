import { Button, Center, Group, Loader, Menu, ScrollArea, Text } from '@mantine/core'
import { IconChevronDown, IconPlus } from '@tabler/icons-react'
import type { UseInfiniteQueryResult } from '@tanstack/react-query'
import type { PropsWithChildren } from 'react'
import React from 'react'
import { HeaderButton } from '~/client/components/layout/header-button'
import { NewCorpModal, useNewCorp } from '~/client/components/modals'
import { NextLinkOpt, type NextLinkOptProps } from '~/client/components/util'
import { LoadingErrorComp } from '~/client/components/util/error'
import { SearchInputWithLoading } from '~/client/components/util/search-input-with-loading'
import { zIndex } from '~/client/components/z-index'
import { hooks, useCorpCryptId } from '~/client/lib/hooks'
import { useCorpSearch } from '~/client/lib/hooks/search'
import { theme } from '~/client/lib/theme'
import type { Paginated, ZAugmentedQueryCorpsItem } from '~/common/schema'
import type { ZAugmentedCacheCorp } from '~/common/schema/user'

const useUpdateCorp = () => {
  const { switchCurrentCorp } = useCorpCryptId()
  const updateCacheCorps = hooks.trpc().user.updateCacheCorps.useMutation({
    // Skip invalidation which triggers unnecessary double-loading
    // of new company data when switching companies
    meta: { skipInvalidate: true },
  })

  const handleSelectCorp = React.useCallback(
    async (corp: ZAugmentedCacheCorp) => {
      switchCurrentCorp(corp.cryptId)
      await updateCacheCorps.mutateAsync({ corp })
    },
    [switchCurrentCorp, updateCacheCorps]
  )

  return {
    handleSelectCorp,
  }
}

interface CompaniesListProps {
  corps: ZAugmentedCacheCorp[]
}

const CorpsList: React.FC<CompaniesListProps> = (props) => {
  const { handleSelectCorp } = useUpdateCorp()
  const { corpCryptId } = useCorpCryptId()

  return (
    <>
      {props.corps.map((corp) => (
        <Menu.Item
          data-testid={`testid-${corp.name.value}`}
          key={corp.cryptId.idStr}
          disabled={corp.cryptId.equals(corpCryptId)}
          onClick={() => handleSelectCorp(corp)}
        >
          <Text inline data-testid='corp-result'>
            {corp.name.value}
          </Text>
        </Menu.Item>
      ))}
    </>
  )
}

const TextEnd: React.FC<PropsWithChildren<{}>> = (props) => {
  return (
    <Text m='sm' size='sm' ta='center'>
      {props.children}
    </Text>
  )
}

const TextEndButton: React.FC<PropsWithChildren<NextLinkOptProps>> = ({ children, ...props }) => {
  return (
    <NextLinkOpt display='block' {...props}>
      <TextEnd>{children}</TextEnd>
    </NextLinkOpt>
  )
}

type Query = UseInfiniteQueryResult<Paginated<ZAugmentedQueryCorpsItem> | undefined>

interface SearchResultsProps {
  searchResults: Query
}

const SearchResults: React.FC<SearchResultsProps> = ({ searchResults }) => {
  const pages = searchResults.data?.pages ?? []
  const corps = pages.flatMap((p) => p?.data ?? [])

  return (
    <>
      <Menu.Label>Search Results</Menu.Label>
      <LoadingErrorComp queryResult={searchResults} variant='tooltip'>
        <ScrollArea.Autosize type='auto' mah='50vh'>
          <CorpsList corps={corps} />
        </ScrollArea.Autosize>
        {corps.length === 0 && <TextEnd>No results</TextEnd>}
        {searchResults.hasNextPage && (
          <TextEndButton onClick={() => searchResults.fetchNextPage()}>Load more</TextEndButton>
        )}
      </LoadingErrorComp>
    </>
  )
}

const EndStatusComponent: React.FC<{
  isLoading: boolean
  corpsAmount: number
}> = ({ isLoading, corpsAmount }) => {
  if (isLoading)
    return (
      <Center>
        <Loader size='sm' />
      </Center>
    )
  if (corpsAmount === 0) return <TextEnd>No results</TextEnd>
  if (corpsAmount === 1) return null
  return <TextEndButton href='/'>See more</TextEndButton>
}

const RecentlyViewed: React.FC = () => {
  const recentlyCreatedCorpsQuery = hooks
    .trpc()
    .searchCorps.useQuery({ limit: 5, query: '', sortField: '_id', direction: -1 })
  const user = hooks.useCurrentUser()
  const cacheCorps = user.data?.cacheCorps || []
  const cacheCryptIds = new Set(cacheCorps.map((corp) => corp.cryptId.idStr))
  const recentlyCreatedCorps =
    recentlyCreatedCorpsQuery.data?.data.filter((corp) => !cacheCryptIds.has(corp.cryptId.idStr)) ||
    []
  const corps = [...cacheCorps, ...recentlyCreatedCorps]

  return (
    <>
      <Menu.Label>Recent</Menu.Label>
      {corps.length > 0 && <CorpsList corps={corps} />}
      <EndStatusComponent
        isLoading={recentlyCreatedCorpsQuery.isFetching}
        corpsAmount={corps.length}
      />
    </>
  )
}

const PAGE_LIMIT = 5

export const CompanyMenu: React.FC = () => {
  const { searchResults, query, setQuery } = useCorpSearch({
    queryWithEmptyString: false,
    limit: PAGE_LIMIT,
    sortField: 'name.value',
    direction: 1,
  })

  const currentCorpResult = hooks.useCurrentCorp()
  const { openModal, ...newCorpProps } = useNewCorp()

  return (
    <>
      <Menu
        onClose={() => setQuery('')}
        position='bottom-start'
        shadow='lg'
        trigger='click'
        withinPortal
        // high z-index to show above the navbar
        zIndex={zIndex.headerDropdown}
        offset={0}
      >
        <Menu.Target>
          <HeaderButton data-testid='corp-menu'>
            <Group data-testid='current-corp' gap='xs' wrap='nowrap'>
              <IconChevronDown size={14} />
              <LoadingErrorComp queryResult={currentCorpResult} variant='tooltip' loaderSize='sm'>
                <Text maw={theme.other.widths.xs} style={{ whiteSpace: 'nowrap' }} truncate='end'>
                  {currentCorpResult.data?.name.value}
                </Text>
              </LoadingErrorComp>
            </Group>
          </HeaderButton>
        </Menu.Target>
        <Menu.Dropdown maw='100%' p={0}>
          <SearchInputWithLoading
            data-testid='search-corp'
            m='sm'
            defaultValue={query}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
            isFetching={searchResults.isFetching}
            placeholder='Search'
          />
          <Menu.Divider mx='xs' />
          {query ? <SearchResults searchResults={searchResults} /> : <RecentlyViewed />}
          <Menu.Divider mx='xs' />
          <Menu.Item component='div' closeMenuOnClick={false}>
            <Button
              size='sm'
              onClick={openModal}
              leftSection={<IconPlus />}
              w='100%'
              style={{
                alignSelf: 'center',
              }}
              data-testid='add-corp-button'
            >
              Add Corp
            </Button>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <NewCorpModal {...newCorpProps} />
    </>
  )
}
