import { Text, Tooltip } from '@mantine/core'
import { theme } from '~/client/lib/theme'

export const RelationTooltip: React.FC<{ relationDisplay?: string }> = ({
  relationDisplay,
  children,
}) => {
  if (!relationDisplay) return <>{children}</>

  return (
    <Tooltip label={relationDisplay} multiline maw={theme.other.widths.xs}>
      <Text
        display='inline'
        td='underline'
        style={{
          textDecorationStyle: 'dotted',
          textDecorationColor: theme.colors.primary[6],
        }}
      >
        {children}
      </Text>
    </Tooltip>
  )
}
