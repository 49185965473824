import { z } from 'zod'
import { AugmentedMetadataString } from '~/common/schema/metadata'
import { ZCryptId, ZEmailLowerCase } from './util'

export const ZAugmentedCacheCorp = z.object({
  name: AugmentedMetadataString.pickValueSchema,
  cryptId: ZCryptId,
})

export interface ZAugmentedCacheCorp extends z.infer<typeof ZAugmentedCacheCorp> {}

const ZUserNotificationPreferences = z.discriminatedUnion('type', [
  z.object({ type: z.literal('enabled') }),
  z.object({ type: z.literal('disabled') }),
])

export const ZUser = z.object({
  userEmail: ZEmailLowerCase,
  agreementVersion: z.number(),
  notification: ZUserNotificationPreferences,
})
export interface ZUser extends z.infer<typeof ZUser> {}

export const ZAugmentedUser = Object.assign(
  ZUser.extend({
    cacheCorps: z.array(ZAugmentedCacheCorp).optional(),
    superAdmin: z.boolean(),
  }),
  {
    displayFn: (name: string | undefined, email: string): string =>
      name ? `${name} (${email})` : email,
  }
)

export interface ZAugmentedUser extends z.infer<typeof ZAugmentedUser> {}
