import type { LayoutTextOptions, PDFPage, TextPosition } from '@cantoo/pdf-lib'
import { fetchRetry } from '~/common/util'

interface IndexText {
  text: string
  indentation: number
}

export interface GenerateIndexPdfProps {
  corpName?: string
  files: {
    path: string
    isDirectory: boolean
  }[]
}

export const generateIndexTxt = (files: GenerateIndexPdfProps['files']): IndexText[] => {
  // sort the files by their path, with numbers sorted numerically not alphabetically; e.g. 7 < 10
  const sortedFiles = files.sort((a, b) =>
    new Intl.Collator('us', { numeric: true }).compare(a.path, b.path)
  )

  const txtContent = sortedFiles.map(({ path, isDirectory }) => {
    // if the file ends in / remove it. This makes sure to display folders accurately
    const fileWithoutTrailingSlash = path.endsWith('/') ? path.slice(0, -1) : path
    const pathList = fileWithoutTrailingSlash.split('/')
    const fileName = pathList.at(-1) ?? ''
    return {
      text: isDirectory ? fileName : `• ${fileName}`,
      indentation: pathList.length - 1,
    }
  })
  return txtContent
}

/**
 * PDF-lib uses points as unit of measure
 * 1 inch = 72 points
 * For more info: https://en.wikipedia.org/wiki/Point_(typography)
 * @param inches
 */
const inchesToPoints = (inches: number) => inches * 72

const loadIndexCover = () => fetchRetry('/index-cover.pdf').then((res) => res.arrayBuffer())

export const generateIndexPdf = async ({
  files,
  corpName,
}: GenerateIndexPdfProps): Promise<Uint8Array> => {
  const [
    coverBytes,
    {
      PDFDocument,
      PageSizes,
      StandardFonts,
      layoutMultilineText,
      TextAlignment,
      layoutSinglelineText,
    },
  ] = await Promise.all([loadIndexCover(), import('@cantoo/pdf-lib')])

  // Build index by appending pages to cover
  const pdf = await PDFDocument.load(coverBytes)
  const lines = generateIndexTxt(files)

  const margin = inchesToPoints(0.25)
  const indentationSize = inchesToPoints(0.25)
  const [pageWidth, pageHeight] = PageSizes.A4
  const maxTextWidth = pageWidth - margin * 2
  const font = pdf.embedStandardFont(StandardFonts.TimesRoman)
  const textFontSize = 18
  const lineSpacing = 3
  const textBounds = {
    width: maxTextWidth,
    // Since we assign x and y to handle page splitting and margins, these 2 values don't matter
    x: 0,
    y: 0,
    // Consider we have infinite height for the text
    height: 0,
  }

  const mkTextLineDrawProps = (textInfo: IndexText): LayoutTextOptions => ({
    font,
    fontSize: textFontSize - 2 * textInfo.indentation,
    bounds: {
      ...textBounds,
      y: 0,
      x: textInfo.indentation * indentationSize + margin,
      width: maxTextWidth - textInfo.indentation * indentationSize,
    },
    alignment: TextAlignment.Left,
  })

  /**
   * When drawing with PDF-lib, (x,y) specify the element's bottom-left anchor (elements are drawn upwards). Also, y=0
   * is the bottom of the page. This format is a bit difficult when writing sequential lines.
   * @param yFromTop The y component of a top-left anchor with y=0 being the top of the page
   * @param elementHeight
   */
  const convertYFromTop = (yFromTop: number, elementHeight: number) =>
    pageHeight - yFromTop - elementHeight

  const drawSubLines = (
    page: PDFPage,
    subLines: TextPosition[],
    { initialHeight, fontSize }: { initialHeight: number; fontSize: number }
  ) => {
    let currentHeight = initialHeight
    subLines.forEach((subLine) => {
      page.drawText(subLine.text, {
        font,
        x: subLine.x + margin,
        y: convertYFromTop(currentHeight, subLine.height),
        maxWidth: maxTextWidth,
        size: fontSize,
      })
      currentHeight += subLine.height + lineSpacing
    })
  }

  const drawHeaderAndFooter = (page: PDFPage, pageIndex: number) => {
    const headerFooterSize = 14
    const smallMargin = inchesToPoints(0.25)
    const numberLayout = layoutSinglelineText(pageIndex.toString(), {
      font,
      fontSize: headerFooterSize,
      alignment: TextAlignment.Center,
      bounds: textBounds,
    })
    page.drawText(numberLayout.line.text, {
      font,
      x: numberLayout.line.x + margin,
      y: smallMargin, // Not converting because we want to use a bottom anchor (so the element never invades the margin)
      maxWidth: maxTextWidth,
      size: headerFooterSize,
    })

    const title = corpName ? `${corpName} - Aerial Index` : `Aerial Index`
    const titleLayout = layoutMultilineText(title, {
      font,
      fontSize: headerFooterSize,
      alignment: TextAlignment.Center,
      bounds: textBounds,
    })
    drawSubLines(page, titleLayout.lines, {
      initialHeight: smallMargin,
      fontSize: headerFooterSize,
    })
    return {
      titleLayout,
      numberLayout,
    }
  }

  let currentHeight = margin
  let page = pdf.addPage([pageWidth, pageHeight])
  const { titleLayout: firstPageTitleLayout } = drawHeaderAndFooter(page, 1)
  currentHeight += firstPageTitleLayout.bounds.height

  lines.forEach((line) => {
    const textLineDrawProps = mkTextLineDrawProps(line)
    // This method handles wrapping long lines, but does not split it into pages
    // https://github.com/Hopding/pdf-lib/issues/72
    const layout = layoutMultilineText(line.text, textLineDrawProps)

    // Check if the current height plus the new element's height is over one page long.
    // If it is, we add a new page and start over.
    if (currentHeight + layout.bounds.height > pageHeight - margin) {
      page = pdf.addPage([pageWidth, pageHeight])
      const { titleLayout } = drawHeaderAndFooter(page, pdf.getPageCount() - 1)
      currentHeight = margin + titleLayout.bounds.height
    }

    drawSubLines(page, layout.lines, {
      initialHeight: currentHeight,
      fontSize: textLineDrawProps.fontSize ?? textFontSize,
    })

    currentHeight += layout.bounds.height
  })

  return pdf.save()
}
