import { useIsMutating } from '@tanstack/react-query'
import { getQueryKey } from '@trpc/react-query'
import { z } from 'zod'
import { trpc } from '~/client/lib/trpc'
import { ZCryptId } from '~/common/schema'

const ZObjectCryptId = z.object({ cryptId: ZCryptId })

export const useIsIntegrationFetchingDocuments = (cryptId: ZCryptId): boolean => {
  // TODO: When we upgrade to react query 5, we might be able to make this
  // simpler with something like https://tanstack.com/query/v5/docs/react/reference/useMutationState
  const mutationCount = useIsMutating({
    mutationKey: getQueryKey(trpc.integration.firstTimeQueryAndUpload),
    predicate: (context) => {
      const result = ZObjectCryptId.safeParse(context.state.variables)
      if (!result.success) return false
      return result.data.cryptId.idStr === cryptId.idStr
    },
  })
  return mutationCount > 0
}
export type UseIsIntegrationFetchingDocuments = typeof useIsIntegrationFetchingDocuments
