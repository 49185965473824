import type { ComboboxParsedItem, MantineStyleProps } from '@mantine/core'
import { Group, Loader, Pagination as MantinePagination, Select, Text } from '@mantine/core'
import type { UseQueryResult } from '@tanstack/react-query'
import React from 'react'
import type { UsePagination } from '~/client/lib/hooks'
import { theme } from '~/client/lib/theme'
import { enhanceCount } from '~/common/enhance'
import type { Paginated } from '~/common/schema'

export type PageSize = 10 | 20 | 50

const pageSizes: PageSize[] = [10, 20, 50]

interface PaginationProps
  extends Pick<
    UsePagination,
    'activePage' | 'totalPages' | 'totalItems' | 'pageSize' | 'setPageSize' | 'setActivePage'
  > {
  styles?: MantineStyleProps
  hideSizeSelector?: boolean
  queryResult:
    | Pick<UseQueryResult<Paginated<unknown>>, 'data' | 'isFetching' | 'isLoading'>
    | undefined
  zIndex?: number
}

const PaginationRangeDisplay: React.FC<
  Pick<PaginationProps, 'activePage' | 'pageSize' | 'totalItems' | 'queryResult'>
> = ({ activePage, pageSize, totalItems, queryResult }) => {
  const currentPageCount = queryResult?.data?.data.length ?? 0
  const rangeMin = activePage * pageSize + 1
  const rangeMax = activePage * pageSize + currentPageCount

  if (totalItems.count === 0) return null
  return (
    <Text>
      {rangeMin}-{rangeMax} of {enhanceCount(totalItems)}
    </Text>
  )
}

const pageSizeOptions: ComboboxParsedItem[] = pageSizes.map((numRows) => ({
  value: `${numRows}`,
  label: `${numRows} rows`,
}))

const calculateTotalPagesWithNumberExceed = ({
  activePage,
  totalPages,
  queryResult,
}: Pick<PaginationProps, 'activePage' | 'totalPages' | 'queryResult'>) => {
  const currentPage = activePage + 1
  const count = queryResult?.data?.count ?? { count: 0, exceeds: false }

  // add one more page if count exceeds limit and there are more items
  if (count.exceeds && currentPage >= totalPages) {
    return queryResult?.data?.nextCursor ? currentPage + 1 : currentPage
  }

  return totalPages
}

export const Pagination: React.FC<PaginationProps> = ({
  pageSize,
  setPageSize,
  setActivePage,
  totalPages,
  totalItems,
  activePage,
  styles,
  queryResult,
  hideSizeSelector,
  zIndex,
}) => {
  const onPageSizeChange = (newValue: string | null) => {
    if (newValue) {
      setPageSize(parseInt(newValue, 10) as PageSize)
      setActivePage(0)
    }
  }

  return (
    <Group style={{ gap: theme.spacing.md }} {...styles}>
      {!hideSizeSelector && (
        <Select
          selectFirstOptionOnChange
          value={`${pageSize}`}
          onChange={onPageSizeChange}
          data={pageSizeOptions}
          // https://mantine.dev/core/select/#change-z-index
          comboboxProps={{ zIndex }}
        />
      )}
      {queryResult?.isLoading || queryResult?.isFetching ? (
        <Loader size='sm' mx='sm' />
      ) : (
        <PaginationRangeDisplay
          totalItems={totalItems}
          pageSize={pageSize}
          activePage={activePage}
          queryResult={queryResult}
        />
      )}
      <MantinePagination
        value={activePage + 1}
        onChange={(page) => setActivePage(page - 1)}
        total={calculateTotalPagesWithNumberExceed({ queryResult, activePage, totalPages })}
      />
    </Group>
  )
}
