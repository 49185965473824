import { Accordion, Group, Loader, ScrollArea, Stack, Text, ThemeIcon } from '@mantine/core'
import { IconAlertTriangle, IconCircleCheck, IconX } from '@tabler/icons-react'
import Router from 'next/router'
import React from 'react'
import { useMultiDocModalStore, useMultiDocStore } from '~/client/components/multi-doc-drop/state'
import { NextLinkOpt } from '~/client/components/util/link'
import { useCorpCryptId } from '~/client/lib/hooks'
import { theme } from '~/client/lib/theme'
import type { UploadResultButtonsProps } from './upload-result-buttons'
import { UploadResultButtons } from './upload-result-buttons'
import { UploadedFileListItem } from './upload-result-item'

const HeadlineFrame: React.FC<{
  icon: React.ReactNode
  title: string
  subtitle: React.ReactNode
}> = ({ icon, subtitle, title }) => {
  return (
    <Group gap='xl'>
      {icon}
      <Stack gap='sm'>
        <Text size='xl' inline>
          {title}
        </Text>
        <Text
          size='sm'
          c='dimmed'
          inline
          data-testid='multi-doc-uploaded-text'
          maw={theme.other.widths.sm}
        >
          {subtitle}
        </Text>
      </Stack>
    </Group>
  )
}

const FilesUploadedHeadline: React.FC = () => {
  const globalUploadStatus = useMultiDocStore((state) => state.globalUploadStatus)
  const closeModal = useMultiDocModalStore((state) => state.closeModal)
  const { mkCurrentCorpRoute } = useCorpCryptId()

  switch (globalUploadStatus) {
    case 'uploading':
      return (
        <HeadlineFrame
          icon={<Loader size={64} />}
          title='Uploading documents'
          subtitle="We're working on it ..."
        />
      )
    case 'successful':
      return (
        <HeadlineFrame
          icon={
            <ThemeIcon color='go' display='contents'>
              <IconCircleCheck size={64} />
            </ThemeIcon>
          }
          title='You are done.  We got it from here!'
          subtitle={
            <Text size='sm'>
              Your documents are uploaded and being processed by Aerial! They will soon be sorted
              and stored. In the mean time{' '}
              <NextLinkOpt
                onClick={async () => {
                  closeModal()
                  await Router.push(mkCurrentCorpRoute('processing'))
                }}
              >
                you can access them here
              </NextLinkOpt>
              .
            </Text>
          }
        />
      )
    case 'withErrors':
      return (
        <HeadlineFrame
          icon={
            <ThemeIcon color='urgent' display='contents'>
              <IconAlertTriangle size={64} />
            </ThemeIcon>
          }
          title='Some documents were not uploaded'
          subtitle={
            <NextLinkOpt href={mkCurrentCorpRoute('processing')}>
              Aerial is processing the ones that did.
            </NextLinkOpt>
          }
        />
      )
    case 'onlyErrors':
      return (
        <HeadlineFrame
          icon={
            <ThemeIcon color='danger' display='contents'>
              <IconX size={64} />
            </ThemeIcon>
          }
          title='Documents not uploaded'
          subtitle='See below why no documents were uploaded'
        />
      )
  }
}

export const FilesUploaded: React.FC<UploadResultButtonsProps> = (props) => {
  const fileStatuses = useMultiDocStore((state) => state.fileStatuses)
  const docPaths = React.useMemo(() => fileStatuses.map((f) => f.path), [fileStatuses])

  return (
    <Stack justify='center' align='center' p='xs' gap='lg' h='100%'>
      <FilesUploadedHeadline />
      <ScrollArea w='100%'>
        <Accordion>
          {docPaths.map((path, index) => {
            return <UploadedFileListItem path={path} key={index} />
          })}
        </Accordion>
      </ScrollArea>
      <UploadResultButtons {...props} />
    </Stack>
  )
}
