interface ConfigMap {
  aliases: readonly string[]
  production: boolean
  gitCryptEnv: 'preview' | 'production'
}

export const STAGING_DOMAIN = 'app.staging.aerialops.io'
export const CANARY_DOMAIN = 'app.canary.aerialops.io'
/** Although there are other domains that are used for production, this is the main one. */
export const PRODUCTION_DOMAIN = 'app.aerialops.io'

/** Hosting the app under vercel.app lets us perform auth requests in feature
 * branches through this URL, and not get blocked by third party storage access. */
export const STAGING_VERCEL_APP_ALIAS = 'aerial-staging.vercel.app'

/** A map of branch names to their Vercel config.
 * `aliases` are the domains that the branch is deployed to.
 * `production` makes the deployment be a production deployment
 * `gitCryptEnv` defines the key that is used to decrypt the env files with
 *  git-crypt on the Vercel build step.
 */
const PER_BRANCH_CONFIG_MAP = new Map<string, ConfigMap>(
  Object.entries({
    staging: {
      aliases: [STAGING_DOMAIN, STAGING_VERCEL_APP_ALIAS],
      production: false,
      gitCryptEnv: 'preview',
    },
    canary: {
      aliases: [CANARY_DOMAIN],
      production: false,
      gitCryptEnv: 'production',
    },
    production: {
      aliases: [PRODUCTION_DOMAIN, 'app.production.aerialops.io', 'aerial-app.vercel.app'],
      production: true,
      gitCryptEnv: 'production',
    },
  })
)

const DEFAULT_CONFIG_MAP: ConfigMap = {
  aliases: [],
  production: false,
  gitCryptEnv: 'preview',
}

/** Returns the config for the given branch. If no config is found, the default config is returned */
export const getBranchConfig = (branch: string): ConfigMap => {
  return PER_BRANCH_CONFIG_MAP.get(branch) ?? DEFAULT_CONFIG_MAP
}

/** Returns the config for the given branch. If no config is found, an error is thrown */
export const getOrThrowBranchConfig = (branch: string): ConfigMap => {
  const config = PER_BRANCH_CONFIG_MAP.get(branch)
  if (!config) throw new Error(`No config found for branch ${branch}`)
  return config
}
