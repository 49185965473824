import {
  ActionIcon,
  type ActionIconProps,
  Box,
  Center,
  Group,
  Text,
  ThemeIcon,
  Tooltip,
} from '@mantine/core'
import type { MantineSpacing, MantineStyleProps } from '@mantine/core'
import { IconAlertOctagon } from '@tabler/icons-react'
import * as React from 'react'
import { theme } from '~/client/lib/theme'

interface NameEmailProps {
  name?: string
  email?: string
  inactive?: boolean
}

export const NameEmail: React.FC<NameEmailProps> = ({ name, email, inactive }) => {
  return (
    <>
      <Group style={{ gap: theme.spacing.xs }}>
        <Text truncate fw={500} c={inactive ? 'inactive' : undefined} maw='fit-content'>
          {name ?? ''}
        </Text>
      </Group>
      <Text truncate c={inactive ? 'inactive' : 'dimmed'} maw='fit-content'>
        {email ?? ''}
      </Text>
    </>
  )
}

export const FullScreenMsg: React.FC<{
  title: string
  icon?: React.ReactNode
  my?: MantineSpacing
}> = ({ title, icon, my = 128, children, ...props }) => {
  return (
    <Box my={my} {...props}>
      <Center mb='md'>{icon}</Center>
      <Text ta='center' mb='md' tt='uppercase' c='dimmed'>
        {title}
      </Text>
      <Center>{children}</Center>
    </Box>
  )
}

export const NoDataComp: React.FC<{ message?: string; variant?: 'fullscreen' | 'tooltip' }> = ({
  message,
  variant = 'fullscreen',
}) => {
  if (variant === 'tooltip')
    return (
      <Center>
        <Tooltip label={message ?? 'No Record Found'}>
          <ThemeIcon color='urgent'>
            <IconAlertOctagon />
          </ThemeIcon>
        </Tooltip>
      </Center>
    )

  return (
    <FullScreenMsg title={message ?? 'No Record Found'}>
      <ThemeIcon color='urgent'>
        <IconAlertOctagon />
      </ThemeIcon>
    </FullScreenMsg>
  )
}

interface LabelWithIndexProps {
  label: string
  indexList: number[]
  labelStyle?: MantineStyleProps
}

export const LabelWithIndex: React.FC<LabelWithIndexProps> = ({ label, indexList, labelStyle }) => {
  return (
    <Group align='start' style={{ gap: theme.spacing.xs, flexWrap: 'nowrap' }}>
      <Text w={20} {...labelStyle}>
        {indexList.join('.')}
      </Text>
      <Text {...labelStyle}>{label}</Text>
    </Group>
  )
}

interface HoverActionIconProps extends ActionIconProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const HoverActionIcon = React.forwardRef<HTMLButtonElement, HoverActionIconProps>(
  ({ ...props }, ref) => (
    <ActionIcon
      ref={ref}
      style={{
        ':hover': { color: theme.colors.primary[5] },
      }}
      color='gray'
      {...props}
    />
  )
)

const cssClass = 'nested-click-area'

export const nestedClickArea = {
  /** Should be passed to a component's `className` prop to make it ignore it's ancestor's click handler
   * The ancestor's handler has to be wrapped with `nestedClickArea.avoid`.
   */
  cssClass,
  /** Should wrap a components click handler to avoid some descendants in the DOM when handling the event
   * The descendants should have their `className` set to `nestedClickArea.cssClass`.
   */
  avoid:
    <E extends Element>(handler: React.MouseEventHandler<E>): React.MouseEventHandler<E> =>
    (event) => {
      if (event.target instanceof Element && event.target.closest(`.${cssClass}`)) return
      handler(event)
    },
}

/**
 * Hack to prevent click when trying to select text to copy https://stackoverflow.com/a/56802199
 */
export const ignoreSelectionInClickArea =
  <E extends Element>(handler: React.MouseEventHandler<E>): React.MouseEventHandler<E> =>
  (event) => {
    const selection = window.getSelection()?.toString()
    if (!selection || selection.length <= 0) {
      return handler(event)
    }
  }

/**
 * Combines `ignoreNestedClickArea` and `ignoreSelectionInClickArea`
 */
export const ignoreSelectionsAndNestedClicks =
  <E extends Element>(handler: React.MouseEventHandler<E>): React.MouseEventHandler<E> =>
  (event) => {
    nestedClickArea.avoid(ignoreSelectionInClickArea(handler))(event)
  }

export * from './breadcrumbs'
export * from './hook'
export * from './link'
export * from './tooltip'
