import { Text } from '@mantine/core'
import { showNotification, updateNotification } from '@mantine/notifications'
import React, { useEffect } from 'react'
import { hooks } from '~/client/lib/hooks'
import { NextLinkOpt } from './link'

const NotificationErrorMessage: React.FC = () => {
  const trpcContext = hooks.trpc().useContext()

  return (
    <Text size='sm'>
      Unable to connect to Server{' '}
      <NextLinkOpt onClick={() => trpcContext.client.public.ping.query()}>Retry</NextLinkOpt>
    </Text>
  )
}

export const useOfflineNotification = (): void => {
  const onlineHandler = React.useCallback(() => {
    updateNotification({
      id: 'network error',
      title: 'Success',
      message: 'Connection Restored',
      color: 'go',
      autoClose: 1000,
    })
  }, [])

  const offlineHandler = React.useCallback(() => {
    showNotification({
      id: 'network error',
      title: 'Error',
      message: <NotificationErrorMessage />,
      withCloseButton: false,
      color: 'danger',
      autoClose: false,
    })
  }, [])

  useEffect(() => {
    window.addEventListener('online', onlineHandler)
    window.addEventListener('offline', offlineHandler)

    return () => {
      window.removeEventListener('online', onlineHandler)
      window.removeEventListener('offline', offlineHandler)
    }
  }, [onlineHandler, offlineHandler])
}
