import type { ButtonProps } from '@mantine/core'
import { Button, ScrollArea, Stack, Title } from '@mantine/core'
import React from 'react'
import { ManageIntegrationComp } from '~/client/components/integration/manage-integration-comp'
import { useIntegrationModals } from '~/client/components/integration/modal-hooks'
import { BaseModal } from '~/client/components/modals'
import { displayIntegrationType } from '~/common/integration/util'
import type { ZIntegrationType } from '~/common/schema/integration'

export type ModalButtonStatus = 'initial' | 'loading' | 'done'

const buttonProps = (status: ModalButtonStatus): ButtonProps => {
  switch (status) {
    case 'initial': {
      return {
        variant: 'outline',
        color: 'gray',
      }
    }
    case 'loading': {
      return {
        variant: 'outline',
        color: 'gray',
        disabled: true,
      }
    }
    case 'done':
      return {}
  }
}

interface ManageIntegrationModalProps {
  /** To be used only on storybook */
  forceOpenedIntegration?: ZIntegrationType
}
export const ManageIntegrationModal: React.FC<ManageIntegrationModalProps> = ({
  forceOpenedIntegration,
}) => {
  const { openedIntegration: _openedIntegration, closeIntegrationModal } = useIntegrationModals()
  const openedIntegration = forceOpenedIntegration ?? _openedIntegration

  const [modalButtonStatus, setModalButtonStatus] = React.useState<ModalButtonStatus>('initial')

  return (
    <BaseModal
      title={
        <Title order={3} pl='sm'>
          Configure {openedIntegration && displayIntegrationType(openedIntegration)} Integrations
        </Title>
      }
      onClose={() => {
        closeIntegrationModal()
      }}
      opened={!!openedIntegration}
      centered={false}
      trapFocus={false}
    >
      <Stack gap='sm'>
        <ScrollArea.Autosize type='auto' mah='70vh'>
          {openedIntegration && (
            <ManageIntegrationComp
              type={openedIntegration}
              setModalButtonStatus={setModalButtonStatus}
            />
          )}
        </ScrollArea.Autosize>
        <Button
          style={{ alignSelf: 'flex-end' }}
          mr='md'
          onClick={() => closeIntegrationModal()}
          {...buttonProps(modalButtonStatus)}
          data-testid='docusign-modal-button'
        >
          {modalButtonStatus === 'done' ? 'Done' : 'Cancel'}
        </Button>
      </Stack>
    </BaseModal>
  )
}
