import { Box, Modal, ScrollArea } from '@mantine/core'
import type { ModalBaseCloseButtonProps, ModalProps } from '@mantine/core'
import React from 'react'
import { zIndex } from '~/client/components/z-index'

interface ModalCloseButtonWithTestIdProps extends ModalBaseCloseButtonProps {
  'data-testid': string
}

/**
 * Mantine's modal with default props.
 */
export const BaseModal: React.FC<ModalProps> = ({ children, title, ...props }) => {
  return (
    <Modal
      size='auto'
      overlayProps={{
        backgroundOpacity: 0.5,
        color: 'gray',
        blur: 3,
      }}
      closeButtonProps={
        {
          'aria-label': 'Close',
          'data-testid': 'close-modal-button',
        } as ModalCloseButtonWithTestIdProps
      }
      centered
      zIndex={zIndex.modal}
      // prevent focus on close buttons
      trapFocus={false}
      // Wrap title in a div to prevent invalid DOM nesting. Otherwise using a
      // header (e.g. h3) element for the title would result in an error printed
      // to console.
      title={title ? <Box> {title} </Box> : undefined}
      {...props}
    >
      {children}
    </Modal>
  )
}

/**
 * The modal version for uploading and editing docs.
 * In Mantine, modals are always rendered but
 * only displayed based on the user pushing an update.
 */
export const DocFrameModal: React.FC<ModalProps> = ({ children, ...props }) => {
  return (
    <BaseModal
      size={1200}
      scrollAreaComponent={ScrollArea.Autosize}
      {...props}
      styles={{
        body: { height: '75vh', display: 'flex', flexDirection: 'column' },
        title: { flex: 1 },
        ...props.styles,
      }}
    >
      {children}
    </BaseModal>
  )
}
