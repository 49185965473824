/**
 * Utility functions required for MultiSelectCreatable & OptionsDropdown component
 */
import {
  type ComboboxParsedItem,
  type ComboboxParsedItemGroup,
  isOptionsGroup,
} from '@mantine/core'

// Check if the data has empty items
// For each group type array element, check if every element.items is empty
// https://github.com/mantinedev/mantine/blob/00f03c412db02f3973058466d8f674f797e06149/src/mantine-core/src/components/Combobox/OptionsDropdown/is-empty-combobox-data.ts
export const isEmptyComboboxData = (data: ComboboxParsedItem[]): boolean => {
  if (data.length === 0) {
    return true
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const item of data) {
    if (!('group' in item)) {
      return false
    }
    if ((item as ComboboxParsedItemGroup).items.length > 0) {
      return false
    }
  }
  return true
}

interface FilterPickedTagsInput {
  data: ComboboxParsedItem[]
  value: string[]
}

/**
 * Filter only not-selected items from whole data
 * @param data - MultiSelect data
 * @param value - Currently selected values array
 * https://github.com/mantinedev/mantine/blob/00f03c412db02f3973058466d8f674f797e06149/src/mantine-core/src/components/MultiSelect/filter-picked-values.ts
 */
export const filterPickedValues = ({
  data,
  value,
}: FilterPickedTagsInput): ComboboxParsedItem[] => {
  const normalizedValue = value.map((item) => item.trim().toLowerCase())

  const filtered = data.reduce<ComboboxParsedItem[]>((acc, item) => {
    if (isOptionsGroup(item)) {
      acc.push({
        group: item.group,
        items: item.items.filter(
          (option) => normalizedValue.indexOf(option.value.toLowerCase().trim()) === -1
        ),
      })
    } else if (normalizedValue.indexOf(item.value.toLowerCase().trim()) === -1) {
      acc.push(item)
    }

    return acc
  }, [])

  return filtered
}

interface IsExactMatchInput {
  data: ComboboxParsedItem[]
  // Currently selected values for the MultiSelect
  value: string[]
  // Search input value
  search: string
}
/**
 * Find out if `search` exactly matches one of the selected values, or among the
 * remaining combobox data
 * @param param0 - IsExactMatchInput
 * @returns
 */
export const isExactMatch = ({ data, value, search }: IsExactMatchInput): boolean => {
  if (!search) {
    return false
  }
  const matchesData =
    data.findIndex((item) => {
      if (isOptionsGroup(item)) {
        return item.items.findIndex((i) => i.value.toLowerCase() === search.toLowerCase()) >= 0
      }
      return item.value.toLowerCase() === search.toLowerCase()
    }) >= 0
  const matchesValue = value.findIndex((v) => v.toLowerCase() === search.toLowerCase()) >= 0
  return matchesData || matchesValue
}

// https://github.com/mantinedev/mantine/blob/00f03c412db02f3973058466d8f674f797e06149/src/mantine-core/src/components/Combobox/OptionsDropdown/validate-options.ts
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateOptions = (options: any, valuesSet = new Set()): void => {
  // This casts options to unknown[]
  if (!Array.isArray(options)) {
    return
  }

  // The conditional check above narrows options to unknown[]
  // eslint-disable-next-line no-restricted-syntax, @typescript-eslint/no-explicit-any
  for (const option of options as any[]) {
    if (isOptionsGroup(option)) {
      validateOptions(option.items, valuesSet)
    } else {
      if (typeof option.value === 'undefined') {
        throw new Error('[@mantine/core] Each option must have value property')
      }

      if (typeof option.value !== 'string') {
        throw new Error(
          `[@mantine/core] Option value must be a string, other data formats are not supported, got ${typeof option.value}`
        )
      }

      if (valuesSet.has(option.value)) {
        throw new Error(
          `[@mantine/core] Duplicate options are not supported. Option with value "${option.value}" was provided more than once`
        )
      }

      valuesSet.add(option.value)
    }
  }
}
