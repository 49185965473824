import { Button, Card, Group, Radio, Stack, Text, Title } from '@mantine/core'
import React from 'react'
import { EnrollPhoneAuth } from '~/client/components/login/signup'
import { EnrollTotpAuth } from '~/client/components/login/totp-verifications'
import { AuthenticationFrame } from '~/client/components/login/util'
import { NextLinkOpt } from '~/client/components/util'
import { hooks } from '~/client/lib/hooks'

type Option = 'phone' | 'totp'

const MFAMethodCard: React.FC<{
  title: string
  description: string
  checked: boolean
  onClick: () => void
}> = ({ title, description, checked, onClick }) => {
  return (
    <Card shadow='md' p='md' radius='md' withBorder style={{ cursor: 'pointer' }} onClick={onClick}>
      {/* Set readOnly to prevent an error about no "onChange" handler. We handle changes on card clicks*/}
      <Radio checked={checked} label={<Title order={5}>{title}</Title>} pb='md' readOnly />
      <Text>{description}</Text>
    </Card>
  )
}

const SelectMFAOption: React.FC<{ onSelect: (option: Option) => void }> = ({ onSelect }) => {
  const [selected, setSelected] = React.useState<Option>('totp')
  const skipEnrollMFA = hooks.useAuthStore((state) => state.skipEnrollMFA)

  return (
    <AuthenticationFrame title='Two-Factor Auth'>
      <Title order={4}>Secure Client Data</Title>
      <Text>Please choose one of the following MFA methods</Text>
      <Stack>
        <MFAMethodCard
          title='Authenticator app (Recommended)'
          description='Use an authenticator app like Google Authenticator to generate secure one-time codes.'
          checked={selected === 'totp'}
          onClick={() => setSelected('totp')}
        />
        <MFAMethodCard
          title='Phone authentication'
          description='Receive a 6-digit verification code by text message to your phone to secure your login.'
          checked={selected === 'phone'}
          onClick={() => setSelected('phone')}
        />
      </Stack>
      <Group justify='space-between'>
        <NextLinkOpt size='sm' onClick={() => skipEnrollMFA()} data-testid='skip-MFA'>
          Skip this step
        </NextLinkOpt>
        <Button onClick={() => onSelect(selected)}>Next</Button>
      </Group>
    </AuthenticationFrame>
  )
}

export const EnrollMultiFactorAuth: React.FC = () => {
  const [selected, setSelected] = React.useState<Option>()
  if (!selected) return <SelectMFAOption onSelect={setSelected} />
  switch (selected) {
    case 'phone':
      return <EnrollPhoneAuth onBack={() => setSelected(undefined)} />
    case 'totp':
      return <EnrollTotpAuth onBack={() => setSelected(undefined)} />
  }
}
