import { Group, Table } from '@mantine/core'
import type { TableThProps } from '@mantine/core'

export const TableHeaderRightAligned: React.FC<TableThProps> = ({ children, ...props }) => {
  return (
    <Table.Th ta='end' pr={0} {...props}>
      <Group justify='end'>{children}</Group>
    </Table.Th>
  )
}
