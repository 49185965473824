import { z } from 'zod'
import { fromUTCDate } from '~/common/date-utc'
import { formatNumber } from '~/common/util'
import { ZCryptId } from './util'

/**
 * Metadata
 */
const mkAugmentedMetadata = <T extends z.ZodTypeAny, U extends Record<string, unknown> = {}>(
  value: T,
  display: (_: z.infer<T> | null | undefined) => string | null | undefined,
  additionalData: U = {} as U
) => {
  const nullableValue = value.nullable()

  const AugmentedMetadata = z.union([
    z.object({ value: nullableValue, type: z.literal('edited') }),
    z.object({
      value: nullableValue,
      type: z.literal('document'),
      sourceCryptId: ZCryptId,
    }),
  ])

  return Object.assign(AugmentedMetadata, {
    display,
    __type: 'Metadata',
    /** To be used by InlineXInput components. Converts possible undefined value to null. */
    pickValueSchema: z.object({ value: nullableValue.default(null) }),
    ...additionalData,
  })
}

// NB: time is stored in UTC but converted to local timezone for proper display
export const AugmentedMetadataDate = mkAugmentedMetadata(
  z.date(),
  (v) => fromUTCDate(v)?.toLocaleDateString()
)
export type AugmentedMetadataDate = z.infer<typeof AugmentedMetadataDate>

export const AugmentedMetadataNumber = mkAugmentedMetadata(z.number(), (v) => formatNumber(v, 2), {
  precision: 2,
  minValue: 0,
})
export type AugmentedMetadataNumber = z.infer<typeof AugmentedMetadataNumber>

export const AugmentedMetadataInteger = mkAugmentedMetadata(z.number(), (v) => formatNumber(v, 0), {
  precision: 0,
})
export type AugmentedMetadataInteger = z.infer<typeof AugmentedMetadataInteger>

export const AugmentedMetadataPrice2 = mkAugmentedMetadata(
  z.number(),
  (v) =>
    // !v would return null if v is 0.
    v === null || v === undefined ? null : `$${formatNumber(v, 2)}`,
  { precision: 2, minValue: 0 }
)
export type AugmentedMetadataPrice2 = z.infer<typeof AugmentedMetadataPrice2>

export const AugmentedMetadataPrice4 = mkAugmentedMetadata(
  z.number(),
  (v) => (v === null || v === undefined ? null : `$${formatNumber(v, 4)}`),
  { precision: 4, minValue: 0 }
)
export type AugmentedMetadataPrice4 = z.infer<typeof AugmentedMetadataPrice4>

export const AugmentedMetadataPercentage = mkAugmentedMetadata(
  z.number(),
  (v) => (v === null || v === undefined ? null : `${formatNumber(v, 2)}%`),
  { precision: 2, minValue: 0, maxValue: 100 }
)
export type AugmentedMetadataPercentage = z.infer<typeof AugmentedMetadataPercentage>

export const AugmentedMetadataString = mkAugmentedMetadata(
  z
    .string()
    .trim()
    // Converts empty string to null
    .transform((v) => v || null),
  (v) => v
)
export type AugmentedMetadataString = z.infer<typeof AugmentedMetadataString>

const mkAugmentedMetadataBoolean = ({
  trueLabel,
  falseLabel,
}: {
  trueLabel: string
  falseLabel: string
}) =>
  mkAugmentedMetadata(z.boolean(), (v) => (v ? trueLabel : v === false ? falseLabel : null), {
    selectOptions: [trueLabel, falseLabel],
    parseValue: (value: string | null) =>
      value === trueLabel ? true : value === falseLabel ? false : null,
  })

export type AugmentedMetadataBoolean = z.infer<ReturnType<typeof mkAugmentedMetadataBoolean>>

export const AugmentedMetadataUsePool = mkAugmentedMetadataBoolean({
  trueLabel: 'Shares from Equity Plan',
  falseLabel: 'Shares not from Equity Plan',
})

export type AugmentedMetadataUsePool = z.infer<typeof AugmentedMetadataUsePool>

export const AugmentedMetadata = z.union([
  AugmentedMetadataDate,
  AugmentedMetadataNumber,
  AugmentedMetadataPrice2,
  AugmentedMetadataPrice4,
  AugmentedMetadataPercentage,
  AugmentedMetadataString,
  AugmentedMetadataUsePool,
  AugmentedMetadataInteger,
])

export type AugmentedMetadata = z.infer<typeof AugmentedMetadata>

/**
 * Create a sort function for Date, descending by default.
 */
export const mkSortDate =
  (ascending?: boolean) =>
  (a: Date, b: Date): number => {
    const order = a.getTime() - b.getTime()
    return ascending ? order : -order
  }

const _ZWarningAggregate = z.object({
  value: z.number(),
  warning: z.string().optional(),
})

export const missingValuesEquitySummaryWarning =
  'Some values were not defined and assumed to be 0 (check the Cap Table).'
export const exceedsMaximumSharesEquitySummaryError =
  'Data for this chart might be incorrect because it exceeds the amount of available shares (check the Cap Table).'

export interface ZWarningAggregate extends z.infer<typeof _ZWarningAggregate> {}

export const ZWarningAggregate = Object.assign(_ZWarningAggregate, {
  display: AugmentedMetadataNumber.display,
})
