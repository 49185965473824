import type { User } from 'firebase/auth'
import type { EventType, MonitoringData } from '~/client/components/monitoring/handlers'

export abstract class AnalyticsBase<TApi> {
  protected _apiPromise: Promise<TApi | undefined> | undefined
  protected abstract _load(): Promise<TApi | undefined>

  protected _getApi(): Promise<TApi | undefined> | undefined {
    if (!this._apiPromise) {
      this._apiPromise = this._load()
    }
    return this._apiPromise
  }

  // These methods don't return a Promise because we are not supposed to await them
  abstract identify(user: Pick<User, 'uid' | 'email' | 'displayName'> | undefined | null): void
  /** `from` should only be undefined if it's the first page view of the
   * session, and there is no previous URL.*/
  abstract page(from: string | undefined, to: string): void
  abstract track(data: Pick<MonitoringData, 'type' | 'extra'>): void

  trackEventSuccess = (eventType: EventType, data?: object): void =>
    this.track({ type: 'Event', extra: { eventType, success: true, ...data } })

  abstract register(data: Record<string, unknown>): void
  abstract unregister(key: string): void
}

export interface IAnalytics
  extends Pick<
    AnalyticsBase<unknown>,
    'identify' | 'page' | 'track' | 'trackEventSuccess' | 'register' | 'unregister'
  > {}

interface HostParams {
  host: string
  path: string
  queryParams: string
  hash: string
}

/** Store these values separately to avoid having to do regexp preprocessing
 * when analyzing the data */
export const hostParams = (): HostParams => ({
  host: window.location.host,
  path: window.location.pathname,
  queryParams: window.location.search,
  hash: window.location.hash,
  // We don't want to send each query parameter separately in all events, as
  // they are not useful except on page views.
})

/** Create an object with containing all query parameter key value pairs from a
 * given URL, to be used in analytics for easier report creation. This way, we
 * don't have to parse the strings when creating the report. */
export const mkQueryParamsObject = (
  pathOrUrl: string,
  keyPrefix: string
): Record<string, string> => {
  const { searchParams } = new URL(pathOrUrl, window.location.origin)
  const obj: Record<string, string> = {}
  searchParams.forEach((value, key) => {
    obj[`${keyPrefix}_${key}`] = value
  })
  return obj
}
