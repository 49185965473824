import type { BoxProps } from '@mantine/core'
import { Box, Image, type ImageProps } from '@mantine/core'
import { forwardRef, useEffect, useState } from 'react'

interface ImageWithPlaceholderProps extends BoxProps {
  placeholder?: React.ReactNode
  imageProps: Omit<ImageProps, 'fallbackSrc'>
}

export const ImageWithPlaceholder = forwardRef<HTMLImageElement, ImageWithPlaceholderProps>(
  ({ placeholder, imageProps, ...props }, ref) => {
    const [error, setError] = useState(!imageProps.src)
    const shouldShowPlaceholder = !!placeholder && error

    useEffect(() => setError(!imageProps.src), [imageProps.src])

    if (shouldShowPlaceholder) {
      return <Box {...props}>{placeholder}</Box>
    }
    return (
      <Box {...props}>
        <Image
          {...imageProps}
          ref={ref}
          onError={(event) => {
            imageProps.onError?.(event)
            setError(true)
          }}
        />
      </Box>
    )
  }
)
