import React from 'react'

export const useReloadCallback = (func: React.EffectCallback): void => {
  React.useEffect(() => {
    const isReload = performance
      .getEntriesByType('navigation')
      .some((entry) => (entry as unknown as { type: string }).type === 'reload')

    if (isReload) {
      return func()
    }
  }, [func])
}
