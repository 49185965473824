import { z } from 'zod'
import { ZPaymentMethod } from '~/common/schema/subscription/payment-method'

export const ZBillingInfoFreeTier = z.object({
  type: z.literal('free'),
  paymentMethod: ZPaymentMethod.optional(),
})
export type ZBillingInfoFreeTier = z.infer<typeof ZBillingInfoFreeTier>

export const ZBillingInfoPaidTier = z.object({
  type: z.literal('paid'),
  billingInterval: z.enum(['monthly', 'yearly']),
  nextPaymentDue: z.date(),
  /** The payment method can be missing if the user removed it after paying */
  paymentMethod: ZPaymentMethod.optional(),
})
export type ZBillingInfoPaidTier = z.infer<typeof ZBillingInfoPaidTier>

export const ZBillingInfo = z.union([ZBillingInfoFreeTier, ZBillingInfoPaidTier])
export type ZBillingInfo = z.infer<typeof ZBillingInfo>

// We cannot `.omit` or `.require` from a union, so we need to create the free and paid schemas
// and create a new union in order to have a schema with required "paymentMethod".
const ZBillingInfoFreeTierWithPaymentMethod = ZBillingInfoFreeTier.omit({
  paymentMethod: true,
}).extend({
  paymentMethod: ZPaymentMethod,
})
type ZBillingInfoFreeTierWithPaymentMethod = z.infer<typeof ZBillingInfoFreeTierWithPaymentMethod>

const ZBillingInfoPaidTierWithPaymentMethod = ZBillingInfoPaidTier.omit({
  paymentMethod: true,
}).extend({
  paymentMethod: ZPaymentMethod,
})
type ZBillingInfoPaidTierWithPaymentMethod = z.infer<typeof ZBillingInfoPaidTierWithPaymentMethod>

export const ZBillingInfoWithPaymentMethod = z.union([
  ZBillingInfoFreeTierWithPaymentMethod,
  ZBillingInfoPaidTierWithPaymentMethod,
])
export type ZBillingInfoWithPaymentMethod = z.infer<typeof ZBillingInfoWithPaymentMethod>
