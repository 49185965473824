import type { OperationLink } from '@trpc/client'
import type { AnyRouter } from '@trpc/server'
import { observable, tap } from '@trpc/server/observable'
import { openVersionUpdateModal } from '~/client/components/util/poll'
import { isOutdatedClientResponseHeaderKey } from '~/common/header-key'

/** TRPC does not provide typesafety for this, although the response is always
 * defined when using HTTPBatchLink or HTTPLink */
const getResponseHeaders = (context?: {}): Headers | undefined => {
  if (!context) return
  if (!('response' in context)) return
  if (!(typeof context.response === 'object')) return
  if (context.response === null) return
  if (!('headers' in context.response)) return
  if (!(context.response.headers instanceof Headers)) return
  return context.response.headers
}
/**
 * A Trpc Link that shows a notification when the client is detected as outdated
 * by the server.
 */
export const outdatedClientLink = <
  TRouter extends AnyRouter = AnyRouter,
>(): OperationLink<TRouter> => {
  return ({ op, next }) => {
    return observable((observer) => {
      // Do nothing pre-request

      const notificationIfOutdatedClient = (headers?: Headers) => {
        if (!headers) return
        const isOutdated =
          headers.get(isOutdatedClientResponseHeaderKey) === Boolean(true).toString()
        if (isOutdated) {
          openVersionUpdateModal()
        }
      }

      return next(op)
        .pipe(
          tap({
            next: (result) => {
              const headers = getResponseHeaders(result.context)
              notificationIfOutdatedClient(headers)
            },
            error: (result) => {
              const headers = getResponseHeaders(result.meta)
              notificationIfOutdatedClient(headers)
            },
          })
        )
        .subscribe(observer)
    })
  }
}
