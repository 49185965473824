import type { User } from 'firebase/auth'
import type { Mixpanel } from 'mixpanel-browser'
import type { MonitoringData } from '~/client/components/monitoring/handlers'
import { mkStaticPathFromUrl } from '~/client/components/monitoring/util'
import { zenvCommon } from '~/common/zenv-common'
import { AnalyticsBase, hostParams, mkQueryParamsObject } from './util'

export class MixpanelAnalytics extends AnalyticsBase<Mixpanel> {
  /** Keep track of whether we identified a user or not, because we only want to
   * `mixpanel.reset()` if we previously `mixpanel.identify` and not otherwise.
   * This allows us to keep the `$device_id` for users from the marketing website   */
  private hasAnyUserIdentified: boolean = false

  protected async _load(): Promise<Mixpanel | undefined> {
    const mixpanel = (await import('mixpanel-browser')).default

    // We need to set the cookie domain to `.aerialops.io` so we can track users
    // on the marketing website and the app with the same $device_id
    const cookieDomain = window.location.hostname.endsWith('aerialops.io')
      ? '.aerialops.io'
      : undefined

    mixpanel.init(zenvCommon.NEXT_PUBLIC_MIXPANEL_PROJECT_KEY, {
      // track_pageview does not work for client-side navigation, so we have to
      // do it manually
      track_pageview: false,
      debug: zenvCommon.NEXT_PUBLIC_MIXPANEL_ENABLE_DEBUG,
      cookie_domain: cookieDomain,
      cross_subdomain_cookie: true,
    })
    return mixpanel
  }

  identify = async (user: User | undefined | null): Promise<void> => {
    const mixpanel = await this._getApi()

    // Do not reset the $device_id if no user logged in
    if (!user && !this.hasAnyUserIdentified) return

    // Handle logouts
    if (!user) {
      mixpanel?.reset()
      return
    }

    // Handle logins + cases where the user is already logged in when the app loads

    this.hasAnyUserIdentified = true
    mixpanel?.identify(user.uid)

    // After identifying the user, we can set the user profile properties (which
    // are stored in a separate table than the events)
    // Values set here will be inserted in the row matching the `user.uid`

    // Override name and email on each sign-in in case they have changed
    mixpanel?.people.set({
      name: user.displayName,
      email: user.email,
    })
    // set_once will only set the property if it is not already present in the user profile
    mixpanel?.people.set_once({
      // We store the host in the mixpanel user profile table so we can identify each
      // user's environment (as the will have different user ids in each env)
      // The path will represent the page where the user first landed on the app
      // (as we are using `set_once` it will not change on further sign-ins)
      ...hostParams(),
    })
  }

  page = async (from: string, to: string): Promise<void> => {
    const mixpanel = await this._getApi()
    // We only care about the query params in the `to` page, as that is the page
    // the user is navigating to.
    mixpanel?.track_pageview({
      from,
      to,
      ...hostParams(),
      ...mkQueryParamsObject(to, 'to_qp'),
      to_path: mkStaticPathFromUrl(to),
    })
  }

  track = async (data: Pick<MonitoringData, 'type' | 'extra'>): Promise<void> => {
    const mixpanel = await this._getApi()
    mixpanel?.track(data.type, { ...data.extra, ...hostParams() })
  }

  register = async (data: Record<string, unknown>): Promise<void> => {
    const mixpanel = await this._getApi()
    mixpanel?.register(data)
  }

  unregister = async (key: string): Promise<void> => {
    const mixpanel = await this._getApi()
    mixpanel?.unregister(key)
  }
}
