import * as React from 'react'
import { z } from 'zod'
import { inlineInputWidth } from '~/client/components/util/inline/util'
import { SelectCreatable } from '~/client/components/util/select-creatable'
import { ZStateEnum } from '~/common/schema/relation'
import { InlineBaseInput, type InlineCommonProps } from './base'

const selectData = Object.values(ZStateEnum.enum)
export const InlineStateSelect: React.FC<Omit<InlineCommonProps<ZStateEnum>, 'docs'>> = ({
  update,
  initialValue,
  alignment,
  noWrap,
}) => {
  return (
    <InlineBaseInput
      initialValues={{ value: initialValue as ZStateEnum }}
      schema={z.object({ value: ZStateEnum })}
      update={update && (({ values }) => update(values.value))}
      display={initialValue}
      valueIsFilled={!!initialValue}
      alignment={alignment}
      noWrap={noWrap}
      dropOverContent={({ formGetInputProps }) => (
        <SelectCreatable
          creatable={false}
          clearable={false}
          selectFirstOptionOnChange
          {...formGetInputProps('value')}
          data-testid='state-select'
          items={selectData}
          width={inlineInputWidth}
        />
      )}
    />
  )
}
