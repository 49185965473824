import { ChartTooltip } from '@mantine/charts'
import type { DefaultMantineColor, MantineColorsTuple } from '@mantine/core'
import {
  Accordion,
  ActionIcon,
  Badge,
  Button,
  Checkbox,
  DEFAULT_THEME,
  Menu,
  Notification,
  Popover,
  Radio,
  Select,
  Switch,
  Table,
  Tabs,
  ThemeIcon,
  Tooltip,
  createTheme,
  lighten,
  mergeMantineTheme,
} from '@mantine/core'
import { Exo } from 'next/font/google'
import { zIndex } from '~/client/components/z-index'

type ExtendedCustomColors =
  | 'primary'
  | 'urgent'
  | 'go'
  | 'danger'
  | 'inactive'
  | DefaultMantineColor

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, MantineColorsTuple>
  }

  export interface MantineThemeOther {
    widths: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', number>
    navbarWidth: number
  }

  // So we can use these in openConfirmationModal. https://github.com/mantinedev/mantine/discussions/3179
  // I tried having it in types.d.ts but caused all imports from @mantine/core be invalid for somewhat reason.
  export interface ButtonProps {
    'data-testid'?: string
    'data-autofocus'?: boolean
  }
}
export const exoFont = Exo({ weight: ['400', '300'], subsets: ['latin'] })

const origTheme = DEFAULT_THEME
const themeOverrides = createTheme({
  headings: {
    fontFamily: exoFont.style.fontFamily,
  },
  fontFamily: exoFont.style.fontFamily,
  other: {
    // https://getbootstrap.com/docs/5.0/layout/breakpoints/#available-breakpoints
    widths: {
      xs: 200,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
    navbarWidth: 275,
  },
  colors: {
    primary: origTheme.colors.blue,
    urgent: origTheme.colors.yellow,
    go: origTheme.colors.green,
    danger: origTheme.colors.red,
    inactive: origTheme.colors.gray.map((c) => lighten(c, 0.5)) as unknown as MantineColorsTuple,
  },
  components: {
    Button: Button.extend({
      defaultProps: { color: 'primary', variant: 'outline' },
      styles: {
        section: {
          width: '18px',
          height: '18px',
        },
        label: {
          textTransform: 'uppercase',
        },
      },
    }),
    Select: Select.extend({
      defaultProps: {
        checkIconPosition: 'right',
      },
    }),
    Table: Table.extend({
      defaultProps: {
        horizontalSpacing: 'md',
        verticalSpacing: 'xs',
      },
      styles: {
        table: { color: 'inherit' },
        th: {
          fontSize: origTheme.fontSizes.md,
          paddingBottom: 4,
        },
        td: { fontSize: origTheme.fontSizes.md },
      },
    }),
    Badge: Badge.extend({
      defaultProps: {
        variant: 'outline',
      },
      styles: {
        label: {
          // Vertically center the text on the badges when zoomed in.
          // https://github.com/mantinedev/mantine/issues/5484
          lineHeight: 'normal',
        },
      },
    }),
    ActionIcon: ActionIcon.extend({
      defaultProps: {
        color: 'primary',
        variant: 'transparent',
      },
    }),
    ThemeIcon: ThemeIcon.extend({
      defaultProps: {
        variant: 'light',
      },
      styles: (_theme, props, _ctx) => ({
        root: { background: props.variant === 'light' ? 'none' : undefined },
      }),
    }),
    Tabs: Tabs.extend({
      styles: {
        tabLabel: {
          fontSize: origTheme.fontSizes.md,
          padding: origTheme.spacing.xs,
        },
      },
    }),
    Menu: Menu.extend({
      styles: {
        itemLabel: {
          color: 'inherit',
          fontSize: origTheme.fontSizes.md,
        },
        item: {
          color: 'inherit',
        },
      },
    }),
    Popover: Popover.extend({
      defaultProps: {
        shadow: 'sm',
      },
    }),
    Notification: Notification.extend({
      defaultProps: {
        withBorder: true,
      },
      styles: {
        root: {
          borderColor: origTheme.colors.gray[2],
        },
      },
    }),
    Tooltip: Tooltip.extend({
      defaultProps: {
        zIndex: zIndex.tooltip,
      },
    }),
    Radio: Radio.extend({
      styles: {
        radio: {
          cursor: 'pointer',
        },
      },
    }),
    Switch: Switch.extend({
      styles: {
        track: { cursor: 'pointer' },
      },
    }),
    Checkbox: Checkbox.extend({
      styles: {
        input: {
          cursor: 'pointer',
        },
      },
    }),
    ChartTooltip: ChartTooltip.extend({
      styles: {
        tooltipItemData: {
          display: 'flex',
          alignItems: 'center',
        },
      },
    }),
    Accordion: Accordion.extend({
      styles: { control: { color: 'inherit' } },
    }),
  },
})

export const theme = mergeMantineTheme(DEFAULT_THEME, themeOverrides)
